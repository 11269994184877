import { createSlice } from "@reduxjs/toolkit";
import { getBranches,  addNewBranch,updateBranch,  deleteBranch } from './thunk';

export const initialState = {
    branches: [],
    error: {},
};

const BranchSlice = createSlice({
    name: 'BranchSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getBranches.fulfilled, (state, action) => {
            if (action.payload && action.payload.success){
                state.branches = action.payload.data;
                state.isBranchCreated = false;
                state.isBranchSuccess = true;
            }
        });

        builder.addCase(getBranches.rejected, (state, action) => {
            if (action.payload && action.payload.error) {
                state.error = action.payload.error || null;
                state.isBranchCreated = false;
                state.isBranchSuccess = true;
            }
        });

        builder.addCase(addNewBranch.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.branches.push(action.payload.data);
                state.isBranchCreated = true;
            }else{
                state.error = action.payload.data || null;
                state.isBranchCreated = false;
            }
        });

        builder.addCase(addNewBranch.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isBranchCreated = false;
        });

        builder.addCase(updateBranch.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.branches = state.branches.map(branch =>
                    branch.id === action.payload.data.id
                        ? {...branch, ...action.payload.data}
                        : branch
                )
                state.isBranchCreated = true;
            }else{
            state.error = action.payload.data || null;
            state.isBranchCreated = false;
            }
        });
        builder.addCase(updateBranch.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(deleteBranch.fulfilled, (state, action) => {
        
            if(action.payload!=undefined){
            state.branches = state.branches.filter(
                branch => branch.id.toString() !== action.payload.branch.toString()
            );
            }
        });
        builder.addCase(deleteBranch.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


    }
});

export default BranchSlice.reducer;