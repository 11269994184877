import React, {useEffect, useState} from "react";
import UiContent from "../../Components/Common/UiContent";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import {isEmpty} from "lodash";
import Loader from "../../Components/Common/Loader";
import Select from "react-select";
import {Link, useParams} from "react-router-dom";
import AudioPlayer from "../../Components/Common/AudioPlayer";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {useFormik} from "formik";
import * as Yup from "yup";
import {api} from "../../config";

import {
    addQuestionTranslation as onAddQuestionTranslation,
    removeQuestionAsset as onRemoveQuestionAsset,
    getTranslationsForQuestion as onGetTranslationForQuestion, resetFlagsTrenslation,
} from "../../slices/translateQuestion/thunk";
import {Button, Card, CardBody, Col, Container, Form, FormFeedback, Input, Label, Row, Spinner,} from "reactstrap";
import VideoPlayer from "../../Components/Common/VideoPlayer";
import axios from "axios";
import Swal from "sweetalert2";


const QuestionTranslation = () => {
    const audioSource = api.IMAGE_URL;
    const getFlagPath = (countryCode) =>
        import(`../../assets/images/flags/${countryCode.toLowerCase()}.svg`);
    const images = require.context("../../assets/images/flags", true);

    const dispatch = useDispatch();
    const [language, setLanguage] = useState([]);
    const [questionData, setQuestionData] = useState([]);
    const [questionTranslationData, setQuestionTranslationData] = useState([]);
    const [translationData, setTranslationData] = useState([]);
    const [course, setCourse] = useState([]);
    const [topicArea, setTopicArea] = useState([]);
    const [selectedMulti, setselectedMulti] = useState([]);
    const [selectedMultiCourseId, setselectedMultiCourseId] = useState([]);
    const [showFormElements, setShowFormElements] = useState(false);
    const [isEditQuestion, setIsEditQuestion] = useState(false);
    const [defualtTranslation, setdefualtTranslation] = useState("");
    const id = useParams();
    const [transQtype, setTransQtype] = useState([]);
    const [transQAudio, setTransQAudio] = useState([]);
    const [transOptA, setTransOptA] = useState([]);
    const [transOptB, setTransOptB] = useState([]);
    const [transOptC, setTransOptC] = useState([]);

    const [transStatus, setTransStatus] = useState([]);

    const [transOptAaudio, setOptAaudio] = useState([]);
    const [transOptBaudio, setOptBaudio] = useState([]);
    const [transOptCaudio, setOptCaudio] = useState([]);

    const [correctOption, setCorrectOption] = useState([]);

    const [audioFiles, setAudioFiles] = useState(null);
    const [audioAFiles, setAudioAFiles] = useState(null);
    const [audioBFiles, setAudioBFiles] = useState(null);
    const [audioCFiles, setAudioCFiles] = useState(null);

    const [q_image, setQ_image] = useState(null);
    const [q_video, setQ_video] = useState(null);
    const [opt_a_img, setopt_a_img] = useState(null);
    const [opt_b_img, setopt_b_img] = useState(null);
    const [opt_c_img, setopt_c_img] = useState(null);

    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);

    const [itemId, setItemId] = useState([]);
    const [assetType, setAssetType] = useState([]);

    const QDate = [];
    const OptAQData = [];
    const OptBQData = [];
    const OptCQData = [];
    const TransStatusArray = [];

    const QDateAudio = [];
    const OptAQDataAudio = [];
    const OptBQDataAudio = [];
    const OptCQDataAudio = [];

    const handleQimage = (e) => {
        setQ_image(e.target.files[0]);
    };
    const handleQaudio = (index, e, lang) => {
        const newArray = [...audioFiles];
        newArray[index] = {
            [lang]: e.target.files[0]
        };
        setAudioFiles(newArray)
    };
    const handleq_video = (e) => {
        setQ_video(e.target.files[0]);
    };
    const handleopt_a_img = (e) => {
        setopt_a_img(e.target.files[0]);
    };
    const handleopt_b_img = (e) => {
        setopt_b_img(e.target.files[0]);
    };
    const handleopt_c_img = (e) => {
        setopt_c_img(e.target.files[0]);
    };
    const handleopt_a_audio = (index, e, lang) => {
        const newArray = [...audioAFiles];
        newArray[index] = {
            [lang]: e.target.files[0]
        };
        setAudioAFiles(newArray)
    };
    const handleopt_b_audio = (index, e, lang) => {
        const newArray = [...audioBFiles];
        newArray[index] = {
            [lang]: e.target.files[0]
        };
        setAudioBFiles(newArray)
    };
    const handleopt_c_audio = (index, e, lang) => {

        const newArray = [...audioCFiles];
        newArray[index] = {
            [lang]: e.target.files[0]
        };
        setAudioCFiles(newArray)
    };
    const handleMulti = (s) => {
        setselectedMultiCourseId(s.map((item) => item.value));
        setselectedMulti(s);
    };





    const selectLayoutState = (state) => state.TranslateQuestion;
    const createQuestionProperties = createSelector(selectLayoutState, (app) => ({
        Questions: app.Questions,
        QuestionTranslations: app.QuestionTranslations,
        languages: app.languages,
        courses: app.courses,
        topicAreas: app.topicAreas,
        isAddQuestionSuccess: app.isAddQuestionSuccess,
        isAddQuestionCreated: app.isAddQuestionCreated,
        error: app.error,
    }));
    const {
        languages,
        courses,
        topicAreas,
        Questions,
        QuestionTranslations,
        isAddQuestionSuccess,
        error,
        isAddQuestionCreated
    } = useSelector(createQuestionProperties);

    useEffect(() => {
        dispatch(onGetTranslationForQuestion(id));
    }, [dispatch,isAddQuestionSuccess]);

    useEffect(() => {
        if (!isEmpty(QuestionTranslations)) {
           
            setdefualtTranslation(QuestionTranslations[0]);
            setQuestionTranslationData(QuestionTranslations);
         
            languages.map((item,key) => (
                    QDate.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).q_title:QuestionTranslations[0].q_title}
                    ),
                    TransStatusArray.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).status:QuestionTranslations[0].status}
                    ),
                    OptAQData.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).opt_a:QuestionTranslations[0].opt_a}
                    ),
                    OptBQData.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).opt_b:QuestionTranslations[0].opt_b}
                    ),
                    OptCQData.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).opt_c:QuestionTranslations[0].opt_c}
                    ),
                    QDateAudio.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).q_audio:''}
                    ),
                    OptAQDataAudio.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).opt_a_audio:''}
                    ),
                    OptBQDataAudio.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).opt_b_audio:''}
                    ),
                    OptCQDataAudio.push(
                        {key: item.lang_short, title: QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short)?QuestionTranslations.find(itemTrans => itemTrans.lang === item.lang_short).opt_c_audio:''}
                    )
            ))

            setTransQtype(QDate);
            setTransStatus(TransStatusArray);
            setTransOptA(OptAQData);
            setTransOptB(OptBQData);
            setTransOptC(OptCQData);

            setTransQAudio(QDateAudio);
            setOptAaudio(OptAQDataAudio);
            setOptBaudio(OptBQDataAudio);
            setOptCaudio(OptCQDataAudio);

            
            setAudioAFiles(
                languages.map((item) => ({
                    [item.lang_short]: ""
                }))
            );
            setAudioBFiles(
                languages.map((item) => ({
                    [item.lang_short]: ""
                }))
            );
            setAudioCFiles(
                languages.map((item) => ({
                    [item.lang_short]: ""
                }))
            );
            setAudioFiles(
                languages.map((item) => ({
                    [item.lang_short]: ""
                }))
            );
        }
    }, [QuestionTranslations]);

    useEffect(() => {
        isAddQuestionCreated? setIsloading(false):"";
        error? setIsloading(false):null;
        dispatch(resetFlagsTrenslation());
    }, [dispatch,error,isAddQuestionCreated]);

    useEffect(() => {
        setLanguage(languages);
        setCourse(courses);
        setTopicArea(topicAreas);
        setLoading(!isAddQuestionSuccess);
    }, [languages]);

    useEffect(() => {
        if (!isEmpty(Questions)) {
            id.id > 0 ? setIsEditQuestion(true) : setIsEditQuestion(false);
            if (id.id > 0) {
                setLoading(!isAddQuestionSuccess);

                setQuestionData(Questions);
                setselectedMulti(
                    Questions.q_courses.map((item) => ({
                            label: item.course.course_translation[0].full_name, value: item.course_id
                        }
                    ))
                );
                setselectedMultiCourseId(
                    Questions.q_courses.map((item) => (
                        item.course_id
                    ))
                );
            }

        }
    }, [Questions, QuestionTranslations, isAddQuestionSuccess]);

    const onClickRemove =async (itemId,assetType) => {
        const item = {
            id: itemId ? itemId : "",
            assetType: assetType ? assetType : "",
        }
        dispatch(onRemoveQuestionAsset(item));

        dispatch(onGetTranslationForQuestion(id));

    };

    const validation = useFormik({

        enableReinitialize: true,
        initialValues: {
            id: (isEditQuestion ? id && id.id : "") || "",
            lang:
                language.map((item) => item.id) || "",

            q_type: (isEditQuestion ? questionData && questionData.q_type : "") || "",
            topic_id:
                (isEditQuestion ? questionData && questionData.topic_id : "") || "",
            q_title:
                (isEditQuestion ? transQtype && transQtype : "") ||
                "",
            q_code: (isEditQuestion ? questionData && questionData.code : "") || "",

            q_img: (isEditQuestion ? questionData && questionData.q_image : "") || "",

            switchCheckbox: (isEditQuestion ? questionData && questionData.q_is_video == 1? true : false :false) || false,

            q_video: (isEditQuestion ? questionData && questionData.q_video : "") || "",

            opt_a:
                (isEditQuestion ? transOptA && transOptA : "") || "",

            trans_status:
                (isEditQuestion ? transStatus && transStatus : "") || "",

            opt_b:
                (isEditQuestion ? transOptB && transOptB : "") ||
                "",
            opt_c:
                (isEditQuestion ? transOptC && transOptC : "") ||
                "",
            opt_a_img:
                (isEditQuestion ? questionData && questionData.opt_a_image : "") || "",
            opt_b_img:
                (isEditQuestion ? questionData && questionData.opt_b_image : "") || "",
            opt_c_img:
                (isEditQuestion ? questionData && questionData.opt_c_image : "") || "",

            q_audio:
                (isEditQuestion ? audioFiles && audioFiles : "") ||
                "",
            opt_a_audio:
                (isEditQuestion ? audioAFiles && audioAFiles : "") ||
                "",
            opt_b_audio:
                (isEditQuestion ? audioBFiles && audioBFiles : "") ||
                "",
            opt_c_audio:
                (isEditQuestion ? audioCFiles && audioCFiles : "") ||
                "",
            q_audio_file:
                (isEditQuestion ? transQAudio && transQAudio : "") ||
                "",
            opt_a_audio_file:
                (isEditQuestion ? transOptAaudio && transOptAaudio : "") ||
                "",
            opt_b_audio_file:
                (isEditQuestion ? transOptBaudio && transOptBaudio : "") ||
                "",
            opt_c_audio_file:
                (isEditQuestion ? transOptCaudio && transOptCaudio : "") ||
                "",
            correct_opt:
                (isEditQuestion ? questionData && questionData.correct_opt : "") || "",
            course_id: [],
            t_lang: language.map((item) => item.lang_short) || "",
            t_lang_id: language.map((item) => item.id) || ""
        },
        validationSchema: Yup.object({}),
        onSubmit: (values) => {
            setIsloading(true);
            const questionTranslation = {
                q_id: values["id"] ? values["id"] : "",
                lang_id: values["lang"] ? values["lang"] : "",
                t_lang: values["t_lang"] ? values["t_lang"] : "",
                t_lang_id: values["t_lang_id"] ? values["t_lang_id"] : "",
                q_title: values["q_title"] ? values["q_title"] : "",
                q_audio: values["q_audio"] ? values["q_audio"] : "",
                trans_status: values["trans_status"] ? values["trans_status"] : "",
                opt_a: values["opt_a"] ? values["opt_a"] : "",
                opt_b: values["opt_b"] ? values["opt_b"] : "",
                opt_c: values["opt_c"] ? values["opt_c"] : "",
                opt_a_audio: values["opt_a_audio"] ? values["opt_a_audio"] : "",
                opt_b_audio: values["opt_b_audio"] ? values["opt_b_audio"] : "",
                opt_c_audio: values["opt_c_audio"] ? values["opt_c_audio"] : "",
            };
            console.log("question data for save",questionTranslation);
            dispatch(onAddQuestionTranslation(questionTranslation));
        },
    });

    const questionTypeList = [
        {
            options: [
                {label: "Select Question Type", value: ""},
                {label: "Common", value: "common"},
                {label: "Specific", value: "specific"},
            ],
        },
    ];

    const statusArray = [
        {
            options: [
                {label: "Active", value: "1"},
                {label: "In Active", value: "2"},
            ],
        },
    ];

    const handleSwitchToggle = () => {
        setShowFormElements(!showFormElements);
    };

    const [selectedTab, setSelectedTab] = useState();

    const SingleOptions = course.map((item) => ({
        value: item.id,
        label: item.course_translation[0].full_name,
    }));

    const [activeIndex, setActiveIndex] = useState(0);

    const handleTabClick = (index, tabId) => {

        setActiveIndex(index);
        setSelectedTab(tabId);
        const selectedTabData = questionTranslationData.find(tab => tab.lang_id === tabId);
        setTranslationData(selectedTabData);

    };
    const handleInputChange = (index, value, lang) => {
        const newArray = [...transQtype];
        newArray[index] = {
            key: lang, title: value
        };
        setTransQtype(newArray);
    };
    const handleInputChangeOptA = (index, value, lang) => {
        const newArray = [...transOptA];
        newArray[index] = {
            key: lang, title: value
        };
        setTransOptA(newArray);
    };
    const handleInputChangeStatus = (index, value, lang) => {
        const newArray = [...transStatus];
        newArray[index] = {
            key: lang, title: value
        };
        setTransStatus(newArray);
    
    };
    const handleInputChangeOptB = (index, value, lang) => {
        const newArray = [...transOptB];
        newArray[index] = {
            key: lang, title: value
        };
        setTransOptB(newArray);
    };
    const handleInputChangeOptC = (index, value, lang) => {
        const newArray = [...transOptC];
        newArray[index] = {
            key: lang, title: value
        };
        setTransOptC(newArray);
    };

    const handleRemoveAsset = (id,type) => {
        Swal.fire({
            text: 'Are you sure you want to remove this asset',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#F1416C',
            cancelButtonColor: '#181C32',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        }).then((result) => {
            if (result.isConfirmed) {
                onClickRemove(id,type)
            }
        })
    }




    document.title = "Question Translation | Theory Practice";
    return (
        <React.Fragment>
            <UiContent/>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        title={"Question Translation"}
                        pageTitle="Question Bank"
                    />
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <CardBody>
                                    {(loading ?
                                            <React.Fragment>
                                                <div className="d-flex justify-content-center mx-2 mt-2">
                                                    <Spinner color="primary"> Loading... </Spinner>
                                                </div>
                                            </React.Fragment>
                                            : <div>
                                                {isAddQuestionSuccess ? (
                                                    <div className="">
                                                        <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                                                            <ul
                                                                className="nav nav-pills nav-justified custom-nav"
                                                                role="tablist">
                                                                <li className="nav-item active" role="presentation">
                                                                    <button
                                                                        className="nav-link fs-15 p-3 active"
                                                                        id="pills-translation-tab"
                                                                        data-bs-toggle="pill"
                                                                        data-bs-target="#pills-payment"
                                                                        type="button"
                                                                        role="tab"
                                                                        aria-controls="pills-payment"
                                                                        aria-selected="true"
                                                                        data-position="0">
                                                                        <i className="ri-translate fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                                                                        Translation
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="tab-content">
                                                            <div
                                                                className="tab-pane fade active show"
                                                                id="pills-payment"
                                                                role="tabpanel"
                                                                aria-labelledby="pills-translation-tab">
                                                                <div className="card">
                                                                    <div className="card-body">
                                                                        <Form
                                                                            className="tablelist-form"
                                                                            onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                validation.handleSubmit();
                                                                                return false;
                                                                            }}>
                                                                            <input
                                                                                type="hidden"
                                                                                id="id"
                                                                                value={validation.values.id || ""}
                                                                            />
                                                                            <div key="">
                                                                                <ul className="nav nav-pills animation-nav nav-justified gap-2 mb-3"
                                                                                    role="tablist">
                                                                                    {language && language.map((language, key) => (
                                                                                        <li
                                                                                            className="nav-item waves-effect waves-light"
                                                                                            role="presentation"
                                                                                            key={key}>
                                                                                            <a key={key}
                                                                                               onClick={() => handleTabClick(key, language.id)}
                                                                                               className={`nav-link border p-3 ${key === activeIndex ? "active" : ""}`}
                                                                                               id={"custom-v-pills-profile-tab-" + language.lang_short}
                                                                                               data-bs-toggle="tab"
                                                                                               role="tab"
                                                                                               aria-controls="custom-v-pills-profile"
                                                                                               aria-selected="true"
                                                                                               tabIndex={key}>
                                                                                                <img
                                                                                                    id="header-lang-img"
                                                                                                    src={ images(`./${language.lang_short}.svg`, true)?images(`./${language.lang_short}.svg`, true):""}
                                                                                                    alt="flag"
                                                                                                    height="20"
                                                                                                    className="rounded me-3"/>
                                                                                                <span className="fs-14 text-wrap">{language.lang}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    ))}
                                                                                </ul>

                                                                                <div className="tab-content text-muted">
                                                                                    {language &&
                                                                                        language.map((language, key) => (
                                                                                            <div key={key}
                                                                                                 className={key === activeIndex ? "tab-pane fade active show" : "tab-pane fade "}
                                                                                                 id={"translation-tab-" + language.lang_short} role="tabpanel">
                                                                                                <div>
                                                                                                    <div className="card-body">
                                                                                                        <div
                                                                                                            className="row mb-3"></div>
                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-6 mb-3">
                                                                                                                <Input
                                                                                                                    type="hidden"
                                                                                                                    name="t_lang[]"
                                                                                                                    value={language.lang_short}
                                                                                                                />
                                                                                                                <Input
                                                                                                                    type="hidden"
                                                                                                                    name="t_lang_id[]"
                                                                                                                    value={language.id}
                                                                                                                />
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="status-field"
                                                                                                                        className="form-label"
                                                                                                                    >
                                                                                                                        Language
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="lang"
                                                                                                                        type="select"
                                                                                                                        className="form-select"
                                                                                                                        id="status-field"
                                                                                                                        validate={{required: {value: true,},}}
                                                                                                                        onChange={validation.handleChange}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        value={validation.values.lang[key] || ""}
                                                                                                                        invalid={validation.touched.lang && validation.errors.lang ? true : false}
                                                                                                                        disabled={true}>
                                                                                                                        <React.Fragment>
                                                                                                                            <option value="">Select Language</option>
                                                                                                                            <option value={language.id} key={key}>{language.lang}</option>
                                                                                                                        </React.Fragment>
                                                                                                                    </Input>
                                                                                                                    {validation.touched.lang &&
                                                                                                                    validation.errors.lang ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.lang}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-6">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="status-field"
                                                                                                                        className="form-label">
                                                                                                                        Question Type
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="q_type"
                                                                                                                        type="select"
                                                                                                                        className="form-select"
                                                                                                                        id="q_type-field"
                                                                                                                        validate={{required: {value: true,},}}
                                                                                                                        onChange={validation.handleChange}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        value={validation.values.q_type || ""}
                                                                                                                        invalid={validation.touched.q_type && validation.errors.q_type ? true : false}
                                                                                                                        disabled={true}>
                                                                                                                        {questionTypeList.map(
                                                                                                                            (item, i) => (
                                                                                                                                <React.Fragment
                                                                                                                                    key={i}>
                                                                                                                                    {item.options.map((item, i) => (
                                                                                                                                            <option value={item.value} key={i}>
                                                                                                                                                {item.label}
                                                                                                                                            </option>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                    </Input>
                                                                                                                    {validation.touched.q_type && validation.errors.q_type ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">{validation.errors.q_type}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {validation.values.q_type == "specific" && (
                                                                                                                <div className="col-lg-4">
                                                                                                                    <div
                                                                                                                        className="mb-3 mb-lg-0">
                                                                                                                        <Label
                                                                                                                            htmlFor="course_id-field"
                                                                                                                            className="form-label">
                                                                                                                            Course
                                                                                                                        </Label>

                                                                                                                        <div className="filter-choices-input">
                                                                                                                            <Select
                                                                                                                                isDisabled={true}
                                                                                                                                value={selectedMulti}
                                                                                                                                name="course_id"
                                                                                                                                isMulti={true}
                                                                                                                                type="select"
                                                                                                                                id="course_id-field"
                                                                                                                                validate={{required: {value: true,},}}
                                                                                                                                invalid={validation.touched.course_id && validation.errors.course_id ? true : false}
                                                                                                                                onChange={(e) => handleMulti(e)}
                                                                                                                                onBlur={validation.handleBlur}
                                                                                                                                options={SingleOptions}
                                                                                                                                isSearchable={true}
                                                                                                                            />
                                                                                                                            {validation.touched.course_id && validation.errors.course_id ? (
                                                                                                                                <FormFeedback type="invalid">
                                                                                                                                    {validation.errors.course_id}
                                                                                                                                </FormFeedback>
                                                                                                                            ) : null}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <div
                                                                                                                className="col-lg-4">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="topic_id-field"
                                                                                                                        className="form-label">
                                                                                                                        Topic Area
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="topic_id"
                                                                                                                        type="select"
                                                                                                                        className="form-select"
                                                                                                                        id="topic_id-field"
                                                                                                                        validate={{required: {value: true,},}}
                                                                                                                        onChange={validation.handleChange}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        value={validation.values.topic_id || ""}
                                                                                                                        invalid={validation.touched.topic_id && validation.errors.topic_id ? true : false}
                                                                                                                        disabled={true}
                                                                                                                    >
                                                                                                                        <React.Fragment>
                                                                                                                            <option value="">Select Topic Area</option>
                                                                                                                            {topicArea.map((item, i) => (
                                                                                                                                    <option value={item.id} key={i}>
                                                                                                                                        {item.topic_area_translation[0].full_name}
                                                                                                                                    </option>
                                                                                                                                )
                                                                                                                            )}
                                                                                                                        </React.Fragment>
                                                                                                                    </Input>
                                                                                                                    {validation.touched.topic_id && validation.errors.topic_id ? (
                                                                                                                        <FormFeedback type="invalid">
                                                                                                                            {validation.errors.topic_id}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div
                                                                                                                className="col-lg-4">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="status-field"
                                                                                                                        className="form-label">
                                                                                                                        Status
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="trans_status[]"
                                                                                                                        type="select"
                                                                                                                        className="form-select"
                                                                                                                        id="status-field"
                                                                                                                        validate={{required: {value: true,},}}
                                                                                                                        onChange={(e) => handleInputChangeStatus(key, e.target.value, language.lang_short)}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        value={transStatus[key] && transStatus[key].title}
                                                                                                                        invalid={validation.touched.trans_status && validation.errors.trans_status ? true : false}
                                                                                                                     
                                                                                                                    >
                                                                                                                        {statusArray.map(
                                                                                                                            (item, i) => (
                                                                                                                                <React.Fragment
                                                                                                                                    key={i}>
                                                                                                                                    {item.options.map((item, i) => (
                                                                                                                                            <option value={item.value} key={i}>
                                                                                                                                                {item.label}
                                                                                                                                            </option>
                                                                                                                                        )
                                                                                                                                    )}
                                                                                                                                </React.Fragment>
                                                                                                                            )
                                                                                                                        )}
                                                                                                                    </Input>
                                                                                                                    {validation.touched.topic_id && validation.errors.topic_id ? (
                                                                                                                        <FormFeedback type="invalid">
                                                                                                                            {validation.errors.topic_id}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-12">
                                                                                                                <Label
                                                                                                                    htmlFor="q_des-field"
                                                                                                                    className="form-label">
                                                                                                                    Question Description
                                                                                                                </Label>
                                                                                                                <textarea
                                                                                                                    className="form-control"
                                                                                                                    id="q_des-field"
                                                                                                                    rows="3"
                                                                                                                    name="q_title[]"
                                                                                                                    onChange={(e) => handleInputChange(key, e.target.value, language.lang_short)}
                                                                                                                    onBlur={validation.handleBlur}
                                                                                                                    value={(transQtype[key] && transQtype[key].title || "")}
                                                                                                                    dir={language.direction == 2 ? "ltr" : "rtl"}
                                                                                                                />
                                                                                                                {validation.touched.q_title && validation.errors.q_title ? (
                                                                                                                    <FormFeedback type="invalid">
                                                                                                                        {validation.errors.q_title}
                                                                                                                    </FormFeedback>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-12">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="q_code-field"
                                                                                                                        className="form-label">
                                                                                                                        Question Code
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="q_code"
                                                                                                                        type="text"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Code........"
                                                                                                                        id="q_code-field"
                                                                                                                        validate={{required: {value: true,},}}
                                                                                                                        onChange={validation.handleChange}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        value={validation.values.q_code || ""}
                                                                                                                        invalid={validation.touched.q_code && validation.errors.q_code ? true : false}
                                                                                                                        disabled={true}
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.q_code && validation.errors.q_code ? (
                                                                                                                        <FormFeedback type="invalid">
                                                                                                                            {validation.errors.q_code}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        {validation.values.switchCheckbox && (
                                                                                                            <Row className="mb-3">
                                                                                                                <div
                                                                                                                    className="col-lg-3">
                                                                                                                    <div
                                                                                                                        className="mb-3 mb-lg-0">
                                                                                                                        <Label
                                                                                                                            htmlFor="q_video-field"
                                                                                                                            className="form-label">
                                                                                                                            Question Video
                                                                                                                        </Label>
                                                                                                                        <Input
                                                                                                                            name="q_video"
                                                                                                                            type="file"
                                                                                                                            className="form-control"
                                                                                                                            placeholder="Enter Question Video"
                                                                                                                            id="q_video-field"
                                                                                                                            validate={{required: {value: true,},}}
                                                                                                                            onChange={(event) => {handleq_video(event);}}
                                                                                                                            onBlur={validation.handleBlur}
                                                                                                                            accept="video/*"
                                                                                                                            disabled={true}
                                                                                                                        ></Input>
                                                                                                                        {validation.touched.q_video && validation.errors.q_video ? (
                                                                                                                            <FormFeedback type="invalid">
                                                                                                                                {validation.errors.q_video}
                                                                                                                            </FormFeedback>
                                                                                                                        ) : null}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                {validation.values.q_video && validation.values.switchCheckbox == 1 && (
                                                                                                                    <div className="col-lg-5">
                                                                                                                        <div
                                                                                                                            className="mb-3 mb-lg-0">
                                                                                                                            <Label
                                                                                                                                htmlFor="q_audio-field"
                                                                                                                                className="form-label">
                                                                                                                                Question
                                                                                                                                Video
                                                                                                                            </Label>
                                                                                                                            <VideoPlayer
                                                                                                                                src={questionData && validation.values.q_video ? audioSource + validation.values.q_video : ""}
                                                                                                                            />
                                                                                                                            <Link
                                                                                                                                to="#"
                                                                                                                                className="text-danger d-inline-block remove-item-btn"
                                                                                                                                onClick={() => {handleRemoveAsset(validation.values.id,'q_video'); }}
                                                                                                                            >
                                                                                                                                <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                            </Link>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Row>
                                                                                                        )}

                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="q_img-field"
                                                                                                                        className="form-label">
                                                                                                                        Question Image
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="q_img"
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Image"
                                                                                                                        id="q_img-field"
                                                                                                                        validate={{required: {value: true,},}}
                                                                                                                        onChange={(event) => {handleQimage(event);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept=".png,.jpg,.jpeg"
                                                                                                                        disabled={true}
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.q_img && validation.errors.q_img ? (
                                                                                                                        <FormFeedback type="invalid">
                                                                                                                            {validation.errors.q_img}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {validation.values.q_img && validation.values.q_img && (
                                                                                                                <div className="col-lg-2">
                                                                                                                    <div className="mb-3 mb-lg-0">
                                                                                                                        <Label htmlFor="q_img-field"
                                                                                                                               className="form-label">
                                                                                                                            Question Image
                                                                                                                        </Label>
                                                                                                                        <div
                                                                                                                            className="p-2">
                                                                                                                            <div
                                                                                                                                className="align-items-center">
                                                                                                                                <div
                                                                                                                                    className="col-auto">
                                                                                                                                    <img
                                                                                                                                        data-dz-thumbnail=""
                                                                                                                                        width="100%"
                                                                                                                                        height="auto"
                                                                                                                                        className="avatar-lg rounded bg-light"
                                                                                                                                        alt=""
                                                                                                                                        src={audioSource + validation.values.q_img}/>
                                                                                                                                    <Link
                                                                                                                                        to = '#'
                                                                                                                                        className="text-danger d-inline-block remove-item-btn"
                                                                                                                                        onClick={() => {handleRemoveAsset(validation.values.id,'q-image');}}
                                                                                                                                        // onClick={() => {onClickRemove(validation.values.id,'q-image'); }}
                                                                                                                                    >
                                                                                                                                        <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove test"></i>
                                                                                                                                    </Link >
                                                                                                                                </div>

                                                                                                                            </div>

                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )}
                                                                                                            <div
                                                                                                                className="col-lg-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="q_audioa-field"
                                                                                                                        className="form-label">
                                                                                                                        Question Audio
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name={"q_audio" + [language.lang_short]}
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Audio........"
                                                                                                                        id="q_audioa-field"
                                                                                                                        onChange={(event,) => {handleQaudio(key, event, language.lang_short);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept="audio/*"
                                                                                                                    ></Input>

                                                                                                                </div>
                                                                                                            </div>
                                                                                                            {validation.values.q_audio_file[key] &&
                                                                                                                validation.values.q_audio_file[key].title != 1 &&
                                                                                                                validation.values.q_audio_file[key].title != "" &&
                                                                                                                validation.values.q_audio_file[key].title != null
                                                                                                                && (
                                                                                                                    <div
                                                                                                                        className="col-lg-3">
                                                                                                                        <div
                                                                                                                            className="mb-3 mb-lg-0">
                                                                                                                            <Label
                                                                                                                                htmlFor="q_audio-field"
                                                                                                                                className="form-label">
                                                                                                                                Question
                                                                                                                                Audio
                                                                                                                            </Label>
                                                                                                                            <AudioPlayer
                                                                                                                                src={questionData ? audioSource + validation.values.q_audio_file[key].title : ""}
                                                                                                                            />
                                                                                                                            <Link
                                                                                                                                to="#"
                                                                                                                                className="text-danger d-inline-block remove-item-btn"
                                                                                                                                onClick={() => {handleRemoveAsset(questionTranslationData[key].id,'q-audio'); }}
                                                                                                                            >
                                                                                                                                <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                            </Link>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                )}
                                                                                                        </Row>

                                                                                                        <div
                                                                                                            className="card-header align-items-center d-flex mb-3">
                                                                                                            <h5 className="card-title mb-0 flex-grow-1">
                                                                                                                Answer Options
                                                                                                            </h5>
                                                                                                        </div>
                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-5 mb-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <label
                                                                                                                        htmlFor="choices-priority-input"
                                                                                                                        className="form-label">{" "}Option A
                                                                                                                    </label>
                                                                                                                    <div
                                                                                                                        className="input-group">
                                                                                                                        <div
                                                                                                                            className="input-group-text">
                                                                                                                            <Input
                                                                                                                                className="form-check-input mt-0"
                                                                                                                                type="radio"
                                                                                                                                name={"correct_opt_"+language.lang_short}
                                                                                                                                onChange={validation.handleChange}
                                                                                                                                onBlur={validation.handleBlur}
                                                                                                                                value={"a"}
                                                                                                                                invalid={validation.touched.correct_opt && validation.errors.correct_opt ? true : false}
                                                                                                                                disabled={true}
                                                                                                                                checked={validation.values.correct_opt == "a" || ""}
                                                                                                                            />
                                                                                                                            {validation.touched.correct_opt && validation.errors.correct_opt ? (
                                                                                                                                <FormFeedback
                                                                                                                                    type="invalid">
                                                                                                                                    {validation.errors.correct_opt}
                                                                                                                                </FormFeedback>
                                                                                                                            ) : null}
                                                                                                                        </div>
                                                                                                                        <Input
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            aria-label="Text input with checkbox"
                                                                                                                            name="opt_a[]"
                                                                                                                            placeholder="Option A"
                                                                                                                            onChange={(e) => handleInputChangeOptA(key, e.target.value, language.lang_short)}
                                                                                                                            onBlur={validation.handleBlur}
                                                                                                                            value={(transOptA[key] && transOptA[key].title || "")}
                                                                                                                            dir={language.direction == 2 ? "ltr" : "rtl"}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="opt_a_img-field"
                                                                                                                        className="form-label">
                                                                                                                        Option A Image
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="opt_a_img"
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Image........"
                                                                                                                        id="opt_a_img-field"
                                                                                                                        onChange={(event) => {handleopt_a_img(event);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept=".png,.jpg,.jpeg"
                                                                                                                        disabled={true}
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.opt_a_img && validation.errors.opt_a_img ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.opt_a_img}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                    {validation.values.opt_a_img && validation.values.opt_a_img != 1 && (
                                                                                                                        <div
                                                                                                                            className="mt-3">
                                                                                                                            <img
                                                                                                                                data-dz-thumbnail=""
                                                                                                                                className="avatar-lg rounded bg-light"
                                                                                                                                alt=""
                                                                                                                                src={audioSource + validation.values.opt_a_img}
                                                                                                                            />
                                                                                                                            <Link
                                                                                                                                to="#"
                                                                                                                                className="text-danger d-inline-block remove-item-btn"
                                                                                                                                onClick={() => {handleRemoveAsset(validation.values.id,'opt-a-image'); }}
                                                                                                                            >
                                                                                                                                <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                            </Link>
                                                                                                                        </div>

                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-4">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="opt_a_audio-field"
                                                                                                                        className="form-label">
                                                                                                                        Option A Audio
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name={"opt_a_audio" + language.lang_short}
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Audio........"
                                                                                                                        id="opt_a_audio-field"
                                                                                                                        onChange={(event) => {handleopt_a_audio(key, event, language.lang_short);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept="audio/*"
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.opt_a_audio && validation.errors.opt_a_audio ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.opt_a_audio}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                                {validation.values.opt_a_audio_file[key] &&
                                                                                                                    validation.values.opt_a_audio_file[key].title != 1 &&
                                                                                                                    validation.values.opt_a_audio_file[key].title != "" &&
                                                                                                                    validation.values.opt_a_audio_file[key].title != null && (
                                                                                                                        <div
                                                                                                                            className="mt-3 mb-lg-0">
                                                                                                                            <AudioPlayer
                                                                                                                                src={
                                                                                                                                    validation.values.opt_a_audio_file[key] ? audioSource + validation.values.opt_a_audio_file[key].title : ""
                                                                                                                                }
                                                                                                                            />
                                                                                                                            <Link
                                                                                                                                to="#"
                                                                                                                                className="text-danger d-inline-block remove-item-btn"
                                                                                                                                onClick={() => {handleRemoveAsset(questionTranslationData[key].id,'opt-a-audio'); }}
                                                                                                                            >
                                                                                                                                <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                            </Link>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-5 mb-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <label
                                                                                                                        htmlFor="choices-priority-input"
                                                                                                                        className="form-label">{" "}
                                                                                                                        Option B
                                                                                                                    </label>
                                                                                                                    <div
                                                                                                                        className="input-group">
                                                                                                                        <div
                                                                                                                            className="input-group-text">
                                                                                                                            <Input
                                                                                                                                className="form-check-input mt-0"
                                                                                                                                type="radio"
                                                                                                                                name={"correct_opt_"+language.lang_short}
                                                                                                                                onChange={validation.handleChange}
                                                                                                                                onBlur={validation.handleBlur}
                                                                                                                                value={"b"}
                                                                                                                                invalid={validation.touched.correct_opt && validation.errors.correct_opt ? true : false}
                                                                                                                                checked={validation.values.correct_opt == "b" || ""}
                                                                                                                                disabled={true}
                                                                                                                            />
                                                                                                                            {validation.touched.correct_opt && validation.errors.correct_opt ? (
                                                                                                                                <FormFeedback
                                                                                                                                    type="invalid">
                                                                                                                                    {validation.errors.correct_opt}
                                                                                                                                </FormFeedback>
                                                                                                                            ) : null}
                                                                                                                        </div>
                                                                                                                        <Input
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            aria-label="Text input with checkbox"
                                                                                                                            name="opt_b[]"
                                                                                                                            placeholder="Option B"
                                                                                                                            onChange={(e) => handleInputChangeOptB(key, e.target.value, language.lang_short)}
                                                                                                                            onBlur={validation.handleBlur}
                                                                                                                            value={(transOptB[key] && transOptB[key].title || "")}
                                                                                                                            dir={language.direction == 2 ? "ltr" : "rtl"}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="opt_b_img-field"
                                                                                                                        className="form-label">
                                                                                                                        Option B Image
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="opt_b_img"
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Image........"
                                                                                                                        id="opt_b_img-field"
                                                                                                                        onChange={(event) => {handleopt_b_img(event);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept=".png,.jpg,.jpeg"
                                                                                                                        disabled={true}
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.opt_b_img && validation.errors.opt_b_img ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.opt_b_img}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                                {validation.values.opt_b_img && validation.values.opt_b_img != 1 && (
                                                                                                                    <div
                                                                                                                        className="mt-3">
                                                                                                                        <img
                                                                                                                            data-dz-thumbnail=""
                                                                                                                            className="avatar-lg rounded bg-light"
                                                                                                                            alt=""
                                                                                                                            src={audioSource + validation.values.opt_b_img}
                                                                                                                        />
                                                                                                                        <Link
                                                                                                                            to="#"
                                                                                                                            className="text-danger d-inline-block remove-item-btn"
                                                                                                                            onClick={() => {handleRemoveAsset(validation.values.id,'opt-b-image'); }}
                                                                                                                        >
                                                                                                                            <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-4">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="opt_b_audio-field"
                                                                                                                        className="form-label">
                                                                                                                        Option B Audio
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name={"opt_b_audio" + language.lang_short}
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Audio........"
                                                                                                                        id="opt_b_audio-field"
                                                                                                                        onChange={(event) => {handleopt_b_audio(key, event, language.lang_short);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept="audio/*"
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.opt_b_audio && validation.errors.opt_b_audio ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.opt_b_audio}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                    {validation.values.opt_b_audio_file[key] &&
                                                                                                                        validation.values.opt_b_audio_file[key].title != 1 &&
                                                                                                                        validation.values.opt_b_audio_file[key].title != "" &&
                                                                                                                        validation.values.opt_b_audio_file[key].title != null
                                                                                                                        && (
                                                                                                                            <div
                                                                                                                                className="mt-3 mb-lg-0">
                                                                                                                                <AudioPlayer
                                                                                                                                    src={
                                                                                                                                        validation.values.opt_b_audio_file[key] ? audioSource + validation.values.opt_b_audio_file[key].title : ""
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <Link
                                                                                                                                    to="#"
                                                                                                                                    className="text-danger d-inline-block remove-item-btn"
                                                                                                                                    onClick={() => {handleRemoveAsset(questionTranslationData[key].id,'opt-b-audio'); }}
                                                                                                                                >
                                                                                                                                    <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                                </Link>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>
                                                                                                        <Row className="mb-3">
                                                                                                            <div
                                                                                                                className="col-lg-5 mb-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <label
                                                                                                                        htmlFor="choices-priority-input"
                                                                                                                        className="form-label">
                                                                                                                        {" "}
                                                                                                                        Option C
                                                                                                                    </label>
                                                                                                                    <div
                                                                                                                        className="input-group">
                                                                                                                        <div
                                                                                                                            className="input-group-text">
                                                                                                                            <Input
                                                                                                                                className="form-check-input mt-0"
                                                                                                                                type="radio"
                                                                                                                                name={"correct_opt_"+language.lang_short}
                                                                                                                                onChange={validation.handleChange}
                                                                                                                                onBlur={validation.handleBlur}
                                                                                                                                value={"c"}
                                                                                                                                invalid={validation.touched.correct_opt && validation.errors.correct_opt ? true : false}
                                                                                                                                checked={validation.values.correct_opt == "c" || ""}
                                                                                                                                disabled={true}
                                                                                                                            />
                                                                                                                            {validation.touched.correct_opt &&
                                                                                                                            validation.errors.correct_opt ? (
                                                                                                                                <FormFeedback
                                                                                                                                    type="invalid">
                                                                                                                                    {validation.errors.correct_opt}
                                                                                                                                </FormFeedback>
                                                                                                                            ) : null}
                                                                                                                        </div>
                                                                                                                        <Input
                                                                                                                            type="text"
                                                                                                                            className="form-control"
                                                                                                                            aria-label="Text input with checkbox"
                                                                                                                            name="opt_c[]"
                                                                                                                            placeholder="Option C"
                                                                                                                            onChange={(e) => handleInputChangeOptC(key, e.target.value, language.lang_short)}
                                                                                                                            onBlur={validation.handleBlur}
                                                                                                                            value={(transOptC[key] && transOptC[key].title || "")}
                                                                                                                            dir={language.direction == 2 ? "ltr" : "rtl"}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-3">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="opt_c_img-field"
                                                                                                                        className="form-label">
                                                                                                                        Option C Image
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name="opt_c_img"
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Image........"
                                                                                                                        id="opt_c_img-field"
                                                                                                                        onChange={(event) => {handleopt_c_img(event);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept=".png,.jpg,.jpeg"
                                                                                                                        disabled={true}
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.opt_c_img && validation.errors.opt_c_img ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.opt_c_img}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                                {validation.values.opt_c_img && validation.values.opt_c_img != 1 && (
                                                                                                                    <div
                                                                                                                        className="mt-3">
                                                                                                                        <img
                                                                                                                            data-dz-thumbnail=""
                                                                                                                            className="avatar-lg rounded bg-light"
                                                                                                                            alt=""
                                                                                                                            src={audioSource + validation.values.opt_c_img}
                                                                                                                        />
                                                                                                                        <Link
                                                                                                                            to="#"
                                                                                                                            className="text-danger d-inline-block remove-item-btn"
                                                                                                                            onClick={() => {handleRemoveAsset(validation.values.id,'opt-c-image'); }}
                                                                                                                        >
                                                                                                                            <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                        </Link>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                className="col-lg-4">
                                                                                                                <div
                                                                                                                    className="mb-3 mb-lg-0">
                                                                                                                    <Label
                                                                                                                        htmlFor="opt_c_audio-field"
                                                                                                                        className="form-label">
                                                                                                                        Option C Audio
                                                                                                                    </Label>
                                                                                                                    <Input
                                                                                                                        name={"opt_c_audio" + language.lang_short}
                                                                                                                        type="file"
                                                                                                                        className="form-control"
                                                                                                                        placeholder="Enter Question Audio........"
                                                                                                                        id="opt_c_audio-field"
                                                                                                                        onChange={(event) => {handleopt_c_audio(key, event, language.lang_short);}}
                                                                                                                        onBlur={validation.handleBlur}
                                                                                                                        accept="audio/*"
                                                                                                                    ></Input>
                                                                                                                    {validation.touched.opt_c_audio && validation.errors.opt_c_audio ? (
                                                                                                                        <FormFeedback
                                                                                                                            type="invalid">
                                                                                                                            {validation.errors.opt_c_audio}
                                                                                                                        </FormFeedback>
                                                                                                                    ) : null}
                                                                                                                    {validation.values.opt_c_audio_file[key] &&
                                                                                                                        validation.values.opt_c_audio_file[key].title != 1 &&
                                                                                                                        validation.values.opt_c_audio_file[key].title != "" &&
                                                                                                                        validation.values.opt_c_audio_file[key].title != null && (
                                                                                                                            <div
                                                                                                                                className="mt-3 mb-lg-0">
                                                                                                                                <AudioPlayer
                                                                                                                                    src={
                                                                                                                                        validation.values.opt_c_audio_file[key] ? audioSource + validation.values.opt_c_audio_file[key].title : ""
                                                                                                                                    }
                                                                                                                                />
                                                                                                                                <Link
                                                                                                                                    to="#"
                                                                                                                                    className="text-danger d-inline-block remove-item-btn"
                                                                                                                                    onClick={() => {handleRemoveAsset(questionTranslationData[key].id,'opt-c-audio'); }}
                                                                                                                                >
                                                                                                                                    <i className="ri ri-close-fill text-danger ms-2 cursor-pointer btn-remove fs-5" title="Remove"></i>
                                                                                                                                </Link>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </Row>

                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    <div className="row g-3 mt-2">
                                                                                        <div
                                                                                            className="hstack gap-2 justify-content-end">
                                                                                            <Link to="/admin/question-bank"
                                                                                                  className="btn btn-light">Close</Link>
                                                                                            <Button color="primary"
                                                                                                    disabled={isloading ? true : false}
                                                                                                    className="btn-load btn-label right  next tab btn-submit"
                                                                                                    type="submit">
                                                                                  <span
                                                                                      className="d-flex align-items-center">
                                                                                        {isloading ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}
                                                                                      <i className="ri-rocket-line label-icon align-middle fs-16 ms-2"></i>
                                                                                        <span
                                                                                            className="flex-grow-1 ms-2">
                                                                                        Save Changes
                                                                                        </span>
                                                                                    </span>
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </Form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="py-4 text-center">
                                                        <div>
                                                            <lord-icon
                                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                                trigger="loop"
                                                                colors="primary:#405189,secondary:#0ab39c"
                                                                style={{width: "72px", height: "72px"}}
                                                            ></lord-icon>
                                                        </div>
                                                        <div className="mt-4">
                                                            <h5>Sorry! No Record Found</h5>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                    )}
                                    <ToastContainer closeButton={false} limit={1}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default QuestionTranslation;