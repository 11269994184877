import React from 'react'
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';


function Template2Footer(props) {
  const {handleNextPrevClick,isShowBtnPrev,isShowBtnFinish,direction,handelExit,totalWrongQ,totalCorrectQ,totalSkipQ,examType,isVidSecShow} = props;
  const { t, i18n }  = useTranslation();
  return (
    
    <footer className="footer bg-primary py-2" style={{position:"fixed"}}>
    <div className="container-fluid clearfix">

{ !isVidSecShow?
        <div className={`d-flex gap-3 ${direction === 'ltr' ? 'float-end' : 'float-start'}`}>

                
    
                   {isShowBtnPrev?
                      
                      <Button color="outline-light"  className={`w-lg btn-lg btn-label waves-effect waves-light ${direction === 'ltr' ? 'left' : 'right'}`} onClick={() => { handleNextPrevClick('prev') }} > <i className={` ${direction === 'ltr' ? "las la-arrow-circle-left label-icon align-middle fs-24 me-2" : "las la-arrow-circle-right label-icon align-middle fs-24 ms-2"} `} ></i> {t('Previous')} </Button>
                     
                     :''}
                      {!isShowBtnFinish ? (
                        <Button color="outline-light"  className={`w-lg btn-lg btn-label waves-effect waves-light ${direction === 'ltr' ? 'right' : ''}`} onClick={() => { handleNextPrevClick('next') }}> <i className={` ${direction === 'ltr' ? "las la-arrow-circle-right label-icon align-middle fs-24 ms-2" : "las la-arrow-circle-left label-icon align-middle fs-24 me-2"} `}></i> {t('Next')} </Button>
                      ) :
                       
                        <Button color="outline-light"  className={`w-lg btn-lg btn-label waves-effect waves-light ${direction === 'ltr' ? 'right' : ''}`} onClick={() => { handelExit() }}> <i className={` ${direction === 'ltr' ? "las la-times-circle label-icon align-middle fs-24 ms-2" : "las la-times-circle label-icon align-middle fs-24 me-2"} `}></i> {t('Finish')} </Button>
                    
                      }
        </div>
        :''}
        {examType==2?
                <div className={`d-flex gap-3 ${direction === 'ltr' ? 'float-start' : 'float-end'}`}>
                  <span className="btn btn-outline-light btn-lg  waves-effect waves-light">WA : {totalWrongQ}</span>
                  <button className="btn btn-outline-light btn-lg  waves-effect waves-light" >CA : {totalCorrectQ}</button>
                  <button className="btn btn-outline-light btn-lg  waves-effect waves-light">SA : {totalSkipQ}</button>
                  </div>
              
                  :''}
    </div>
</footer>
  )
}

export default Template2Footer