import React, { useEffect, useState } from 'react'
import { api } from '../../config';

function PagesLogoComponent() {
  const mediaSource = api.IMAGE_URL;
  const [storedData, setStoredData] = useState([]);

  useEffect(() => {
    const dataFromLocalStorage = localStorage.getItem('apiData');
    setStoredData(dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : null);

    },[]);


  return (
    <>
      
            <div className="text-center mb-5">

            <img src={storedData && mediaSource+storedData.logo} alt="" height="100" />

         
      </div>
    </>
  )
}

export default PagesLogoComponent