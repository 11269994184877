import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getAllQuestions as getAllQuestionsApi,
  importQuestionFile as importQuestionFileApi,
  deleteQuestion as deleteQuestionApi, getBranches as getBranchesApi, deleteBranch as deleteBranchApi

} from "../../helpers/custom_helper/backend_helper";



export const importQuestionFile = createAsyncThunk("import-questions-file", async (questions) => {
  try {
    const response = importQuestionFileApi(questions);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getQuestionsBank = createAsyncThunk("get-questions-bank", async () => {
  try {
    const response = getAllQuestionsApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const deleteQuestion = createAsyncThunk("delete-question", async (question) => {
  try {
    const response = deleteQuestionApi(question);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { question, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

