import React, {useCallback, useEffect, useMemo, useState} from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import {toast, ToastContainer} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import {
    getAllResults as onGetAllResults,
} from "../../slices/result/thunk";

import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";
import { changeDateFormate } from "../utility/TimerUtility";

const Result = () => {

    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(false);



    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const startDate = changeDateFormate(sevenDaysAgo);
    const  endDate= changeDateFormate(new Date());


    const [selectedDates, setSelectedDates] = useState([startDate,endDate]);
    const [isLoadingFilter,setIsLoadingFilter]=useState(false);

    let navigate = useNavigate();


    const selectLayoutState = (state) => state.ResultReducer;
    const questionBankProperties = createSelector(
        selectLayoutState,
        (ecom) => ({
            allResultsList: ecom.allResultsList,
            isQuestionSuccess: ecom.isQuestionSuccess,
            error: ecom.error,
            isLoading:ecom.isLoading
        })
    );
  
    const {
        allResultsList,isQuestionSuccess, error,isLoading
    } = useSelector(questionBankProperties)


    useEffect(() => {

      
        handleGetResultList(selectedDates[0],selectedDates[1]);
           // dispatch(onGetAllResults());
    }, [dispatch,isQuestionSuccess]);


    const handleGetResultList = (fromDate,toDate)=>{

          const resultPostedData = {
            start_date:fromDate,
            end_date:toDate
          }
        
          dispatch(onGetAllResults(resultPostedData));
        }
        


    useEffect(() => {
        setResult(allResultsList);
        setLoading(!isQuestionSuccess);
    }, [isQuestionSuccess]);

    useEffect(() => {

      setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        
        if (!isEmpty(allResultsList)) {
            setResult(allResultsList);
     
        }
        setLoading(!isQuestionSuccess);
    }, [isQuestionSuccess]);


    const columns = useMemo(
        () => [
            {
                Header: "Traffic ID",
                accessor: "exam.student.traffic_id",
                filterable: false,
            },
            {
                Header: "Student Name",
                accessor: "exam.student.std_name",
                filterable: false,
            },

               
        
            {
                Header: "Held On",
                accessor: "created_at",
                filterable: false,
            },
            {
                Header: "Course",
                accessor: "exam.course.short_name",
                filterable: false,
            },

            {
                Header: "Send SMS",
                accessor: "is_send_sms",
                Cell: (cell) => {

                    if(cell.row.original.is_send_sms==1){
                        return <span className=" text-uppercase  text-green fw-bold">Yes </span>;
                    }else{
                        return <span className=" text-uppercase  text-danger fw-bold">No </span>;
                    }
                  

                
                }
            },
            {
                Header: "Send Email",
                accessor: "is_send_email",
                Cell: (cell) => {

                    if(cell.row.original.is_send_email==1){
                        return <span className="text-uppercase text-green fw-bold">Yes </span>;
                    }else{
                        return <span className="text-uppercase text-danger fw-bold">No </span>;
                    }
                  

                
                }
            },
            {
                Header: "Status",
                accessor: "exam_status",
                Cell: (cell) => {

                    if(cell.row.original.status===1){
                        return <span className=" text-uppercase  text-green fw-bold">Pass </span>;
                    }else{
                        return <span className=" text-uppercase text-danger fw-bold">Fail </span>;
                    }
                  

                
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <AuthorizeForAction requiredPermissions={['exam-result-view']}>
                          <li className="list-inline-item" title="View Result">
                         
                        
                            {cellProps.row.original.exam.attempt?
                                <Link
                                    to={`/admin/result-detail/${cellProps.row.original.exam.attempt.id}/1`}
                                    className="text-primary d-inline-block create-item-btn"
                                
                                >
                                    <i className="ri-slideshow-fill fs-18"></i>
                                </Link>
                                :''}
                            </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['exam-result-view']}>
                            <li className="list-inline-item" title="Print Result">
                                <Link
                                    to={`/student-result/${cellProps.row.original.exam_id}/admin`}
                                    className="text-success d-inline-block translate-item-btn"
                                    >
                                    <i className="ri-printer-fill fs-18"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                        </ul>
                    );
                },
            },

        ],
        []
    );

    
const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
 };

 
const filterExamResult= () =>{

      setIsLoadingFilter(true)
      if(selectedDates[0] &&  selectedDates[1]){
        handleGetResultList(selectedDates[0],selectedDates[1]);
      }else{
        toast.error('Invalid date', { autoClose: 3000 });
      }
      setIsLoadingFilter(false)
  
  }



  
  


    document.title = "Exam Results | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Exam Results" pageTitle="Dashboard" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Exam Results</h5>
                                            </div>
                                        </div>

                                        <div className="col-sm-auto d-flex">

<div>

<div className="mt-3 mt-lg-0">

                              <div className="row g-3 mb-0 align-items-center">
                                  <div className="col-sm-auto">
                                      <div className="input-group">

                  <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              defaultDate: [startDate,endDate],
                              onChange:handleDateChange

                          }}

                      />
                      <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>


                                      </div>
                                  </div>

                                  <div className="col-auto">
                                      <Button type="button" color='success' onClick={filterExamResult}>    {isLoadingFilter ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}<i className="ri-search-line align-middle me-1"></i>Filter</Button>

                                  </div>



                              </div>

                      </div>



</div>
</div>

                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0"> 
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        : <div>
                                        {isQuestionSuccess && allResultsList.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(allResultsList || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for student, traffic id, course, type status ...'
                                            />
                                        ) :(
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{ width: "72px", height: "72px" }}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>)}
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default Result;