import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getRoles as getRolesApi,
  addNewRole as addNewRoleApi,
  updateRole as updateRoleApi,
  deleteRole as deleteRoleApi, getLanguages as getLanguagesApi, deleteLanguage as deleteLanguageApi,

} from "../../helpers/custom_helper/backend_helper";

export const updateRole = createAsyncThunk("update-role", async (role) => {
  try {
    const response = addNewRoleApi(role);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const addNewRole = createAsyncThunk("save-role", async (role) => {
  try {
    const response = addNewRoleApi(role);
    console.log(response);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getRoles = createAsyncThunk("get-all-roles", async () => {

  try {
    const response = getRolesApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const deleteRole = createAsyncThunk("delete-role", async (role) => {
  try {
    const response = deleteRoleApi(role);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { role, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});