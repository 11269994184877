import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {
  getScheduleExamList as getScheduleExamListApi,
  getBdcStudent as getBdcStudentApi,
  saveScheduleExam as  saveScheduleExamApi,
  updaeScheduleExam as  updaeScheduleExamApi,
  deleteExam as deleteExamApi,
  restartExam as restartExamApi,
  exitExam as exitExamApi,
  getRunningExam as getRunningExamApi,
  syncExam as syncExamApi,
  holdExam as holdExamApi,
  updateSystemmAndExam as updateSystemmAndExamApi
 
} from "../../helpers/custom_helper/backend_helper";




export const getScheduleExam = createAsyncThunk("get-exam", async (examPostedData,{dispatch}) => {
  try {
    
    dispatch(resetSaveExamSchedule())
    const response = getScheduleExamListApi(examPostedData);
    const data = await response;
 
   

    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }

});


export const getBdcStudent = createAsyncThunk("get-std", async (trafficId,{ dispatch }) => {
  try {
 
     dispatch(resetBdcStd())
    const response = getBdcStudentApi(trafficId);
    const data = await response;
    if(data.success)
    {
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }

});


export const saveScheduleExam = createAsyncThunk("save-exam", async (postedData,{ dispatch }) => {
  try {

    dispatch(resetSaveExamSchedule())
    const response = saveScheduleExamApi(postedData);
    const data = await response;


    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return response;
    }
  } catch (error) {
   toast.error(data, { autoClose: 3000 });
    return error;
  }

});


export const updateExam = createAsyncThunk("update-exam", async (postedData) => {
  try {
    const response = updaeScheduleExamApi(postedData);
    const data = await response;

    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const deleteExam = createAsyncThunk("delete-exam", async (systemId) => {
  try {
    const response = deleteExamApi(systemId);
    const data = await response;
  

    if(data.status)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { systemId, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});

export const restartExam = createAsyncThunk("restart-exam", async (examId) => {
  try {
    const response = restartExamApi(examId);
    const data = await response
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { examId, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});


export const resetFlags =createAsyncThunk("reset-flag", async (qid) => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    
  }
});

export const exitExam = createAsyncThunk("exit-exam", async (examId) => {
  try {
    const response = exitExamApi(examId);
    const data = await response
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return response
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});




export const resetBdcStd =createAsyncThunk("reset-std", async (qid) => {
  try {
  
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    
  }
});

export const resetSaveExamSchedule =createAsyncThunk("reset-save-exam", async () => {
  try {
   
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    
  }
});

// Get running exams
export const getRunningExams = createAsyncThunk("get-running-exam", async (examPostedData,{dispatch}) => {
  try {
    
    // dispatch(resetSaveExamSchedule())
    const response = getRunningExamApi(examPostedData);
    const data = await response;

    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }

});


// sync exam
export const syncExam = createAsyncThunk("sync-exam", async (examPostedData,{dispatch}) => {
  try {
    
    const response = syncExamApi(examPostedData);
    const data = await response;
  
    if(data.success)
    {
      
      toast.success(data.message, { autoClose: 3000 });
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }

});


//update system and exam
export const holdExam = createAsyncThunk("update-system-exam-status", async (postedData) => {
  try {

    const response =await updateSystemmAndExamApi(postedData); 
    if(response.success){
      toast.success('Exam hold successfully', { autoClose: 3000 });
      return response;
    }else{
      return response.data;
    }
  
  } catch (error) {
    toast.error('Exam holding error', { autoClose: 3000 });
    return error;
  }
});










