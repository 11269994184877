import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getSystemsList as getSystemListApi,
  createSystem as createSystemApi,
  deleteSystem as deleteSystemApi, getBranches as getBranchesApi,
   deleteBranch as deleteBranchApi,
   getRoomSystem as getRoomSystemApi,
   checkSystemIp as checkSystemIpApi


} from "../../helpers/custom_helper/backend_helper";



export const getSystem = createAsyncThunk("get-system", async () => {
  try {
    const response = getSystemListApi();
    const data = await response;
    console.log('system data',data)
 
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});


export const addNewSystem = createAsyncThunk("save-system", async (system) => {
  try {
    const response = createSystemApi(system);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const updateSystem = createAsyncThunk("update-room", async (systemPostedData) => {
  try {
    const response = createSystemApi(systemPostedData);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});


export const deleteSystem = createAsyncThunk("delete-room", async (systemId) => {
  try {
    const response = deleteSystemApi(systemId);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { systemId, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});

export const getRoomSystem = createAsyncThunk("get-room-system", async (roomId) => {
  try {
    const response = getRoomSystemApi(roomId);
    const data = await response;

    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});

export const checkSystemIp = createAsyncThunk("system-ip", async (systemIp,{ dispatch }) => {
  try {
    dispatch(resetSystemStates())
    const response = checkSystemIpApi(systemIp);
    const data = await response;
    if(data.success)
    {
    return data;
    }else {
      // toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});

export const resetSystemStates =createAsyncThunk("reset-system", async () => {
  try {
   
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    
  }
});



