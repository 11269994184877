import { createSlice } from "@reduxjs/toolkit";
import {   addNewRolePermissions,getAllModulePermissions   } from './thunk';
export const initialState = {


    modulepermissions: [],
    rolepermissions: [],
    roles: [],
    error: {},
};

const RolePermissionsSlice = createSlice({
    name: 'RolePermissionsSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getAllModulePermissions.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
            state.modulepermissions = action.payload.data['permissions'];
            state.rolepermissions = action.payload.data['rolepermissions'];
            state.roles = action.payload.data['roleName'];
            state.isPermissionsCreated = false;
            state.isPermissionsSuccess = true;
            }else {
                state.isPermissionsSuccess = false;
            }
        });

        builder.addCase(getAllModulePermissions.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isPermissionsCreated = false;
            state.isPermissionsSuccess = false;
        });

        builder.addCase(addNewRolePermissions.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.isPermissionsCreated = true;
                state.error = 'false';
            }else{
                state.error = action.payload.data || null;
                state.isPermissionsCreated = false;
            }
            state.error = 'true';

        });

        builder.addCase(addNewRolePermissions.rejected, (state, action) => {
            state.error = action.payload.error;
            state.isPermissionsCreated = false;
        });



    }
});

export default RolePermissionsSlice.reducer;