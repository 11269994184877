import React from 'react';
import { Spinner } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ExamLoader = (props) => {
    return (
        <React.Fragment>
            <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="position-relative">
            <div className="spinner-overlay"></div>
                <Spinner color="dark"> Loading... </Spinner>
            </div>
            </div>
            {toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}
        </React.Fragment>
    );
};

export default ExamLoader;
