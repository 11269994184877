import React, { useEffect, useState } from "react";
import UiContent from "../../Components/Common/UiContent";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import Loader from "../../Components/Common/Loader";
import Select from "react-select";
import {Link, useParams} from "react-router-dom";
import AudioPlayer from "../../Components/Common/AudioPlayer";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import {Formik, useFormik} from "formik";
import * as Yup from "yup";
import { api } from "../../config";
import {
  addNewQuestion as onAddNewQuestion,
  getDataForQuestion as onGetDataForQuestion, resetFlags,
} from "../../slices/createQuestion/thunk";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row, Spinner,
} from "reactstrap";
import VideoPlayer from "../../Components/Common/VideoPlayer";
import {resetLoginFlag} from "../../slices/auth/login/thunk";

const CreateQuestion = () => {
  const audioSource = api.IMAGE_URL;

  const dispatch = useDispatch();
  const [language, setLanguage] = useState([]);
  const [editQuestionData, setEditQuestionData] = useState([]);
  const [course, setCourse] = useState([]);
  const [topicArea, setTopicArea] = useState([]);
  const [selectedMultiCourses, setselectedMultiCourses] = useState([]);
  const [selectedMulti, setselectedMulti] = useState([]);
  const [selectedMultiCourseId, setselectedMultiCourseId] = useState([]);
  const [showFormElements, setShowFormElements] = useState(false);
  const [isEditQuestion, setIsEditQuestion] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [errorSelect, setErrorSelect] = useState('');
  const [errorVideo, setErrorVideo] = useState('');
  const id = useParams();
  const [q_image, setQ_image] = useState(null);
  const [q_audio, setQ_audio] = useState(null);
  const [q_video, setQ_video] = useState(null);
  const [opt_a_img, setopt_a_img] = useState(null);
  const [opt_b_img, setopt_b_img] = useState(null);
  const [opt_c_img, setopt_c_img] = useState(null);
  const [opt_a_audio, setopt_a_audio] = useState(null);
  const [opt_b_audio, setopt_b_audio] = useState(null);
  const [opt_c_audio, setopt_c_audio] = useState(null);

  const handleQimage = (e) => {
    setQ_image(e.target.files[0]);
  };
  const handleQaudio = (e) => {
    setQ_audio(e.target.files[0]);
  };
  const handleq_video = (e) => {
    setQ_video(e.target.files[0]);
  };
  const handleopt_a_img = (e) => {
    setopt_a_img(e.target.files[0]);
  };
  const handleopt_b_img = (e) => {
    setopt_b_img(e.target.files[0]);
  };
  const handleopt_c_img = (e) => {
    setopt_c_img(e.target.files[0]);
  };
  const handleopt_a_audio = (e) => {
    setopt_a_audio(e.target.files[0]);
  };
  const handleopt_b_audio = (e) => {
    setopt_b_audio(e.target.files[0]);
  };
  const handleopt_c_audio = (e) => {
    setopt_c_audio(e.target.files[0]);
  };

  const handleMulti = (s) => {
    setselectedMultiCourseId(s.map((item) => item.value));
    setselectedMulti(s);
  };

  const selectLayoutState = (state) => state.CreateQuestion;
  const createQuestionProperties = createSelector(selectLayoutState, (app) => ({
    editQuestions: app.editQuestions,
    languages: app.languages,
    courses: app.courses,
    topicAreas: app.topicAreas,
    isAddQuestionSuccess: app.isAddQuestionSuccess,
    isAddQuestionCreated: app.isAddQuestionCreated,
    error: app.error,
  }));

  const {
    languages,
    courses,
    topicAreas,
    editQuestions,
    isAddQuestionSuccess,
    isAddQuestionCreated,
    error,
  } = useSelector(createQuestionProperties);

  useEffect(() => {
    if (id.id ) {
    dispatch(onGetDataForQuestion(id.id));
    }
  }, [dispatch,isAddQuestionSuccess]);

  useEffect(() => {
    if (isAddQuestionCreated) {
      setIsloading(false);
      !isEditQuestion ? validation.resetForm() : "";
    }
      error? setIsloading(false):null;
      dispatch(resetFlags());
  }, [dispatch,error,isAddQuestionCreated]);

  useEffect(() => {
    setLanguage(languages);
    setCourse(courses);
    setTopicArea(topicAreas);
    setLoading(!isAddQuestionSuccess);
  }, [languages,isAddQuestionSuccess]);

  useEffect(() => {
    if (!isEmpty(editQuestions)) {
    id.id > 0 ? setIsEditQuestion(true) : setIsEditQuestion(false);
      setEditQuestionData(editQuestions);
      setLoading(!isAddQuestionSuccess);
      setselectedMultiCourses(editQuestions.questions['q_courses']);

      if(id.id > 0){
      setselectedMulti(
          editQuestions.questions['q_courses'].map((item) =>({
                label:item.course.course_translation[0].full_name ,value:item.course_id
              }
          ))
      );
      setselectedMultiCourseId(
          editQuestions.questions['q_courses'].map((item) =>(
              item.course_id
          ))
      );


    }



    }

  }, [languages,editQuestions,isAddQuestionSuccess]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: (isEditQuestion ? id && id.id : "") || "",
      lang:
        (isEditQuestion ? editQuestionData && editQuestionData.lang_id : "") ||
        "",
      q_type:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.q_type
          : "") || "",
      topic_id:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.topic_id
          : "") || "",
      q_title:
        (isEditQuestion ? editQuestionData && editQuestionData.q_title : "") ||
        "",
      q_code:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.code
          : "") || "",
      q_audio:
        (isEditQuestion ? editQuestionData && editQuestionData.q_audio : "") ||
        "",
      q_img:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.q_image
          : "") || "",
      opt_a:
        (isEditQuestion ? editQuestionData && editQuestionData.opt_a : "") ||
        "",
      opt_b:
        (isEditQuestion ? editQuestionData && editQuestionData.opt_b : "") ||
        "",
      opt_c:
        (isEditQuestion ? editQuestionData && editQuestionData.opt_c : "") ||
        "",
      opt_a_img:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.opt_a_image
          : "") || "",
      opt_b_img:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.opt_b_image
          : "") || "",
      opt_c_img:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.opt_c_image
          : "") || "",
      opt_a_audio:
        (isEditQuestion
          ? editQuestionData && editQuestionData.opt_a_audio
          : "") || "",
      opt_b_audio:
        (isEditQuestion
          ? editQuestionData && editQuestionData.opt_b_audio
          : "") || "",
      opt_c_audio:
        (isEditQuestion
          ? editQuestionData && editQuestionData.opt_c_audio
          : "") || "",
      correct_opt:
        (isEditQuestion
          ? editQuestionData && editQuestionData.questions.correct_opt
          : "") || "",
      course_id:[],
      is_video_q: (isEditQuestion
          ? editQuestionData && editQuestionData.questions.q_is_video
          : "") || "",
      q_video: (isEditQuestion
          ? editQuestionData && editQuestionData.questions.q_video
          : "") || "",
      switchCheckbox:(isEditQuestion
          ? editQuestionData && editQuestionData.questions.q_is_video==1 ? true : false
          : false) || false,

    },
    validationSchema: Yup.object({
      lang: Yup.string().required("Please Select Language"),
      q_type: Yup.string().required("Please Select Question Type"),
      topic_id: Yup.string().required("Please Select Topic Area"),
      q_title: Yup.string().required("Please Select Question Title"),
      // course_id: Yup.string().required("Please Select Question Course"),
      // course_id: Yup.array().min(1, 'Select at least one option'),
      q_code: Yup.string().required("Please Select Question Code"),
      correct_opt: Yup.string().required("Please Select Option"),
    }),

    onSubmit: (values) => {

      setIsloading(true);

      const newQuestion = {
        question_edit_id: values["id"] ? values["id"] : "",
        lang_id: values["lang"] ? values["lang"] : "",
        q_type: values["q_type"] ? values["q_type"] : "",
        topic_id: values["topic_id"] ? values["topic_id"] : "",
        q_title: values["q_title"] ? values["q_title"] : "",
        q_code: values["q_code"] ? values["q_code"] : "",
        q_video: q_video ? q_video : "",
        is_video: values["switchCheckbox"] && values["switchCheckbox"] ? "1" : "0",
        q_image: q_image ? q_image : "",
        q_audio: q_audio ? q_audio : "",
        correct_opt: values["correct_opt"] ? values["correct_opt"] : "",
        opt_a: values["opt_a"] ? values["opt_a"] : "",
        opt_b: values["opt_b"] ? values["opt_b"] : "",
        opt_c: values["opt_c"] ? values["opt_c"] : "",
        opt_a_audio: opt_a_audio ? opt_a_audio : "",
        opt_b_audio: opt_b_audio ? opt_b_audio : "",
        opt_c_audio: opt_c_audio ? opt_c_audio : "",
        opt_a_image: opt_a_img ? opt_a_img : "",
        opt_b_image: opt_b_img ? opt_b_img : "",
        opt_c_image: opt_c_img ? opt_c_img : "",
        course_id: selectedMultiCourseId ? selectedMultiCourseId : "",
      };
      console.log(newQuestion);
      dispatch(onAddNewQuestion(newQuestion));

    },
  });

  const questionTypeList = [
    {
      options: [
        { label: "Select Question Type", value: "" },
        { label: "Common", value: "common" },
        { label: "Specific", value: "specific" },
      ],
    },
  ];
  const questionTypeListSpecific = [
    {
      options: [
        { label: "Select Question Type", value: "" },
        { label: "Specific", value: "specific" },
      ],
    },
  ];

  const handleSwitchToggle = () => {
    setShowFormElements(!showFormElements);
  };

  const SingleOptions = course.map((item) => ({
    value: item.id,
    label: item.course_translation[0].full_name,
  }));
  document.title =
    (!!isEditQuestion ? "Edit " : "Add ") + "Question | Theory Practice";
  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={(!!isEditQuestion ? "Edit " : "Add ") + "Question"}
            pageTitle="Question Bank"
          />
          <Row>
            <Col xxl={12}>
              <Card>
                <CardHeader className="border-1">
                  <Row className="g-4 align-items-center d-flex">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          {(!!isEditQuestion ? "Edit " : "Add New ") +
                            "Question"}
                        </h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div className="flex-shrink-0">
                        <div className="form-check form-switch form-switch-right form-switch-md">
                          <label
                            htmlFor="form-grid-showcode"
                            className="form-label text-muted"
                          >
                            Is Video Question
                          </label>
                          <Input
                              type="checkbox"
                              id="switchCheckbox"
                              name="switchCheckbox"
                              checked={validation.values.switchCheckbox}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              className="form-check-input"
                          />
                        </div>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <CardBody>
                  {(loading ?
                      <React.Fragment>
                        <div className="d-flex justify-content-center mx-2 mt-2">
                          <Spinner color="primary"> Loading... </Spinner>
                        </div>
                      </React.Fragment>
                      :<div>
                  {isAddQuestionSuccess ? (
                    <div className="">
                      <Form className="tablelist-form" onSubmit={(e) => {
                            e.preventDefault();
                            if (selectedMulti.length === 0 && validation.values.q_type == "specific" ) {
                              setErrorSelect('Please select at least one option.');
                            } else {
                              if (!isEditQuestion && validation.values.switchCheckbox && !q_video) {
                                  setErrorVideo('Please select video.');
                              }else {
                                validation.handleSubmit();
                                setErrorSelect('');
                                setErrorVideo('');
                              }
                            }
                            return false;
                          }}>
                        <input
                            type="hidden"
                            id="id"
                            value={validation.values.id || ""}
                        />
                        <Row className="mb-3">
                          <div className="col-lg-6 mb-3">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="status-field"
                                  className="form-label"
                              >
                                Language
                              </Label>
                              <Input
                                  name="lang"
                                  type="select"
                                  className="form-select"
                                  id="status-field"
                                  validate={{
                                    required: {value: true},
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.lang || ""}
                                  invalid={
                                    validation.touched.lang && validation.errors.lang ? true : false
                                  }
                              >
                                <React.Fragment>
                                  <option value="">Select Language</option>
                                  {language.map((item, key) => (
                                      <option value={item.id} key={key}>
                                        {item.lang}
                                      </option>
                                  ))}
                                </React.Fragment>
                              </Input>
                              {validation.touched.lang &&
                              validation.errors.lang ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lang}
                                  </FormFeedback>
                              ) : null}
                            </div>
                          </div>

                          <div className="col-lg-6">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="status-field" className="form-label">
                                Question Type
                              </Label>
                              <Input
                                  name="q_type"
                                  type="select"
                                  className="form-select"
                                  id="q_type-field"
                                  validate={{
                                    required: {value: true},
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.q_type || ""}
                                  invalid={validation.touched.q_type && validation.errors.q_type ? true : false
                                  }
                              >
                             
                                 {validation.values.switchCheckbox ? (

                                questionTypeListSpecific.map((item, key) => (
                                    <React.Fragment key={key}>
                                      {item.options.map((item, key) => (
                                          <option value={item.value} key={key}>
                                            {item.label}
                                          </option>
                                      ))}
                                    </React.Fragment>
                                ))
                              ) : (
                                questionTypeList.map((item, key) => (
                                    <React.Fragment key={key}>
                                      {item.options.map((item, key) => (
                                          <option value={item.value} key={key}>
                                            {item.label}
                                          </option>
                                      ))}
                                    </React.Fragment>
                                ))
                              )}
                              </Input>
                              {validation.touched.q_type && validation.errors.q_type ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.q_type}
                                  </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          {validation.values.q_type == "specific" && (
                              <div className="col-lg-6">
                                <div className="mb-3 mb-lg-0">
                                  <Label
                                      htmlFor="course_id-field"
                                      className="form-label">
                                    Course
                                  </Label>

                                  <div className="filter-choices-input">
                                    <Select
                                        value={selectedMulti}
                                        name="course_id"
                                        isMulti={true}
                                        type="select"
                                        id="course_id-field"
                                        validate={{
                                          required: {value: true},
                                        }}
                                        invalid={
                                          validation.touched.course_id && validation.errors.course_id ? true : false
                                        }
                                        onChange={(e) => handleMulti(e)}
                                        onBlur={validation.handleBlur}
                                        options={SingleOptions}
                                        isSearchable={true}
                                    />
                                    {errorSelect && <div style={{ color: 'red' }}>{errorSelect}</div>}
                                  </div>
                                </div>
                              </div>
                          )}
                          <div className="col-lg-6">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="topic_id-field"
                                  className="form-label">
                                Topic Area
                              </Label>
                              <Input
                                  name="topic_id"
                                  type="select"
                                  className="form-select"
                                  id="topic_id-field"
                                  validate={{
                                    required: {value: true},
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.topic_id || ""}
                                  invalid={validation.touched.topic_id && validation.errors.topic_id ? true : false}
                              >
                                <React.Fragment>
                                  <option value="">Select Topic Area</option>
                                  {topicArea.map((item, key) => (
                                      <option value={item.id} key={key}>
                                        {item.topic_area_translation[0].full_name}
                                      </option>
                                  ))}
                                </React.Fragment>
                              </Input>
                              {validation.touched.topic_id && validation.errors.topic_id ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.topic_id}
                                  </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-12">
                            <Label htmlFor="q_des-field" className="form-label">
                              Question Description
                            </Label>
                            <textarea
                                className="form-control"
                                id="q_des-field"
                                rows="3"
                                name="q_title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.q_title || ""}
                            />
                            {validation.touched.q_title && validation.errors.q_title ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.q_title}
                                </FormFeedback>
                            ) : null}
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-12">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="q_code-field"
                                  className="form-label">
                                Question Code
                              </Label>
                              <Input
                                  name="q_code"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Question Code........"
                                  id="q_code-field"
                                  validate={{
                                    required: {value: true},
                                  }}
                                  onChange={validation.handleChange}
                                  onBlur={validation.handleBlur}
                                  value={validation.values.q_code || ""}
                                  invalid={validation.touched.q_code && validation.errors.q_code ? true : false}
                              ></Input>
                              {validation.touched.q_code && validation.errors.q_code ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.q_code}
                                  </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                        </Row>
                        {validation.values.switchCheckbox && (
                            <Row className="mb-3">
                              <div className="col-lg-3">
                                <div className="mb-3 mb-lg-0">
                                  <Label
                                      htmlFor="q_video-field"
                                      className="form-label">
                                    Question Video
                                  </Label>
                                  <Input
                                      name="q_video"
                                      type="file"
                                      className="form-control"
                                      placeholder="Enter Question Video........"
                                      id="q_video-field"
                                      validate={{
                                        required: {value: true},
                                      }}
                                      onChange={(event) => {
                                        handleq_video(event);
                                      }}
                                      onBlur={validation.handleBlur}
                                      accept="video/*"
                                  ></Input>
                                  {errorVideo && <div style={{ color: 'red' }}>{errorVideo}</div>}
                                </div>
                              </div>
                              {validation.values.is_video_q && validation.values.is_video_q == 1 && (
                                      <div className="col-lg-5">
                                        <div className="mb-3 mb-lg-0">
                                          <Label
                                              htmlFor="q_audio-field"
                                              className="form-label">
                                            Question Video
                                          </Label>
                                         <VideoPlayer
                                           src={editQuestionData && validation.values.q_video ? audioSource + validation.values.q_video : ""}
                                         />
                                        </div>
                                      </div>
                                  )}
                            </Row>
                        )}

                            <Row className="mb-3">
                              <div className="col-lg-3">
                                <div className="mb-3 mb-lg-0">
                                  <Label
                                      htmlFor="q_img-field"
                                      className="form-label">
                                    Question Image
                                  </Label>
                                  <Input
                                      name="q_img"
                                      type="file"
                                      className="form-control"
                                      placeholder="Enter Question Image........"
                                      id="q_img-field"
                                      validate={{required: {value: true},}}
                                      onChange={(event) => {
                                        handleQimage(event);
                                      }}
                                      onBlur={validation.handleBlur}
                                      accept=".png,.jpg,.jpeg"
                                  ></Input>
                                  {validation.touched.q_img && validation.errors.q_img ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.q_img}
                                      </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                              {validation.values.q_img && validation.values.q_img && (
                                      <div className="col-lg-2">
                                        <div className="mb-3 mb-lg-0">
                                          <Label
                                              htmlFor="q_img-field"
                                              className="form-label">
                                            Question Image
                                          </Label>
                                            <div className="p-2">
                                              <div className="align-items-center">
                                                <div className="col-auto">
                                                  <img
                                                      data-dz-thumbnail=""
                                                      width="100%"
                                                      height="auto"
                                                      className="avatar-lg rounded bg-light"
                                                      alt=""
                                                      src={audioSource + validation.values.q_img}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                        </div>
                                      </div>
                                  )}
                              <div className="col-lg-3">
                                <div className="mb-3 mb-lg-0">
                                  <Label
                                      htmlFor="q_audioa-field"
                                      className="form-label">
                                    Question Audio
                                  </Label>

                                  <Input
                                      name="q_audioa"
                                      type="file"
                                      className="form-control"
                                      placeholder="Enter Question Audio........"
                                      id="q_audioa-field"
                                      onChange={(event) => {
                                        handleQaudio(event);
                                      }}
                                      onBlur={validation.handleBlur}
                                      accept="audio/*"
                                  ></Input>
                                  {validation.touched.q_audio && validation.errors.q_audio ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.q_audio}
                                      </FormFeedback>
                                  ) : null}
                                </div>
                              </div>
                              {validation.values.q_audio && validation.values.q_audio != 1 && (
                                      <div className="col-lg-3">
                                        <div className="mb-3 mb-lg-0">
                                          <Label
                                              htmlFor="q_audio-field"
                                              className="form-label">
                                            Question Audio
                                          </Label>
                                          <AudioPlayer
                                              src={editQuestionData ? audioSource + validation.values.q_audio : ""}
                                          />
                                        </div>
                                      </div>
                                  )}
                            </Row>

                        <div className="card-header align-items-center d-flex mb-3">
                          <h5 className="card-title mb-0 flex-grow-1">
                            Answer Options
                          </h5>
                        </div>
                        <Row className="mb-3">
                          <div className="col-lg-5 mb-3">
                            <div className="mb-3 mb-lg-0">
                              <label
                                  htmlFor="choices-priority-input"
                                  className="form-label">
                                {" "}
                                Option A
                              </label>
                              <div className="input-group">
                                <div className="input-group-text">
                                  <Input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="correct_opt"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={"a"}
                                      invalid={validation.touched.correct_opt && validation.errors.correct_opt ? true : false}
                                      checked={validation.values.correct_opt == "a" || ""}
                                  />
                                  {validation.touched.correct_opt && validation.errors.correct_opt ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.correct_opt}
                                      </FormFeedback>
                                  ) : null}
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    aria-label="Text input with checkbox"
                                    name="opt_a"
                                    placeholder="Option A"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.opt_a || ""}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="opt_a_img-field"
                                  className="form-label">
                                Option A Image
                              </Label>
                              <Input
                                  name="opt_a_img"
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Question Image........"
                                  id="opt_a_img-field"
                                  onChange={(event) => {
                                    handleopt_a_img(event);
                                  }}
                                  onBlur={validation.handleBlur}
                                  accept=".png,.jpg,.jpeg"
                              ></Input>
                              {validation.touched.opt_a_img && validation.errors.opt_a_img ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.opt_a_img}
                                  </FormFeedback>
                              ) : null}
                              {validation.values.opt_a_img && validation.values.opt_a_img != 1 && (
                                      <div className="mt-3">
                                          <img
                                              data-dz-thumbnail=""
                                              className="avatar-lg rounded bg-light"
                                              alt=""
                                              src={audioSource + validation.values.opt_a_img}/>
                                      </div>
                                  )}
                            </div>
                          </div>

                          <div className="col-lg-4">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="opt_a_audio-field"
                                  className="form-label">
                                Option A Audio
                              </Label>
                              <Input
                                  name="opt_a_audio"
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Question Audio........"
                                  id="opt_a_audio-field"
                                  onChange={(event) => {
                                    handleopt_a_audio(event);
                                  }}
                                  onBlur={validation.handleBlur}
                                  accept="audio/*"
                              ></Input>
                              {validation.touched.opt_a_audio && validation.errors.opt_a_audio ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.opt_a_audio}
                                  </FormFeedback>
                              ) : null}
                            </div>
                            {validation.values.opt_a_audio && validation.values.opt_a_audio != 1 && (
                                    <div className="mt-3 mb-lg-0">
                                      <AudioPlayer
                                          src={editQuestionData ? audioSource + validation.values.opt_a_audio : ""}
                                      />
                                    </div>
                                )}
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-5 mb-3">
                            <div className="mb-3 mb-lg-0">
                              <label
                                  htmlFor="choices-priority-input"
                                  className="form-label">
                                {" "}
                                Option B
                              </label>
                              <div className="input-group">
                                <div className="input-group-text">
                                  <Input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="correct_opt"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={"b"}
                                      invalid={validation.touched.correct_opt && validation.errors.correct_opt ? true : false}
                                      checked={validation.values.correct_opt == "b" || ""}
                                  />
                                  {validation.touched.correct_opt && validation.errors.correct_opt ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.correct_opt}
                                      </FormFeedback>
                                  ) : null}
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    aria-label="Text input with checkbox"
                                    name="opt_b"
                                    placeholder="Option B"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.opt_b || ""}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="opt_b_img-field"
                                  className="form-label">
                                Option B Image
                              </Label>
                              <Input
                                  name="opt_b_img"
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Question Image........"
                                  id="opt_b_img-field"
                                  onChange={(event) => {
                                    handleopt_b_img(event);
                                  }}
                                  onBlur={validation.handleBlur}
                                  accept=".png,.jpg,.jpeg"
                              ></Input>
                              {validation.touched.opt_b_img && validation.errors.opt_b_img ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.opt_b_img}
                                  </FormFeedback>
                              ) : null}
                            </div>
                            {validation.values.opt_b_img && validation.values.opt_b_img != 1 && (
                                    <div className="mt-3">
                                        <img
                                            data-dz-thumbnail=""
                                            className="avatar-lg rounded bg-light"
                                            alt=""
                                            src={audioSource + validation.values.opt_b_img}
                                        />
                                    </div>
                                )}
                          </div>

                          <div className="col-lg-4">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="opt_b_audio-field"
                                  className="form-label">
                                Option B Audio
                              </Label>
                              <Input
                                  name="opt_b_audio"
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Question Audio........"
                                  id="opt_b_audio-field"
                                  onChange={(event) => {
                                    handleopt_b_audio(event);
                                  }}
                                  onBlur={validation.handleBlur}
                                  accept="audio/*"
                              ></Input>
                              {validation.touched.opt_b_audio && validation.errors.opt_b_audio ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.opt_b_audio}
                                  </FormFeedback>
                              ) : null}

                              {validation.values.opt_b_audio && validation.values.opt_b_audio != 1 && (
                                      <div className="mt-3 mb-lg-0">
                                        <AudioPlayer
                                            src={editQuestionData ? audioSource + validation.values.opt_b_audio : ""}
                                        />
                                      </div>
                                  )}
                            </div>
                          </div>
                        </Row>
                        <Row className="mb-3">
                          <div className="col-lg-5 mb-3">
                            <div className="mb-3 mb-lg-0">
                              <label
                                  htmlFor="choices-priority-input"
                                  className="form-label">
                                {" "}
                                Option C
                              </label>
                              <div className="input-group">
                                <div className="input-group-text">
                                  <Input
                                      className="form-check-input mt-0"
                                      type="radio"
                                      name="correct_opt"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={"c"}
                                      invalid={validation.touched.correct_opt && validation.errors.correct_opt ? true : false}
                                      checked={validation.values.correct_opt == "c" || ""}
                                  />
                                  {validation.touched.correct_opt && validation.errors.correct_opt ? (
                                      <FormFeedback type="invalid">
                                        {validation.errors.correct_opt}
                                      </FormFeedback>
                                  ) : null}
                                </div>
                                <Input
                                    type="text"
                                    className="form-control"
                                    aria-label="Text input with checkbox"
                                    name="opt_c"
                                    placeholder="Option C"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.opt_c || ""}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-3">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="opt_c_img-field"
                                  className="form-label">
                                Option C Image
                              </Label>
                              <Input
                                  name="opt_c_img"
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Question Image........"
                                  id="opt_c_img-field"
                                  onChange={(event) => {
                                    handleopt_c_img(event);
                                  }}
                                  onBlur={validation.handleBlur}
                                  accept=".png,.jpg,.jpeg"
                              ></Input>
                              {validation.touched.opt_c_img && validation.errors.opt_c_img ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.opt_c_img}
                                  </FormFeedback>
                              ) : null}
                            </div>
                            {validation.values.opt_c_img && validation.values.opt_c_img != 1 && (
                                    <div className="mt-3">
                                        <img
                                            data-dz-thumbnail=""
                                            className="avatar-lg rounded bg-light"
                                            alt=""
                                            src={audioSource + validation.values.opt_c_img}
                                        />
                                    </div>
                                )}
                          </div>

                          <div className="col-lg-4">
                            <div className="mb-3 mb-lg-0">
                              <Label
                                  htmlFor="opt_c_audio-field"
                                  className="form-label">
                                Option C Audio
                              </Label>
                              <Input
                                  name="opt_c_audio"
                                  type="file"
                                  className="form-control"
                                  placeholder="Enter Question Audio........"
                                  id="opt_c_audio-field"
                                  onChange={(event) => {
                                    handleopt_c_audio(event);
                                  }}
                                  onBlur={validation.handleBlur}
                                  accept="audio/*"
                              ></Input>
                              {validation.touched.opt_c_audio && validation.errors.opt_c_audio ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.opt_c_audio}
                                  </FormFeedback>
                              ) : null}

                              {validation.values.opt_c_audio && validation.values.opt_c_audio != 1 && (
                                      <div className="mt-3 mb-lg-0">
                                        <AudioPlayer
                                            src={editQuestionData ? audioSource + validation.values.opt_c_audio : ""}
                                        />
                                      </div>
                              )}
                            </div>
                          </div>
                        </Row>
                        <div className="row g-3 mt-2">
                          <div className="hstack gap-2 justify-content-end">
                            <Link to="/admin/question-bank" className="btn btn-light">Close</Link>
                            <Button color="primary" disabled={isloading ? true : false} className="btn-load btn-label right  next tab btn-submit" type="submit">
                              <span className="d-flex align-items-center">
                                    {isloading ?
                                        <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}
                                <i className="ri-rocket-line label-icon align-middle fs-16 ms-2"></i>
                                    <span className="flex-grow-1 ms-2">
                                    {!!isEditQuestion ? "Save Changes" : "Save"}
                                    </span>
                                </span>
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  ) : (
                      <div className="py-4 text-center">
                        <div>
                          <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{width: "72px", height: "72px"}}
                          ></lord-icon>
                        </div>
                        <div className="mt-4">
                          <h5>Sorry! No Record Found</h5>
                        </div>
                      </div>
                  )}
                      </div>)}
                  <ToastContainer closeButton={false} limit={1}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default CreateQuestion;
