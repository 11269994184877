import React from 'react';
import ReactPlayer from 'react-player';

function VideoPlayer({ src }) {
    return (
        <div>
            <ReactPlayer
                url={ src }
                controls={true}
                width="50%"
                height="50%"
            />
        </div>
    );
}

export default VideoPlayer;
