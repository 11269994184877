import React from 'react'
import { useTranslation } from 'react-i18next';
import TimerComponent2 from '../../timer/TimerComponent2';
import FullScreenDropdown from '../../../../Components/Common/FullScreenDropdown';
import { Button } from 'reactstrap';



const Template2Header = (props) => {
  const {handelExit,timeProps,direction,percentage,stdName,trafficId,examType,examDuration,handleTimeUp,isReviewMode,index,totalQuestion,courseName,reviewAfterMarkedStack,handleReviewAfter,isShowRevSection} = props;


  const { t, i18n } = useTranslation();



  return (
    <>
<header id="page-topbar" style={{position:"unset"}}>
    <div className="bg-primary layout-fluid">
        <div className="navbar-header">
            <div className="d-flex align-items-center">
         
              
                <div className="col-md pb-0 px-4 pt-2 practice-heading">
                    <div className="row align-items-center g-3">
                        <div className="col-md">
                            <div>
                                <h4 className="text-light fw-bold">{stdName} | {trafficId}</h4>
                                <div className="hstack gap-2 text-light flex-wrap">
                                

                                    <div><i className="ri-book-line align-bottom me-1"></i>{courseName}
                                       
                                        </div>
                                    <div className="vr"></div>
                                    <div className="badge rounded-pill bg-warning fs-12">{examType==1?'Exam':'Practice'}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              
            </div>
            <div className="d-flex align-items-center">
                <div className='col-md vstack'>
                    <div className="ms-1">
                        <TimerComponent2 
                                duration={examDuration}
                                handleTimeUp={handleTimeUp} 
                                examType={examType}
                                />
                    </div>
                    <div className='col-md'>
                        <h6 className={`mb-0 text-white fs-5 ${direction === 'ltr' ? 'me-3 text-end' : 'ms-3 text-start'}`} style={{ direction: "ltr"}}>
                            <span className={`mb-0 text-white fw-bold fs-5 ${direction === 'ltr' ? 'me-1' : 'ms-1'}`}> # </span>
                                <span className="index-val">{index + 1}</span>/<span className="total-question">{totalQuestion}</span> 
                            
                        </h6>
                    </div>
                    {/* <div className="card-body">
                        <div className="d-flex">
                            <div className="flex-shrink-0">
                                
                            </div>
                            
                     
                           
                        </div>
                    </div>
                    <div className="progress bg-soft-warning rounded-0">
                    <div className="progress-bar bg-success" role="progressbar"   style={{ width: `${percentage}%` }} aria-valuenow="30" aria-valuemin="0" aria-valuemax="100">
                        </div>'
                    </div> */}
                    
                </div>

                

               {examType==2?
                            <div className="ms-1 header-item d-sm-flex">
                                <button className="btn btn-icon btn-top-bar btn-ghost-secondary rounded-circle" title='Exit' onClick={() => { handelExit() }}><i className="bx bx-exit fs-22 text-light"></i> </button>
                            </div>
                            :''}
                            <FullScreenDropdown />
                            {isReviewMode?
                            <i className='ri-close-line fs-36 text-light cursor-pointer' onClick={() => { handelExit() }} title={t('Exit Review')}></i>
                  
                            :''}

{examType==1 && !isShowRevSection?
                        <div className="flex-grow-1 fs-2">
                            <input className="form-check-input" type="checkbox" id="formCheck6"  onChange={(e) => handleReviewAfter(e, index)}
                             checked={reviewAfterMarkedStack.includes(index)}
                             style={{backgroundColor:"#ffffff", borderColor:"#ffffff"}}
                            />
                        <label className="form-check-label text-white " htmlFor="formCheck6">Review Later</label>
        
    
                            </div>
                            :''}

       


            
            </div>
        </div>
    </div>
</header>
    </>
  )
}

export default Template2Header
