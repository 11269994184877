import React, { useCallback, useEffect, useMemo, useState } from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    addNewUser as onAddNewUser, deleteUser as onDeleteUser,
    getUsers as onGetUsers,
    updateUser as onUpdateUser
} from "../../slices/user/thunk";

import { getBranchRooms as onGetBranchRooms } from "../../slices/room/thunk";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Link, Navigate } from "react-router-dom";
import { isEmpty } from "lodash";
import { Route, useNavigate } from 'react-router-dom'
import AuthorizeForAction from "../../Routes/AuthorizeForAction";

const Users = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);

    const [modal, setModal] = useState(false);

    const [user, setUser] = useState([]);
    const [role, setRole] = useState([]);
    const [branch, setBranch] = useState([]);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    // Delete customer
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const [lastdeletedtitle, setlastdeletedtitle] = useState("");

    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);
    const [branchRooms, setBranchRooms] = useState([]);


    const selectLayoutState = (state) => state.User;
    const usersProperties = createSelector(
        selectLayoutState,
        (data) => ({
            users: data.users,
            roles: data.roles,
            branches: data.branches,
            isUserSuccess: data.isUserSuccess,
            isUserCreated: data.isUserCreated,
            error: data.error,
        })
    );
    // Inside your component
    const {
        users, roles, isUserSuccess, error, branches, isUserCreated
    } = useSelector(usersProperties)


    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);


    useEffect(() => {
        // if (users && !users.length) {
        dispatch(onGetUsers());
        // }
    }, [dispatch]);


    useEffect(() => {
        setUser(users);
        setRole(roles);
        setBranch(branches);
        setLoading(!isUserSuccess);
        if (isUserCreated) {
            setIsloading(false);
            validation.resetForm();
            setModal(false);
        }
        error ? setIsloading(false) : null
    }, [users, roles, branches, isUserSuccess, isUserCreated, error]);

    useEffect(() => {
        if (!isEmpty(users)) {
            setUser(users);
            setRole(roles);
    
            setBranch(branches);
            setLoading(!isUserSuccess);
            setIsEdit(false);
        }
    }, [users, roles, branches, isUserSuccess, isUserCreated]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id: (isEdit ? (user && user.id) : "") || '',
            name: (isEdit ? (user && user.name) : "") || '',
            email: (isEdit ? (user && user.email) : "") || '',
            phone: (isEdit ? (user && user.phone) : "") || '',
            role_id: (isEdit ? (user && user.role_id) : "") || '',
            branch_id: (isEdit ? (user && user.branch_id) : "") || '',
            room_id: (isEdit ? (user && user.room_id) : "") || '',
            password: (isEdit ? (user && user.password) : "") || '',
            confirm_password: (isEdit ? (user && user.password) : "") || '',
            status: (isEdit ? (user && user.status) : "") || '',
        },

        validationSchema: Yup.object({

            name: Yup.string().required("Please enter name"),
            email: Yup.string().required("Please enter email"),
            phone: Yup.string().required("Please enter phone"),
            password: (!isEdit ? Yup.string().required("Please enter password") : ""),
            confirm_password: (!isEdit ? Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match') : ""),
            role_id: Yup.string().required("Please Select Role"),
         
            status: Yup.string().required("Please Select Your Status")
        }),
        onSubmit: (values) => {
            setIsloading(true);
            if (isEdit) {
                dispatch(onUpdateUser(values));
            } else {
                dispatch(onAddNewUser(values));
            }
        },
    });

    const userStatus = [
        {

            options: [
                { label: "Select Status", value: "" },
                { label: "Active", value: "1" },
                { label: "In-Active", value: "2" },
            ],
        },
    ];




    const handleCustomerClick = useCallback((arg) => {
        const muser = arg;
     
     
            (muser.branch_id > 0 )?  dispatch(onGetBranchRooms(arg.branch_id)) :''
    
       
        setUser({
            id: muser.id,
            name: muser.name,
            email: muser.email,
            phone: muser.phone,
            role_id: muser.role.id,
            branch_id: muser.branch_id,
            room_id: muser.room_id,
            status: muser.status
        });
        setIsEdit(true);
        toggle();
    }, [toggle]);

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".customerCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".customerCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="customerCheckBox form-check-input" value={cellProps.row.original.id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: " User",
                accessor: "name",
                filterable: false,
            },
            {
                Header: " Email",
                accessor: "email",
                filterable: false,
            },
            {
                Header: "Branch",
                accessor: "branch",
                Cell: (cell) => {
                    return (
                        <span className="badge text-uppercase bg-success-subtle text-success"> {cell.value ? cell.value.title : "No Branch"}</span>
                    );
                },
            },
            {
                Header: "Room",
                accessor: "room",
                Cell: (cell) => {
                   
                    return (
                        <span className="badge text-uppercase bg-success-subtle text-success"> {cell.value ? cell.value.title : "No Room"}</span>
                    );
                },
            },
            {
                Header: "Role",
                accessor: "role.name",
                filterable: false,
            },
            {
                Header: "Joining Date",
                accessor: "created_at",
                filterable: false,
            },

            {
                Header: "Status",
                accessor: "status",
                Cell: (cell) => {
                 
            
                    if(cell.value == 1){
                   
                        return <span className="badge text-uppercase bg-success-subtle text-success">Active</span>;
                    }
                    if(cell.value == 2){
                    
                        return <span className="badge text-uppercase bg-danger-subtle text-danger">In-Active</span>;
                    }

                 
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <AuthorizeForAction requiredPermissions={['user-edit']}>
                                <li className="list-inline-item edit" title="Edit">
                                    <Link
                                        to="#"
                                        className="text-primary d-inline-block edit-item-btn"
                                        onClick={() => { const customerData = cellProps.row.original; handleCustomerClick(customerData); }}
                                    >

                                        <i className="ri-pencil-fill fs-16"></i>
                                    </Link>
                                </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['user-delete']}>
                                <li className="list-inline-item" title="Remove">
                                    <Link
                                        to="#"
                                        className="text-danger d-inline-block remove-item-btn"
                                        onClick={() => { const customerData = cellProps.row.original; onClickDelete(customerData); }}
                                    >
                                        <i className="ri-delete-bin-5-fill fs-16"></i>
                                    </Link>
                                </li>
                            </AuthorizeForAction>
                        </ul>
                    );
                },
            },

        ],
        [handleCustomerClick, checkedAll]
    );

    const onClickDelete = (user) => {
        setUser(user);
        setDeleteModal(true);
    };

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(onDeleteUser(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    const handleCustomerClicks = () => {
        setUser("");
        setIsEdit(false);
        toggle();
    };

    const handleDeleteCustomer = () => {
        if (user) {
            dispatch(onDeleteUser(user.id));
            setDeleteModal(false);
        }
    };


   const handleChangeBrnachId = (event) => {
    dispatch(onGetBranchRooms(event.target.value))

      };



      const roomStates = (state) => state.Room;
      const roomProperties = createSelector(
             roomStates,
          (data) => ({ branchWiseRooms: data.branchWiseRooms }
            ));
           
      // Inside your component
      const {branchWiseRooms } = useSelector(roomProperties)
         useEffect(() => {
        setBranchRooms(branchWiseRooms)   
        }, [branchWiseRooms]);
    

    document.title = "Users | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteCustomer}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Users" pageTitle="Settings" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Users List</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div>
                                                <AuthorizeForAction requiredPermissions={['user-delete']}>
                                                    {isMultiDeleteButton && <button className="btn btn-soft-danger me-1"
                                                        onClick={() => setDeleteModalMulti(true)}
                                                    ><i className="ri-delete-bin-2-line"></i></button>}
                                                </AuthorizeForAction>
                                                <AuthorizeForAction requiredPermissions={['user-create']}>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success add-btn"
                                                        id="create-btn"
                                                        onClick={() => { setIsEdit(false); toggle(); }}>
                                                        <i className="ri-add-line align-bottom me-1"></i> Add
                                                        User
                                                    </button>{" "}
                                                </AuthorizeForAction>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0">
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                            {/*{toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}*/}
                                        </React.Fragment>
                                        : <div>
                                            {isUserSuccess && users.length ? (
                                                <TableContainer
                                                    columns={columns}
                                                    data={(users || [])}
                                                    isGlobalFilter={true}
                                                    isAddUserList={false}
                                                    customPageSize={8}
                                                    className="custom-header-css"
                                                    handleCustomerClick={handleCustomerClicks}
                                                    isCustomerFilter={false}
                                                    SearchPlaceholder='Search for User, status or something...'
                                                />
                                            ) : (
                                                <div className="py-4 text-center">
                                                    <div>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#405189,secondary:#0ab39c"
                                                            style={{ width: "72px", height: "72px" }}
                                                        ></lord-icon>
                                                    </div>

                                                    <div className="mt-4">
                                                        <h5>Sorry! No Record Found</h5>
                                                    </div>
                                                </div>
                                            )
                                            }
                                        </div>)}

                                    <Modal id="showModal" isOpen={modal} toggle={toggle} backdrop="static" centered className='modal modal-lg'>
                                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit User" : "Add User"}
                                        </ModalHeader>
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <ModalBody>
                                                <input type="hidden" id="id-field" />

                                        <div className='row mb-3'>

                                                <div
                                                    className="mb-3"
                                                    id="modal-id"
                                                    style={{ display: "none" }}
                                                >
                                                    <Label htmlFor="id-field1" className="form-label">
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="id-field1"
                                                        className="form-control"
                                                        placeholder="ID"
                                                        readOnly
                                                    />
                                                </div>
                                        

                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="name-field"
                                                        className="form-label"
                                                    >
                                                        Name
                                                    </Label>
                                                    <Input
                                                        name="name"
                                                        id="name-field"
                                                        className="form-control"
                                                        placeholder="Enter full name..."
                                                        type="text"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name && validation.errors.name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.name}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>

                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="email-field"
                                                        className="form-label"
                                                    >
                                                        Email
                                                    </Label>
                                                    <Input
                                                        name="email"
                                                        id="email-field"
                                                        className="form-control"
                                                        placeholder="Enter email..."
                                                        type="email"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            validation.touched.email && validation.errors.email ? true : false
                                                        }
                                                    />
                                                    {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>

                                                <div className='col-md-12'>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="phone-field"
                                                        className="form-label"
                                                    >
                                                        Phone
                                                    </Label>
                                                    <Input
                                                        name="phone"
                                                        id="phone-field"
                                                        className="form-control"
                                                        placeholder="Enter phone..."
                                                        type="text"
                                                       
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.phone || ""}
                                                        invalid={
                                                            validation.touched.phone && validation.errors.phone ? true : false
                                                        }
                                                    />
                                                    {validation.touched.phone && validation.errors.phone ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.phone}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>

                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="password-field"
                                                        className="form-label"
                                                    >
                                                        Password
                                                    </Label>
                                                    <Input
                                                        name="password"
                                                        id="password-field"
                                                        className="form-control"
                                                        placeholder="Enter password..."
                                                        type="password"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>

                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="confirm_password-field"
                                                        className="form-label"
                                                    >
                                                        Confirm Password
                                                    </Label>
                                                    <Input
                                                        name="confirm_password"
                                                        id="confirm_password-field"
                                                        className="form-control"
                                                        placeholder="Enter confirm password..."
                                                        type="password"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.confirm_password || ""}
                                                        invalid={
                                                            validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.confirm_password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>

                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label htmlFor="branch-field" className="form-label">Branch</Label>
                                                        
                                                    

                                                    <Input
                                                        name="branch_id"
                                                        type="select"
                                                        className="form-select"
                                                        id="branch-field"
                                                        // onChange={validation.handleChange}
                                                      
                                                        onChange={(event) => {
                                                            handleChangeBrnachId(event);
                                                            validation.handleChange(event);
                                                          }}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.branch_id || ""
                                                        }
                                                        invalid={
                                                            validation.touched.branch_id && validation.errors.branch_id ? true : false
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <option value="">Select Branch</option>
                                                            {branch.map((item, key) => (
                                                                <option value={item.id} key={key}>{item.title}</option>
                                                            ))}
                                                        </React.Fragment>

                                                    </Input>
                                                    {validation.touched.branch_id &&
                                                        validation.errors.branch_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.branch_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>
                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label htmlFor="room-field" className="form-label">Room </Label>
                                                        
                                                   

                                                    <Input
                                                        name="room_id"
                                                        type="select"
                                                        className="form-select"
                                                        id="room-field"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.room_id || ""
                                                        }
                                                        invalid={
                                                            validation.touched.room_id && validation.errors.room_id ? true : false
                                                        }
                                                    >
                                                        <React.Fragment>
                                                            <option value="">Select Room</option>
                                                            {branchRooms.map((item, key) => (
                                                                <option value={item.id} key={key}>{item.title}</option>
                                                            ))}
                                                        </React.Fragment>

                                                    </Input>
                                                    {validation.touched.room_id &&
                                                        validation.errors.room_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.room_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>
                                                
                                                <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <Label htmlFor="role-field" className="form-label">
                                                        Role
                                                    </Label>

                                                    <Input
                                                        name="role_id"
                                                        type="select"
                                                        className="form-select"
                                                        id="role-field"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.role_id || ""
                                                        }
                                                        invalid={
                                                            validation.touched.role_id && validation.errors.role_id ? true : false
                                                        }
                                                    >

                                                        <React.Fragment>
                                                            <option value="">Select Role</option>
                                                            {role.map((item, key) => (
                                                                <option value={item.id} key={key}>{item.name}</option>
                                                            ))}
                                                        </React.Fragment>


                                                    </Input>
                                                    {validation.touched.role_id &&
                                                        validation.errors.role_id ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.role_id}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>
                                                <div className='col-md-6'>

                                                <div className="mb-3">
                                                    <Label htmlFor="status-field" className="form-label">
                                                        Status
                                                    </Label>
                                                    <Input
                                                        name="status"
                                                        type="select"
                                                        className="form-select"
                                                        id="status-field"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.status || ""
                                                        }
                                                        invalid={
                                                            validation.touched.status && validation.errors.status ? true : false
                                                        }
                                                    >
                                                        {userStatus.map((item, key) => (
                                                            <React.Fragment key={key}>
                                                                {item.options.map((item, key) => (
                                                                    <option value={item.value}
                                                                        key={key}>{item.label}</option>))}
                                                            </React.Fragment>
                                                        ))}

                                                    </Input>
                                                    {validation.touched.status &&
                                                        validation.errors.status ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.status}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                                </div>
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => {
                                                        setModal(false);
                                                        validation.resetForm()
                                                    }}> Close
                                                    </button>

                                                    <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                        <span className="d-flex align-items-center">
                                                            {isloading ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}
                                                            <span className="flex-grow-1 ms-2">
                                                                {!!isEdit ? "Update" : "Add User"}{" "}
                                                            </span>
                                                        </span>
                                                    </Button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default Users;