import { createSlice } from "@reduxjs/toolkit";
import {addNewQuestion, getDataForQuestion, resetFlags} from './thunk';
export const initialState = {

    languages: [],
    editQuestions: [],
    courses: [],
    topicAreas: [],
    error: {},
    isAddQuestionCreated:false,
    isAddQuestionSuccess:false,
};

const CreateQuestionSlice = createSlice({
    name: 'CreateQuestionSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getDataForQuestion.fulfilled, (state, action) => {

            if(action.payload && action.payload.success){
                state.editQuestions = action.payload.data['editQuestion']? action.payload.data['editQuestion'].data:"";
                state.languages = action.payload.data['langs']['data'];
                state.courses = action.payload.data['courses']['data'];
                state.topicAreas = action.payload.data['topicAreas']['data'];
                state.isAddQuestionCreated = false;
                state.isAddQuestionSuccess = true;
            } else {
                state.isAddQuestionSuccess = false;
            }
        });

        builder.addCase(getDataForQuestion.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isAddQuestionCreated = false;
            state.isAddQuestionSuccess = false;
        });

        builder.addCase(addNewQuestion.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.error ="no-error";
                state.isAddQuestionCreated = true;
            }else{
                state.error = action.payload.data || null;
                state.isAddQuestionCreated = false;
            }
        });

        builder.addCase(addNewQuestion.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isAddQuestionCreated = false;
        });

        builder.addCase(resetFlags.fulfilled, (state, action) => {
            state.error = null;
            state.isAddQuestionCreated = false;
            // state.isAddQuestionSuccess = false;
        });



    }

});


export default CreateQuestionSlice.reducer;