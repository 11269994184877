import React from 'react';
import { api } from '../config'

const ImageComponent = React.memo(({choosedAns,imagePath}) => {

 
  const fileUrl =api.IMAGE_URL;

  return (
    <div>
      <span>{choosedAns}</span>
      {imagePath && imagePath!=null?
      <img src={fileUrl + imagePath} height="60"/>
      :''}
    </div>
  );
});

export default ImageComponent;
