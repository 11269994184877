//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin, postLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

export const loginUser = (user, history) => async (dispatch) => {
  try {
    let response;
      response = postLogin({
        email: user.email,
        password: user.password,
      });
    var login = await response;
    if (login.success) {

      const sessionExpirationTime = 1 * 60 * 1000; // 5 minutes
    const expirationTime = new Date().getTime() + sessionExpirationTime;
    window.localStorage.setItem('sessionExpiration', expirationTime);


       window.localStorage.setItem("authUser", JSON.stringify(login));
       window.localStorage.setItem("user", JSON.stringify(login.data));
       window.localStorage.setItem("permissions", JSON.stringify(login.data.permissions.role.permissions));
       const expiresAt = new Date().getTime() + (12 *60 * 60 * 1000); // Current time + 12 hours
       window.localStorage.setItem('expires_at', expiresAt);

       dispatch(loginSuccess(login));
       history('/admin/dashboard')
    }else {
      dispatch(apiError(login));
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    window.localStorage.removeItem("authUser");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("permissions");
    window.localStorage.removeItem("sessionExpiration");
    window.localStorage.removeItem("expires_at");
      dispatch(logoutUserSuccess(true));
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (type, history) => async (dispatch) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.socialLoginUser(type);
    }
    //  else {
      //   response = postSocialLogin(data);
      // }
      
      const socialdata = await response;
    if (socialdata) {
      sessionStorage.setItem("authUser", JSON.stringify(response));
      dispatch(loginSuccess(response));
      history('/dashboard')
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};