module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {


        //  API_URL: "http://127.0.0.1:8000/api",
        //  IMAGE_URL: "http://127.0.0.1:8000/storage/uploads/",


        //  API_URL: "https://theroy.designkorner.com/internal-theory-apis/api",
        //  IMAGE_URL:"https://theroy.designkorner.com/internal-theory-apis/public/storage/uploads/",

         API_URL: "https://theroy.designkorner.com/internal-theory-apis/api",
        IMAGE_URL:"https://theroy.designkorner.com/internal-theory-apis/public/storage/uploads/",


    FLAGS: "../../assets/images/flags/",
  },
};
