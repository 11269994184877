import { createSlice } from "@reduxjs/toolkit";
import { getRooms,  addNewRoom,updateRoom,  deleteRoom, getBranchRooms } from './thunk';

export const initialState = {
    branches: [],
    rooms: [],
    branchWiseRooms: [],
    error: {},
};

const RoomSlice = createSlice({
    name: 'RoomSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getRooms.fulfilled, (state, action) => {
            if (action.payload && action.payload.success){
            state.branches = action.payload.data['branches']['data'];
            state.rooms = action.payload.data['rooms']['data'];
            state.isRoomCreated = false;
            state.isRoomSuccess = true;
            }else{
                state.isRoomSuccess = false;
            }
        });

        builder.addCase(getRooms.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isRoomCreated = false;
            state.isRoomSuccess = false;
        });

        builder.addCase(addNewRoom.fulfilled, (state, action) => {
            if (action.payload && action.payload.success){
                state.rooms.push(action.payload.data);
                state.isRoomCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isRoomCreated = false;
            }
        });

        builder.addCase(addNewRoom.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isRoomCreated = false;
        });

        builder.addCase(updateRoom.fulfilled, (state, action) => {
            if (action.payload && action.payload.success){
                state.rooms = state.rooms.map(room =>
                    room.id === action.payload.data.id
                        ? {...room, ...action.payload.data}
                        : room
                )
                state.isRoomCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isRoomCreated = false;
            }
        });
        builder.addCase(updateRoom.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isRoomCreated = false;
        });


        builder.addCase(deleteRoom.fulfilled, (state, action) => {
           if(action.payload!=undefined){
            state.rooms = state.rooms.filter(
                room => room.id.toString() !== action.payload.room.toString()
            );
           }
        });
        builder.addCase(deleteRoom.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

        //get branch wise rooms
  
        builder.addCase(getBranchRooms.fulfilled, (state, action) => {
            
            if (action.payload && action.payload.success){
             
                state.branchWiseRooms=action.payload.data;
            }else{
                state.isRoomSuccess = false;
            }
        });

        builder.addCase(getBranchRooms.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isRoomCreated = false;
            state.isRoomSuccess = false;
        });



    }
});

export default RoomSlice.reducer;