import { userForgetPasswordSuccess, userForgetPasswordError } from "./reducer"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import {
  postFakeForgetPwd,
  postJwtForgetPwd,
} from "../../../helpers/fakebackend_helper";



export const userForgetPassword = (user, history) => async (dispatch) => {
  try {
      let response;
        if (user) {
          response = postFakeForgetPwd(
              user
          )
      }

      const data = await response;
        console.log("data",data);
      if (data) {
          dispatch(userForgetPasswordSuccess(
              data.message
          ))
      }else {
          dispatch(userForgetPasswordError( data.message))
      }
  } catch (forgetError) {
      console.log("forgetError",forgetError)
      dispatch(userForgetPasswordError(forgetError))
  }
}