import React, {useEffect, useState} from "react";
import UiContent from "../../Components/Common/UiContent";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addNewRolePermissions as onAddNewRolePermissions,
    getAllModulePermissions as onGetModulePermissions,

} from "../../slices/rolePermissions/thunk";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

import {isEmpty} from "lodash";
import Loader from "../../Components/Common/Loader";
import {Link, useLocation} from 'react-router-dom'
import {  useParams } from 'react-router-dom'
const RolePermissions = () => {


    const id = useParams();
    const [roleId, setRoleId] = useState(useParams());

    const dispatch = useDispatch();
    const [modulePermission, setModulePermission] = useState([]);
    const [rolePermission, setRolePermission] = useState([]);
    const [rolePermissionId, setRolePermissionId] = useState([]);
    const [checkedItems, setCheckedItems] = useState([]);



    const [role, setRole] = useState([]);
    const [test, setTest] = useState("");

    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);


    const selectLayoutState = (state) => state.RolePermission;
    const rolePermissionsProperties = createSelector(
        selectLayoutState,
        (app) => ({
            modulepermissions: app.modulepermissions,
            rolepermissions: app.rolepermissions,
            roles: app.roles,
            isPermissionsSuccess: app.isPermissionsSuccess,
            isPermissionsCreated: app.isPermissionsCreated,
            error: app.error,
        })
    );
    // Inside your component
    const {
        modulepermissions,rolepermissions,roles, isPermissionsSuccess, error,isPermissionsCreated
    } = useSelector(rolePermissionsProperties)


    useEffect(() => {
        // if (modulepermissions && !modulepermissions.length) {
            dispatch(onGetModulePermissions(id));
            setTest("new");
        // }
    }, [dispatch,test,isPermissionsCreated,error]);


    useEffect(() => {
        setModulePermission(modulepermissions);
        setRolePermission(rolepermissions);
        setRolePermissionId(rolePermission.map(item => item.id))
        setLoading(!isPermissionsSuccess);
        setRole(roles);
        if(isPermissionsCreated){
            setIsloading(false);
            // validation.resetForm();
        }
        error? setIsloading(false):null
    }, [modulepermissions,rolepermissions,roles,isPermissionsCreated,error]);

    useEffect(() => {
        if (!isEmpty(modulepermissions)) {
            setModulePermission(modulepermissions);
            setRolePermission(rolepermissions);
            setRolePermissionId(rolePermission.map(item => item.id))
            setRole(roles);
            setLoading(!isPermissionsSuccess);
        }
    }, [modulepermissions,rolepermissions,roles,error,isPermissionsCreated]);


    const handleEditPermissionChange = (value) => {
        const newSelectedValues = rolePermissionId.includes(value)
            ? rolePermissionId.filter((item) => item !== value)
            : [...rolePermissionId, value]
        setRolePermissionId(newSelectedValues)
    }

    // console.log(rolePermissionId);


    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            role_id: (role && role.id) || '',
            permissions: (rolePermissionId) || '',

        },
        onSubmit: (values) => {
            setIsloading(true);
            const newRolePermissions = {
                role_id: values["role_id"] ? values["role_id"] : "",
                permissions: rolePermissionId,
            };
            dispatch(onAddNewRolePermissions(newRolePermissions));
        },
    });

    document.title = "Role Permissions | Theory Practice";
    return (
        <React.Fragment>
            <UiContent/>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Role Permissions" pageTitle="Settings"/>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <PreviewCardHeader title={role.name+ " "+ "Permissions"}/>
                                <CardBody>
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        : <div>
                                    {isPermissionsSuccess ? (
                                    <div className="">
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                                <div className="row g-3 mt-1 mb-3">
                                                    <div className="col-xxl-2">
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">View</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Add</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Edit</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <div className="ms-1">
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Delete</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-2">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Translate</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input type="hidden"
                                                       name="role_id"
                                                       onChange={validation.handleChange}
                                                       onBlur={validation.handleBlur}
                                                       value={validation.values.role_id || ""}
                                                />
                                            {
                                                modulePermission ? modulePermission.map((item ,i) => {
                                                    return (
                                                    <div className="row g-3" key={i}>
                                                        <div className="col-xxl-2">
                                                            <div>
                                                                <label htmlFor="firstName"
                                                                       className="form-label">{item.title}</label>
                                                            </div>
                                                        </div>
                                                        {item.permissions
                                                            ? item.permissions.map((perm ,index) => {
                                                                return (
                                                        <div className="col-xxl-2" key={index}>
                                                            <div className="form-check form-switch form-switch-md mb-3"
                                                                >
                                                                <input
                                                                    className='form-check-input'
                                                                    type='checkbox'
                                                                    value={perm.id}
                                                                    name='permissions'
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    onChange={(event) =>
                                                                        handleEditPermissionChange(perm.id)
                                                                    }
                                                                    checked={rolePermissionId.includes(perm.id)}
                                                                />

                                                            </div>
                                                        </div>
                                                                )
                                                            })
                                                            : ''}
                                                    </div>
                                                            )
                                                         })
                                                    : ''}
                                                    <div className="row g-3 mt-3">
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <Link to="/admin/roles" className="btn btn-light">Close</Link>
                                                            <Button color="primary" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                              <span className="d-flex align-items-center">
                                                                  {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                                  <span className="flex-grow-1 ms-2">
                                                                     Save Changes
                                                                  </span>
                                                              </span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                        </Form>
                                    </div>
                                    ) : (<div className="py-4 text-center">
                                        <div>
                                            <lord-icon
                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                trigger="loop"
                                                colors="primary:#405189,secondary:#0ab39c"
                                                style={{width: "72px", height: "72px"}}
                                            ></lord-icon>
                                        </div>

                                        <div className="mt-4">
                                            <h5>Sorry! No Record Found</h5>
                                        </div>
                                    </div>)}
                                        </div>)}

                                    <ToastContainer closeButton={false} limit={1}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

};

export default RolePermissions;

