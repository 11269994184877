import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getBranches as getBranchesApi,
  addNewBranch as addNewBranchApi,
  updateBranch as updateBranchApi,
  deleteBranch as deleteBranchApi, getRooms as getRoomsApi, deleteRoom as deleteRoomApi,

} from "../../helpers/custom_helper/backend_helper";


export const updateBranch = createAsyncThunk("update-branch", async (branch) => {
  try {
    const response = addNewBranchApi(branch);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("Branch Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const addNewBranch = createAsyncThunk("save-branch", async (branch) => {
  try {
    const response = addNewBranchApi(branch);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("Branch Added Failed", { autoClose: 3000 });
    return error;
  }
});

export const getBranches = createAsyncThunk("get-branches", async () => {
  try {
    const response = getBranchesApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }
});

export const deleteBranch = createAsyncThunk("delete-branch", async (branch) => {
  try {
    const response = deleteBranchApi(branch);
    const data = await response;
 
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { branch, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Branch Deleted Failed", { autoClose: 3000 });
    return error;
  }

});