import React from 'react'
import { Card, Col, Container, Input, Label, Row ,Button} from 'reactstrap';
import PagesLogoComponent from '../../../Components/Common/PagesLogoComponent';
import { Link } from 'react-router-dom';

function AlertComponent(props) {

  const {examType} = props;


  return (
    <>


      <React.Fragment>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="bg-overlay"></div>
          <div className="auth-page-content overflow-hidden pt-lg-5">



            <PagesLogoComponent />
            <Container>
              <Row>
                <Col lg={12}>
                  <Card className="overflow-hidden">
                    <Row className="g-0">

                 
             
                    <div className="p-lg-5 p-4">

<div>

  <div className="alert border-0 alert-success text-center" role="alert">
    <strong style={{ color: 'red' }}>Questions not found of given criteria </strong>
  </div>
</div>

<div>


  <div className="mt-4">
    <div className="mt-4">
   
      {examType==2?
          <Link to="/practice-type"><Button color="primary" className="w-100" type="submit">Back</Button></Link>
      
  
      :
      <Link to="/student"><Button color="primary" className="w-100" type="submit">Back</Button></Link>
}
    </div>
  </div>
</div>


</div>
                     
                
                  
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>



        </div>
      </React.Fragment>
    </>
  )
}

export default AlertComponent