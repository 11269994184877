import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { toast, ToastContainer } from 'react-toastify';
import TableContainer from '../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Common/Loader';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from "yup";
import {
  getScheduleExam as onGetScheduleExam,
  getBdcStudent as onGetBdcStudent,
  saveScheduleExam as onSaveScheduleExam,
  updateExam as onUpdateExam,
  deleteExam as onDeleteExam,
  resetFlags as onResetFlags,
  restartExam as onRestartExam,
  exitExam as onExitExam,
  resetBdcStd as onResetBdcStd,
  syncExam as onSyncExam,
  holdExam as onHoldExam

} from '../../slices/examSchedule/thunk';


import { getCourses as onGetCourses } from '../../slices/course/thunk';
import {
   getRoomSystem as onGetRoomSystem } from '../../slices/system/thunk';
import { getLanguages as onGetLanguages } from '../../slices/language/thunk';

import DeleteModal from '../../Components/Common/DeleteModal';
import { deleteRoom as onDeleteRoom } from "../../slices/room/thunk";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";
import MyLoader from '../../common/MyLoader'
import { getDateWithFormated } from '../utility/TimerUtility';
import Swal from 'sweetalert2';
import Flatpickr from "react-flatpickr";

import { changeDateFormate } from '../utility/TimerUtility';






const ExamSchedule = () => {

  const [examData, setExamData] = useState([]);

  const [deleteModal, setDeleteModal] = useState(false);

  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [courseData, setCourseData] = useState([]);
  const [systemDataList, setSystemDataList] = useState([]);
  const [languageDataList, setLanguageDataList] = useState([]);
  const [isloading, setIsloading] = useState(false);
  const [trafficId, setTrafficId] = useState('');
  const [trafficIdError, setTrafficIdError] = useState(false);
  const [stdData, setStdData] = useState([]);
  const [isTrafficIdValid, setIsTrafficIdValid] = useState(false);
  const [courseShortName, setCourseShortName] = useState('');
  const [isfetchDataLoader, setIsFetchDataLoader] = useState(true);
  const [isSyncLoader, setIsSyncLoader] = useState(false);
  const [roomPurpose, setRoomPurpose] = useState("");

  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [authUser, setAuthUser] = useState([]);

  const [filterDateError, setFilterDateError] = useState('');
  const [isTrafficIdReadOnly, setIsTrafficIdReadOnly] = useState(false);
  const [isLoadingFilter,setIsLoadingFilter]=useState(false);

  const flatpickrRef = useRef(null);


  const selectLayoutState = (state) => state.ScheduleExamReducer;
  const selectCourseState = (state) => state.Course;
  const selectSystemState = (state) => state.System;
  const selectLangusgeState = (state) => state.Language;

  const startDate = changeDateFormate(new Date());
  const endDate = changeDateFormate(new Date());

  const [selectedDates, setSelectedDates] = useState([new Date(),new Date()]);

  const examProperties = createSelector(
    selectLayoutState,

    (data) => ({
      scheduleExamList: data.scheduleExamList,
      isExamSuccess: data.isExamSuccess,
      error: data.error,
      studentInfo: data.studentInfo,
      traficIdValid: data.traficIdValid,
      isLoader: data.isLoader,
      isSaveExam: data.isSaveExam,
      isCloseExam:data.isCloseExam,
      isContinueExam:data.isContinueExam,
      isFilterLoader:data.isFilterLoader,
      isSyncExam:data.isSyncExam

    })
  );


  const {scheduleExamList, error, isExamSuccess, studentInfo, traficIdValid,isLoader, isSaveExam,isCloseExam,isContinueExam,isFilterLoader,isSyncExam} = useSelector(examProperties)

  useEffect(() => {
    const authUser = JSON.parse(window.localStorage.getItem('user'));
    setAuthUser(authUser);
      handleGetScheduleExamList(authUser.user_id,getDateWithFormated(new Date()),getDateWithFormated(new Date()));
  }, [dispatch]);




  useEffect(() => {
  setIsloading(isLoader)

  }, [isLoader]);

  useEffect(() => {

    setIsLoadingFilter(isFilterLoader)

    }, [isFilterLoader]);

  useEffect(() => {

    setExamData(scheduleExamList);
    setStdData(studentInfo);
    setIsTrafficIdValid(traficIdValid);
    setIsFetchDataLoader(!isExamSuccess)


  }, [scheduleExamList, studentInfo, isSaveExam, isLoader, isExamSuccess]);


  useEffect(() => {


    if (traficIdValid) {
      setIsSyncLoader(false)
      setIsTrafficIdReadOnly(true)
    }

  }, [traficIdValid]);


  useEffect(() => {
  
    if(isSyncExam){
    handleGetScheduleExamList(authUser.user_id,getDateWithFormated(selectedDates[0]),getDateWithFormated(selectedDates[1]))
    }
  
    }, [isSyncExam]);

  useEffect(() => {

    setIsloading(false);
    if (isSaveExam==true) {

       handleGetScheduleExamList(authUser.user_id,getDateWithFormated(selectedDates[0]),getDateWithFormated(selectedDates[1]));
      validation.resetForm();
      setModal(false);
      setTrafficId('');
      setStdData([]);
      setIsTrafficIdValid(false);
      setIsTrafficIdReadOnly(false);

    }


    if (error) {

      setIsloading(false);
      setIsSyncLoader(false)
    }

    dispatch(onResetFlags());
  }, [isSaveExam, error]);





  const courseProperties = createSelector(
    selectCourseState,
    (data) => ({
      courses: data.courses,

    })
  );


  const { courses } = useSelector(courseProperties)
  useEffect(() => {
    if (courses && !courses.length) {
      dispatch(onGetCourses());
    }
  }, [dispatch, courses]);


  useEffect(() => {
    setCourseData(courses);
  }, [courses]);


  const systemProperties = createSelector(selectSystemState, (data) => ({ roomSystemList: data.roomSystemList }));
  const languageProperties = createSelector(selectLangusgeState, (data) => ({ languages: data.languages }));

  const { roomSystemList } = useSelector(systemProperties)
  const { languages } = useSelector(languageProperties)




  useEffect(() => { setSystemDataList(roomSystemList) }, [roomSystemList]);


  useEffect(() => {
    if (languages && !languages.length) {
      dispatch(onGetLanguages());
    }
  }, [dispatch]);

  useEffect(() => { setLanguageDataList(languages) }, [languages]);




  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);

    } else {
      setModal(true);
    }
  }, [modal]);



  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {


      exam_type: (isEdit ? (examData && examData.exam_type) : roomPurpose && roomPurpose) || '',
      system_id: (isEdit ? (examData && examData.system_id) : "") || '',
      q_lang: (isEdit ? (examData && examData.q_lang) : "en") || '',
      audio_lang: (isEdit ? (examData && examData.audio_lang) : "") || '',

    },




    validationSchema: Yup.object({

      exam_type: Yup.string().required("Required field"),
      q_lang: Yup.string().required("Required field"),
    

    }),


    onSubmit: (values) => {
      setIsloading(true);
      const postedData = {
        id: isEdit ? examData.id : 0,
        exam_type: values["exam_type"],
        q_lang: values["q_lang"],
        audio_lang: values["audio_lang"],
        system_id: values["system_id"],
        stdData: stdData,
        invgId: authUser && authUser.user_id,

      };



      isEdit ?
        dispatch(onUpdateExam(postedData))
        :
        dispatch(onSaveScheduleExam(postedData));

    },
  });



  const examTableColumns = useMemo(
    () => [

      {
        Header: "Student",
        accessor: "student.std_name",
        filterable: false,
      },
      {
        Header: "Traffic ID",
        accessor: "student.traffic_id",
        filterable: false,
      },
      {
        Header: "Course",
        accessor: "course.short_name",
        filterable: false,
      },



      {
        Header: "Type",
        accessor: "exam_type",
        Cell: (cell) => {

          if (cell.value == 1) {
            return (
              <span className="badge text-uppercase bg-success-subtle text-success">Exam</span>
            );
          }
          if (cell.value == 2) {
            return (
              <span className="badge text-uppercase bg-info-subtle text-info">Practice</span>
            );
          }


        },
      },

      {
        Header: "System",
        accessor: "system.title",
        filterable: false,
      },
      {
        Header: "Q Lang",
        accessor: "q_language.lang",
        filterable: false,
      },
      {
        Header: "Date",
        accessor: "created_at",
        filterable: false,
      },

      {
        Header: "Status",
        accessor: "exam_status",
        Cell: (cell) => {

          if (cell.value == 1) {
            return (
              <span className="badge text-uppercase bg-success-subtle text-success">Initlized</span>
            );
          }
          if (cell.value == 2) {
            return (
              <span className="badge text-uppercase bg-danger-subtle text-danger">InProgress</span>
            );
          }
          if (cell.value == 3) {
            return (
              <span className="badge text-uppercase bg-info-subtle text-info">Completed</span>
            );
          }

          if (cell.value == 4) {
            return (
              <span className="badge text-uppercase bg-info-subtle text-info">Closed</span>
            );
          }

          if (cell.value == 5) {
            return (
              <span className="badge text-uppercase bg-info-subtle text-info">Hold</span>
            );
          }

        },
      },

      {
        Header: "Is Sync",
        accessor: "is_sync",
        Cell: (cell) => {

          if (cell.value == 1) {
            return (
              <span className="badge text-uppercase  text-green">Yes</span>
            );
          }
          if (cell.value == 2) {
            return (
              <span className="badge text-uppercase text-danger">No</span>
            );
          }

      

        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {

        

          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <AuthorizeForAction requiredPermissions={['exam-edit']}>
              {

          (cellProps.row.original.exam_status == 1) ?
                <li className="list-inline-item edit" title="Edit">
                  <Link
                    to="#"
                    className="text-primary d-inline-block edit-item-btn"
                    onClick={() => { const examData = cellProps.row.original; handleEditExamData(examData); }}

                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </Link>
                </li>
                   : ''}

              </AuthorizeForAction>
              <AuthorizeForAction requiredPermissions={['exam-delete']}>
                {
              (cellProps.row.original.exam_status == 1 ) ?

                <li className="list-inline-item" title="Remove">
                  <Link
                    to="#"
                    className="text-danger d-inline-block remove-item-btn"
                    onClick={() => { const examData = cellProps.row.original; onClickDelete(examData); }}
                  >
                    <i className="ri-delete-bin-5-fill fs-16"></i>
                  </Link>
                </li>
                     : ''}
              </AuthorizeForAction>

              {

                (cellProps.row.original.exam_status == 1) ?

                  <li className="list-inline-item" title="Restart">
                    <Link
                      to="#"
                      className="text-success d-inline-block remove-item-btn"
                      onClick={() => { const examData = cellProps.row.original; onClickRestartTest(examData); }}

                    >
                      <i className="ri-douban-line fs-16"></i>
                    </Link>
                  </li>

: ''}



                 



{
(cellProps.row.original.exam_status == 1 || cellProps.row.original.exam_status == 2 || cellProps.row.original.exam_status == 5) ?

  <li className="list-inline-item" title="Close">
    <Link
      to="#"
      className="text-success d-inline-block remove-item-btn"
      onClick={() => { const examData = cellProps.row.original; onClickExitExam(examData); }}

    >
      <i className="ri-close-circle-fill fs-16"></i>
    </Link>
  </li>

 : ''}

{

(cellProps.row.original.exam_type == 2) ?

(cellProps.row.original.exam_status == 1 || cellProps.row.original.exam_status == 2 ) ?
<li className="list-inline-item" title="Hold">
<Link
  to="#"
  className="text-primary d-inline-block remove-item-btn"
  onClick={() => { const examData = cellProps.row.original; onClickHoldExam(examData); }}

>
  <i className="ri-pause-circle-line fs-16"></i>
</Link>
</li>

 : ''
 :''
}



{
(cellProps.row.original.exam_status == 3) ?
  <li className="list-inline-item" title="Sync Exam">
    <Link
      to="#"
      className="text-success d-inline-block remove-item-btn"
      onClick={() => { const examData = cellProps.row.original; handleSyncExam(examData); }}

    >
      <i className="ri-share-forward-line fs-4"></i>
    </Link>
  </li>
 : ''}



            </ul>
          );
        },
      },

    ],

    []
  );


  const handleCustomerClicks = () => {

    setIsEdit(false);
    toggle();
  };

  const handleSyncExam= (examData) =>{

Swal.fire({
  text: 'Are you sure you want to sync exam',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonColor: '#F1416C',
  cancelButtonColor: '#181C32',
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
}).then((result) => {
  if (result.isConfirmed) {
   
    const postedData = {
      exam_id:examData.id,
    };
     dispatch(onSyncExam(postedData))
    
  }
})
  }

  const handleEditExamData = useCallback((arg) => {

    const exam = arg;

    setIsTrafficIdValid(true);
    setExamData({
      id: exam.id,
      system_id: exam.system_id,
      q_lang: exam.q_lang,
      audio_lang: exam.audio_lang,
      exam_type: exam.exam_type,
    });

    setTrafficId(exam.student.traffic_id);
    setStdData(exam.student);
    setCourseShortName(exam.course.short_name)
    setIsEdit(true);

    toggle();
  }, [toggle]);


  const onClickDelete = (exam) => {

    setExamData(exam);
    setDeleteModal(true);
  };





  const handleDeleteExam = () => {
    if (examData) {
      dispatch(onDeleteExam(examData.id));
      setDeleteModal(false);
    }
  };


  const syncTrafficId = () => {

    if (isEmpty(trafficId)) {
      setTrafficIdError(true)
    } else {
      setTrafficIdError(false)
      setIsSyncLoader(true);
      const postedTrafficId = { 
        traffic_id: trafficId,
        exam_template:authUser.branch.exam_template
      };


      authUser.room? dispatch(onGetRoomSystem(authUser.room.id)):''
       dispatch(onGetBdcStudent(postedTrafficId));

    }
  };


  useEffect(() => {

    authUser && authUser.room ? setRoomPurpose(authUser.room.room_purpose) : '';

     if (roomSystemList && !roomSystemList.length) {
      authUser.room ?

        dispatch(onGetRoomSystem(authUser.room.id))
        : ''
     }
  }, [dispatch, authUser]);

const fnCloase=()=>{

  setModal(false);
  setTrafficId('');
  setStdData([])
  validation.resetForm();
  setIsloading(false)
  setIsSyncLoader(false)
  dispatch(onResetBdcStd())
  setIsTrafficIdReadOnly(false);


}




  //onClickExitExam

  const onClickExitExam = (exam) => {

    Swal.fire({
      text: 'Are you sure you want to close exam/practice,once closed all attempted questions will be reset',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onExitExam(exam.id))
      }
    })
  };

  //Hold Exam
  const onClickHoldExam = (exam) => {


     const examPostedData = {
       exam_id:exam.id,
      system_ip:exam.system.system_ip,
      closeType:5
     };

    Swal.fire({
      text: 'Are you sure you want to hold exam',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onHoldExam(examPostedData))
      }
    })
  };

   //onClickRestartTest
   const onClickRestartTest = (exam) => {


    Swal.fire({
      text: 'Are you sure you want to restart exam',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(onRestartExam(exam.id))
      }
    })
  };



useEffect(() => {

if(isCloseExam){
   handleGetScheduleExamList(authUser.user_id,getDateWithFormated(selectedDates[0]),getDateWithFormated(selectedDates[1]))
}

}, [isCloseExam]);

const handleGetScheduleExamList = (invgId,fromDate,toDate)=>{


if(invgId!==undefined){
  const examPostedData = {
    invgId:invgId,
    start_date:fromDate,
    end_date:toDate
  };

  dispatch(onGetScheduleExam(examPostedData));
}
}



useEffect(() => {

isContinueExam==0?setIsloading(false):''

}, [isContinueExam]);



const filterScheduleExam= () =>{


  if (isEmpty(selectedDates)) {

    setFilterDateError(true)
       setIsLoadingFilter(false)
  } else {

    setFilterDateError(false)
    setIsLoadingFilter(true)
    if(selectedDates[0] &&  selectedDates[1]){
      handleGetScheduleExamList(authUser.user_id,getDateWithFormated(selectedDates[0]),getDateWithFormated(selectedDates[1]));
    }else{
      toast.error('Invalid date', { autoClose: 3000 });
    }
    setIsLoadingFilter(false)


  }

}


useEffect(() => {
 setIsloading(!isLoader)
}, [isLoader]);


const handleDateChange = (selectedDates) => {

   setSelectedDates(selectedDates);
};


  document.title = "Exam List | Theory Practice";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>

          <Row>
            <Col xs={12}>
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">

                <h4 className="mb-sm-0">{authUser && authUser.branch && authUser.room && authUser.room.title ? authUser.branch.title + '('+ authUser.room.title + ')': 'Exam Schedule'}</h4>

                <div className="page-title-right">
                  <ol className="breadcrumb m-0">
                    <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
                    <li className="breadcrumb-item active">Scheduled Exams</li>
                  </ol>
                </div>

              </div>
            </Col>
          </Row>
          <Row>



            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Exams List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto d-flex">

                      <div>

                      <div className="mt-3 mt-lg-0">

                                                    <div className="row g-3 mb-0 align-items-center">
                                                        <div className="col-sm-auto">
                                                            <div className="input-group">

                                        <Flatpickr
                                                className="form-control border-0 dash-filter-picker shadow"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d M, Y",
                                                    defaultDate: [startDate,endDate],
                                                    onChange:handleDateChange

                                                }}

                                            />
                                            <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>


                                                            </div>
                                                        </div>

                                                        <div className="col-auto">
                                                            <Button type="button" color='success' onClick={filterScheduleExam}>    {isLoadingFilter ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}<i className="ri-search-line align-middle me-1"></i>Filter</Button>

                                                              <AuthorizeForAction requiredPermissions={['exam-create']}>
                                                                {authUser.room?
                                                            <Button type="button" className='ms-1' color='primary'  onClick={() => { setIsEdit(false); toggle(); }}><i className="ri-add-circle-line align-middle me-1"></i> Add Exam/Practice</Button>
                                                            :''}
                                                            </AuthorizeForAction>
                                                        </div>



                                                    </div>

                                            </div>



                      </div>
                    </div>
                  </Row>

                </CardHeader>
                <div className="card-body pt-0">


                  <div>
                    <div>

                      {isfetchDataLoader ?
                        <MyLoader />
                        :
                        <>
                          {isExamSuccess && scheduleExamList.length ? (
                            <TableContainer
                              columns={examTableColumns}
                              data={(scheduleExamList || [])}
                              isGlobalFilter={true}
                              isAddUserList={false}
                              customPageSize={8}
                              className="custom-header-css"
                              handleCustomerClick={handleCustomerClicks}
                              isCustomerFilter={false}
                              SearchPlaceholder='Search for Room, status or something...'
                            />
                          ) : (
                            <div className="py-4 text-center">
                              <div>
                                <lord-icon
                                  src="https://cdn.lordicon.com/msoeawqm.json"
                                  trigger="loop"
                                  colors="primary:#405189,secondary:#0ab39c"
                                  style={{ width: "72px", height: "72px" }}
                                ></lord-icon>
                              </div>

                              <div className="mt-4">
                                <h5>Sorry! No Record Found</h5>
                              </div>
                            </div>
                          )
                          }
                        </>
                      }


                    </div>


                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>



                <DeleteModal
                  show={deleteModal}
                  onDeleteClick={handleDeleteExam}
                  onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                  show={deleteModalMulti}
                  onDeleteClick={() => {
                    deleteMultiple();
                    setDeleteModalMulti(false);
                  }}
                  onCloseClick={() => setDeleteModalMulti(false)}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal id="showModal" isOpen={modal} backdrop="static" toggle={toggle} centered className='modal modal-lg'>
        <ModalHeader className="bg-light p-3 " toggle={toggle}>

          {!!isEdit ? "Edit Exam/Practice" : "Add Exam/Practice"}

        </ModalHeader>



        <Form className="tablelist-form" onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}>
          <ModalBody>
            <input type="hidden" id="id-field" />




            <div className='row mb-3'>
              <div className='col-md-9'>
                <Label htmlFor="title-field" className="form-label" >Traffic Id</Label>
                <Input
                  name=""
                  id="name-field"
                  className="form-control"
                  placeholder="Traffice ID"
                  type="number"
                  onChange={(e) => setTrafficId(e.target.value)}
                  validate={{ required: { value: true } }} value={trafficId} invalid={trafficIdError ? true : false}
                  readOnly={!!isTrafficIdReadOnly}


                   />
              </div>
              <div className='col-md-3 d-grid'>
                {!isEdit ?
                  <Button color="success" disabled={isSyncLoader ? true : false} className="btn-load mt-4" type="button" onClick={() => { syncTrafficId() }}  >
                    <span className="d-flex align-items-center">
                      {isSyncLoader ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}
                      <span className="flex-grow-1 ms-2">
                        Fetch
                      </span>
                    </span>
                  </Button>
                  : ''}
              </div>
            </div>

            {isTrafficIdValid ?
              <>

                <div className='row mb-3'>

                  <div className='col-md-6'>
                    <Label htmlFor="title-field" className="form-label" >Name</Label>
                    <Input
                      name="std_name"
                      id="name-field"
                      className="form-control"
                      placeholder="Student Name"
                      type="text"
                      readOnly
                      value={isEdit ? stdData.std_name : stdData.studname}

                      validate={{
                        required: { value: true },
                      }}

                      invalid={
                        validation.touched.std_name && validation.errors.std_name ? true : false
                      }

                    />


                  </div>
                  <div className='col-md-6'>

                    <Label htmlFor="title-field" className="form-label" >Email</Label>
                    <Input
                      name="std_email"
                      id="name-field"
                      className="form-control"
                      placeholder="Student Email"
                      type="email"
                      value={stdData.email}
                      readOnly

                      onChange={(e) => setTrafficId(e.target.value)}
                      validate={{
                        required: { value: true },
                      }}

                      invalid={
                        validation.touched.std_name && validation.errors.std_name ? true : false
                      }

                    />



                  </div>
                </div>
                <div className="mb-3">
                  <Label htmlFor="title-field" className="form-label" >Course</Label>


                  <Input
                    name="course_id"
                    type="select"
                    className="form-select"
                    id="branch_id-field"
                    onChange={validation.handleChange}
                    disabled

                    onBlur={validation.handleBlur}
                    value={isEdit ? courseShortName : stdData['appltype']}
                    invalid={
                      validation.touched.course_id && validation.errors.course_id ? true : false
                    }

                  >

                    <React.Fragment>
                      <option value="">Select Course</option>
                      {courseData.map((item, key) => (
                        <option value={item.short_name} key={key}  >{item.course_translation[0].full_name}</option>


                      ))}
                    </React.Fragment>
                  </Input>


                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback
                      type="invalid">{validation.errors.title}</FormFeedback>
                  ) : null}

                </div>

                <div className="row mt-4 mb-3">

<div className="col-6 d-grid">


  <input type="radio" className="btn-check" name="exam_type" id="danger-outlined" value={"1"}
    onBlur={validation.handleBlur}
    onChange={validation.handleChange}
    checked={validation.values.exam_type == "1" || false}
    disabled={roomPurpose && roomPurpose == 2 ? true : false}
    invalid={
      validation.touched.exam_type && validation.errors.exam_type ? true : false
    }
  />
  <label className="btn w-lg btn-outline-success" htmlFor="danger-outlined">Exam</label>
  {validation.touched.exam_type && validation.errors.exam_type ? (
  <FormFeedback
    type="invalid">{validation.errors.exam_type}</FormFeedback>
) : null}

</div>
<div className="col-6 d-grid">


  <input type="radio" className="btn-check" name="exam_type" id="success-outlined" value={"2"}
    onBlur={validation.handleBlur}
    onChange={validation.handleChange}
    checked={validation.values.exam_type == "2" || ""}
    disabled={roomPurpose && roomPurpose == 1 ? true : false}

  />
  <label className="btn w-lg btn-outline-success" htmlFor="success-outlined">Practice</label>




  {validation.touched.audio_lang && validation.errors.audio_lang ? (
    <FormFeedback
      type="invalid">{validation.errors.audio_lang}</FormFeedback>
  ) : null}
</div>

</div>

                <div className="mb-3">
                  <Label
                    htmlFor="title-field"
                    className="form-label"
                  >
                    System
                  </Label>
                  <Input
                    name="system_id"
                    type="select"
                    className="form-select"
                    id="branch_id-field"
                    onChange={validation.handleChange}

                    onBlur={validation.handleBlur}
                    value={
                      validation.values.system_id || ""
                    }
                    invalid={
                      validation.touched.system_id && validation.errors.system_id ? true : false
                    }

                  >

                    <React.Fragment>
                      <option value="">Select System</option>
                      {systemDataList.map((item, key) => (
                        <option value={item.id} key={key}> {item.title}( {item.system_ip})</option>
                      ))}
                    </React.Fragment>
                  </Input>



                </div>


                <div className="mb-3">
                  <Label htmlFor="branch_id-field" className="form-label">
                    Question Language
                  </Label>
                  <Input
                    name="q_lang"
                    type="select"
                    className="form-select"
                    id="branch_id-field"
                    onChange={validation.handleChange}
                    validate={{
                      required: { value: true },
                    }}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.q_lang || ""
                    }
                    invalid={
                      validation.touched.q_lang && validation.errors.q_lang ? true : false
                    }

                  >

                    <React.Fragment>
                      <option value="">Select Language</option>
                      {languageDataList.map((item, key) => (
                        <option value={item.lang_short} key={key}>
                          {item.lang}
                        </option>
                      ))}
                    </React.Fragment>
                  </Input>

                  {validation.touched.q_lang && validation.errors.q_lang ? (
                    <FormFeedback
                      type="invalid">{validation.errors.q_lang}</FormFeedback>
                  ) : null}


                </div>

                <div className="mb-3">
                  <Label htmlFor="status-field" className="form-label">Audio Language</Label>
                  <Input
                    name="audio_lang"
                    type="select"
                    className="form-select"
                    id="branch_id-field"
                    onChange={validation.handleChange}
                    validate={{
                      required: { value: true },
                    }}
                    onBlur={validation.handleBlur}
                    value={
                      validation.values.audio_lang || ""
                    }
                    invalid={
                      validation.touched.audio_lang && validation.errors.audio_lang ? true : false
                    }

                  >

                    <React.Fragment>
                      <option value="">No Audio</option>
                      {languageDataList.map((item, key) => (
                        <option value={item.lang_short} key={key}>
                          {item.lang}
                        </option>
                      ))}
                    </React.Fragment>



                  </Input>

                  {validation.touched.audio_lang && validation.errors.audio_lang ? (
                    <FormFeedback
                      type="invalid">{validation.errors.audio_lang}</FormFeedback>
                  ) : null}

                </div>



              </> : ''}
          </ModalBody>

          {isTrafficIdValid ?
            <ModalFooter>
              <div className="hstack gap-2 justify-content-end">
                <button type="button" className="btn btn-light" onClick={() => {
                  fnCloase()


                }}> Close
                </button>

                <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                  <span className="d-flex align-items-center">
                    {isloading ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}
                    <span className="flex-grow-1 ms-2">
                      {!!isEdit ? "Update" : "Add"}{" "}
                    </span>
                  </span>
                </Button>
              </div>
            </ModalFooter>
            : ''}

        </Form>
      </Modal>


    </React.Fragment>
  );
};

export default ExamSchedule;
