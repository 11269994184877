import { createSlice } from "@reduxjs/toolkit";
import { getLanguages,  addNewLanguage,updateLanguage,  deleteLanguage } from './thunk';
export const initialState = {
    languages: [],
    error: {},
};

const LanguageSlice = createSlice({
    name: 'LanguageSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getLanguages.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
            state.languages = action.payload.data;
            state.isLanguageCreated = false;
            state.isLanguageSuccess = true;
            }else {
                state.isLanguageSuccess = false;
            }
        });

        builder.addCase(getLanguages.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isLanguageCreated = false;
            state.isLanguageSuccess = false;
        });

        builder.addCase(addNewLanguage.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.languages.push(action.payload.data);
                state.isLanguageCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isLanguageCreated = false;
            }
        });

        builder.addCase(addNewLanguage.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isLanguageCreated = false;
        });


        builder.addCase(updateLanguage.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
            state.languages = state.languages.map(language =>
                language.id === action.payload.data.id
                    ? { ...language, ...action.payload.data }
                    : language
            );
                state.isLanguageCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isLanguageCreated = false;
            }
        });
        builder.addCase(updateLanguage.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isLanguageCreated = false;
        });


        builder.addCase(deleteLanguage.fulfilled, (state, action) => {
            state.languages = state.languages.filter(
                language => language.id.toString() !== action.payload.language.toString()
            );
        });
        builder.addCase(deleteLanguage.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


    }
});

export default LanguageSlice.reducer;