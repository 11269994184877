import React, {useEffect, useState} from "react";
import UiContent from "../../Components/Common/UiContent";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addCourseConfig as onAddCourseConfig,
    getCourseConfigs as onGetCourseConfigs,

} from "../../slices/courseConfig/thunk";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

import {isEmpty} from "lodash";
import Loader from "../../Components/Common/Loader";
import {Link, useLocation} from 'react-router-dom'
import {  useParams } from 'react-router-dom'
const CourseConfig = () => {


    const id = useParams();
    const [courseId, setCourseId] = useState(useParams());
    const dispatch = useDispatch();
    const [courseConfig, setCourseConfig] = useState([]);
    const [course, setCourse] = useState([]);
    const [test, setTest] = useState("");

    const [selectedOption, setSelectedOption] = useState(null);



    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);



    const handleRadioChange = (event) => {
        setSelectedOption(event.target.value);
    };


    const selectLayoutState = (state) => state.CourseConfig;
    const couseConfigProperties = createSelector(
        selectLayoutState,
        (app) => ({
            courseConfigs: app.courseConfigs,
            courses: app.courses,
            isCourseConfigSuccess: app.isCourseConfigSuccess,
            isCourseConfigCreated: app.isCourseConfigCreated,
            error: app.error,
        })
    );
    // Inside your component
    const {
        courseConfigs,courses, isCourseConfigSuccess, error,isCourseConfigCreated
    } = useSelector(couseConfigProperties)


    useEffect(() => {
            dispatch(onGetCourseConfigs(id));
    }, [dispatch,isCourseConfigSuccess,error,isCourseConfigCreated]);


    useEffect(() => {
        setCourseConfig(courseConfigs);
        setCourse(courses);
        setLoading(!isCourseConfigSuccess);
        if(isCourseConfigCreated){
            setIsloading(false);
        }
        error? setIsloading(false):null

    }, [courseConfigs,courses,isCourseConfigSuccess,error,isCourseConfigCreated]);

    useEffect(() => {
        if (!isEmpty(courseConfigs)) {
            setCourseConfig(courseConfigs);
            setCourse(courses);
            setLoading(!isCourseConfigSuccess);
        }
    }, [courseConfigs,courses,isCourseConfigSuccess,error,isCourseConfigCreated]);





    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            course_id : (id && id.id) || "",
            specific_question: (courseConfig && courseConfig.specific_question) || "",
            common_question: (courseConfig && courseConfig.common_question) || "",
            video_question: (courseConfig && courseConfig.video_question) || "",
            specific_require: ( courseConfig && courseConfig.specific_require) || "",
            require_type: ( courseConfig && courseConfig.require_type) || "",
            common_require: (courseConfig && courseConfig.common_require) || "",
            video_require: (courseConfig && courseConfig.video_require) || "",
            total_require: (courseConfig && courseConfig.total_require) || "",
            total_duration: (courseConfig && courseConfig.total_duration) || "",
            practice_duration: (courseConfig && courseConfig.practice_duration) || "",
            practice_persontage: (courseConfig && courseConfig.practice_persontage) || "",
            video_duration: (courseConfig && courseConfig.video_duration) || "0",

            p_specific_question: (courseConfig && courseConfig.p_specific_question) || "",
            p_common_question: (courseConfig && courseConfig.p_common_question) || "",
            p_video_question: (courseConfig && courseConfig.p_video_question) || "",
            is_enable_p_description: (courseConfig && courseConfig.is_enable_p_description) || "",
            is_enable_exam_description: (courseConfig && courseConfig.is_enable_exam_description) || "",
            
          

        },
        onSubmit: (values) => {
            setIsloading(true);
            const newCourseConfig = {
                course_id: values["course_id"],
                specific_question: values["specific_question"],
                common_question: values["common_question"],
                video_question: values["video_question"],
                require_type:values["require_type"],
                specific_require: values["specific_require"],
                common_require: values["common_require"],
                video_require: values["video_require"],
                total_require: values["total_require"],
                total_duration: values["total_duration"],
                practice_duration: values["practice_duration"],
                practice_persontage: values["practice_persontage"],
                video_duration: values["video_duration"],
                p_specific_question: values["p_specific_question"],
                p_common_question: values["p_common_question"],
                p_video_question: values["p_video_question"],
                is_enable_p_description: values["is_enable_p_description"],
                is_enable_exam_description: values["is_enable_exam_description"],
                
            };
       
            dispatch(onAddCourseConfig(newCourseConfig));
            // validation.resetForm();
        },
    });


    const courseStatus = [
        {
          options: [
            { label: "Choose One", value: "" },
            { label: "Yes", value: "1" },
            { label: "No", value: "2" },
          ],
        },
      ];



    document.title = "Course Configuration | Theory Practice";
    return (
        <React.Fragment>
            <UiContent/>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Course Configuration" pageTitle="Course"/>
                    <Row>
                        <Col xxl={12}>

                            <Card>
                                <PreviewCardHeader title={"Course Configuration-"+course.short_name}/>
                                <CardBody>
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        :
                                    isCourseConfigSuccess ? (
                                    <div className="">
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                             <h5>Exam</h5>
                                                <div className="row g-3 mt-1 mb-3">
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Type</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Specific</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Common</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Video</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <input type="hidden"
                                                       name="course_id"
                                                       onChange={validation.handleChange}
                                                       onBlur={validation.handleBlur}
                                                       value={validation.values.course_id || ""}
                                                />

                                            <div className="row mb-3 g-3" >
                                                        <div className="col-xxl-3">
                                                            <div>
                                                                <label htmlFor="firstName"
                                                                       className="form-label">No. of questions</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3" >
                                                            <Input
                                                                name="specific_question"
                                                                type="text"
                                                                id="sp-q-field"
                                                                placeholder="Enter Specific Questions like 0,1,2..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.specific_question || ""}
                                                                invalid={
                                                                    validation.touched.specific_question && validation.errors.specific_question ? true : false
                                                                }
                                                            />
                                                            {validation.touched.specific_question && validation.errors.specific_question ? (
                                                                <FormFeedback type="invalid">{validation.errors.specific_question}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-xxl-3" >
                                                            <Input
                                                                name="common_question"
                                                                type="text"
                                                                id="com-q-field"
                                                                className="no-spinners"
                                                                placeholder="Enter Common Question like 0,1,2..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.common_question || ""}
                                                                invalid={
                                                                    validation.touched.common_question && validation.errors.common_question ? true : false
                                                                }
                                                            />
                                                            {validation.touched.common_question && validation.errors.common_question ? (
                                                                <FormFeedback type="invalid">{validation.errors.common_question}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-xxl-3" >
                                                            <Input
                                                                name="video_question"
                                                                type="text"
                                                                id="vd-q-field"
                                                                className="no-spinners"
                                                                placeholder="Enter Specific Question like 0,1,2..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.video_question || ""}
                                                                invalid={
                                                                    validation.touched.video_question && validation.errors.video_question ? true : false
                                                                }
                                                            />
                                                            {validation.touched.video_question && validation.errors.video_question ? (
                                                                <FormFeedback type="invalid">{validation.errors.video_question}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Select option for required answers</label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2">
                                                    <div className="form-check form-radio-secondary mb-3">
                                                        <Input className="form-check-input"
                                                               type="radio"
                                                               name="require_type"
                                                               id="formradioRight6"
                                                               value={"1"}
                                                               onBlur={validation.handleBlur}
                                                               checked={validation.values.require_type == "1" || ""}
                                                            //    onChange={handleRadioChange}
                                                            onChange={validation.handleChange}
                                                            data-toggle="tooltip" title="Sepcific Common & Video Answer Require"
                                                               />
                                                        <Label className="form-check-label" htmlFor="formradioRight6" data-toggle="tooltip" title="Sepcific Common & Video Answer Require">
                                                           Option One
                                                        </Label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2">
                                                    <div className="form-check form-radio-secondary mb-3">
                                                        <Input className="form-check-input"
                                                               type="radio"
                                                               name="require_type"
                                                               id="formradioRight6"
                                                               value={"2"}
                                                               onBlur={validation.handleBlur}
                                                               checked={validation.values.require_type  == "2" || ""}
                                                            //    onChange={handleRadioChange}
                                                               onChange={validation.handleChange}
                                                               data-toggle="tooltip" title="Total Require Answer"

                                                               />

                                                        <Label className="form-check-label" htmlFor="formradioRight6"  data-toggle="tooltip" title="Total Require Answer">
                                                            Option two
                                                        </Label>
                                                    </div>
                                                </div>
                                            </div>
                                            {validation.values.require_type == '1' && (
                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">No. of required answers</label>
                                                    </div>
                                                </div>

                                                <div className="col-xxl-3" >
                                                    <Input
                                                        name="specific_require"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="sp-r-field"
                                                        placeholder="Enter Specific Require like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.specific_require || ""}
                                                        invalid={
                                                            validation.touched.specific_require && validation.errors.specific_require ? true : false
                                                        }
                                                    />
                                                    {validation.touched.specific_require && validation.errors.specific_require ? (
                                                        <FormFeedback type="invalid">{validation.errors.specific_require}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="col-xxl-3" >
                                                    <Input
                                                        name="common_require"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="com-r-field"
                                                        placeholder="Enter Common Require like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.common_require || ""}
                                                        invalid={
                                                            validation.touched.common_require && validation.errors.common_require ? true : false
                                                        }
                                                    />
                                                    {validation.touched.common_require && validation.errors.common_require ? (
                                                        <FormFeedback type="invalid">{validation.errors.common_require}</FormFeedback>
                                                    ) : null}
                                                </div>
                                                <div className="col-xxl-3" >
                                                    <Input
                                                        name="video_require"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="vid-r-field"
                                                        placeholder="Enter Video Require like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.video_require || ""}
                                                        invalid={
                                                            validation.touched.video_require && validation.errors.video_require ? true : false
                                                        }
                                                    />
                                                    {validation.touched.video_require && validation.errors.video_require ? (
                                                        <FormFeedback type="invalid">{validation.errors.video_require}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            )}
                                            {validation.values.require_type == '2' && (
                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">No. of required answers</label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                    <Input
                                                        name="total_require"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="tot-r-field"
                                                        placeholder="Enter Total Require like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.total_require || ""}
                                                        invalid={
                                                            validation.touched.total_require && validation.errors.total_require ? true : false
                                                        }
                                                    />
                                                    {validation.touched.total_require && validation.errors.total_require ? (
                                                        <FormFeedback type="invalid">{validation.errors.total_require}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            )}

                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Total duration (In minutes) </label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                    <Input
                                                        name="total_duration"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="tot-d-field"
                                                        placeholder="Enter Total Duration like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.total_duration || ""}
                                                        invalid={
                                                            validation.touched.total_duration && validation.errors.total_duration ? true : false
                                                        }
                                                    />
                                                    {validation.touched.total_duration && validation.errors.total_duration ? (
                                                        <FormFeedback type="invalid">{validation.errors.total_duration}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Is Enable Info ?</label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                <Input
                            name="is_enable_exam_description"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.is_enable_exam_description || ""}
                            invalid={
                              validation.touched.is_enable_exam_description &&
                              validation.errors.is_enable_exam_description
                                ? true
                                : false
                            }
                          >
                            {courseStatus.map((item, key) => (
                              <React.Fragment key={key}>
                                {item.options.map((item, key) => (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.touched.is_enable_exam_description &&
                          validation.errors.is_enable_exam_description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.is_enable_exam_description}
                            </FormFeedback>
                          ) : null}
                                                    {validation.touched.is_enable_exam_description && validation.errors.is_enable_exam_description ? (
                                                        <FormFeedback type="invalid">{validation.errors.is_enable_exam_description}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                         

                                            <div className="row mb-3 g-3 d-none" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Time of one video question (In seconds)</label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                    <Input
                                                        name="video_duration"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="vid-d-field"
                                                        placeholder="Enter Video Duration like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.video_duration || ""}
                                                        invalid={
                                                            validation.touched.video_duration && validation.errors.video_duration ? true : false
                                                        }
                                                    />
                                                    {validation.touched.video_duration && validation.errors.video_duration ? (
                                                        <FormFeedback type="invalid">{validation.errors.video_duration}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <hr/>
                                            <h5>Practice</h5>
                                        

                                            <div className="row g-3 mt-1 mb-3">
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Type</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Specific</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Common</label>
                                                        </div>
                                                    </div>
                                                    <div className="col-xxl-3">
                                                        <div className="ms-1" >
                                                            <label className="form-check-label" htmlFor="customSwitchsizemd">Video</label>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="row mb-3 g-3" >
                                                        <div className="col-xxl-3">
                                                            <div>
                                                                <label htmlFor="firstName"
                                                                       className="form-label">No. of questions</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-3" >
                                                            <Input
                                                                name="p_specific_question"
                                                                type="text"
                                                                className="no-spinners"
                                                                id="sp-q-field"
                                                                placeholder="Enter Specific Question like 0,1,2..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.p_specific_question || ""}
                                                                invalid={
                                                                    validation.touched.p_specific_question && validation.errors.p_specific_question ? true : false
                                                                }
                                                            />
                                                            {validation.touched.p_specific_question && validation.errors.p_specific_question ? (
                                                                <FormFeedback type="invalid">{validation.errors.p_specific_question}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-xxl-3" >
                                                            <Input
                                                                name="p_common_question"
                                                                type="text"
                                                                className="no-spinners"
                                                                id="com-q-field"
                                                                placeholder="Enter Common Question like 0,1,2..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.p_common_question || ""}
                                                                invalid={
                                                                    validation.touched.p_common_question && validation.errors.p_common_question ? true : false
                                                                }
                                                            />
                                                            {validation.touched.p_common_question && validation.errors.p_common_question ? (
                                                                <FormFeedback type="invalid">{validation.errors.p_common_question}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-xxl-3" >
                                                            <Input
                                                                name="p_video_question"
                                                                type="text"
                                                                className="no-spinners"
                                                                id="vd-q-field"
                                                                placeholder="Enter Specific Question like 0,1,2..."
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.p_video_question || ""}
                                                                invalid={
                                                                    validation.touched.p_video_question && validation.errors.p_video_question ? true : false
                                                                }
                                                            />
                                                            {validation.touched.p_video_question && validation.errors.p_video_question ? (
                                                                <FormFeedback type="invalid">{validation.errors.p_video_question}</FormFeedback>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Practice duration (In minutes) </label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                    <Input
                                                        name="practice_duration"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="tot-d-field"
                                                        placeholder="Enter Practice Duration like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.practice_duration || ""}
                                                        invalid={
                                                            validation.touched.practice_duration && validation.errors.practice_duration ? true : false
                                                        }
                                                    />
                                                    {validation.touched.practice_duration && validation.errors.practice_duration ? (
                                                        <FormFeedback type="invalid">{validation.errors.practice_duration}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Practice Pass (Persontage %) </label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                    <Input
                                                        name="practice_persontage"
                                                        type="text"
                                                        className="no-spinners"
                                                        id="tot-d-field"
                                                        placeholder="Enter Practice Pass (Persontage %) like 0,1,2..."
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.practice_persontage || ""}
                                                        invalid={
                                                            validation.touched.practice_persontage && validation.errors.practice_persontage ? true : false
                                                        }
                                                    />
                                                    {validation.touched.practice_persontage && validation.errors.practice_persontage ? (
                                                        <FormFeedback type="invalid">{validation.errors.practice_persontage}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="row mb-3 g-3" >
                                                <div className="col-xxl-3">
                                                    <div>
                                                        <label htmlFor="firstName"
                                                               className="form-label">Is Enable Info ?</label>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-6" >
                                                <Input
                            name="is_enable_p_description"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.is_enable_p_description || ""}
                            invalid={
                              validation.touched.is_enable_p_description &&
                              validation.errors.is_enable_p_description
                                ? true
                                : false
                            }
                          >
                            {courseStatus.map((item, key) => (
                              <React.Fragment key={key}>
                                {item.options.map((item, key) => (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.touched.is_enable_p_description &&
                          validation.errors.is_enable_p_description ? (
                            <FormFeedback type="invalid">
                              {validation.errors.is_enable_p_description}
                            </FormFeedback>
                          ) : null}
                                                    {validation.touched.is_enable_p_description && validation.errors.is_enable_p_description ? (
                                                        <FormFeedback type="invalid">{validation.errors.is_enable_p_description}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>
                                            

                                                    <div className="row g-3 mt-3">
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <Link to="/admin/course" className="btn btn-light">Close</Link>
                                                            <Button color="primary" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                              <span className="d-flex align-items-center">
                                                                  {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                                  <span className="flex-grow-1 ms-2">
                                                                    Save Changes
                                                                  </span>
                                                              </span>
                                                            </Button>
                                                        </div>
                                                    </div>
                                        </Form>
                                    </div>
                                    ) : (<div className="py-4 text-center">
                                        <div>
                                            <lord-icon
                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                trigger="loop"
                                                colors="primary:#405189,secondary:#0ab39c"
                                                style={{width: "72px", height: "72px"}}
                                            ></lord-icon>
                                        </div>

                                        <div className="mt-4">
                                            <h5>Sorry! No Record Found</h5>
                                        </div>
                                    </div>)
                                    )}
                                    <ToastContainer closeButton={false} limit={1}/>
                                </CardBody>
                            </Card>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

};

export default CourseConfig;

