import { createSlice } from "@reduxjs/toolkit";
import { getTopicAreas,  addNewTopicArea,addTopicAreaTranslation,updateTopicArea,  deleteTopicArea } from './thunk';

export const initialState = {
    topicAreas: [],
    langs: [],
    error: {},
};

const TopicAreaSlice = createSlice({
    name: 'TopicAreaSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getTopicAreas.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
            state.topicAreas = action.payload.data['topicAreas']['data'];
            state.langs = action.payload.data['langs']['data'];
            state.isTopicAreaCreated = false;
            state.isTopicAreaSuccess = true;
            }else {
                state.error = action.payload.data || null;
                state.isTopicAreaSuccess = false;
            }
        });

        builder.addCase(getTopicAreas.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isTopicAreaCreated = false;
            state.isTopicAreaSuccess = false;
        });

        builder.addCase(addNewTopicArea.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.topicAreas.push(action.payload.data);
                state.isTopicAreaCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isTopicAreaCreated = false;
            }
        });

        builder.addCase(addNewTopicArea.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(addTopicAreaTranslation.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.topicAreas = state.topicAreas.map(topicArea =>
                    topicArea.id === action.payload.data.id
                        ? {...topicArea, ...action.payload.data}
                        : topicArea
                );
                state.isTopicAreaCreated = true;
            } else {
            state.error = action.payload.data || null;
            state.isTopicAreaCreated = false;
             }
        });

        builder.addCase(addTopicAreaTranslation.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isTopicAreaCreated = false;
        });


        builder.addCase(updateTopicArea.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.topicAreas = state.topicAreas.map(topicArea =>
                    topicArea.id === action.payload.data.id
                        ? {...topicArea, ...action.payload.data}
                        : topicArea
                );
                state.isTopicAreaCreated = true;
            } else {
                state.error = action.payload.data || null;
                state.isTopicAreaCreated = false;
            }
        });
        builder.addCase(updateTopicArea.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isTopicAreaCreated = false;
        });


        builder.addCase(deleteTopicArea.fulfilled, (state, action) => {
            state.topicAreas = state.topicAreas.filter(
                topicArea => topicArea.id.toString() !== action.payload.topicArea.toString()
            );
        });
        builder.addCase(deleteTopicArea.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


    }
});

export default TopicAreaSlice.reducer;