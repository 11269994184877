import React, { useEffect, useState } from 'react';
import { startTimer,getTimeLeft,clearTimer } from '../../utility/TimerUtility';
import { useNavigate } from 'react-router-dom'; // Make sure you've installed react-router-dom
import Swal from 'sweetalert2';
import { useDispatch } from 'react-redux';
import { updateSystemAndExam as onUpdateSystemAndExam } from '../../../slices/exam/thunk';
import '../../../assets/custom/templateStyle.css'

const TimerComponent = (props) => {

  const {duration,handleTimeUp,examType,direction} = props;
  const [timeLeft, setTimeLeft] = useState(getTimeLeft(duration));
  const navigate = useNavigate();
  
  const dispatch = useDispatch();

  useEffect(() => {
    startTimer();
    const intervalId = setInterval(() => {
      
      const timeRemaining = getTimeLeft(duration);
      
      setTimeLeft(timeRemaining);

      if (timeRemaining <= 0) {

        const systemLocalIp = localStorage.getItem('systemLocalIp');

        const studentDataFromLocalStorage =localStorage.getItem('StudentInformation');
        const stdInfo=JSON.parse(studentDataFromLocalStorage);
        const examPostedData = {
          exam_id:stdInfo.examId,
          system_ip:systemLocalIp,
          closeType:examType==1?3:5
        };

        dispatch(onUpdateSystemAndExam(examPostedData));

        clearInterval(intervalId);
        clearTimer();
        if(examType==1){
          handleTimeUp();
        }else{
     
          handlePracticeTimeUp()
        // navigate('/student'); 
        }
  
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [navigate]);




  const handlePracticeTimeUp = () => {
    
        Swal.fire({
          text: 'Your practice time is finish',
          icon: 'warning',
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: '#F1416C',
          cancelButtonColor: '#181C32',
          confirmButtonText: 'OK',
        }).then((result) => {
          if (result.isConfirmed) {
          navigate('/student')
          }
        })
      }
    
      

  return (
    <div id="timer2">

      {/* Time left: {Math.floor(timeLeft / 60000)} minutes and {Math.floor((timeLeft / 1000) % 60)} seconds */}

      <div id="countdown" className={`countdownlist ${direction === 'ltr' ? 'ms-3' : 'me-3'}`} style={{ direction: "ltr"}}>
      {/* <div className=""> */}
                  
                  <div className="mt-1 fs-5 text-white text-nowrap"> <i class=" ri-time-line align-bottom me-1"></i> Time Remaining</div>
              {/* </div> */}
              <div className="countdownlist-item">
                  
                                    <div className="count-num">{ Math.floor(timeLeft / (1000 * 60 * 60)).toString().padStart(2, '0')}</div>
                                </div>
                                <div className="countdownlist-item">
                                    <div className="count-num">{Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0')}</div>
                                </div>
                                <div className="countdownlist-item">
                                    <div className="count-num">{Math.floor((timeLeft % (1000 * 60)) / 1000).toString().padStart(2, '0')}
                                </div>
                                </div>
                            </div>
    </div>
  );
};

export default TimerComponent;
