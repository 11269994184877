import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getRooms as getRoomsApi,
  addNewRoom as addNewRoomApi,
  updateRoom as updateRoomApi,
  deleteRoom as deleteRoomApi,
   getLanguages as getLanguagesApi, 
   deleteLanguage as deleteLanguageApi,
   getBranchRooms as getBranchRoomsApi

} from "../../helpers/custom_helper/backend_helper";


export const updateRoom = createAsyncThunk("update-room", async (room) => {
  try {
    const response = addNewRoomApi(room);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const addNewRoom = createAsyncThunk("save-room", async (room) => {
  try {
    const response = addNewRoomApi(room);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getRooms = createAsyncThunk("get-rooms", async () => {
  try {
    const response = getRoomsApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const deleteRoom = createAsyncThunk("delete-room", async (room) => {
  try {
    const response = deleteRoomApi(room);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { room, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});


export const getBranchRooms = createAsyncThunk("get-branch-rooms", async (branchId) => {
  try {

    const response = getBranchRoomsApi(branchId);
    const data = await response;
    if(data.success)
    {
      return response
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});