import React, { useEffect, useState } from 'react'
import Template2Header from './Template2Header'
import Template2Footer from './Template2Footer'
import { useDispatch, useSelector } from 'react-redux';
import { 
  getQuestions as onGetQuestionsForExam,
  saveQuestions as onSaveQuestions,
  savePracticeQuestions as onSavePracticeQuestions,
  saveStdInfo as onGetStdInfo,
  questionListResetFlag as onQuestionListResetFlag
 } from '../../../../slices/exam/thunk';

import { createSelector } from 'reselect'
import { api } from '../../../../config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { Howl } from 'howler'
import { useTranslation } from 'react-i18next'
import {Link, json, useNavigate} from 'react-router-dom'
import ExamVideoSection from '../ExamVideoSection';
import { Button } from 'reactstrap'
import AlertComponent from '../AlertComponent';
import ExamReviewSection from '../ExamReviewSection';
import '../../../../assets/custom/templateStyle.css'


const customStyle = {
  checkedOption: {
    background: 'linear-gradient(90deg, #e8a23d 0%, #000000cf 100%)',
    borderRadius: '15px',
  },

  defaultOption: {

    borderRadius: "15px",
    boxShadow: "inset 4px 3.1px 5px -3px #38414a26",
    color: "#653b13"

  },

  checkedColor: {
    color: "#ffff"

  },
  defaultColor: {

    color: "#653b13"

  },
  audioPlayingColor: {
    color: "#71a832"
  },
  muteAudioColor: {
    color: "#653b13"
  },



}


function ExamTemplate2Index(props) {
  const {isHighlightedQAudio,qData,index,handlePlayAudio,selectedOptions ,testType,handleChangeRadioOptions,handleMarkedMcqs,qAudioRef,changeHighlightState,optAAudioRef,optBAudioRef ,optCAudioRef,audioPlayingEvent,direction} = props;
  const fileUrl = api.IMAGE_URL;
  
  return (
    <React.Fragment>
       <div className="page-content mt-3 pt-0 px-5">

                
        <div className={`full-modal-body content-section ${direction=='rtl'?'content-ur':'content-en'}`}>



        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.q_audio ? (
                          <audio
                            ref={qAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('q')
                            }}
                          >

                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.q_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}
                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.opt_a_audio ? (
                          <audio
                            ref={optAAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('a')
                            }}
                          >
                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.opt_a_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}



                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.opt_b_audio ? (
                          <audio
                            ref={optBAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('b')
                            }}
                          >
                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.opt_b_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}


                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.opt_c_audio ? (
                          <audio
                            ref={optCAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('c')
                            }}
                          >
                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.opt_c_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}


        <div className="question-image-container text-center">
    <span className="testText"></span>

    {qData[index] && qData[index].question.q_image != '' ? (
                         
                            <img src={fileUrl + qData[index].question.q_image} className="question-image" height="200" />
                          ) : (
                            ''
                          )}

    
    </div>
    <div className="question-text audio-section  my-4" onClick={() => {
                                  handlePlayAudio('q', index)
                                }}>
    
        <span className="question-text fs-1  lh-lg" style={isHighlightedQAudio ? customStyle.audioPlayingColor : customStyle.muteAudioColor}>{ qData[index].question.question_translations && qData.length > 0 ? qData[index].question.question_translations[0].q_title : ''}</span>
    </div>
    
    
    <div className="question-choice-container">
        <table className="table table-borderless align-middle">
            <tbody>
    <tr className='d-block'>
    <td>
        <div className="form-check form-check-info">
          <input className="form-check-input inp-radio default-class " type="radio" value="a" name="opt"
             onChange={(e) => {
              handleChangeRadioOptions(index, 'optA', e.target.value, qData[index].q_id, qData[index].id)
              handleMarkedMcqs(index)
            }}

            checked={
              selectedOptions[index] && selectedOptions[index].selectedValue === 'a'
                ? 'checked'
                : ''
            }
            disabled={
              testType == 2 && selectedOptions[index]
                ? selectedOptions[index].isDisable
                : ''
            }
           />
            </div>
          
        </td>
    <td>
     <p className="option-text fs-1 mb-0 opt-b play-audio opt-b-span " onClick={() => {
                                        handlePlayAudio('a', index)
                                      }}>{qData[index].question.question_translations && qData.length > 0 ? qData[index].question.question_translations[0].opt_a : ''}</p>
     
       {qData[index] && qData[index].question.opt_a_image != '' && qData[index].question.opt_a_image !=1 ? (
                                          <img
                                            src={fileUrl + qData[index].question.opt_a_image}
                                            alt=''
                                            className='option-question-image' height="85"
                                          />
                                        ) : (
                                          ''
                                        )}
   </td>

             

                            <td className='choice-result-display'>
                              
                              {testType == 2 ? (
                                <span class='ml-5'>
                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA == qData[index].question.correct_opt ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      <i className='ri-check-fill fw-bolder fs-36'></i>
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA != '' &&
                                  selectedOptions[index].optA != qData[index].question.correct_opt ? (
                                    <span className='btn btn-icon btn-danger rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#cc3516"}}>
                                      <i className='ri-close-fill fw-bolder fs-36'></i>
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB &&
                                  qData[index].question.correct_opt == 'a' ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC &&
                                  qData[index].question.correct_opt == 'a' ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
  
</tr>


<tr className='d-block'>
    <td>
        <div className="form-check form-check-info">
          <input className="form-check-input inp-radio default-class " type="radio" value="b" name="opt"
             onChange={(e) => {
              handleChangeRadioOptions(index, 'optB', e.target.value, qData[index].q_id, qData[index].id)
              handleMarkedMcqs(index)
            }}

            checked={
              selectedOptions[index] && selectedOptions[index].selectedValue === 'b'
                ? 'checked'
                : ''
            }
            disabled={
              testType == 2 && selectedOptions[index]
                ? selectedOptions[index].isDisable
                : ''
            }
           />
            </div>
          
        </td>
    <td>
     <p className="option-text fs-1 mb-0 opt-b play-audio opt-b-span " onClick={() => {
                                        handlePlayAudio('b', index)
                                      }}>{qData.length > 0 ? qData[index].question.question_translations[0].opt_b : ''}</p>
     
       {qData[index] && qData[index].question.opt_b_image != '' && qData[index].question.opt_b_image !=1  ? (
                                          <img
                                            src={fileUrl + qData[index].question.opt_b_image}
                                            alt=''
                                            className='option-question-image' height="85"
                                          />
                                        ) : (
                                          ''
                                        )}
   </td>
   
   <td className='choice-result-display'>
                              {testType == 2 ? (
                                <span className='ml-5'>
                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB == qData[index].question.correct_opt ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB != '' &&
                                  selectedOptions[index].optB != qData[index].question.correct_opt ? (
                                    <span className='btn btn-icon btn-danger rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#cc3516"}}>
                                      {' '}
                                      <i className='ri-close-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA &&
                                  qData[index].question.correct_opt == 'b' ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC &&
                                  qData[index].question.correct_opt == 'b' ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>
  
</tr>

<tr className='d-block'>
    <td>
        <div className="form-check form-check-info">
          <input className="form-check-input inp-radio default-class " type="radio" value="c" name="opt"
             onChange={(e) => {
              handleChangeRadioOptions(index, 'optC', e.target.value, qData[index].q_id, qData[index].id)
              handleMarkedMcqs(index)
            }}

            checked={
              selectedOptions[index] && selectedOptions[index].selectedValue === 'c'
                ? 'checked'
                : ''
            }
            disabled={
              testType == 2 && selectedOptions[index]
                ? selectedOptions[index].isDisable
                : ''
            }
           />
            </div>
          
        </td>
    <td>
     <p className="option-text fs-1 mb-0 opt-b play-audio opt-b-span" onClick={() => {
                                        handlePlayAudio('c', index)
                                      }}>{qData.length > 0 ? qData[index].question.question_translations[0].opt_c : ''}</p>
     
       {qData[index] && qData[index].question.opt_b_image != '' && qData[index].question.opt_c_image !=1  ? (
                                          <img
                                            src={fileUrl + qData[index].question.opt_c_image}
                                            alt=''
                                            className='option-question-image' height="85"
                                          />
                                        ) : (
                                          ''
                                        )}
   </td>
  
   <td className='choice-result-display'>
                              
                              {testType == 2 ? (
                                <span class='ml-5'>
                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC == qData[index].question.correct_opt ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      <i className='ri-check-fill fw-bolder fs-36'></i>
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optC != '' &&
                                  selectedOptions[index].optC != qData[index].question.correct_opt ? (
                                    <span className='btn btn-icon btn-danger rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#cc3516"}}>
                                      <i className='ri-close-fill fw-bolder fs-36'></i>
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optB &&
                                  qData[index].question.correct_opt == 'c' ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}

                                  {qData[index] &&
                                  selectedOptions[index] &&
                                  selectedOptions[index].optA &&
                                  qData[index].question.correct_opt == 'c' ? (
                                    <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}>
                                      {' '}
                                      <i className='ri-check-fill fw-bolder fs-36'></i>{' '}
                                    </span>
                                  ) : (
                                    ''
                                  )}
                                </span>
                              ) : (
                                ''
                              )}
                            </td>

</tr>

            </tbody>
        </table>
    </div>
                </div>
                </div>
           
 
 
    </React.Fragment>

  )
}

export default ExamTemplate2Index