import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getCourseConfigs as  getCourseConfigsApi,

  addCourseConfig as  addCourseConfigApi,


} from "../../helpers/custom_helper/backend_helper";



export const addCourseConfig = createAsyncThunk("save-course-config", async (role) => {
  try {
    const response = addCourseConfigApi(role);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(" Course Configuration Added Failed", { autoClose: 3000 });
    return error;
  }
});

export const getCourseConfigs = createAsyncThunk("get-course-config", async (courseId) => {
  try {
    const response = getCourseConfigsApi(courseId.id);
    return response;
  } catch (error) {
    return error;
  }
});
