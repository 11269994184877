import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getLanguages as getLanguagesApi,
  addNewLanguage as addNewLanguageApi,
  updateLanguage as updateLanguageApi,
  deleteLanguage as deleteLanguageApi, getTopicAreas as getTopicAreasApi, deleteTopicArea as deleteTopicAreaApi,

} from "../../helpers/custom_helper/backend_helper";

export const updateLanguage = createAsyncThunk("update-lang", async (language) => {
  try {
    const response = addNewLanguageApi(language);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("Language Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const addNewLanguage = createAsyncThunk("save-lang", async (language) => {
  try {
    const response = addNewLanguageApi(language);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("Language Added Failed", { autoClose: 3000 });
    return error;
  }
});

export const getLanguages = createAsyncThunk("", async () => {
  try {
    const response = getLanguagesApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }
});

export const deleteLanguage = createAsyncThunk("delete-lang", async (language) => {
  try {
    const response = deleteLanguageApi(language);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { language, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Language Deleted Failed", { autoClose: 3000 });
    return error;
  }

});