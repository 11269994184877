
import React, { useEffect, useRef, useState, useTransition } from 'react'
import ExamHeader from './ExamHeader'
import ExamFooter from './ExamFooter'
import { useDispatch, useSelector } from 'react-redux'
import {
  getQuestions as onGetQuestionsForExam,
  saveQuestions as onSaveQuestions,
  savePracticeQuestions as onSavePracticeQuestions,
  saveStdInfo as onGetStdInfo,
  questionListResetFlag as onQuestionListResetFlag

} from '../../../slices/exam/thunk'
import { createSelector } from 'reselect'
import { api } from '../../../config'
import MyLoader from '../../../common/MyLoader'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ExamReviewSection from './ExamReviewSection'
import Swal from 'sweetalert2'
import { Howl } from 'howler'
import { useTranslation } from 'react-i18next'
import {Link, json, useNavigate} from 'react-router-dom'
import ExamVideoSection from './ExamVideoSection'
import { Button } from 'reactstrap'
import AlertComponent from './AlertComponent'
import Template2Header from './template2/Template2Header'
import ExamTemplate2Index from './template2/ExamTemplate2Index'
import Template2Footer from './template2/Template2Footer'
import '../../../assets/custom/templateStyle.css'
import Loader from '../../../Components/Common/Loader'
import ExamLoader from '../../../Components/Common/ExamLoader'



const customStyle = {
  checkedOption: {
    background: 'linear-gradient(90deg, #e8a23d 0%, #000000cf 100%)',
    borderRadius: '15px',
  },

  defaultOption: {

    borderRadius: "15px",
    boxShadow: "inset 4px 3.1px 5px -3px #38414a26",


  },
  noClass: {
    //width: "93%"
  },
  checkedColor: {
    width: "93%"
  },
  defaultColor: {
    width: "93%"
  },
  audioPlayingColor: {
    color: "#71a832",
    fontSize: "42px"
  },
  muteAudioColor: {
    color: "#653b13",
    fontSize: "42px"
  },



}


function ExamIndex() {

  const dispatch = useDispatch();
  const selectLayoutState = (state) => state.ExamReducer;
  const navigate = useNavigate()

  const { t, i18n }  = useTranslation();

  const [qData, setQData] = useState([]);
  const [index, setIndex] = useState(0);
  const [reviewStack, setReviewStack] = useState([]);
  const [reviewAfterMarkedStack, setReviewAfterMarkedStack] = useState([]);
  const [isShowBtnFinish, setIsShowBtnFinish] = useState(false)
  const [isShowBtnPrev, setIsShowBtnPrev] = useState(false)
  const [totalQuestion, setTotalQuestion] = useState(0)
  const [selectedOptions, setSelectedOptions] = useState([
    {
      id: '',
      optA: '',
      optB: '',
      optC: '',
      correctAns: '',
      isDisable: false,
      qId: '',
      selectedValue: '',

    },
  ])

  const [testType, setTestType] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isShowRevSection, setIsShowRevSection] = useState(false)
  const [isShowMcqsSection, setIsShowMcqsSection] = useState(true)
  const [isShowResultSection, setIsShowResultSection] = useState(false)

  
  const [isActiveReviewMarkedOnly, setIsActiveReviewMarkedOnly] = useState(true)
  const [stackCounter, setStackCounter] = useState(0)
  const [markMcqs, setMarkMcqs] = useState([])
  const [isVidSecShow, setIsVidSecShow] = useState(false)
  const [isSaveQ, setIsSaveQ] = useState(false)
  const [isHighlightedQAudio, setIsHighlightedQAudio] = useState(false)
  const [isHighlightedOptA, setIsHighlightedOptA] = useState(false)
  const [isHighlightedOptB, setIsHighlightedOptB] = useState(false)
  const [isHighlightedOptC, setIsHighlightedOptC] = useState(false)

  const [firstAudioSound, setFirstAudioSound] = useState('')
  const [questionPerc, setQuestionPerc] = useState(0)
  const [isPlaying, setIsPlaying] = useState(false)
  const [progress, setProgress] = useState(100)
  const [createdAtExam, setCreatedAtExam] = useState('')
  const [examInitialTime, setExamInitialTime] = useState('')

  const [optClass, setOptClass] = useState('')

  const [optAMarkedClass, setOptAMarkedClass] = useState('')
  const [optBMarkedClass, setOptBMarkedClass] = useState('')
  const [optCMarkedClass, setOptCMarkedClass] = useState('')

  const [studentInfo, setstudentInfo] = useState([])

  const [direction, setDirection] = useState('')
  const [qLang, setQLang] = useState('en')
  const [audioLang, setAudioLang] = useState('')

  const [totalCorrectQ, setTotalCorrectQ] = useState(0)
  const [totalWrongQ, setTotalWrongQ] = useState(0)
  const [totalSkipQ, setTotalSkipQ] = useState(0)
  const [examPracticeAttemptId, setExamPracticeAttemptId] = useState(0)
  const [examAttemptId, setExamAttemptId] = useState(0)
  const [isReviewMode, setIsReviewMode] = useState(false)
  const [examTemplate, setExamTemplate]=useState(2);
  const [isQuestionLoader, setIsQuestionLoader] = useState(true)

  const [correctOptAIcon, setCorrectOptAIcon] = useState(false)
  const [wrongOptAIcon, setWrongOptAIcon] = useState(false)
  const [correctOptBIcon, setCorrectOptBIcon] = useState(false)
  const [wrongOptBIcon, setWrongOptBIcon] = useState(false)
  const [correctOptCIcon, setCorrectOptCIcon] = useState(false)
  const [wrongOptCIcon, setWrongOptCIcon] = useState(false)


  const fileUrl = api.IMAGE_URL;
  const qAudioRef = React.createRef(null)
  const optAAudioRef = React.createRef()
  const optBAudioRef = React.createRef()
  const optCAudioRef = React.createRef()


  const examProperties = createSelector(
    selectLayoutState,
    (data) => ({

      questionsList: data.questionsList,
      isQuestionSuccess: data.isQuestionSuccess,
      isQuestionSave: data.isQuestionSave,
      stdInfo:data.stdInfo,
      error: data.error,
      questionType:data.questionType,
      attemptId:data.attemptId
      
    })
  );

  const {
    questionsList, error, isQuestionSuccess, isQuestionSave,stdInfo,questionType,attemptId
  } = useSelector(examProperties)

  useEffect(() => {

  
    if (questionsList && !questionsList.length) {
      setAudioLang(true);
      const postedExamData = {

        std_id: stdInfo  && stdInfo.stdId,
        q_lang: stdInfo  && stdInfo.qLangShortName,
        audio_lang: stdInfo  && stdInfo.audioLangShortName,
        exam_type: stdInfo  && stdInfo.examType,
        exam_id: stdInfo  && stdInfo.examId,
        course_id: stdInfo  && stdInfo.courseId,
        questionType:questionType && questionType,
        attemptId:attemptId && attemptId

    
      };

  
      dispatch(onGetQuestionsForExam(postedExamData));
    }
  }, [dispatch,questionType,attemptId]);

  useEffect(() => {
  
    setIsQuestionLoader(!isQuestionSuccess);
  }, [isQuestionSuccess]);

  //

    useEffect(() => {
    const studentDataFromLocalStorage = localStorage.getItem('StudentInformation');
    window.localStorage.setItem('isStart',1);
    },[]);

    useEffect(() => {
      i18n.changeLanguage(qLang)
      },[qLang]);


  useEffect(() => {

    setTestType(stdInfo.examType)
  
    if (questionsList && questionsList.length > 0) {


      setQData(questionsList);
   
      setTotalQuestion(questionsList.length);
      //  setIsLoading(!isQuestionSuccess);
      setIsSaveQ(isQuestionSave);
      setstudentInfo(stdInfo);
      setQLang(stdInfo.qLangShortName);
      setDirection(stdInfo.direction);
      // setTestType(stdInfo.examType)
     
  
      setExamPracticeAttemptId(questionsList[0].attempt_id);
      setExamAttemptId(questionsList[0].attempt_id);
      setExamTemplate(stdInfo.examTemplate)

      if(questionsList.length===1){
      
        setIsShowBtnFinish(true);
        setIsShowBtnPrev(false);
      }

      if(questionsList[0].question.q_is_video==1){
      
        handleVideQIndex(0)
        
      setIsVidSecShow(true)
      setIsShowMcqsSection(false)
      }else {
    
      setIsVidSecShow(false)
      setIsShowMcqsSection(true)
    
      }

    } else {

      setIsLoading(false);
      // toast.error('Question list empty', { autoClose: 3000 });
    }
  }, [questionsList, isQuestionSave]);



  const buttonRef = useRef(null);
  const prevButtonRef = useRef(null);
  useEffect(() => {
    const handleKeyDown = (event) => {
      
      //Next
      if (event.key === 'ArrowRight') {
     
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      
      }

      //Prevoius
      if (event.key === 'ArrowLeft') {
        if (prevButtonRef.current) {
          prevButtonRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleNextPrevClick = (type) => {

    if (type == 'next' && index < qData.length - 1) {

      decideShowOptStyle(index + 1);
      handleAutoPlayAudio('q', index + 1);

      setExamAttemptId(qData[index].attempt_id)
      setCreatedAtExam(qData[index].created_at)

      if (reviewStack.length > 0) {

        if (reviewStack[stackCounter]) {
          setIndex(reviewStack[stackCounter])
          setStackCounter(stackCounter + 1)
             handleVideQIndex(reviewStack[stackCounter])

          if (reviewStack.length == stackCounter + 1) {
            setIsShowBtnFinish(true)
          }

        } else {
          setIsShowBtnFinish(true)
        }

      

      } else {
        setIndex(index + 1)
         handleVideQIndex(index + 1)

        if (index + 1 == totalQuestion - 1) {
          setIsShowBtnFinish(true)
        }
      }

      handleCountSkipQ(index,'next');
    }

    if (type == 'prev' && index > 0) {

      handleVideQIndex(index - 1)
      decideShowOptStyle(index - 1);
      handleAutoPlayAudio('q', index - 1);

 
      if (reviewStack.length > 0) {
        const prevCounter = stackCounter - 2

        if (reviewStack.length > stackCounter - 1) {
          setIsShowBtnFinish(false)
        }


        if (reviewStack.indexOf(stackCounter - 1) === -1) {
        //  console.log('not exist');
        }else{
         
         setIndex(reviewStack[prevCounter])
        setStackCounter(stackCounter - 1)
        }

       
      } else {
        setIsShowBtnFinish(false)
        setIndex(index - 1)
      }


      handleCountSkipQ(index,'prev');
    }



    
  }

  const handleChangeRadioOptions = (index, property, value, qId, id) => {
    // value && value === qData[index].correct_opt ? setAns(1) : setAns(0)
    const newArray = [...selectedOptions]
    const updatedObject = { ...newArray[index] }

    updatedObject['isDisable'] = true
    updatedObject['correctAns'] = qData[index].question.correct_opt
    updatedObject['qId'] = qId
    updatedObject['id'] = id

    handleCountRightWrongQ(qData[index].question.correct_opt,value);


    if (value == 'a' && property == 'optA') {
      updatedObject[property] = value
      updatedObject['selectedValue'] = value
      updatedObject['optB'] = ''
      updatedObject['optC'] = ''

      newArray[index] = updatedObject
    }

    if (value == 'b' && property == 'optB') {
      updatedObject[property] = value
      updatedObject['selectedValue'] = value
      updatedObject['optA'] = ''
      updatedObject['optC'] = ''
      newArray[index] = updatedObject
    }

    if (value == 'c' && property == 'optC') {
      updatedObject[property] = value
      updatedObject['selectedValue'] = value
      updatedObject['optA'] = ''
      updatedObject['optB'] = ''
      newArray[index] = updatedObject
    }

    setSelectedOptions(newArray)

    if (testType == 2) {
      // handleSubmitPracticeExam(qData[index].id, value && value === qData[index].correct_opt ? 1 : 0, value)
      handleSubmitPracticeExam(id, qData[index].question.correct_opt, value)
    }


  }

  const handleCountRightWrongQ =(correctAns,choosedOption)=>{
      if(correctAns===choosedOption){
        setTotalCorrectQ(prevTotal => prevTotal + 1);
      }else{
        setTotalWrongQ(prevTotal => prevTotal + 1);
      }
   
  }



  const handleCountSkipQ = (qIndex, type) => {



    const qIndexInt = parseInt(qIndex);
    const isMarked = markMcqs.includes(qIndexInt);

    if (!isMarked && type === 'next') {
        setTotalSkipQ(prevTotalSkipQ => prevTotalSkipQ + 1);
    }

    if ( type === 'prev') {
        setTotalSkipQ(prevTotalSkipQ => Math.max(prevTotalSkipQ - 1, 0)); // Ensure skipQ never goes below 0
    }
}


  const handleReviewMarkedOnly = (e,paramIndex) => {
    if (e.target.checked) {

      if (reviewStack.indexOf(paramIndex) === -1) {
        const updatedArray = [...reviewStack]
        updatedArray[reviewStack.length] = paramIndex
        setReviewStack(updatedArray)
      }
    } else {

      const updatedStack = [...reviewStack]
      updatedStack.pop()
      setReviewStack(updatedStack)
    }
  }

  const handleReviewAfter = (e,paramIndex) => {



    // if (e.target.checked) {
    //   if (reviewAfterMarkedStack.indexOf(paramIndex) === -1) {
    //     const updatedArray = [...reviewAfterMarkedStack]
    //     updatedArray[reviewAfterMarkedStack.length] = paramIndex
    //     setReviewAfterMarkedStack(updatedArray)
    //   }
    // } else {

    //   const updatedStack = [...reviewAfterMarkedStack]
    //   updatedStack.pop()
    //   setReviewAfterMarkedStack(updatedStack)
    // }

    if (e.target.checked) {
      if (reviewAfterMarkedStack.indexOf(paramIndex) === -1) {
        // If the index is not already in the stack, add it
        setReviewAfterMarkedStack(prevStack => [...prevStack, paramIndex]);
      }
    } else {
      // If the index is marked, remove it from the stack
      const updatedStack = reviewAfterMarkedStack.filter(index => index !== paramIndex);
      setReviewAfterMarkedStack(updatedStack);
    }
    
  }

  const handelExit = () => {

    if (testType == 2) {
   
  
      if (questionPerc < 100) {


        Swal.fire({
          text: t('Are you sure you want to exit'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F1416C',
          cancelButtonColor: '#181C32',
          confirmButtonText: t('Yes'),
          cancelButtonText: t('No'),
        }).then((result) => {
          if (result.isConfirmed) {
            setQData([]);
            navigate('/practice-type')
            dispatch(onQuestionListResetFlag())
          }
        })
      }

      if (questionPerc == 100) {
       
        Swal.fire({
          text: t('Are you sure you want to exit?'),
          icon: 'success',
          showCancelButton: true,
          confirmButtonColor: '#F1416C',
          cancelButtonColor: '#181C32',
          confirmButtonText: t('Yes'),
          cancelButtonText: t('No'),
        }).then((result) => {
          if (result.isConfirmed) {
            setQData([]);
            navigate('/practice-type')
            dispatch(onQuestionListResetFlag())
          }
        })
      }

    } else {
      setIsShowRevSection(true)
      setReviewStack([])
      setIsShowBtnFinish(false)
      setIsShowMcqsSection(false)
      setIsVidSecShow(false)
      setIsReviewMode(false)
    }
  }

  //handleReviewAll
  const handleReview = (type) => {
    //review all
   
    if (type == 1) {
      handleReviewStackStates()
      setIndex(0)
      setIsReviewMode(true)
    }

    //review unanswerd
    if (type == 2) {

      if (markMcqs.length !== qData.length) {
        setIsReviewMode(true)
        handleReviewStackStates()
       
        const nonMatched = []
        for (let i = 0; i < qData.length; i++) {
          const index = markMcqs.indexOf(i)
          if (index === -1) {
            nonMatched.push(i)
          }
        }
       

        setReviewStack(nonMatched)


        handleVideQIndex(nonMatched[0])
        setIndex(nonMatched[0])
        setStackCounter(1)
        if (nonMatched.length == 1) {
        
          setIsShowBtnFinish(true)
          //this btn new add if any function disturb then we can remove  it
          // setIsShowBtnPrev(false);
        }
      } else {
        toast.error('No pending question', { autoClose: 3000 });



      }
    }

    //review marked only
    if (type == 3) {
      reviewAfterMarkedStack.map(item => reviewStack.push(item));
      if (reviewStack.length > 0) {
       

        setIsReviewMode(true)
        handleReviewStackStates()
        handleVideQIndex(reviewStack[0])
        setIndex(reviewStack[0])
        setStackCounter(1)
        if (reviewStack.length == 1) {
          setIsShowBtnFinish(true)
        }
      } else {
        toast.error('Select questions please', { autoClose: 3000 });
      
      }
    }
  }

  const handleEndReview = () => {
    Swal.fire({
      text: t('Are you sure you want to end review'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: t('Yes'),
      cancelButtonText: t('No'),
    }).then((result) => {
      if (result.isConfirmed) {
        handleExamSubmit()
      }
    })
  }



  const handleReviewStackStates = () => {
    setIsShowRevSection(false)
    setIsShowBtnFinish(false)
    setIsShowMcqsSection(true)
    setStackCounter(0)

        
    if(reviewStack.length==1){

      setIsShowBtnPrev(false)
    }else{
      setIsShowBtnPrev(true)
 
    }

  }

  const handleVideQIndex = (indexVal) => {


    if (qData.length > 0 && qData[indexVal] && qData[indexVal].question.q_video != null && qData[indexVal].question.q_is_video==1) {

      setIsVidSecShow(true)
      setIsShowMcqsSection(false)
      handlePauseAllAudios();
    } else {
    
      setIsVidSecShow(false)
      setIsShowMcqsSection(true)
    }
  }
  const handleVideoEnded = () => {
 
    setIsVidSecShow(false)
    setIsShowMcqsSection(true)

    if(qData[index].question.q_lang_audio!=null){
    handleFirstLoadAudio(qData[index].question.q_lang_audio.q_audio, 1)
    }
  }


  const handleMarkedMcqs = (paramIndex) => {
    if (!markMcqs.includes(paramIndex)) {
      setMarkMcqs([...markMcqs, paramIndex])
    }

  }

  const handleExamSubmit = async () => {
  
    const postedExamData = {
      markedQuestions: selectedOptions,
      exam_id: stdInfo  && stdInfo.examId,
      createdAt:createdAtExam,
      examAttemptId:examAttemptId
     
    };
  
    dispatch(onSaveQuestions(postedExamData))

  }

  const handleSubmitPracticeExam = async (id, correctOpt, choosedValue) => {

    const postedExamData = {
      id: id,
      correctOpt: correctOpt,
      selectedOpt: choosedValue,
    };

    dispatch(onSavePracticeQuestions(postedExamData))

  }

  //audioPlayingEvent
  const audioPlayingEvent = (qType) => {


    if (qType == 'q') {
      setIsHighlightedQAudio(true)
    } else {
      setIsHighlightedQAudio(false)
    }
    if (qType == 'a') {
      setIsHighlightedOptA(true)
    } else {
      setIsHighlightedOptA(false)
    }
    if (qType == 'b') {
      setIsHighlightedOptB(true)
    } else {
      setIsHighlightedOptB(false)
    }
    if (qType == 'c') {
      setIsHighlightedOptC(true)
    } else {
      setIsHighlightedOptC(false)
    }
  }

  const changeHighlightState = () => {
    setIsHighlightedQAudio(false)
    setIsHighlightedOptA(false)
    setIsHighlightedOptB(false)
    setIsHighlightedOptC(false)
  }

  const handlePlayAudio = (type, index) => {
    
   handlePauseFirstAudio();

    if (type == 'q' && qData[index].question.q_lang_audio && qData[index].question.q_lang_audio.q_audio) {
      


      if ((optAAudioRef.current != null && !optAAudioRef.current.paused) || (optBAudioRef.current!=null && !optBAudioRef.current.paused) || (optCAudioRef.current!=null && !optCAudioRef.current.paused)) {

        if (optAAudioRef.current != null) {
          optAAudioRef.current.load()
          optAAudioRef.current.pause()
        }
        
        if (optBAudioRef.current != null) {
          optBAudioRef.current.load()
          optBAudioRef.current.pause()
        }
        
        if (optCAudioRef.current != null) {
          optCAudioRef.current.load()
          optCAudioRef.current.pause()
        }
        
        if (qAudioRef.current != null) {
          qAudioRef.current.load()
          qAudioRef.current.play()
        }
        
      } else {

        if (qAudioRef.current != null && qAudioRef.current.paused) {
          qAudioRef.current.load()
          qAudioRef.current.play()
        }
        else {

          setIsHighlightedQAudio(false)
          if (qAudioRef.current != null){
            qAudioRef.current.load()
            qAudioRef.current.pause()
          }
          
        }
      }
    }

    //opt A
    if (type == 'a' && qData[index].question.q_lang_audio && qData[index].question.q_lang_audio.opt_a_audio) {

      // if (!qAudioRef.current.paused || !optBAudioRef.current.paused || !optCAudioRef.current.paused) {
      
      if ((qAudioRef.current != null && !qAudioRef.current.paused) || (optBAudioRef.current!=null && !optBAudioRef.current.paused) || (optCAudioRef.current!=null && !optCAudioRef.current.paused)) {

        if (qAudioRef.current != null) {
          qAudioRef.current.load()
          qAudioRef.current.pause()
        }
        
        if (optBAudioRef.current != null) {
          optBAudioRef.current.load()
          optBAudioRef.current.pause()
        }
        
        if (optCAudioRef.current != null) {
          optCAudioRef.current.load()
          optCAudioRef.current.pause()
        }
        
        if (optAAudioRef.current != null) {
          optAAudioRef.current.load()
          optAAudioRef.current.play()
        }

      } else {

        if (optAAudioRef.current != null && optAAudioRef.current.paused) {
          optAAudioRef.current.load()
          optAAudioRef.current.play()
        }
        else {

          setIsHighlightedOptA(false)
          if (optAAudioRef.current != null) {
            optAAudioRef.current.load()
            optAAudioRef.current.pause()
          }
          
        }
      }
    }


    //opt B
    if (type == 'b' && qData[index].question.q_lang_audio && qData[index].question.q_lang_audio.opt_b_audio) {

      // if (!qAudioRef.current.paused || !optAAudioRef.current.paused || !optCAudioRef.current.paused) {
      if ((qAudioRef.current != null && !qAudioRef.current.paused) || (optAAudioRef.current!=null && !optAAudioRef.current.paused) || (optCAudioRef.current!=null && !optCAudioRef.current.paused)) {  

        if (qAudioRef.current != null) {
          qAudioRef.current.load()
          qAudioRef.current.pause()
        }
        
        if (optAAudioRef.current != null) {
          optAAudioRef.current.load()
          optAAudioRef.current.pause()
        }
        
        if (optCAudioRef.current != null) {
          optCAudioRef.current.load()
          optCAudioRef.current.pause()
        }
        
        if (optBAudioRef.current != null) {
          optBAudioRef.current.load()
          optBAudioRef.current.play()
        }

      } else {

        if (optBAudioRef.current != null && optBAudioRef.current.paused) {
          optBAudioRef.current.load()
          optBAudioRef.current.play()
        }
        else {

          setIsHighlightedOptB(false)
          if (optBAudioRef.current != null){
            optBAudioRef.current.load()
            optBAudioRef.current.pause()  
          }
        }
      }
    }


    //opt C
    if (type == 'c' && qData[index].question.q_lang_audio && qData[index].question.q_lang_audio.opt_c_audio) {

      // if (!qAudioRef.current.paused || !optAAudioRef.current.paused || !optBAudioRef.current.paused) {
      if ((qAudioRef.current != null && !qAudioRef.current.paused) || (optAAudioRef.current!=null && !optAAudioRef.current.paused) || (optBAudioRef.current!=null && !optBAudioRef.current.paused)) {  

        if (qAudioRef.current != null) {
          qAudioRef.current.load()
          qAudioRef.current.pause()
        }
        
        if (optAAudioRef.current != null) {
          optAAudioRef.current.load()
          optAAudioRef.current.pause()
        }
        
        if (optBAudioRef.current != null) {
          optBAudioRef.current.load()
          optBAudioRef.current.pause()
        }
        
        if (optCAudioRef.current != null) {
          optCAudioRef.current.load()
          optCAudioRef.current.play()
        }

      } else {

        if (optCAudioRef.current != null && optCAudioRef.current.paused) {
          optCAudioRef.current.load()
          optCAudioRef.current.play()
        }
        else {

          setIsHighlightedOptC(false)
          if (optCAudioRef.current != null) {
            optCAudioRef.current.load()
            optCAudioRef.current.pause()
          }          
        }
      }
    }




    setIsPlaying(!isPlaying)
  }

  const handleAutoPlayAudio = async (type, indexNo) => {


    if (type == 'q' && qData[indexNo].question.q_lang_audio && qData[indexNo].question.q_lang_audio.q_audio && qData[indexNo].question.q_is_video!=1) {

 

      handlePauseFirstAudio();
      setIsHighlightedQAudio(true)

      if(optAAudioRef.current!=null){
        optAAudioRef.current.pause()
        setIsHighlightedOptA(false)
      }
      if(optBAudioRef.current!=null){
        optBAudioRef.current.pause()
        setIsHighlightedOptB(false)
      }

      if(optCAudioRef.current!=null){
        optCAudioRef.current.pause()
        setIsHighlightedOptC(false)
      }
   
     
      if(qAudioRef.current!=null){
        qAudioRef.current.load()
        qAudioRef.current.play()
      }
   

    }

  }


  const handlePauseFirstAudio = () => {

firstAudioSound?
    firstAudioSound.pause()
    :'';


  }

  useEffect(() => {
    const calculatePercentage = () => {
      if (totalQuestion > 0) {
        const calc = (markMcqs.length * 100) / parseFloat(totalQuestion)
        setQuestionPerc(calc)
      }
    }

    calculatePercentage()
  }, [markMcqs])


  useEffect(() => {



    if(index==0 || stackCounter ==1){
      setIsShowBtnPrev(false)
    }else{
      setIsShowBtnPrev(true)
    }

  }, [index])


  useEffect(() => {

    const firtTimeQuestionGet = () => {
     qData.length > 0 && qData[0].question.q_is_video!=1 && qData[0].question.q_lang_audio?
        handleFirstLoadAudio(qData[0].question.q_lang_audio.q_audio, 1)
        : ''
    }

    firtTimeQuestionGet()
  }, [qData])


  const handleFirstLoadAudio = (audioSrc, isPlay) => {
    const sound = new Howl({
      src: [fileUrl + audioSrc],
      html5: true,
      onend: () => {
        setIsHighlightedQAudio(false)
      },
    });

    setFirstAudioSound(sound);


    if (isPlay === 1) {
      setIsHighlightedQAudio(true)
      sound.play();
    }



  }

  const handleTimeUp = () => {
if(testType==1){

    Swal.fire({
      text: t('Your times up and your exam submited'),
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: t('OK'),
    }).then((result) => {

      if (result.isConfirmed) {
        setIsLoading(true);
        handleExamSubmit()
      }
    })

  }else{

    Swal.fire({
      text: t('Your practice time is finished'),
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: false,
      confirmButtonColor: '#F1416C',
      cancelButtonColor: '#181C32',
      confirmButtonText: t('OK'),
    }).then((result) => {

      if (result.isConfirmed) {
      navigate('/student')
      }
    })
  }

  }

  const examSaveResponce = () => {
  if(isSaveQ){

       setIsLoading(false);
       toast.success('Exam save successfully', { autoClose: 3000 });
       navigate(`/student-result/${stdInfo.examId}/student`);
      
  }
  }

  useEffect(() => {
    examSaveResponce()
  },[isSaveQ])


  const [time, setTime] = useState({
    hours: parseInt(stdInfo.examDuration, 10),
    minutes: parseInt(stdInfo.examDuration, 10),
    seconds: parseInt(0, 10),
  });

  const [examDuration, setExamDuration] = useState(stdInfo.examDuration)


  useEffect(() => {
    const timer = setInterval(() => {
      if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        clearInterval(timer);
        //if exam type 1 it mean it is not practice
        // if (testType == 1) {
          handleTimeUp()
        // }
      } else {
        setTime(prevTime => {
          let updatedHours = prevTime.hours;
          let updatedMinutes = prevTime.minutes;
          let updatedSeconds = prevTime.seconds - 1;

          if (updatedSeconds < 0) {
            updatedSeconds = 59;
            updatedMinutes--;

            if (updatedMinutes < 0) {
              updatedMinutes = 59;
              updatedHours--;

              if (updatedHours < 0) {
                updatedHours = 0;
                updatedMinutes = 0;
                updatedSeconds = 0;
              }
            }
          }

          return {
            hours: updatedHours,
            minutes: updatedMinutes,
            seconds: updatedSeconds,
          };
        });
      }
    }, 1000);



    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(timer);
  }, [time]);


  const timeProps = {
    remainingHrs: String(time.hours).padStart(2, '0'),
    remainingMin: String(time.minutes).padStart(2, '0'),
    remainingSec: String(time.seconds).padStart(2, '0'),
  }

  const decideShowOptStyle = (currentIndex) => {

    if (testType == 2) {
    
      if (qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].optA !== "" && selectedOptions[currentIndex].optA == qData[currentIndex].question.correct_opt) {
        // setOptAMarkedClass('correct-ans-style');
        setCorrectOptAIcon(true);
        setWrongOptAIcon(false);
    
     
      } else {
        if (qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].optA !== "" && selectedOptions[currentIndex].optA !== qData[currentIndex].question.correct_opt) {
          // setOptAMarkedClass('wrong-ans-style');
          setCorrectOptAIcon(false);
          setWrongOptAIcon(true);
       
      
        } else {
          if(qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].id > 0  && qData[currentIndex].question.correct_opt =='a'){
        
            // setOptAMarkedClass('correct-ans-style');
            setCorrectOptAIcon(true);
            setWrongOptAIcon(false);
       
         
          }else{
            // setOptAMarkedClass('default-option');
            setCorrectOptAIcon(false);
            setWrongOptAIcon(false);
      
         
          }
 
        }
      }

        
      if (qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].optB !== "" && selectedOptions[currentIndex].optB == qData[currentIndex].question.correct_opt) {
        // setOptBMarkedClass('correct-ans-style');
        setCorrectOptBIcon(true);
        setWrongOptBIcon(false);
     
      } else {
        if (qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].optB !== "" && selectedOptions[currentIndex].optB !== qData[currentIndex].question.correct_opt) {
          // setOptBMarkedClass('wrong-ans-style');
          setCorrectOptBIcon(false);
          setWrongOptBIcon(true);
      
        } else {
          if(qData[currentIndex] && selectedOptions[currentIndex]  && selectedOptions[currentIndex].id >0 &&  qData[currentIndex].question.correct_opt =='b'){
            // setOptBMarkedClass('correct-ans-style');
            setCorrectOptBIcon(true);
            setWrongOptBIcon(false);
         
          }else{
            // setOptBMarkedClass('default-option');
            setCorrectOptBIcon(false);
            setWrongOptBIcon(false);
         
          }
 
        }
      }




      if (qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].optC !== "" && selectedOptions[currentIndex].optC == qData[currentIndex].question.correct_opt) {
        //setOptCMarkedClass('correct-ans-style');
        setCorrectOptCIcon(true);
        setWrongOptCIcon(false);
      } else {
        if (qData[currentIndex] && selectedOptions[currentIndex] && selectedOptions[currentIndex].optC !== "" && selectedOptions[currentIndex].optC !== qData[currentIndex].question.correct_opt) {
          // setOptCMarkedClass('wrong-ans-style');
          setCorrectOptCIcon(false);
          setWrongOptCIcon(true);
        } else {
          if(qData[currentIndex] && selectedOptions[currentIndex]  && selectedOptions[currentIndex].id >0 && qData[currentIndex].question.correct_opt =='c'){
            // setOptCMarkedClass('correct-ans-style');
            setCorrectOptCIcon(true);
            setWrongOptCIcon(false);
           
          }else{
            // setOptCMarkedClass('default-option');
            setCorrectOptCIcon(false);
            setWrongOptCIcon(false);
          
          }
       
        }
      }
      

    }


    // else {


      if (selectedOptions[currentIndex] && selectedOptions[currentIndex].selectedValue!=""  && selectedOptions[currentIndex].selectedValue == 'a') {
        setOptAMarkedClass('marked-option');

      } else {
        setOptAMarkedClass('default-option');
      }


      if (selectedOptions[currentIndex] && selectedOptions[currentIndex].selectedValue != "" && selectedOptions[currentIndex].selectedValue == 'b') {
        setOptBMarkedClass('marked-option');

      } else {
        setOptBMarkedClass('default-option');
      }


      if (selectedOptions[currentIndex] && selectedOptions[currentIndex].selectedValue !== "" && selectedOptions[currentIndex].selectedValue == 'c') {
        setOptCMarkedClass('marked-option');

      } else {
        setOptCMarkedClass('default-option');
      }


    // }
  }
  useEffect(() => {
    decideShowOptStyle(index)
  }, [selectedOptions])


  const handlePauseAllAudios = async () => {

      if(optAAudioRef.current!=null){
        optAAudioRef.current.pause()
        setIsHighlightedOptA(false)
      }
      if(optBAudioRef.current!=null){
        optBAudioRef.current.pause()
        setIsHighlightedOptB(false)
      }

      if(optCAudioRef.current!=null){
        optCAudioRef.current.pause()
        setIsHighlightedOptC(false)
      }
   
     
      if(qAudioRef.current!=null){
        qAudioRef.current.load()
        qAudioRef.current.pause()
        setIsHighlightedQAudio(false)
      }
   


  }




  return (
    <React.Fragment>
      <div data-layout="horizontal" data-topbar="light" data-sidebar="dark" data-sidebar-size="lg" data-sidebar-image="none" data-preloader="disable" data-sidebar-visibility="hidden" data-layout-style="default" data-layout-mode="light" data-layout-width="fluid" data-layout-position="scrollable" dir={direction}>
 
 {isQuestionLoader?

 <ExamLoader/>
 :
 <>
    {totalQuestion > 0 ?


<>
    { examTemplate==1?
        <ExamHeader
          percentage={questionPerc}
          stdName={studentInfo.stdName}
          trafficId={studentInfo.trafficId}
          remainingTime={timeProps}
          examType={testType}
          handelExit={handelExit}
          examDuration={examDuration}
          handleTimeUp={handleTimeUp}
          isReviewMode={isReviewMode}
          reviewAfterMarkedStack={reviewAfterMarkedStack}
          handleReviewAfter={handleReviewAfter}
          index={index}
          isShowRevSection={isShowRevSection}
          direction={direction}
        />
        :''}

{ examTemplate==2?
        <Template2Header
     
        handelExit={handelExit}
        timeProps={timeProps}
        direction={direction}
        
        percentage={questionPerc}
        stdName={studentInfo.stdName}
        trafficId={studentInfo.trafficId}
     
        examType={testType}
        examDuration={examDuration}
        handleTimeUp={handleTimeUp}
        isReviewMode={isReviewMode}

        index={index}
        totalQuestion={totalQuestion}
        courseName={studentInfo.courseName}
        reviewAfterMarkedStack={reviewAfterMarkedStack}
        handleReviewAfter={handleReviewAfter}
        isShowRevSection={isShowRevSection}
       
      />
        :''}

        

        {isShowMcqsSection ? 
         
       
            examTemplate==1?
            <>
                <section className="section mt-5 bg-white position-relative" style={{ minHeight: "90vh" }}>

                  <div className="container-fluid " dir={direction}>
            
                    <div className="row align-items-center gy-4">
                      <div className="col-lg-8 order-2 order-lg-1 mt-5 ps-4">


                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.q_audio ? (
                          <audio
                            ref={qAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('q')
                            }}
                          >

                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.q_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}
                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.opt_a_audio ? (
                          <audio
                            ref={optAAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('a')
                            }}
                          >
                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.opt_a_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}



                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.opt_b_audio ? (
                          <audio
                            ref={optBAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('b')
                            }}
                          >
                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.opt_b_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}


                        {qData.length > 0 &&
                          qData[index].question.q_lang_audio &&
                          qData[index].question.q_lang_audio.opt_c_audio ? (
                          <audio
                            ref={optCAudioRef}
                            onEnded={changeHighlightState}
                            onPlaying={() => {
                              audioPlayingEvent('c')
                            }}
                          >
                            <source
                              src={fileUrl + qData[index].question.q_lang_audio.opt_c_audio}
                              type='audio/mpeg'
                            />

                          </audio>
                        ) : (
                          ''
                        )}


                        <div className="">
                          <h5 className="fs-24 text-uppercase strong">{t('Question')} {index + 1}</h5>
                          <div className="d-flex align-items-center border-bottom pb-3 mb-4">
                       
                            <div className={`flex-shrink-0 ${direction === 'ltr' ? 'me-3' : 'ms-3'}`}>
                                <div className="h2" onClick={() => {
                                  handlePlayAudio('q', index)
                                }}>
                                  <i className={isHighlightedQAudio ? "ri-volume-up-fill" : " ri-volume-up-fill"} style={isHighlightedQAudio ? customStyle.audioPlayingColor : customStyle.muteAudioColor}></i>
                                </div>
                              </div>
                            <div className="flex-grow-1 text-primary">
                              <p className="fs-2 mb-0" >{ qData[index].question.question_translations &&  qData.length> 0 ? qData[index].question.question_translations[0].q_title : ''}</p>
             
                            </div>
                      
                          </div>
                        

         
                          <div className="row">
                            <div className="col">
                              <div className="card" style={{ borderRadius: "15px" }}>
                        

                              <div className={`card-body ${!isReviewMode ? optAMarkedClass : (selectedOptions[index] && selectedOptions[index].selectedValue === 'a' ? 'marked-option' : '')}`}>

                                  <div className="row row-cols-lg-auto g-3 align-items-center">
                                    <div
                                      className={`col-12`}>
                                      <div className="fs-3" onClick={() => {
                                        handlePlayAudio('a', index)
                                      }}>
                                        <i className={isHighlightedOptA ? "ri-volume-up-fill" : " ri-volume-up-fill"} style={isHighlightedOptA ? customStyle.audioPlayingColor : customStyle.muteAudioColor}></i>
                                      </div>
                                    </div>
 
                                      
                                    <div className={`col-12 ${direction === 'ltr' ? 'ps-3' : 'pe-3'}`} style={ qData[index].question.question_translations[0].opt_a.length > 1 ? testType == 1 && selectedOptions[index] && selectedOptions[index].selectedValue === 'a' ? customStyle.checkedColor : customStyle.defaultColor : customStyle.noClass}>
                                    <div className="row row-cols-lg-auto g-3 align-items-center">
                                      <div className="col-12">
                                      <div className={`form-radio-secondary fs-36 ${direction === 'ltr' ? 'form-check' : 'form-check-reverse'}`}>
                                          <input className="form-check-input border-primary" type="radio" value="a" name="opt_a"

                                          onChange={(e) => {
                                            handleChangeRadioOptions(index, 'optA', e.target.value, qData[index].q_id, qData[index].id)
                                            handleMarkedMcqs(index)
                                          }}

                                          checked={
                                            selectedOptions[index] && selectedOptions[index]!=undefined && selectedOptions[index].selectedValue === 'a'
                                              ? 'checked'
                                              : ''
                                          }
                                          disabled={
                                            testType == 2 && selectedOptions[index]
                                              ? selectedOptions[index].isDisable
                                              : ''
                                          }
                                        />
                                        </div>
                                        </div>
                                        <div class="col-12 p-0" style={{ maxWidth: "90%" }}>
                                          <label htmlFor="inlineFormCheck" className="form-check-label form-label fs-2 fw-normal">{qData.length > 0 ? qData[index].question.question_translations[0].opt_a : ''}
                                  
                                  <>
                                          {
                                          correctOptAIcon?
                                          <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}><i className='ri-check-fill fw-bolder fs-36'></i> </span>
                                          
                                          :''}

{
                                          wrongOptAIcon?
                                          <span className='btn btn-icon btn-danger rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#cc3516"}}> <i className='ri-close-fill fw-bolder fs-36'></i></span>
                                          :''}

</>
</label>
                                        </div>
                                      
                                    </div>
                             
                                    </div>
                                    {qData[index] && qData[index].question.opt_a_image != '' && qData[index].question.opt_a_image !=1 ? (
                                    <div className={`${direction=='ltr' ? 'col-12 content-en text-start' : 'col-12 content-ae text-end'}`}>
                                          <img
                                            src={fileUrl + qData[index].question.opt_a_image}
                                            alt=''
                                            className='option-question-image' height="150"
                                          />
                                             </div>
                                        ) : (
                                          ''
                                        )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="card" style={{ borderRadius: "15px" }}>

                              
                                <div className={`card-body ${!isReviewMode ? optBMarkedClass : (selectedOptions[index] && selectedOptions[index].selectedValue === 'b' ? 'marked-option' : '')}`}>
                                  <div className="row row-cols-lg-auto g-3 align-items-center">
                                    <div
                                      className={`col-12`}>
                                      <div className="fs-3" onClick={() => {
                                        handlePlayAudio('b', index)
                                      }}>
                                        <i className={isHighlightedOptB ? "ri-volume-up-fill" : " ri-volume-up-fill"} style={isHighlightedOptB ? customStyle.audioPlayingColor : customStyle.muteAudioColor}></i>
                                      </div>
                                    </div>
                                    <div className={`col-12 ${direction === 'ltr' ? 'ps-3' : 'pe-3'}`} style={qData[index].question.question_translations[0].opt_b.length > 1 ? testType == 1 && selectedOptions[index] && selectedOptions[index].selectedValue === 'b' ? customStyle.checkedColor : customStyle.defaultColor : customStyle.noClass}>
                                    <div className="row row-cols-lg-auto g-3 align-items-center">
                                    <div className="col-12">
                                      <div className={`form-radio-secondary fs-36 ${direction === 'ltr' ? 'form-check' : 'form-check-reverse'}`}>
                                      <input className="form-check-input border-primary" type="radio" value="b" name="opt_b"
                                          onChange={(e) => {
                                            handleChangeRadioOptions(index, 'optB', e.target.value, qData[index].q_id, qData[index].id)
                                            handleMarkedMcqs(index)
                                          }}

                                          checked={  selectedOptions[index] && selectedOptions[index]!=undefined &&  selectedOptions[index].selectedValue === 'b' ? 'checked': '' }
                                          
                                          disabled={
                                            testType == 2 && selectedOptions[index]
                                              ? selectedOptions[index].isDisable
                                              : ''
                                          }
                                          />
                                        </div>
                                        </div>
                                        <div class="col-12 p-0" style={{ maxWidth: "90%" }}>
                                          <label htmlFor="inlineFormCheck" className="form-check-label form-label fs-2 fw-normal">{qData.length > 0 ? qData[index].question.question_translations[0].opt_b : ''}

                                          <>
                                          {
                                          correctOptBIcon?
                                          <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}><i className='ri-check-fill fw-bolder fs-36'></i> </span>
                                          
                                          :''}

{
                                          wrongOptBIcon?
                                          <span className='btn btn-icon btn-danger rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#cc3516"}}> <i className='ri-close-fill fw-bolder fs-36'></i></span>
                                          :''}

</>
</label>
                                        </div>
                                      
                                      </div>
                                    </div>
                                    {qData[index] && qData[index].question.opt_b_image != '' && qData[index].question.opt_b_image !=1  ? (
                                    <div className={`${direction=='ltr' ? 'col-12 content-en text-start' : 'col-12 content-ae text-end'}`}>
                                    
                                          <img
                                            src={fileUrl + qData[index].question.opt_b_image}
                                            alt=''
                                            className='option-question-image' height="150"
                                          />
                                             </div>
                                        ) : (
                                          ''
                                        )}
                                 
                                  </div>
                                </div>  


                            

                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="card" style={{ borderRadius: "15px" }}>
                                
                                   
                              <div className={`card-body ${!isReviewMode ? optCMarkedClass : (selectedOptions[index] && selectedOptions[index].selectedValue === 'c' ? 'marked-option' : '')}`}>
                                  <div className="row row-cols-lg-auto g-3 align-items-center">
                                    <div
                                      className={`col-12`}>
                                      <div className="fs-3" onClick={() => {
                                        handlePlayAudio('c', index)
                                      }}> 
                                        <i className={isHighlightedOptC ? "ri-volume-up-fill" : " ri-volume-up-fill"} style={isHighlightedOptC ? customStyle.audioPlayingColor : customStyle.muteAudioColor}></i>
                                      </div>
                                    </div>
                                    <div className={`col-12 ${direction === 'ltr' ? 'ps-3' : 'pe-3'}`} style={qData[index].question.question_translations[0].opt_c.length > 1 ? testType == 1 && selectedOptions[index] && selectedOptions[index].selectedValue === 'c' ? customStyle.checkedColor : customStyle.defaultColor : customStyle.noClass}>
                                    <div className="row row-cols-lg-auto g-3 align-items-center">
                                      <div className="col-12">
                                      <div className={`form-radio-secondary fs-36 ${direction === 'ltr' ? 'form-check' : 'form-check-reverse'}`}>
                                          <input className="form-check-input border-primary" type="radio" value="c" name="opt_c"
                                          onChange={(e) => {
                                            handleChangeRadioOptions(index, 'optC', e.target.value, qData[index].q_id, qData[index].id)
                                            handleMarkedMcqs(index)
                                          }}

                                          checked={
                                            selectedOptions[index] && selectedOptions[index]!=undefined && selectedOptions[index].selectedValue === 'c'
                                              ? 'checked'
                                              : ''
                                          }
                                          disabled={
                                            testType == 2 && selectedOptions[index]
                                              ? selectedOptions[index].isDisable
                                              : ''
                                          }
                                          />
                                          </div>
                                        </div>
                                        <div class="col-12 p-0" style={{ maxWidth: "90%" }}>
                                          <label htmlFor="inlineFormCheck" className="form-check-label form-label fs-2 fw-normal">{qData.length > 0 ? qData[index].question.question_translations[0].opt_c : ''}

                                          <>
                                          {
                                          correctOptCIcon?
                                          <span className='btn btn-icon btn-success rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#5b913f"}}><i className='ri-check-fill fw-bolder fs-36'></i> </span>
                                          
                                          :''}

{
                                          wrongOptCIcon?
                                          <span className='btn btn-icon btn-danger rounded-pill btn-lg bg-color-white mx-4 border-white' style={{background:"#cc3516"}}> <i className='ri-close-fill fw-bolder fs-36'></i></span>
                                          :''}

</>
</label>
                                        </div>
                                      </div>
                                    </div>
                                    {qData[index] && qData[index].question.opt_c_image != '' && qData[index].question.opt_c_image !=1  ? (
                                    <div className={`${direction=='ltr' ? 'col-12 content-en text-start' : 'col-12 content-ae text-end'}`}>
                                    
                                          <img
                                            src={fileUrl + qData[index].question.opt_c_image}
                                            alt=''
                                            className='option-question-image' height="150"
                                          />
                                           </div>
                                        ) : (
                                          ''
                                        )}
                                   
                                  </div>
                                </div> 
                                
                             

                              </div>
                            </div>
                          </div>


                        </div>
                      </div>

                          {qData[index] && qData[index].question.q_image != '' ? (
                              <div className="col-lg-4 col-sm-7 col-10 ms-auto order-1 order-lg-2" style={{padding: '25px'}}>
                              <div className={`${direction=='ltr' ? 'content-ae text-end' : 'content-en text-start'}`}>
                            <img
                              src={fileUrl + qData[index].question.q_image}
                              alt=''
                              className='option-question-image'
                            />
                              </div>
                      </div>
                          ) : (
                            ''
                          )}

                      
                    </div>

                  </div>

                  <ToastContainer closeButton={false} limit={1} />
                  <div className="d-flex gap-5 position-fixed" style={{ bottom: '10%', [direction === 'ltr' ? 'right' : 'left']: '5%' }}>

           
                  {isReviewMode && !isShowBtnFinish ?
                      <div className="d-flex">
            <Button color="danger" outline className={`btn-lg w-lg btn-label waves-effect waves-light material-shadow-none ${direction === 'ltr' ? 'right' : ''}`} onClick={() => { handelExit() }} title={t('Exit Review')}> <i className={` ${direction === 'ltr' ? "las la-times-circle label-icon align-middle fs-24 ms-2" : "las la-times-circle label-icon align-middle fs-24 me-2"} `}></i> {t('Exit Review')} </Button>
                      </div>
                     :''}

                    
                      {isShowBtnPrev?
                      <div className="d-flex">
                        <Button color="primary" outline className={`btn-lg w-lg btn-label waves-effect waves-light material-shadow-none ${direction === 'ltr' ? '' : 'right'}`} onClick={() => { handleNextPrevClick('prev') }} > <i className={` ${direction === 'ltr' ? "las la-arrow-circle-left label-icon align-middle fs-24 me-2" : "las la-arrow-circle-right label-icon align-middle fs-24 ms-2"} `} ></i> {t('Previous')} </Button>
                      </div>
                     :''}



                      {!isShowBtnFinish ? (
                        <div className="d-flex">
                          <Button color="primary" outline className={`btn-lg w-lg btn-label waves-effect waves-light material-shadow-none ${direction === 'ltr' ? 'right' : ''}`} onClick={() => { handleNextPrevClick('next') }}> <i className={` ${direction === 'ltr' ? "las la-arrow-circle-right label-icon align-middle fs-24 ms-2" : "las la-arrow-circle-left label-icon align-middle fs-24 me-2"} `}></i> {t('Next')} </Button>
                        </div>


                      ) :
                        <div className="d-flex">
                          <Button color="danger" outline className={`btn-lg w-lg btn-label waves-effect waves-light material-shadow-none ${direction === 'ltr' ? 'right' : ''}`} onClick={() => { handelExit() }}> <i className={` ${direction === 'ltr' ? "las la-times-circle label-icon align-middle fs-24 ms-2" : "las la-times-circle label-icon align-middle fs-24 me-2"} `}></i> {t('Finish')} </Button>
                        </div>
                      }
                  </div> 
                <div className='d-none'>
                  {direction=='ltr'?
                  <>
                  <button ref={buttonRef}  onClick={() => { handleNextPrevClick('next')}}>Next</button>
                  <button ref={prevButtonRef}  onClick={() => { handleNextPrevClick('prev')}}>Previous</button>
                  </>
                  :

                  <>
                  <button ref={prevButtonRef}  onClick={() => { handleNextPrevClick('next')}}>Next</button>
                  <button ref={buttonRef}  onClick={() => { handleNextPrevClick('prev')}}>Previous</button>
                  </>
                  }
                  </div>   
        
      

                </section>
                <ExamFooter
              index={index}
              totalQuestion={totalQuestion}
              examType={testType}
              courseName={studentInfo.courseName}
              totalWrongQ={totalWrongQ}
              totalCorrectQ={totalCorrectQ}
              totalSkipQ={totalSkipQ}
              direction={direction}              
            />
         
             </>
            :

            // template 2
            <>
                <ExamTemplate2Index
                isHighlightedQAudio ={isHighlightedQAudio }
                qData={qData}
                index={index}
                handlePlayAudio ={handlePlayAudio }
                selectedOptions ={selectedOptions}
                testType ={testType }
                handleChangeRadioOptions ={handleChangeRadioOptions}
                handleMarkedMcqs ={handleMarkedMcqs }
                qAudioRef={qAudioRef}
                changeHighlightState={changeHighlightState }
                optAAudioRef ={optAAudioRef }
                optBAudioRef ={optBAudioRef }
                optCAudioRef ={optCAudioRef }
                audioPlayingEvent={audioPlayingEvent }
                direction={direction}
              
                />
                   <Template2Footer
                       handleNextPrevClick ={handleNextPrevClick }
                       isShowBtnPrev={isShowBtnPrev}
                       isShowBtnFinish={isShowBtnFinish}
                       direction={direction }
                       handelExit={handelExit }
                       totalWrongQ={totalWrongQ}
                       totalCorrectQ={totalCorrectQ}
                       totalSkipQ={totalSkipQ}
                       examType={testType}
                 
     />
            
         
             </>
        
         : '' }
          
       
      {isVidSecShow ? (
        <>
        <ExamVideoSection qData={qData} handleVideoEnded={handleVideoEnded} index={index} examTemplate={examTemplate} />

         {examTemplate==1?
        <ExamFooter
              index={index}
              totalQuestion={totalQuestion}
              examType={testType}
              courseName={studentInfo.courseName}
              direction={direction}
              totalWrongQ={totalWrongQ}
              totalCorrectQ={totalCorrectQ}
              totalSkipQ={totalSkipQ}
            />
            :
            <Template2Footer
            handleNextPrevClick ={handleNextPrevClick }
            isShowBtnPrev={isShowBtnPrev}
            isShowBtnFinish={isShowBtnFinish}
            direction={direction }
            handelExit={handelExit }
            totalWrongQ={totalWrongQ}
            totalCorrectQ={totalCorrectQ}
            totalSkipQ={totalSkipQ}
            examType={testType}
            isVidSecShow={isVidSecShow}
      
/>
            
            }
        </>
      ) : (
        ''
      )}

      {isShowRevSection ? (
        <ExamReviewSection
          qData={qData}
          handleReviewMarkedOnly={handleReviewMarkedOnly}
          selectedOptions={selectedOptions}
          handleReview={handleReview}
          handleEndReview={handleEndReview}
          isActiveReviewMarkedOnly={isActiveReviewMarkedOnly}
          direction={1}
          isSaveQ={isSaveQ}
          loader={isLoading}
          reviewAfterMarkedStack={reviewAfterMarkedStack}
        

        />
      ) : (
        ''
      )}
      </>
      :
  
         <AlertComponent
         examType={testType}
          />
          } 
          </>
        }
      </div>


    </React.Fragment>
  )
}

export default ExamIndex