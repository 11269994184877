import { APIClient } from "../api_helper";

import * as url from "./custom_url_helper";
import {
  ADD_NEW_ROLE_PERMISSIONS,
  GET_CONFIG,
  GET_MODULE_PERMISSIONS,
  UPDATE_Language,
} from "../url_helper";
import {ADD_COURSE_TRANSLATION, ADD_TRANSLATION_FOR_QUESTION, GET_BRANCHES_LIST} from "./custom_url_helper";

const api = new APIClient();

// Gets the loggedin user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

//LANGUAGE
export const addNewLanguage = (language) =>
  api.create(url.ADD_NEW_LANGUAGE, language);
export const getLanguages = () => api.get(url.GET_LANGUAGES);
export const deleteLanguage = (language) =>
  api.delete(url.DELETE_LANGUAGE + "/" + language);
export const updateLanguage = (language) =>
  api.update(url.UPDATE_Language, language);

//APP CONFIGURATION
export const addNewAppConfig = (appconfig) =>
  api.create(url.ADD_NEW_Config, appconfig);
export const getAppConfig = () => api.get(url.GET_CONFIG);

//USER ROLES
export const addNewRole = (role) => api.create(url.ADD_NEW_ROLE, role);
export const getRoles = () => api.get(url.GET_ROLES);
export const deleteRole = (role) => api.delete(url.DELETE_ROLE + "/" + role);
export const updateRole = (role) => api.update(url.UPDATE_ROLE, role);

// Role Permissions
export const getAllModulePermissions = (roleId) =>
  api.get(url.GET_MODULE_PERMISSIONS + "/" + roleId);
export const addRolePermissions = (role) =>
  api.create(url.ADD_NEW_ROLE_PERMISSIONS, role);

//USER
export const addNewUser = (user) => api.create(url.ADD_NEW_USER, user);
export const getUsers = () => api.get(url.GET_USERS);
export const deleteUser = (user) => api.delete(url.DELETE_USER + "/" + user);
export const updateUser = (user) => api.update(url.UPDATE_USER, user);

//COURSE
export const addNewCourse = (course) => api.create(url.ADD_NEW_COURSE, course);
export const addCourseTranslation = (course) =>
  api.create(url.ADD_COURSE_TRANSLATION, course);
export const getCourses = () => api.get(url.GET_COURSES);
export const deleteCourse = (course) =>
  api.delete(url.DELETE_COURSE + "/" + course);
export const updateCourse = (course) => api.update(url.UPDATE_COURSE, course);
//COURSE CONFIGURATION
export const getCourseConfigs = (courseId) =>
  api.get(url.GET_COURSE_CONFIG + "/" + courseId);
export const addCourseConfig = (course) =>
  api.create(url.ADD_COURSE_CONFIG, course);

//Topic Area
export const addNewTopicArea = (topicarea) =>
  api.create(url.ADD_NEW_TOPIC_AREA, topicarea);
export const addTopicAreaTranslation = (topicarea) =>
  api.create(url.ADD_TOPIC_AREA_TRANSLATION, topicarea);
export const getTopicAreas = () => api.get(url.GET_TOPIC_AREAS);
export const deleteTopicArea = (topicarea) =>
  api.delete(url.DELETE_TOPIC_AREA + "/" + topicarea);
export const updateTopicArea = (topicarea) =>
  api.update(url.UPDATE_TOPIC_AREA, topicarea);


//Question
export const addNewQuestion = (question) =>
  api.create(url.ADD_NEW_QUESTION, question);
export const getDataForQuestion = (qid) =>
  api.get(url.GET_DATA_FOR_ADD_QUESTION + "/" + qid);
export const importQuestionFile = (questions) =>
  api.create(url.IMPORT_QUESTION, questions);
export const getAllQuestions = () => api.get(url.GET_ALL_QUESTIONS);
export const deleteQuestion = (question) =>
  api.delete(url.DELETE_QUESTION + "/" + question);
export const getTranslationsForQuestion = (qid) =>
  api.get(url.GET_TRANSLATION_FOR_QUESTION + "/" + qid);
export const addQuestionTranslation = (question) =>
    api.create(url.ADD_TRANSLATION_FOR_QUESTION, question);
export const removeQuestionAsset = (item) =>
    api.create(url.REMOVE_ASSET_ITEM_FOR_QUESTION, item);

//Branch
export const addNewBranch = (branch) =>
    api.create(url.ADD_NEW_BRANCH, branch);
export const getBranches = () => api.get(url.GET_BRANCHES);
export const getBranchesList = () => api.get(url.GET_BRANCHES_LIST);
export const deleteBranch = (branch) =>
    api.delete(url.DELETE_BRANCH + "/" + branch);
export const updateBranch = (branch) =>
    api.update(url.UPDATE_BRANCH, branch);


//Room
export const addNewRoom = (room) =>
    api.create(url.ADD_NEW_ROOM, room);
export const getRooms = () => api.get(url.GET_ROOMS);

export const deleteRoom = (room) =>
    api.delete(url.DELETE_ROOMS + "/" + room);

export const updateRoom = (room) =>
    api.update(url.UPDATE_ROOM, room);

    export const getBranchRooms = (branchId) =>api.get(url.GET_BRANCH_ROOMS + "/" + branchId);



//SYstems
    export const getSystemsList = () => api.get(url.GET_SYSTEMS);
    export const createSystem = (system) => api.create(url.CREATE_SYSTEM,system);
    export const deleteSystem = (systemId) => api.delete(url.DELETE_SYSTEM + "/" + systemId);
    export const getRoomSystem = (roomId) => api.get(url.GET_ROOM_SYSTEMS + "/" + roomId);
    export const checkSystemIp = (systemIp) => api.get(url.CHECK_SYSTEM_IP + "/" + systemIp);
   
   


    //Exam
    export const getExamQuestions = (examPostedData) =>api.create(url.GET_EXAM_QUESTIONS, examPostedData);
    export const saveExamQuestions = (solvedExamData) =>api.create(url.SAVE_EXAM_QUESTIONS, solvedExamData);
    export const savePracticeQuestions = (solvedExamData) =>api.create(url.SAVE_PRACTICE_QUESTIONS, solvedExamData);

        //Results
        export const getExamResult = (resPostedData) =>api.create(url.GET_RESULT, resPostedData);
        export const getAllResults = (resData) =>api.create(url.GET_ALL_RESULT_LIST, resData);
        export const getStudentResult = (examId) =>api.get(url.GET_STUDENT_RESULT, examId);
        export const getPracticeResults = (postData) =>api.create(url.GET_PRACTICE_RESULT,postData);
        export const saveResultPdf = (postedData) =>api.create(url.SAVE_RESULT_PDF, postedData);
        export const getAttemptHistory = (postedData) =>api.create(url.GET_ATTEMPT_HISTORY, postedData);
        


        //Schedule Exam List
        export const getScheduleExamList = (echeduleExamPostedData) =>api.get(url.GET_SCHEDULE_EXAM_LIST,echeduleExamPostedData);
        export const getBdcStudent = (trafficId) =>api.get(url.GET_BDC_STUDENT,trafficId);
        export const saveScheduleExam = (postedData) =>api.create(url.SAVE_SCHEDULE_EXAM,postedData);
        export const updaeScheduleExam = (postedData) =>api.create(url.UPDATE_SCHEDULE_EXAM,postedData);
        export const deleteExam = (id) => api.delete(url.DELETE_EXAM + "/" + id);
        export const restartExam = (id) => api.create(url.RESTART_EXAM + "/" + id);
        export const checkPracticeType = (postedData) =>api.create(url.CHEK_PRACTICE_TYPE,postedData);
        export const exitExam = (id) => api.create(url.EXIT_EXAM + "/" + id);
        export const updateSystemmAndExam = (postedData) => api.create(url.UPDATE_SYSTEM_AND_EXAM,postedData);
        export const getRunningExam = (postedData) =>api.get(url.GET_RUNNING_EXAMS,postedData);

        export const getTestData = (trafficId) =>api.get(url.TEST_DATA,trafficId);

        //Course Questions
        export const checkCourseQuestions = (postedData) => api.get(url.CHECK_COURSE_QUESTIONS_AND_EXAM_ATTEMPT_INFO,postedData);

        // SMS Logs
        export const getSMSLogs = (postedData) =>api.get(url.GET_SMS_LOGS,postedData);
        export const resendNotification = (postedData) =>api.get(url.RESEND_NOTIFICATION,postedData);
        export const markEligible = (postedData) =>api.get(url.MARK_ELIGIBLE,postedData);

        //Sync Exam
        export const syncExam = (postedData) =>api.get(url.SYNC_EXAM,postedData);
         
  
    
   