import React, { useEffect, useState } from 'react';
import { api } from '../../../config';
import { Link, useParams } from 'react-router-dom';
import { 
   getStudentResult as onGetStudentResult,
   saveResultPdf as onSaveResultPdf
} from '../../../slices/result/thunk';
import {
   questionListResetFlag as onQuestionListResetFlag,
   questionSaveResetFlage as onQuestionSaveResetFlage
} from '../../../slices/exam/thunk';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { useTranslation } from 'react-i18next';
import '../../../App.css';
import Loader from '../../../Components/Common/Loader';

function StudentResults() {

   const selectResultState = (state) => state.ResultReducer;
   const dispatch = useDispatch();
   const [storedData, setStoredData] = useState([]);
   const mediaSource = api.IMAGE_URL;
   const navigate = useNavigate();
   let { examid, type } = useParams();
   const [trafficId, setTrafficId] = useState('');

   const { t, i18n } = useTranslation();
   const [direction, setDirection] = useState('ltr');
   const [defaultLocale, setDefaultLocale] = useState('en');

   const resultProperties = createSelector(
      selectResultState,
      (data) => ({
         resultList: data.resultList,
         isResultSuccess: data.isResultSuccess,
         error: data.error,
         isLoading: data.isLoading
      })
   );

   const { resultList, error, isResultSuccess, isLoading } = useSelector(resultProperties);

   const [time, setTime] = useState({
      hours: 0,
      minutes: 1,
      seconds: 0,
   });

   useEffect(() => {
      setTrafficId(resultList && resultList.traffic_id);
   }, [resultList]);

   useEffect(() => {
      const postedResultData = { exam_id: examid };
      dispatch(onGetStudentResult(postedResultData));
   }, [dispatch]);

   useEffect(() => {
      const dataFromLocalStorage = localStorage.getItem('apiData');
      setStoredData(dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : null);
   }, []);

   const handleNavigation = () => {
      dispatch(onQuestionSaveResetFlage());
      navigate('/student');
   };

   const handleTimeUp = () => {
      handleNavigation();
   };

   useEffect(() => {
      const timer = setInterval(() => {
         if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
            clearInterval(timer);
            if (type === 'student') handleTimeUp();
         } else {
            setTime(prevTime => {
               let updatedHours = prevTime.hours;
               let updatedMinutes = prevTime.minutes;
               let updatedSeconds = prevTime.seconds - 1;

               if (updatedSeconds < 0) {
                  updatedSeconds = 59;
                  updatedMinutes--;

                  if (updatedMinutes < 0) {
                     updatedMinutes = 59;
                     updatedHours--;

                     if (updatedHours < 0) {
                        updatedHours = 0;
                        updatedMinutes = 0;
                        updatedSeconds = 0;
                     }
                  }
               }

               return {
                  hours: updatedHours,
                  minutes: updatedMinutes,
                  seconds: updatedSeconds,
               };
            });
         }
      }, 1000);

      return () => clearInterval(timer);
   }, [time]);

   const handlePrintPreview = () => {
      window.print();
   };

   useEffect(() => {
      if (type === 'student') {
         printPdfDocument();
      }
   }, [type]);

   const printPdfDocumentOld = () => {
      const pdfButton = document.getElementById('pdf-button');
     

      if (pdfButton) pdfButton.style.display = 'none';

      const input = document.getElementById('divToPdf');
      html2canvas(input, {
         scale: 2, 
         useCORS: true,
         logging: true,
         letterRendering: true,
      }).then((canvas) => {
         const imgWidth = 210;
         const imgHeight = (canvas.height * imgWidth) / canvas.width;
         const pageHeight = 297;
         let heightLeft = imgHeight;

         const imgData = canvas.toDataURL('image/jpeg', 1.7); // Adjust quality to reduce file size
         let position = 0;

         const pdf = new jsPDF('p', 'mm', 'a4');
         pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST'); // Use 'FAST' compression

         heightLeft -= pageHeight;

         while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            pdf.addPage();
            pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
            heightLeft -= pageHeight;
         }

         if (type === 'admin') {
            pdf.save(`${trafficId}.pdf`);
         }

         const pdfBlob = pdf.output('blob');
         callSavePdfApiFunction(pdfBlob);

         if (pdfButton) pdfButton.style.display = '';
      });
   };


   
   const printPdfDocument = () => {
       const pdfButton = document.getElementById('pdf-button');
       if (pdfButton) pdfButton.style.display = 'none';
   
       const input = document.getElementById('divToPdf');
   
       // Ensure all images within the div are fully loaded
       const images = input.getElementsByTagName('img');
       let imagesLoaded = 0;
   
       const loadImagePromises = Array.from(images).map((img) => {
           return new Promise((resolve, reject) => {
               if (img.complete) {
                   resolve();
               } else {
                   img.onload = resolve;
                   img.onerror = reject;
               }
           }).then(() => {
               imagesLoaded++;
           });
       });
   
       Promise.all(loadImagePromises).then(() => {
           html2canvas(input, {
               scale: 2,
               useCORS: true,
               logging: true,
               letterRendering: true,
           }).then((canvas) => {
               const imgWidth = 210; // A4 width in mm
               const imgHeight = (canvas.height * imgWidth) / canvas.width;
               const pageHeight = 297; // A4 height in mm
               let heightLeft = imgHeight;
   
               const imgData = canvas.toDataURL('image/jpeg', 1.7); // Adjust quality to reduce file size
               let position = 0;
   
               const pdf = new jsPDF('p', 'mm', 'a4');
               pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST'); // Use 'FAST' compression
   
               heightLeft -= pageHeight;
   
               while (heightLeft >= 0) {
                   position = heightLeft - imgHeight;
                   pdf.addPage();
                   pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight, undefined, 'FAST');
                   heightLeft -= pageHeight;
               }
   
               // Save or call API
               if (type === 'admin') {
                   pdf.save(`${trafficId}.pdf`);
               }
   
               const pdfBlob = pdf.output('blob');
               callSavePdfApiFunction(pdfBlob);
   
               if (pdfButton) pdfButton.style.display = '';
           }).catch((error) => {
               console.error('Error capturing the canvas:', error);
           });
       }).catch((error) => {
           console.error('Error loading images:', error);
       });
   };
   
   
   

   const callSavePdfApiFunction = (pdfParam) => {
      const formData = new FormData();
      formData.append('pdf', pdfParam);
      formData.append('trafficId', resultList && resultList.traffic_id);
      formData.append('examid', examid);

      dispatch(onSaveResultPdf(formData));
   };

   const handleChangeLocale = (currentLocale) => {
      if (currentLocale === 'en') {
         setDirection('ltr');
      } else {
         setDirection('rtl');
      }
      setDefaultLocale(currentLocale);
   };

   useEffect(() => {
      i18n.changeLanguage(defaultLocale);
   }, [defaultLocale]);

   return (
      <>
         <div className="container" id="divToPdf">
            <div className="page-content">
               <div className="text-center mt-3 mb-5">
                  <img src={storedData && mediaSource + storedData.result_logo} alt="" height="65" />
               </div>

               <div className="">
                  {/* <h3 className='align-right' style={{ display: "none" }} id="resultSheet">{t('THEORY TEST SCORE SHEET')}</h3> */}
                  <div className="card">
                     <div className="card-body pt-3">
                        {isLoading ? <Loader /> :
                           <>
                           
                              <div className="d-flex justify-content-center">
                                 <table className="table table-bordered border-primary align-middle " dir={direction}>
                                    <tbody className="heabody">
                                       <tr >
                                          <th style={{ width: "300px" }}>  {t('Test Date')}</th>
                                          <td colSpan={2} dir='ltr' className={`card-body ${direction === 'rtl' ? 'text-end' : ''}`}>{resultList && resultList.test_date} </td>
                                       </tr>
                                       <tr>
                                          <th>  {t('Student Name')}</th>
                                          <td colSpan={2}>{resultList && resultList.std_name}</td>
                                       </tr>
                                       <tr>
                                          <th> {t('Traffic File Number')}</th>
                                          <td colSpan={2}>{resultList && resultList.traffic_id}</td>
                                       </tr>
                                       <tr>
                                          <th> {t('Category')}</th>
                                          <td colSpan={2}>{resultList && resultList.course}</td>
                                       </tr>
                                       <tr>
                                          <th> {t('Test Time')}</th>
                                          <td colSpan={2}>{resultList && resultList.test_time}</td>
                                       </tr>
                                       <tr>
                                          <th> {t('Total duration (In minutes)')}</th>
                                          <td colSpan={2}>{resultList && resultList.total_duration}</td>
                                       </tr>
                                       <tr>
                                          <th> {t('Test Duration (In minutes)')}</th>
                                          <td colSpan={2}>{resultList && resultList.test_duration}</td>
                                       </tr>
                                       <tr>
                                          {resultList && resultList.status == 0 ?
                                             <th className='fs-5' colSpan={3}><strong> {t('Unfortunately, you failed your theory test')}</strong></th>
                                             :
                                             <th className='fs-5' colSpan={3}><strong>{t('Congratulations, you pass your theory test')}</strong></th>
                                          }
                                       </tr>
                                       {resultList && resultList.topics ?
                                          <tr>
                                             <th> {t('Topic Area')}</th>
                                             <th colSpan={2}> {t('No. of incorrect Answer')}</th>
                                          </tr>
                                          : ''}
                                       {resultList && resultList.topics ?
                                          resultList.topics.map((item, key) => {
                                             return (
                                                item.wrong_ans > 0 ?
                                                   <tr key={key}>
                                                      <th>{item.topic}</th>
                                                      <td colSpan={2}>{item.wrong_ans}</td>
                                                   </tr>
                                                   : ''
                                             );
                                          })
                                          : ''}
                                       <tr>
                                          <th className="text-center" style={{ width: "30%" }}>
                                             <div className="text-center"> {t('Total Questions')}</div>
                                          </th>
                                          <th className="text-center" style={{ width: "35%" }}>
                                             <div className="text-center">{t('Minimum Correct Answers Required')}</div>
                                          </th>
                                          <th className="text-center" style={{ width: "30%" }}>
                                             <div className="text-center">{t('Correct Answers')}</div>
                                          </th>
                                       </tr>
                                       <tr>
                                          <td><div className="text-center">{resultList && resultList.total_question}</div> </td>
                                          <td><div className="text-center">{resultList && resultList.required_ans}</div> </td>
                                          <td><div className="text-center">{resultList && resultList.correct_ans}</div></td>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                              
                              <div className="mt-4 mb-2 no-print" id="pdf-button">
                                 {type === 'student' ?
                                    <button color="primary" className="w-100" onClick={() => handleNavigation()}>Close</button>
                                    :
                                    <div className='d-flex gap-1'>
                                       <Button color="primary" onClick={() => handlePrintPreview()}>Print</Button>
                                       <Link to='/admin/result'><Button color="primary">Back</Button></Link>
                                       <Button color="primary" onClick={() => printPdfDocument()}>PDF</Button>
                                       <Button color="primary" onClick={() => printPdfDocument()}>Resend</Button>
                                       <Button color="primary" onClick={() => handleChangeLocale('en')}>English</Button>
                                       <Button color="primary" onClick={() => handleChangeLocale('ae')}>Arabic</Button>
                                       {resultList && resultList.langShort !== 'en' && resultList.langShort !== 'ae' ?
                                          <Button color="primary" onClick={() => handleChangeLocale(resultList && resultList.langShort)}>{resultList && resultList.examLang}</Button>
                                          : ''}
                                    </div>
                                 }
                              </div>
                           </>
                        }
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default StudentResults;
