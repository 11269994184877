import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation } from 'react-i18next'

const 
ExamReviewSection = (props) => {
  const {qData,selectedOptions,direction,isSaveQ,handleReview,handleEndReview,loader,handleReviewMarkedOnly,reviewAfterMarkedStack} = props;

  const { t, i18n }  = useTranslation();
  useEffect(() => {
    isSaveQ ?(
       
        toast.success('Questions saved', { autoClose: 3000 })
        ):''

  }, [isSaveQ])

  
  return (
    <React.Fragment>
  

        <section className="section mt-5 bg-white position-relative">
            <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">{t('Exam Screen For Review')}</h4>
                        </div>

             
           
                        <div className="card-body">
                            <p className="text-muted">{t('Please check answers')}  <code>{t('before end review')} </code></p>
                            <div className="live-preview">
                                <div className="row gy-4">
                                {qData
                        ? qData.map((item, i) => {
                            return (

                                    <div className="col-xl-2 col-md-6" key={item.id}>
                                        <div className="card card-border card-border-'+qClass+' ribbon-box border shadow-none mb-lg-0 right">
                                            <div className="card-body text-muted">
                                            {selectedOptions[i] && selectedOptions[i].correctAns != '' ? (
                                                <span className="ribbon-three ribbon-three-success"><span> {t('Answered')} </span></span>
                                                ) : (
                                                    <span className="ribbon-three ribbon-three-danger"><span>{t('Pending')} </span></span>
                                                    )}
                                                <div className="form-check form-check-outline form-check-success' mt-3">
                                                    <input className="form-check-input btn-checkbox text-white" type="checkbox"
                                                     value=""
                                                    //  onChange={(e) => handleReviewMarkedOnly(e, i)}
                                                     checked={reviewAfterMarkedStack.includes(i)}
                                                     />
                                                    <label className="form-check-label" htmlFor="formCheck13">{t('Question')}  {i + 1}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                         )
                                        })
                                      : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

            </div>
        </section>
        <footer className="footer py-3" style={{position:"fixed"}}>
            <div className="container-fluid">
                <div className="d-flex flex-wrap gap-3 justify-content-end">
                        <button type="button" className="btn btn-primary bg-gradient waves-effect waves-light " onClick={() => handleReview(1)}>{t('Review All')} </button>
                        <button type="button" className="btn btn-primary bg-gradient waves-effect waves-light " onClick={() => handleReview(2)}>{t('Review Unanswered')} </button>
                        <button type="button" className="btn btn-primary bg-gradient waves-effect waves-light " onClick={(e) => handleReview(3)}>{t('Review Checked Only')} </button>
                        <button type="button"  className="btn btn-primary bg-gradient waves-effect waves-light "onClick={() => handleEndReview()}>{t('End Review')} </button>
                </div>
            </div>
        </footer>

<ToastContainer closeButton={false} limit={1} />
</React.Fragment>
  )
}

export default ExamReviewSection