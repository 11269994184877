import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Input, Label, Row, Button } from 'reactstrap';
import PagesLogoComponent from '../../../Components/Common/PagesLogoComponent';
import { useTranslation } from 'react-i18next';

function InstructionScreen() {


    const [studentInfo, setStudentInfo] = useState([])
    const { t, i18n } = useTranslation();
    const [direction, setDirection] = useState('ltr');

    useEffect(() => {
        const studentDataFromLocalStorage = localStorage.getItem('StudentInformation');
        setStudentInfo(studentDataFromLocalStorage ? JSON.parse(studentDataFromLocalStorage) : null);
    }, []);


    useEffect(() => {
        if (studentInfo.qLangShortName != undefined) {
            i18n.changeLanguage(studentInfo.qLangShortName)
            setDirection(studentInfo.direction)
        }

    }, [studentInfo]);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover  d-flex justify-content-center align-items-center" dir={direction}>
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-5">
                    <PagesLogoComponent />
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <Card className="overflow-hidden">
                                    <Row className="g-0">

                                        <div className="p-lg-5 p-4">
                                            <div>

                                                <h5 className="text-primary">{t('Thoery Practice')}</h5>
                                                <p className="text-muted">{studentInfo.instructions}</p>
                                            </div>

                                            <div>
                                                <iframe
                                                    title="My Embedded Content"
                                                    width="100%"
                                                    height="512"
                                                    src={studentInfo.videoLink}

                                                    allowFullScreen
                                                ></iframe>

                                            </div>
                           
                                                <div className="mt-4 mb-2">
                                              
                                                    {studentInfo.examType == 1 ?
                                                        <Link to="/theory-practice"><Button color="primary" className="w-100" type="submit">{t('Start Exam')}</Button></Link>
                                                        :
                                                        <Link to="/practice-type"><Button color="primary" className="w-100" type="submit">{t('Start Practice')}</Button></Link>
                                                    }
                                               
                                            </div>

                                            {/* <Link to="/student"><Button color="primary" type="submit">{t('Back')}</Button></Link> */}
                                        </div>

                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>



            </div>
        </React.Fragment>
    );
}

export default InstructionScreen