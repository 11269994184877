import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row, Button, Spinner } from 'reactstrap';
import AuthSlider from '../../../pages/AuthenticationInner/authCarousel';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import PagesLogoComponent from '../../../Components/Common/PagesLogoComponent';
import { useDispatch, useSelector } from 'react-redux';
import { checkSystemIp as onCheckSystemIp } from '../../../slices/system/thunk';
import { createSelector } from 'reselect';
import { ToastContainer, toast } from 'react-toastify';



const SystemStart = () => {
  document.title = "Thoery Practice || Practice";

  // localStorage.clear();

  const [systemIp, setSystemIp] = useState('');
  const [systemIpError, setSystemIpError] = useState('');
  const [isloading, setIsloading] = useState(false);


  let navigate = useNavigate();
  const dispatch = useDispatch();


  const selectSystemState = (state) => state.System;
  const systemProperties = createSelector( selectSystemState,(data) => ({   isIpFound: data.isIpFound  }) );

  const {isIpFound  } = useSelector(systemProperties)
    



  const storeSystemIp = () => {
    setIsloading(true)
    if (isEmpty(systemIp)) {
      setSystemIpError(true)
    } else {

      dispatch(onCheckSystemIp(systemIp));
 
    }
  };

  const [localIP, setLocalIP] = useState('Fetching...');

  useEffect(() => {

    if (!isEmpty(localStorage.getItem('systemLocalIp'))) {
      navigate('/student');
    }

    getLocalIP()/*  */
      .then(ip => setLocalIP(ip))
      .catch(error => setLocalIP('Error fetching IP: ' + error.message));
  }, []);

  const getLocalIP = () => {
    return new Promise((resolve, reject) => {
      // Create a WebRTC peer connection
      const pc = new RTCPeerConnection();

      // Null STUN server for local IP discovery
      pc.onicecandidate = (iceEvent) => {
        if (iceEvent.candidate) {
          const pattern = new RegExp('([0-9]{1,3}(\\.[0-9]{1,3}){3})');
          const result = pattern.exec(iceEvent.candidate.candidate);
          if (result) {
            resolve(result[1]);
            pc.close();
          }
        }
      };

      // Create a bogus data channel
      pc.createDataChannel('');

      // Create an offer sdp
      pc.createOffer().then((sdp) => pc.setLocalDescription(sdp));

      // Timeout for failing to retrieve IP
      setTimeout(() => {
        reject(new Error('Failed to obtain IP address'));
        pc.close();
      }, 5000); // 5 seconds timeout
    });
  };


  useEffect(() => {
setIsloading(false)
if(isIpFound==1){
       setSystemIpError(false)
      localStorage.setItem('systemLocalIp', systemIp);
      navigate('/student');
}
if(isIpFound==2){
  toast.error('Invalid IP address', { autoClose: 3000 });
}
  }, [isIpFound]);





  

  return (
    <React.Fragment>
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
      <PagesLogoComponent />
          <Container>
            <Row>
              {/* <div>
                <h2>Local IP Address</h2>
                <h2>Storage IP Address: {localStorage.getItem('systemLocalIp')}</h2>
                <p>{localIP}</p>
              </div> */}
              
              <Col lg={12}>
                <Card className="overflow-hidden">
                  <Row className="g-0">

             
                    <Col lg={12}>

                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">Welcome Back To Thoery Practice</h5>
                          <p className="text-muted">Add system IP and use this system for practice and exam</p>
                        </div>

                        <div
                          className="mb-3"
                          id="modal-id"

                        >


                          <Input
                            name=""
                            id="name-field"
                            className="form-control"
                            placeholder="System IP"
                            type="text"

                            onChange={(e) => setSystemIp(e.target.value)}
                            validate={{ required: { value: true } }} value={systemIp} invalid={systemIpError ? true : false} />
                        </div>

                        <div className="mt-4">
                          <div className="mt-4">
                            <Button color="success" className="w-100" type="button" onClick={() => { storeSystemIp() }}>  {isloading ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null} Login</Button>
                          </div>
                        </div>
                      </div>
                      <ToastContainer closeButton={false} limit={1} />
                    </Col>
              
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>



      </div>
    </React.Fragment>
  );
};

export default SystemStart