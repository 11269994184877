import React, {useCallback, useEffect, useMemo, useState} from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import {toast, ToastContainer} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addNewRole as onAddNewRole, deleteRole as onDeleteRole,
    getRoles as onGetRoles,
    updateRole as onUpdateRole
} from "../../slices/roles/thunk";

import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link, Navigate} from "react-router-dom";
import {isEmpty} from "lodash";
import { Route, useNavigate } from 'react-router-dom'
import AuthorizeForAction from "../../Routes/AuthorizeForAction";

const Roles = () => {

    const navigate=useNavigate();

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);

    const [modal, setModal] = useState(false);

    const [role, setRole] = useState([]);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    // Delete customer
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);


    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);

    const selectLayoutState = (state) => state.Role;
    const rolesProperties = createSelector(
        selectLayoutState,
        (ecom) => ({
            roles: ecom.roles,
            isRolesSuccess: ecom.isRolesSuccess,
            isRolesCreated: ecom.isRolesCreated,
            error: ecom.error,
        })
    );
    // Inside your component
    const {
        roles, isRolesSuccess, error,isRolesCreated
    } = useSelector(rolesProperties)


    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);


    useEffect(() => {
        //if (roles && !roles.length) {
            dispatch(onGetRoles());
      //  }
    }, [dispatch]);


    useEffect(() => {
        setRole(roles);
        setLoading(!isRolesSuccess);
        if(isRolesCreated){
            setIsloading(false);
            validation.resetForm();
            setModal(false);
        }
        error? setIsloading(false):null
    }, [roles,isRolesSuccess,error,isRolesCreated]);

    useEffect(() => {
        if (!isEmpty(roles)) {
            setRole(roles);
            setLoading(!isRolesSuccess);
            setIsEdit(false);
        }
    }, [roles,isRolesSuccess,isRolesCreated]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            id:  (isEdit?(role && role.id):"") || '',
            name:  (isEdit?(role && role.name):"") || '',
            status:  (isEdit?(role && role.status):"") || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Title"),
            status: Yup.string().required("Please Enter Your Status")
        }),
        onSubmit: (values) => {
            setIsloading(true);
            if (isEdit) {
                const updateRole = {
                    id: role ? role.id : 0,
                    name: values.name,
                    status: values.status,
                };
                dispatch(onUpdateRole(updateRole));

            } else {
                const newRole = {
                    name: values["name"],
                    status: values["status"]
                };
                dispatch(onAddNewRole(newRole));

            }

        },
    });

    const roleStatus = [
        {
            options: [
                { label: "Select Status", value: "" },
                { label: "Active", value: "1" },
                { label: "In-Active", value: "2" },
            ],
        },
    ];


    const handleCustomerClick = useCallback((arg) => {
        const role = arg;
        setRole({
            id: role.id,
            name: role.name,
            status: role.status
        });
        setIsEdit(true);
        toggle();
    }, [toggle]);

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".customerCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".customerCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="customerCheckBox form-check-input" value={cellProps.row.original.id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: " Title",
                accessor: "name",
                filterable: false,
            },
            {
                Header: " Assigned To",
                accessor: "user",
                Cell: (cellProps) => {
                    return (
                        <div className="avatar-group">
                            {cellProps.value.map((item,key) => (
                            <a className="avatar-group-item"
                                title={item.name}
                               key={key}
                               href="#">
                                    <img src="https://api-node.themesbrand.website/images/users/avatar-3.jpg"
                                         alt=""
                                         className="rounded-circle avatar-xxs"/>
                            </a>
                                ))}
                        </div>

                    );
                },
            },

            {
                Header: "Status",
                accessor: "status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case 1:
                            return <span className="badge text-uppercase bg-success-subtle text-success">  {cell.value==1?"Active":""} </span>;
                        case 2:
                            return <span className="badge text-uppercase bg-danger-subtle text-danger"> {cell.value==2?"In-Active":""} </span>;
                        default:
                            return <span className="badge text-uppercase bg-info-subtle text-info"> {cell.value==1?"Active":"In-Active"} </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <AuthorizeForAction requiredPermissions={['role-create']}>
                            <li className="list-inline-item" title="Permissions">
                                <Link
                                    to={`/admin/role-permissions/${cellProps.row.original.id}`}
                                    // to="/admin/role-permissions" state={{ role: "new" }}
                                    className="text-primary d-inline-block permission-item-btn"
                                >
                                    <i className="ri-user-settings-line fs-4"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['role-edit']}>
                            <li className="list-inline-item edit" title="Edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={() => { const customerData = cellProps.row.original; handleCustomerClick(customerData); }}
                                >

                                    <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                                <AuthorizeForAction requiredPermissions={['role-delete']}>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => { const customerData = cellProps.row.original; onClickDelete(customerData); }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                                </AuthorizeForAction>
                        </ul>
                    );
                },
            },

        ],
        [handleCustomerClick, checkedAll]
    );

    const onClickDelete = (role) => {
        setRole(role);
        setDeleteModal(true);
    };




    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");

        selectedCheckBoxDelete.forEach((element) => {
            dispatch(onDeleteRole(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };





    const handleCustomerClicks = () => {
        setRole("");
        setIsEdit(false);
        toggle();
    };

    const handleDeleteCustomer = () => {
        if (role) {
            dispatch(onDeleteRole(role.id));
            setDeleteModal(false);
        }
    };

    const handleRolePermissions=(role)=>{
       alert("its me");
        navigate('/');

    }



    document.title = "User Role List | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteCustomer}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="User Role List" pageTitle="Settings" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">User Role List</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div>
                                                <AuthorizeForAction requiredPermissions={['role-delete']}>
                                                {isMultiDeleteButton && <button className="btn btn-soft-danger me-1"
                                                                                onClick={() => setDeleteModalMulti(true)}
                                                ><i className="ri-delete-bin-2-line"></i></button>}
                                                </AuthorizeForAction>
                                                    <AuthorizeForAction requiredPermissions={['role-create']}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success add-btn"
                                                    id="create-btn"
                                                    onClick={() => { setIsEdit(false); toggle(); }}>
                                                    <i className="ri-add-line align-bottom me-1"></i> Add
                                                    Role
                                                </button>{" "}
                                                    </AuthorizeForAction>

                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0">

                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        :<div>
                                        {isRolesSuccess && roles.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(roles || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                handleCustomerClick={handleCustomerClicks}
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for Role, status or something...'
                                            />
                                        ) : (
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{width: "72px", height: "72px"}}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )
                                        }
                                    </div>)}

                                    <Modal id="showModal" isOpen={modal} backdrop="static" toggle={toggle} centered>
                                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit Role" : "Add Role"}
                                        </ModalHeader>
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <ModalBody>
                                                <input type="hidden" id="id-field" />

                                                <div
                                                    className="mb-3"
                                                    id="modal-id"
                                                    style={{ display: "none" }}
                                                >
                                                    <Label htmlFor="id-field1" className="form-label">

                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="id-field1"
                                                        className="form-control"
                                                        placeholder="ID"
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="name-field"
                                                        className="form-label"
                                                        >
                                                         Title
                                                    </Label>
                                                    <Input
                                                        name="name"
                                                        id="name-field"
                                                        className="form-control"
                                                        placeholder="Enter Role Title"
                                                        type="text"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            validation.touched.name && validation.errors.name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.name && validation.errors.name ? (
                                                        <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="status-field" className="form-label">
                                                         Status
                                                    </Label>
                                                    <Input
                                                        name="status"
                                                        type="select"
                                                        className="form-select"
                                                        id="status-field"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.status || ""
                                                        }
                                                        invalid={
                                                            validation.touched.status && validation.errors.status ? true : false
                                                        }
                                                        >
                                                        {roleStatus.map((item, key) => (
                                                            <React.Fragment key={key}>
                                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                                            </React.Fragment>
                                                        ))}

                                                    </Input>
                                                    {validation.touched.status &&
                                                    validation.errors.status ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.status}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { setModal(false); validation.resetForm() }}> Close </button>

                                                    <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                      <span className="d-flex align-items-center">
                                                          {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                          <span className="flex-grow-1 ms-2">
                                                             {!!isEdit ? "Update" : "Add Role"}{" "}
                                                          </span>
                                                      </span>
                                                    </Button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default Roles;