import React, {useCallback, useEffect, useMemo, useState} from "react";


import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import {toast, ToastContainer} from "react-toastify";
import { useNavigate } from 'react-router-dom';
import Flatpickr from "react-flatpickr";

import {    getPracticeResults as onGetPracticeResults,markEligible as onMarkEligible  } from "../../../slices/result/thunk";

import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import AuthorizeForAction from "../../../Routes/AuthorizeForAction";
import { changeDateFormate } from "../../utility/TimerUtility";

const PracticeResult = () => {

    const dispatch = useDispatch();
    const [result, setResult] = useState([]);
    const [loading, setLoading] = useState(true);

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 3);

    const startDate = changeDateFormate(sevenDaysAgo);
    const  endDate= changeDateFormate(new Date());


    const [selectedDates, setSelectedDates] = useState([startDate,endDate]);
    const [isLoadingFilter,setIsLoadingFilter]=useState(false);

    let navigate = useNavigate();


    const selectLayoutState = (state) => state.ResultReducer;
    const resultProperties = createSelector(
        selectLayoutState,
        (ecom) => ({
         practiceResultList: ecom.practiceResultList,
         isResultSuccess: ecom.isResultSuccess,
         error: ecom.error,
        })
    );
  
    const { practiceResultList,isResultSuccess } = useSelector(resultProperties)
     
   


    useEffect(() => {
        // dispatch(onGetPracticeResults());

        handleGetPracticeResultList(selectedDates[0],selectedDates[1]);
        }, [dispatch,isResultSuccess]);



    useEffect(() => {
        setLoading(!isResultSuccess);
        if (!isEmpty(practiceResultList)) {
  
            setResult(practiceResultList);
      
        }
    }, [isResultSuccess]);

    const handleGetPracticeResultList = (fromDate,toDate)=>{

        const resultPostedData = {
          start_date:fromDate,
          end_date:toDate
        }
      
        dispatch(onGetPracticeResults(resultPostedData));
      }

       
const filterExamResult= () =>{

    setIsLoadingFilter(true)
    if(selectedDates[0] &&  selectedDates[1]){
        handleGetPracticeResultList(selectedDates[0],selectedDates[1]);
    }else{
      toast.error('Invalid date', { autoClose: 3000 });
    }
    setIsLoadingFilter(false)

}

const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
 };


 const handleMarkAsRead = (trafficId,examId)=>{

    const resultPostedData = {
        traffic_id:trafficId,
        examId:examId,
    }
     dispatch(onMarkEligible(resultPostedData));
  }


    const columns = useMemo(
        () => [
            {
                Header: "Traffic ID",
                accessor: "traffic_id",
                filterable: false,
            },
        
            {
                Header: "Student Name",
                accessor: "std_name",
                filterable: false,
                Cell: (cellProps) => {
                 return (
        
                    <Link  to={`/admin/attempt-history/${cellProps.row.original.id}`}>   
                    <span>{cellProps.row.original.std_name}</span>
                    </Link>
                
                 );
             },
            },

               
            {
                Header: "Held On",
                accessor: "test_date",
                filterable: false,
            },
            {
                Header: "Course",
                accessor: "course",
                filterable: false,
            },
            {
                Header: "Specific",
                accessor: "specificQ",
                filterable: false,
                Cell: (cellProps) => {
                 return (
                    cellProps.row.original.specificAttemptId > 0?
                    <Link  to={`/admin/result-detail/${cellProps.row.original.specificAttemptId}/2`}>   
                    <span>{cellProps.row.original.specificSolved}/{cellProps.row.original.specificQ}</span>
                    </Link>
                    :
                    <span>{cellProps.row.original.specificSolved}/{cellProps.row.original.specificQ}</span>
                 );
             },
            },

            {
                Header: "Common",
                accessor: "commonQ",
                filterable: false,
                Cell: (cellProps) => {
                 return (
                    cellProps.row.original.commonAttemptId > 0?
                    <Link  to={`/admin/result-detail/${cellProps.row.original.commonAttemptId}/2`}>   
                     <span>{cellProps.row.original.commonSolved}/{cellProps.row.original.commonQ}</span>
                     </Link>
                     :
                     <span>{cellProps.row.original.commonSolved}/{cellProps.row.original.commonQ}</span>
                 );
             },
            },

            {
                Header: "Video",
                accessor: "videoTotalQ",
                filterable: false,
                Cell: (cellProps) => {
                 return (
                    cellProps.row.original.videoAttemptId > 0?
                    <Link  to={`/admin/result-detail/${cellProps.row.original.videoAttemptId}/2`}> 
                     <span>{cellProps.row.original.videoSolved}/{cellProps.row.original.videoTotalQ}</span>
                     </Link>
                     :
                     <span>{cellProps.row.original.videoSolved}/{cellProps.row.original.videoTotalQ}</span>
                 );
             },
            },
         
            {
               Header: "Total",
               accessor: "grandTotalQ",
               filterable: false,
               Cell: (cellProps) => {
                return (
                    <span>{cellProps.row.original.totalSolved}/{cellProps.row.original.grandTotalQ} <span className="badge bg-info-subtle text-info">({cellProps.row.original.percentage}%) </span></span>
                );
            },
           },

           {
            Header: "Action",
            Cell: (cellProps) => {
                return (
                    <ul className="list-inline hstack gap-2 mb-0">
                    
                        <li className="list-inline-item" title="Mark as eligible">
                          
                                <i onClick={()=>handleMarkAsRead(cellProps.row.original.traffic_id,cellProps.row.original.id)} className=" ri-chat-check-line fs-2 cursor-pointer"></i>
                     
                        </li>
                    </ul>
                );
            },
        },
           


        ],
        []
    );


    document.title = "Practice Result | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Practice Result" pageTitle="Dashboard" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Practice Result </h5>
                                            </div>
                                        </div>

                                        <div className="col-sm-auto d-flex">

<div>

<div className="mt-3 mt-lg-0">

                              <div className="row g-3 mb-0 align-items-center">
                                  <div className="col-sm-auto">
                                      <div className="input-group">

                  <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              defaultDate: [startDate,endDate],
                              onChange:handleDateChange

                          }}

                      />
                      <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>


                                      </div>
                                  </div>

                                  <div className="col-auto">
                                      <Button type="button" color='success' onClick={filterExamResult}>    {isLoadingFilter ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}<i className="ri-search-line align-middle me-1"></i>Filter</Button>

                                  </div>



                              </div>

                      </div>



</div>
</div>

                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0">
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        : <div>
                                        {isResultSuccess && practiceResultList.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(practiceResultList || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for student, traffic id ...'
                                            />
                                        ) :(
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{ width: "72px", height: "72px" }}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>)}
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default PracticeResult;