import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import { getTestData as getTestDataApi } from "../../helpers/custom_helper/backend_helper";



export const getTestData = createAsyncThunk("get-test", async (postedData) => {
  try {
    const response =await getTestDataApi(postedData);
    return response;
    if(response.success){
      return response;
    }else{
      return response.data;
    }
  
  } catch (error) {
    return error;
  }
});







