import React, { useCallback, useEffect, useMemo, useState } from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row, Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import { toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addNewCourse as onAddNewCourse,
  addCourseTranslation as onAddCourseTranslation,
  deleteCourse as onDeleteCourse,
  getCourses as onGetCourses,
  updateCourse as onUpdateCourse,
} from "../../slices/course/thunk";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";

const Course = () => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [modal, setModal] = useState(false);
  const [translationModal, setTranslationModal] = useState(false);
  const [course, setCourse] = useState([]);
  const [lang, setLang] = useState([]);
  const [courseTranslation, setCourseTranslation] = useState([]);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [defualtTranslation, setdefualtTranslation] = useState("");

  const [transFullname, setTransFullname] = useState([]);
  const [courseTransFullname, setCourseTransFullname] = useState([]);
  const [transLang, setTransLang] = useState("");
  const [transInstruction, setTransInstruction] = useState([]);
  const [courseTransInstructions, setCourseTransInstruction] = useState([]);
  const [transVideoLink, setTransVideoLink] = useState([]);
  const [courseTransVideoLinks, setCourseTransVideoLink] = useState([]);

  const [loading, setLoading] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [commonQ, setcommonQ] = useState(0);
  const [vidQ, setVidQ] = useState(0);
  const [courseAllData, setCourseAllData] = useState();

  const selectLayoutState = (state) => state.Course;
  
  const courseProperties = createSelector(selectLayoutState, (ecom) => ({
    courses: ecom.courses,
    langs: ecom.langs,
    isCourseSuccess: ecom.isCourseSuccess,
    isCourseCreated: ecom.isCourseCreated,
    isSaveLoading: ecom.isSaveLoading,
    error: ecom.error,
  
    
  }));
  // Inside your component
  const { courses,langs,isCourseSuccess,isCourseCreated,error,isSaveLoading}=useSelector(courseProperties);


  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);

  useEffect(() => {
    if (courses && !courses.length) {
      dispatch(onGetCourses());
    }
  }, [dispatch,courses]);

  useEffect(() => {

    setCourse(courses);
    setLang(langs);
    setLoading(!isCourseSuccess);
  
    if(isCourseCreated){
      setIsloading(false);
      validation.resetForm();
      setModal(false);
      validationTrans.resetForm();
      setTranslationModal(false);
    }
    if(error){
      setIsloading(false);
    }
  }, [courses, langs, isCourseCreated,error]);

  useEffect(() => {

    if (!isEmpty(courses)) {
      setCourse(courses);
      setLang(langs);
      setIsEdit(false);
      setLoading(!isCourseSuccess);
    }
  }, [courses, langs, isCourseCreated]);







  const validation = useFormik({

    enableReinitialize: true,
    initialValues: {
      short_name: (isEdit ? course && course.short_name : "") || "",
      full_name: (isEdit ? course && course.full_name : "") || "",
      status: (isEdit ? course && course.status : "") || "",
  
    },
    validationSchema: Yup.object({
      full_name: Yup.string().required("Please Enter Full Name"),
      short_name: Yup.string().required("Please Enter Short Name"),
      status: Yup.string().required("Please Enter Your Status"),
   
    }),
    onSubmit: (values) => {
      setIsloading(true);
      if (isEdit) {
        const updateCourse = {
          id: course ? course.id : 0,
          full_name: values.full_name,
          short_name: values.short_name,
          status: values.status,
   
        };
        dispatch(onUpdateCourse(updateCourse));
      } else {
        const newCourse = {
          full_name: values["full_name"],
          short_name: values["short_name"],
          status: values["status"],
      
        };

        dispatch(onAddNewCourse(newCourse));
      }
    },
  });

  const validationTrans = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {

      t_course_id: (course && course.id) || "",
      t_full_name: (courseTransFullname && courseTransFullname) || "",
      t_lang: lang.map((item) => item.lang_short) || "",
      t_instructions: (courseTransInstructions && courseTransInstructions) || "",
      t_video_link: (courseTransVideoLinks && courseTransVideoLinks) || "",
   
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      setIsloading(true);
      const newCourseTrans = {
        course_id: values["t_course_id"],
        full_name: values["t_full_name"],
        lang: values["t_lang"],
        instructions: values["t_instructions"],
        video_link: values["t_video_link"],
      };
      console.log('newCourseTrans',newCourseTrans);
      dispatch(onAddCourseTranslation(newCourseTrans));
    },
  });

  const courseStatus = [
    {
      options: [
        { label: "Select Status", value: "" },
        { label: "Active", value: "1" },
        { label: "In-Active", value: "2" },
      ],
    },
  ];

  const handleCustomerClick = useCallback(
    (arg) => {
      const mycourse = arg;
      setCourse({
        id: mycourse.id,
        short_name: mycourse.short_name,
        full_name: mycourse.course_translation[0].full_name,
        status: mycourse.status,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".customerCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".customerCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),
        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="customerCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: "Course",
        accessor: "short_name",
        filterable: false,
      },
      {
        Header: "Specific",
        filterable: false,
        accessor: "specificQuestion",
        
      
      },
      {
        Header: "Common",
        filterable: false,
        accessor: "commonQuestionCount",
      
      },
  

      {
        Header: "video",
        accessor: "videoQuestion",
        filterable: false,
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: (cell) => {
          switch (cell.value) {
            case 1:
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  {" "}
                  {cell.value == 1 ? "Active" : ""}{" "}
                </span>
              );
            case 2:
              return (
                <span className="badge text-uppercase bg-danger-subtle text-danger">
                  {" "}
                  {cell.value == 2 ? "In-Active" : ""}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-info-subtle text-info">
                  {" "}
                  {cell.value == 2 ? "In-Active" : "Active"}{" "}
                </span>
              );
          }
        },
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <AuthorizeForAction requiredPermissions={['course-edit']}>
              <li className="list-inline-item edit" title="Edit">
                <Link
                  to="#"
                  className="text-primary d-inline-block edit-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleCustomerClick(customerData);
                  }}
                >
                  <i className="ri-pencil-fill fs-16"></i>
                </Link>
              </li>
              </AuthorizeForAction>
              <AuthorizeForAction requiredPermissions={['course-delete']}>
              <li className="list-inline-item" title="Remove">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickDelete(customerData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li>
              </AuthorizeForAction>
              <AuthorizeForAction requiredPermissions={['course-translate']}>
              <li className="list-inline-item" title="Translation">
                <Link
                  to="#"
                  className="text-success d-inline-block translate-item-btn"
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    onClickTranslate(customerData);
                  }}
                >
                  <i className="ri-translate fs-18"></i>
                </Link>
              </li>
              </AuthorizeForAction>
              <AuthorizeForAction requiredPermissions={['course-create']}>
              <li className="list-inline-item" title="Configuration">
                <Link
                  to={`/admin/course-config/${cellProps.row.original.id}`}
                  className="text-primary d-inline-block config-item-btn"
                >
                  <i className="ri-settings-2-line fs-18"></i>
                </Link>
              </li>
              </AuthorizeForAction>
            </ul>
          );
        },
      },
    ],
    [handleCustomerClick, checkedAll]
  );
  const onClickDelete = (course) => {
    setCourse(course);
    setDeleteModal(true);
  };


  const onClickTranslate = (customerData) => {
    setActiveIndex(0);
    setCourse(customerData);
    setdefualtTranslation(customerData.course_translation[0]);
    setCourseTranslation(customerData.course_translation);

    setTransFullname(
      customerData.course_translation.map((item) =>({lang:item.lang, title:item.full_name}))
     // customerData.course_translation.map((item) => item.full_name)
    );

    setTransInstruction(
      customerData.course_translation.map((item) =>({lang:item.lang, title:item.instructions}))
      //customerData.course_translation.map((item) => item.instructions)
    );

    setTransVideoLink(

      customerData.course_translation.map((item) =>({lang:item.lang, title:item.video_link}))
     // customerData.course_translation.map((item) => item.video_link)
    );
    setTransLang(customerData.course_translation.map((item) => item.lang));
    setTranslationModal(true);
  };
  const courseTrandfullname = [];
  const courseTransInstruction = [];
  const courseTransVideoLink = [];
  useEffect(() => {
   
    lang.map((item) =>(
      courseTrandfullname.push(
      {lang:item.lang_short, title: transFullname.find(itemTrans => itemTrans.lang === item.lang_short)?transFullname.find(itemTrans => itemTrans.lang === item.lang_short).title:defualtTranslation.full_name}
      ),
      courseTransInstruction.push(
        {lang:item.lang_short, title: transInstruction.find(itemTrans => itemTrans.lang === item.lang_short)?transInstruction.find(itemTrans => itemTrans.lang === item.lang_short).title:defualtTranslation.instructions}
        ),
        courseTransVideoLink.push(
          {lang:item.lang_short, title: transInstruction.find(itemTrans => itemTrans.lang === item.lang_short)?transInstruction.find(itemTrans => itemTrans.lang === item.lang_short).title:defualtTranslation.video_link}
          )
    ))
  
    setCourseTransFullname(courseTrandfullname);
    setCourseTransInstruction(courseTransInstruction);
    setCourseTransVideoLink(courseTransVideoLink);

  }, [transFullname]);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteCourse(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };
  const handleCustomerClicks = () => {
    setCourse("");
    setIsEdit(false);
    toggle();
  };

 
  const handleDeleteCustomer = () => {
    if (course) {
      dispatch(onDeleteCourse(course.id));
      setDeleteModal(false);
    }
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const handleTabClick = (index) => {
    setActiveIndex(index);
  };
  const handleInputChange = (index, value,lang) => {
    const newArray = [...courseTransFullname];
    newArray[index] = {
        key: lang, title: value
    };
    setCourseTransFullname(newArray);
  
  };

  const handleInstructionTranslationOnChnage = (index, value,lang) => {
    const newArray = [...courseTransInstructions];
    newArray[index] = {
        key: lang, title: value
    };
    setCourseTransInstruction(newArray);
  };

  const handleVideoLinkTranslationOnChnage = (index, value,lang) => {
    const newArray = [...courseTransVideoLinks];
    newArray[index] = {
        key: lang, title: value
    };
    setCourseTransVideoLink(newArray);

  };

  function findArrayElementByTitle(array, title) {
    return array.find((element) => {
      if (element.lang === title) {
        return element.full_name;
      } else {
        return "abc";
      }
    });
  }

  document.title = "Courses | Theory Practice";
  return (
    <React.Fragment>
      <div className="page-content">
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteCustomer}
          onCloseClick={() => setDeleteModal(false)}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
          <BreadCrumb title="Courses List" pageTitle="Dashboard" />
          <Row>
            <Col lg={12}>
              <Card id="customerList">
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Courses List</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto">
                      <div>
                        <AuthorizeForAction requiredPermissions={['course-delete']}>
                        {isMultiDeleteButton && (
                          <button
                            className="btn btn-soft-danger me-1"
                            onClick={() => setDeleteModalMulti(true)}
                          >
                            <i className="ri-delete-bin-2-line"></i>
                          </button>
                        )}
                        </AuthorizeForAction>
                        <AuthorizeForAction requiredPermissions={['course-create']}>
                        <button
                          type="button"
                          className="btn btn-success add-btn"
                          id="create-btn"
                          onClick={() => {
                            setIsEdit(false);
                            toggle();
                          }}

                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Course
                        </button>{" "}
                        </AuthorizeForAction>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className="card-body pt-0">
                  {(loading ?
                      <React.Fragment>
                        <div className="d-flex justify-content-center mx-2 mt-2">
                          <Spinner color="primary"> Loading... </Spinner>
                        </div>
                        {/*{toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}*/}
                      </React.Fragment>
                      : <div>
                        {isCourseSuccess && courses.length ? (
                            <TableContainer
                                columns={columns}
                                data={courses || []}
                                isGlobalFilter={true}
                                isAddUserList={false}
                                customPageSize={10}
                                className="custom-header-css"
                                handleCustomerClick={handleCustomerClicks}
                                isCustomerFilter={false}
                                SearchPlaceholder="Search for Course, status or something..."
                            />
                        ) : (
                            <div className="py-4 text-center">
                              <div>
                                <lord-icon
                                    src="https://cdn.lordicon.com/msoeawqm.json"
                                    trigger="loop"
                                    colors="primary:#405189,secondary:#0ab39c"
                                    style={{width: "72px", height: "72px"}}
                                ></lord-icon>
                              </div>

                              <div className="mt-4">
                                <h5>Sorry! No Record Found</h5>
                              </div>
                            </div>
                        )}
                      </div>)}

                  <Modal id="showModal" backdrop="static" isOpen={modal} toggle={toggle} centered>
                    <ModalHeader className="bg-light p-3" toggle={toggle}>
                      {!!isEdit ? "Edit Course" : "Add Course"}
                    </ModalHeader>
                    <Form
                        className="tablelist-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                    >
                      <ModalBody>
                        <input type="hidden" id="id-field"/>

                        <div
                            className="mb-3"
                            id="modal-id"
                            style={{display: "none"}}
                        >
                          <Label
                              htmlFor="id-field1"
                              className="form-label"
                          ></Label>
                          <Input
                              type="text"
                              id="id-field1"
                              className="form-control"
                              placeholder="ID"
                              readOnly
                          />
                        </div>

                        <div className="mb-3">
                          <Label htmlFor="name-field" className="form-label">
                            Course Full Name
                          </Label>
                          <Input
                            name="full_name"
                            id="name-field"
                            className="form-control"
                            placeholder="Enter course full name"
                            type="text"
                            validate={{
                              required: { value: true },
                            }}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.full_name || ""}
                            invalid={
                              validation.touched.full_name &&
                              validation.errors.full_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.full_name &&
                          validation.errors.full_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.full_name}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label
                            htmlFor="short_name_field"
                            className="form-label"
                          >
                            Course Short Name
                          </Label>
                          <Input
                            name="short_name"
                            type="text"
                            id="short_name_field"
                            placeholder="Enter Short Name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.short_name || ""}
                            invalid={
                              validation.touched.short_name &&
                              validation.errors.short_name
                                ? true
                                : false
                            }
                          />
                          {validation.touched.short_name &&
                          validation.errors.short_name ? (
                            <FormFeedback type="invalid">
                              {validation.errors.short_name}
                            </FormFeedback>
                          ) : null}
                        </div>
                        <div className="mb-3">
                          <Label htmlFor="status-field" className="form-label">
                            Status
                          </Label>
                          <Input
                            name="status"
                            type="select"
                            className="form-select"
                            id="status-field"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.status || ""}
                            invalid={
                              validation.touched.status &&
                              validation.errors.status
                                ? true
                                : false
                            }
                          >
                            {courseStatus.map((item, key) => (
                              <React.Fragment key={key}>
                                {item.options.map((item, key) => (
                                  <option value={item.value} key={key}>
                                    {item.label}
                                  </option>
                                ))}
                              </React.Fragment>
                            ))}
                          </Input>
                          {validation.touched.status &&
                          validation.errors.status ? (
                            <FormFeedback type="invalid">
                              {validation.errors.status}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setModal(false);
                              validation.resetForm();
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>
                          <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                              <span className="d-flex align-items-center">
                                  {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                  <span className="flex-grow-1 ms-2">
                                     {!!isEdit ? "Update" : "Add Course"}{" "}
                                  </span>
                              </span>
                          </Button>

                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <Modal id="showModal" backdrop="static" isOpen={translationModal} centered>
                    <ModalHeader className="bg-light p-3">
                      Course Translation -{" "}
                      <span id="translationTitle">
                        {defualtTranslation.full_name}
                      </span>
                    </ModalHeader>
                    <Form
                      className="tablelist-form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationTrans.handleSubmit();
                        return false;
                      }}
                    >
                      <ModalBody>
                        <div className="row">
                          <Input
                            type="hidden"
                            name="t_course_id"
                            value={
                              validationTrans.values.t_course_id || course.id
                            }
                          />
                          <div className="col-lg-3">
                            <div
                              className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills text-center "
                              role="tablist"
                              aria-orientation="vertical"
                              id="course-nav-section"
                            >
                              {lang.map((language, key) => (
                                <a
                                  key={key}
                                  onClick={() => handleTabClick(key)}
                                  className={`nav-link ${
                                    key === activeIndex ? "active" : ""
                                  }`}
                                  id={
                                    "custom-v-pills-profile-tab-" +
                                    language.lang_short
                                  }
                                  data-bs-toggle="pill"
                                  role="tab"
                                  aria-controls="custom-v-pills-profile"
                                  aria-selected="false"
                                  tabIndex={key}
                                >
                                  {language.lang}
                                </a>
                              ))}
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div
                              className="tab-content text-muted mt-3 mt-lg-0"
                              id="course-translation-section"
                            >
                              {lang.map((language, key) => (
                                <div
                                  key={key}
                                  className={
                                    key === activeIndex
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade "
                                  }
                                  id={
                                    "custom-v-pills-profile-" +
                                    language.lang_short
                                  }
                                  role="tabpanel"
                                  aria-labelledby={
                                    "custom-v-pills-profile-tab-" +
                                    language.lang_short
                                  }
                                >
                                  <div className="mb-4">
                                    <Input
                                      type="hidden"
                                      name="t_lang[]"
                                      value={language.lang_short}
                                    />
                                    <div className="mb-3">
                                      <label
                                        htmlFor="fullName"
                                        className="form-label"
                                      >
                                        Course Name{" "}
                                      </label>
                                      <Input
                                        name="t_full_name[]"
                                        id="fullName"
                                        className="form-control"
                                        placeholder="Enter course full name"
                                        type="text"
                                        dir={
                                          language.direction == 2
                                            ? "ltr"
                                            : "rtl"
                                        }
                                        onBlur={validationTrans.handleBlur}
                                        onChange={(e) =>
                                          handleInputChange(key, e.target.value,language.lang_short)
                                        }
                                        value={
                                            validationTrans.values.t_full_name[key]
                                            ? validationTrans.values.t_full_name[key].title
                                            : "" || ""
                                        }
                                      />
                                    </div>

                                    <div className="mb-3">
                                      <label
                                        htmlFor="t_instructions"
                                        className="form-label"
                                      >
                                        Instructions
                                      </label>
                                      <textarea
                                        name="t_instructions[]"
                                        id="t_instructions"
                                        className="form-control"
                                        placeholder="Enter Instructions"
                                        type="text"
                                        dir={
                                          language.direction == 2
                                            ? "ltr"
                                            : "rtl"
                                        }
                                        onBlur={validationTrans.handleBlur}
                                        onChange={(e) =>
                                          handleInstructionTranslationOnChnage(key, e.target.value,language.lang_short)
                                        }
                                        value={
                                          validationTrans.values.t_instructions[
                                            key
                                          ]
                                            ? validationTrans.values
                                                .t_instructions[key].title
                                            : "" || ""
                                        }
                                      />
                                    </div>

                                    <div className="mb-3">
                                      <label
                                        htmlFor="t_video_link"
                                        className="form-label"
                                      >
                                      Video Link
                                      </label>
                                      <Input
                                        name="t_video_link[]"
                                        id="t_video_link"
                                        className="form-control"
                                        placeholder="Enter video link"
                                        type="text"
                                        dir={
                                          language.direction == 2
                                            ? "ltr"
                                            : "rtl"
                                        }
                                        onBlur={validationTrans.handleBlur}
                                        onChange={(e) =>
                                          handleVideoLinkTranslationOnChnage(key, e.target.value,language.lang_short)
                                        }
                                        value={
                                          validationTrans.values.t_video_link[
                                            key
                                          ]
                                            ? validationTrans.values
                                                .t_video_link[key].title
                                            : "" || ""
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-light"
                            onClick={() => {
                              setTranslationModal(false);
                            }}
                          >
                            {" "}
                            Close{" "}
                          </button>
                          <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                              <span className="d-flex align-items-center">
                                  {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                <span className="flex-grow-1 ms-2">
                                    Save Changes
                                  </span>
                              </span>
                          </Button>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                  <ToastContainer closeButton={false} limit={1} />

                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Course;
