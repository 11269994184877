import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Card, Col, Container, Input, Label, Row ,Button} from 'reactstrap';
import AuthSlider from '../../../pages/AuthenticationInner/authCarousel';
import Pusher from 'pusher-js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { saveStdInfo as onSaveStdInfo } from '../../../slices/exam/thunk';
import { isEmpty } from 'lodash';
import PagesLogoComponent from '../../../Components/Common/PagesLogoComponent';
import TimerComponent from '../timer/TimerComponent';
import { clearTimer } from '../../utility/TimerUtility';
import { questionListResetFlag } from '../../../slices/exam/thunk';

    const Home = () => {

    document.title="Thoery Practice || Practice";

    const [examData, setExamData] = useState([])
    const [isStdHaveInfo, setIsStdHaveInfo] = useState(false)
    const [storedSystemIp, steStoredSystemIp] = useState('')
    let navigate = useNavigate();

    const dispatch = useDispatch();

        useEffect(() => {
    //    localStorage.clear();
        if(isEmpty(localStorage.getItem('systemLocalIp'))){
            navigate('/system-start');
        }
        steStoredSystemIp(localStorage.getItem('systemLocalIp'))
    
          const pusher = new Pusher('797c52fa232a2f6149b8', {
              cluster: 'ap2'
          })

          const channel1 = pusher.subscribe('examData');
           channel1.bind_global(function(examData, data) {
    

     if(data && data.data){

        if(localStorage.getItem('systemLocalIp')==data.data.systemIp){
            window.localStorage.setItem("StudentInformation", JSON.stringify(data.data));
            setExamData(data.data);
            dispatch(onSaveStdInfo(data.data));
            setIsStdHaveInfo(true)
            steStoredSystemIp(localStorage.getItem('systemLocalIp'))
        
        }
     
     }else{
      setExamData([])
     }
     window.localStorage.removeItem('isStart');
     clearTimer();
     dispatch(questionListResetFlag())


   
    });
          return (() => {
              pusher.unsubscribe('examData')
              
          })
  
      }, []);

     
        

      
        return (
            <React.Fragment>
                <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
                    <div className="bg-overlay"></div>
                    <div className="auth-page-content overflow-hidden pt-lg-5">
                       
                    

      <PagesLogoComponent />
      
                        <Container className='mt-5'>
                            <Row>
                                <Col lg={12}>
                                
                                    <Card className="overflow-hidden text-center">
                                  
                                        <Row className="g-0">
                                        
                                                <div className="p-lg-5 p-4">
                                                    
                                                    <div>
                                                     
                                                        <h3 className="text-primary">Welcome To Thoery Practice</h3>
                                                        <h5 className="text-muted">Verify your information and  click Go button</h5>
                                                        <p className="text-muted">IP:{storedSystemIp}</p>
                                                    </div>
                                                    {isStdHaveInfo?
                                                    <div>
                                                    <div style={{fontSize:"20px"}}>
                                                        <span>Traffic ID: {examData  && examData.trafficId }</span> <br/>
                                                        <span>Student Name: {examData  && examData.stdName  }</span> <br/>
                                                        <span>Question Language: {examData  && examData.qLangFullName  }</span> <br/>
                                                        <span>Audio Language: {examData  && examData.audioLangFullName  }</span> <br/>
                                                        <span>Course: {examData  && examData.courseName  }</span>
                                                        
                                                    </div>
                                                
                                                   
                                                    <div className="mt-4">
  {examData.examType == 1 ? (
    examData.is_enable_exam_description == 1 ? (
      <Link to="/instructions">
        <Button color="primary" className="w-100" type="submit">Go</Button>
      </Link>
    ) : (
      <Link to="/theory-practice">
        <Button color="primary" className="w-100" type="submit">Go</Button>
      </Link>
    )
  ) : examData.examType == 2 && examData.is_enable_p_description == 1 ? (
    <Link to="/instructions">
      <Button color="primary" className="w-100" type="submit">Go</Button>
    </Link>
  ) : (
    <Link to="/practice-type">
      <Button color="primary" className="w-100" type="submit">Go</Button>
    </Link>
  )}
</div>


           </div>
                                            

                                                        :''}
                                                </div>
                                      
                                     
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
    
             
    
                </div>
            </React.Fragment>
        );
    };

export default Home