import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getAppConfig as getAppConfigApi,

  addNewAppConfig as addNewAppConfigApi, getBranches as getBranchesApi,


} from "../../helpers/custom_helper/backend_helper";



export const addNewConfig = createAsyncThunk("save-config", async (config) => {
  try {
    const response = addNewAppConfigApi(config);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getAppConfig = createAsyncThunk("get-appconfig", async () => {
  try {
    const response = getAppConfigApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});
