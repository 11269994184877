import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import {
    getTranslationsForQuestion as getTranslationsForQuestionApi,
    removeQuestionAsset as removeQuestionAssetApi,
    addQuestionTranslation as addQuestionTranslationApi, getDataForQuestion as getDataForQuestionApi,
} from "../../helpers/custom_helper/backend_helper";
import {REMOVE_ASSET_ITEM_FOR_QUESTION} from "../../helpers/custom_helper/custom_url_helper";

export const addQuestionTranslation = createAsyncThunk("save-question-translation", async (question) => {
    try {
      const response = addQuestionTranslationApi(question);
      const data = await response;
      console.log(data);
        if(data.success)
        {
            toast.success(data.message, { autoClose: 3000 });
            return data;
        }else {
            toast.error(data.message, { autoClose: 3000 });
            return data
        }
    } catch (error) {
      toast.error(error, { autoClose: 3000 });
      return error;
    }
  }
);

export const getTranslationsForQuestion = createAsyncThunk("get-data-for-question", async (qid) => {
    try {
        const response = getTranslationsForQuestionApi(qid.id);
        const data = await response;
        if(data.success)
        {
            return data;
        }else {
            toast.error(data.message, { autoClose: 3000 });
        }
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
        return error;
    }

  });

export const resetFlagsTrenslation =createAsyncThunk("reset-tens-question", async (qid) => {
    try {
        return true;
    } catch (error) {
        toast.error(error, { autoClose: 3000 });
        // return error;
    }
});

export const removeQuestionAsset = createAsyncThunk("remove-question-asset", async (item) => {
        try {
            const response = removeQuestionAssetApi(item);
            const data = await response;
            if(data.success)
            {
                toast.success(data.message, { autoClose: 3000 });
                return data;
            }else {
                toast.error(data.message, { autoClose: 3000 });
                return data
            }
        } catch (error) {
            toast.error(error, { autoClose: 3000 });
            return error;
        }
    }
);