import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthorizedComponent from "../Routes/AuthorizedComponent";
import Appconfig from "../custom-component/config/Appconfig";

const Navdata = () => {

  const [permData, setPermData] = useState([])

  useEffect(() => {
    const storedArray = window.localStorage.getItem('permissions')
    if (storedArray) {
      setPermData(JSON.parse(storedArray))
    }
  }, [])

  const permArray = permData.map(perm => perm.name);

    const desiredPermissions = ['branch-view', 'room-view', 'system-view'];
    const desiredPermissionsSettings = ['config-create', 'language-view', 'role-view','user-view'];
    const resultPermissions = ['exam-result-view', 'practice-result-view'];
    const examPermissions = ['running-exam-view','exam-view'];

    const hasPermission = desiredPermissions.some(permission =>
        permArray.includes(permission)
    );
    const hasPermissionSettings = desiredPermissionsSettings.some(permission =>
        permArray.includes(permission)
    );

    const hasResultPermission = resultPermissions.some(permission =>
      permArray.includes(permission)
  );

  const hasExamPermissions= examPermissions.some(permission =>
    permArray.includes(permission)
);

  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isBranchManagement, setIsBranchManagement] = useState(false);
  const [isCourse, setIsCourse] = useState(false);
  const [isTopicArea, setIsTopicArea] = useState(false);
  const [isQuestionBank, setIsQuestionBank] = useState(false);
  const [isExam, setIsExam] = useState(false);
  const [isResult, setIsResult] = useState(false);
  const [isLogs, setIsLogs] = useState(false);



  // Pages
  const [isProfile, setIsProfile] = useState(false);
  const [isLanding, setIsLanding] = useState(false);


  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Branches") {
        setIsBranchManagement(false);
    }
    if (iscurrentState !== "Course") {
      setIsCourse(false);
    }
    if (iscurrentState !== "Topic-Area") {
      setIsTopicArea(false);
    }
    if (iscurrentState !== "Question-Bank") {
      setIsQuestionBank(false);
    }
    if (iscurrentState !== "result") {
      setIsResult(false);
    }

    if (iscurrentState !== "Exams") {
      setIsExam(false);
    }

    if (iscurrentState !== "Logs") {
      setIsLogs(false);
    }

  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
      isBranchManagement,
    isCourse,
      isTopicArea,
      isQuestionBank,
      isExam,
      isResult,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/admin/dashboard",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

permArray.includes('course-view') && (
    {
      id: "course",
      label: "Courses",
      icon: "ri-outlet-2-line",
      link: "/admin/course",

      click: function (e) {
        e.preventDefault();
        setIsCourse(!isCourse);
        setIscurrentState("Course");
        updateIconSidebar(e);
      },
    }
),


hasExamPermissions && ({
  id: "exam",
  label: "Exams/Practice",
  icon: "ri-outlet-2-line",
  link: "/#",
  click: function (e) {
    e.preventDefault();
    setIsExam(!isExam);
    setIscurrentState("Exams");
    updateIconSidebar(e);
  },

  
  stateVariables: isExam,
  subItems: [
    permArray.includes('exam-view') && (
    {
      id: "schedule",
      label: "Schedule Exams/Practice",
      link: "/admin/exam",
      parentId: "exam",
    }
    ),
    permArray.includes('running-exam-view') && (
    {
      id: "running-exam",
      label: "Running Exams/Practice",
      link: "/admin/running-exams",
      parentId: "exam",
    }
    ),


  ],
}),
  

hasResultPermission && ({

        id: "result",
        label: "Results",
        icon: "ri-file-paper-fill",
        link: "/#",
        click: function (e) {
          e.preventDefault();
          setIsResult(!isResult);
          setIscurrentState("result");
          updateIconSidebar(e);
        },
        stateVariables: isResult,
        subItems: [

          permArray.includes('exam-result-view') && (
          {
            id: "result",
            label: "Exam Result",
            link: "/admin/result",
            parentId: "result",
          }
          ),
          permArray.includes('practice-result-view') && (
          {
            id: "practice",
            label: "Practice Results",
            link: "/admin/practice-result",
            parentId: "result",
          }
          ),
      
  
        ],
      }),


    permArray.includes('topic-view') && (
        {
          id: "topic-area",
          label: "Topic Area",
          icon: "ri-command-fill",
          link: "/admin/topic-area",
          click: function (e) {
            e.preventDefault();
            setIsTopicArea(!isTopicArea);
            setIscurrentState("Topic-Area");
            updateIconSidebar(e);
          },
        }
    ),
    
    permArray.includes('q-view') && (
        {
          id: "question-bank",
          label: "Question Bank",
          icon: "ri-file-paper-fill",
          link: "/admin/question-bank",
          click: function (e) {
            e.preventDefault();
            setIsQuestionBank(!isQuestionBank);
            setIscurrentState("Question-Bank");
            updateIconSidebar(e);
          },
        }
    ),

    permArray.includes('mail-log-view') && (
      {
        id: "mail-log",
        label: "Sms/Email Log",
        icon: "ri-thunderstorms-line",
        link: "/admin/logs",
        click: function (e) {
          e.preventDefault();
          setIsLogs(!isLogs);
          setIscurrentState("Logs");
          updateIconSidebar(e);
        },
      }
  ),




      hasPermission && ({
      id: "branch-management",
      label: "Branch Management",
      icon: "ri-share-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsBranchManagement(!isBranchManagement);
        setIscurrentState("Branches");
        updateIconSidebar(e);
      },
      stateVariables: isBranchManagement,
      subItems: [
        permArray.includes('branch-view') && (
        {
          id: "branch",
          label: "Branches",
          link: "/admin/branch",
          parentId: "branch-management",
        }
        ),
        permArray.includes('room-view') && (
        {
          id: "room",
          label: "Rooms",
          link: "/admin/room",
          parentId: "branch-management",
        }
        ),
        permArray.includes('system-view') && (
        {
          id: "system",
          label: "Systems",
          link: "/admin/system",
          parentId: "branch-management",
        }
        ),

      ],
    }),
      hasPermissionSettings && ({
          id: "settings",
          label: "Settings",
          icon: "ri-apps-2-line",
          link: "/#",
          click: function (e) {
              e.preventDefault();
              setIsApps(!isApps);
              setIscurrentState("Apps");
              updateIconSidebar(e);
          },
          stateVariables: isApps,
          subItems: [
              permArray.includes('config-create') && (
                  {
                      id: "config",
                      label: "Configration",
                      link: "/admin/app-config",
                      parentId: "settings",
                  }
              ),
              permArray.includes('language-view') && (
                  {
                      id: "language",
                      label: "Languages",
                      link: "/admin/language",
                      parentId: "settings",
                  }
              ),
              permArray.includes('role-view') && (
                  {
                      id: "roles",
                      label: "Roles",
                      link: "/admin/roles",
                      parentId: "settings",
                  }
              ),
              permArray.includes('user-view') && (
                  {
                      id: "users",
                      label: "User Management",
                      link: "/admin/user",
                      parentId: "settings",
                  }
              ),

          ],
      })

  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
