import { createSlice } from "@reduxjs/toolkit";
import {   addCourseConfig,getCourseConfigs   } from './thunk';
export const initialState = {


    courseConfigs: [],
    courses: [],
    error: {},
};

const CourseConfigSlice = createSlice({
    name: 'CourseConfigSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getCourseConfigs.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
            state.courseConfigs = action.payload.data["course_config"];
            state.courses = action.payload.data;
            state.isCourseConfigCreated = false;
            state.isCourseConfigSuccess = true;
          }
        });

        builder.addCase(getCourseConfigs.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isCourseConfigCreated = false;
            state.isCourseConfigSuccess = false;
        });

        builder.addCase(addCourseConfig.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.isCourseConfigCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isCourseConfigCreated = false;
            }
        });

        builder.addCase(addCourseConfig.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isCourseConfigCreated = false;
        });



    }
});

export default CourseConfigSlice.reducer;