import React, { useState } from 'react'
import ExamIndex from './ExamIndex';
import ExamTemplate2Index from './template2/ExamTemplate2Index';

function ExamMainPage() {
 const [examTemplate,setExamTemplate]=useState(2);

  return (
<>
   <ExamIndex />
</>

  )
}

export default ExamMainPage