
import React, { useEffect } from 'react'
import { Card, Col, Container, Input, Label, Row, Button } from 'reactstrap';
import PagesLogoComponent from '../../../Components/Common/PagesLogoComponent';
import { Link } from 'react-router-dom';
import {
  questionSaveResetFlage as onQuestionSaveResetFlage,
  updateSystemAndExam as onUpdateSystemAndExam
} from '../../../slices/exam/thunk';
import { useDispatch } from 'react-redux';
import { clearTimer } from '../../utility/TimerUtility';


function ClearComponent() {

  const dispatch = useDispatch();

  const clearAllStorage = () => {


    const examPostedData = {
      exam_id: null,
      system_ip: window.localStorage.getItem('systemLocalIp')
    };
    dispatch(onUpdateSystemAndExam(examPostedData));

    localStorage.removeItem('StudentInformation');
    localStorage.removeItem('systemLocalIp');
    dispatch(onQuestionSaveResetFlage());
    localStorage.clear();
  }

  useEffect(() => {
    clearAllStorage();
    clearTimer()
  }, [1]);

  return (
    <>


      <React.Fragment>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
          <div className="bg-overlay"></div>
          <div className="auth-page-content overflow-hidden pt-lg-5">



            <PagesLogoComponent />
            <Container>
              <Row>
                <Col lg={12}>
                  <Card className="overflow-hidden">
                    <Row className="g-0">


                      <div className="p-lg-5 p-4">

                        <div>

                          <div className="alert border-0 alert-success text-center" role="alert">
                            <strong style={{ color: 'green' }}>unnecessary storage clear successfully </strong>
                          </div>
                        </div>

                        <div>


                          <div className="mt-4">
                            <div className="mt-4">
                              <Link to="/"><Button color="primary" className="w-100" type="submit">Back</Button></Link>
                            </div>
                          </div>
                        </div>


                      </div>


                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>



        </div>
      </React.Fragment>
    </>
  )
}

export default ClearComponent