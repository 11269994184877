import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import AuthorizeForAction from '../../../Routes/AuthorizeForAction';
import TableContainer from '../../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';
import MyLoader from '../../../common/MyLoader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ImageComponent from '../../../common/ImageComponent';
import { getAttemptHistory as onGetAttemptHistory } from '../../../slices/result/thunk';


function AttemptHistory() {
const dispatch = useDispatch();
const [attemptHistoryArray, setAttemptHistoryArray] = useState([]);
const [isLoading, setIsLoading] = useState(false);
const { t, i18n }  = useTranslation();
const {attemptId,type} = useParams();
const selectLayoutState = (state) => state.ResultReducer;

const attemptProperties = createSelector(
selectLayoutState,
(data) => ({
attemptHistory: data.attemptHistory,
isAttemptHistorySuccess:data.isAttemptHistorySuccess
})
);
const {attemptHistory,isAttemptHistorySuccess} = useSelector(attemptProperties)
useEffect(() => {
i18n.changeLanguage('en');
if (attemptHistory && attemptHistory.length > 0) {
setAttemptHistoryArray(attemptHistory);
} 
}, [attemptHistory]);
useEffect(() => {  
setIsLoading(!isAttemptHistorySuccess);
}, [isAttemptHistorySuccess]);
useEffect(() => {
const postedData = {
  exam_id:attemptId,
};
dispatch(onGetAttemptHistory(postedData));
}, [dispatch]);


document.title = "History|Theory Practice";
return (
<React.Fragment>
   <div className="page-content" dir='ltr'>
      <Container fluid>
         <Row>
            <Col lg={12}>
            <Card>
               <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                     <div className="col-sm">
                        <div>
                           <h5 className="card-title mb-0">Attempt History</h5>
                        </div>
                     </div>
                     <div className="col-sm-auto d-flex">
                        <div>
                           <div className="mt-3 mt-lg-0">
                              <div className="row g-3 mb-0 align-items-center">
                                 <div className="col-auto">
                                    <Link to='/admin/practice-result'>
                                    <Button type="button" color='success'>Back</Button></Link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Row>
               </CardHeader>
               <div className="card-body pt-0">
                  <div>
                     <div>
                        {
                        isLoading ?
                        <MyLoader />
                        : 
                        <table role="table" className="table table-hover">
                        <thead className="undefined table-light">
                          <tr role="row">
                            <th className="undefined sort">Question</th>
                            <th className="undefined sort">Chosen Answer</th>
                            <th className="undefined sort">Status</th>
                            <th className="undefined sort">Correct Answer</th>
                            <th className="undefined sort">Type</th>
                          </tr>
                        </thead>
                        {attemptHistory.length > 0
                          ? attemptHistory.map((row, index) => (
                              <React.Fragment key={index}>
                                <th role='cell'> <h5 className='text-success mt-2'>Date: {row.date}</h5></th>
                                <tbody role="rowgroup">
                                  {row.data.length > 0
                                    ? row.data.map((item, index) => (
                                        <tr key={index}>
                                          <td role="cell">{item.question}</td>
                                          <td role="cell">
                                            <div>
                                              <span>
                                            
                                                <ImageComponent
                                        choosedAns={item.choosed_ans}
                                        imagePath={item.choosedImage}
                                  />
                                                </span>
                                            </div>
                                          </td>
                                          <td role="cell">
                                          

                                            <span className={`text-uppercase ${item.text_class} fw-bold`}>
                                              {item.q_status}
                                            </span>
                                          </td>
                                          <td role="cell"><span>
            
                                        <ImageComponent
                                        choosedAns={item.correct_ans}
                                        imagePath={item.correctImage}
                                  />
                                            </span> </td>
                                          <td role="cell"><span>{item.q_type}</span> </td>
                                              

                                        </tr>
                                      ))
                                    : ''}
                                </tbody>
                              </React.Fragment>
                            ))
                          : ''}
                      </table>
                      
                        }
                     </div>
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
               </div>
            </Card>
            </Col>
         </Row>
      </Container>
   </div>
</React.Fragment>
)
}
export default AttemptHistory