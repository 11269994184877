import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getTopicAreas as getTopicAreasApi,
  addNewTopicArea as addNewTopicAreaApi,
  addTopicAreaTranslation as addTopicAreaTranslationApi,
  updateTopicArea as updateTopicAreaApi,
  deleteTopicArea as deleteTopicAreaApi,

} from "../../helpers/custom_helper/backend_helper";


export const updateTopicArea = createAsyncThunk("update-topic-area", async (topicArea) => {
  try {
    const response = addNewTopicAreaApi(topicArea);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const addNewTopicArea = createAsyncThunk("save-topic-area", async (topicArea) => {
  try {
    const response = addNewTopicAreaApi(topicArea);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const addTopicAreaTranslation = createAsyncThunk("save-topic-area-Translation", async (topicArea) => {
  try {
    const response = addTopicAreaTranslationApi(topicArea);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getTopicAreas = createAsyncThunk("get-topic-areas", async () => {
  try {
    const response = getTopicAreasApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const deleteTopicArea = createAsyncThunk("delete-topic-area", async (topicArea) => {
  try {
    const response = deleteTopicAreaApi(topicArea);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { topicArea, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});