import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

 
  getExamQuestions as  getQuestionForExamApi,
  saveExamQuestions as  saveExamQuestionsApi,
  savePracticeQuestions as  savePracticeQuestionsApi,
  checkPracticeType as  checkPracticeTypeApi,
  updateSystemmAndExam as updateSystemmAndExamApi,
  checkCourseQuestions as checkCourseQuestionsApi


} from "../../helpers/custom_helper/backend_helper";



export const getQuestions = createAsyncThunk("get-questions-for-exam", async (examPostedData) => {
  try {


    const response =await getQuestionForExamApi(examPostedData);
    console.log('response',response);
    if(response.success){
      return response;
    }else{
      console.log(response);
      return response.message;
    }
  
  } catch (error) {
    return error;
  }
});


export const saveQuestions = createAsyncThunk("save-questions-for-exam", async (postedData) => {
  try {

    const response =await saveExamQuestionsApi(postedData);

    if(response.success){
      return response;
    }else{
      toast.error(response.message, { autoClose: 3000 });
     // return response.data;
    }
  
  } catch (error) {
    return error;
  }
});

//save practice questions
export const savePracticeQuestions = createAsyncThunk("save-practice-for-exam", async (postedData) => {
  try {
    const response =await savePracticeQuestionsApi(postedData); 
    if(response.success){
      return response;
    }else{
      return response.data;
    }
  
  } catch (error) {
    return error;
  }
});

//save student info
export const saveStdInfo = createAsyncThunk("save-std-info", async (postedData) => {
  try {
   return postedData;
  
  } catch (error) {
    return error;
  }
});

//check-practice-type
export const checkPracticeType = createAsyncThunk("check-practice-type", async (postedData) => {
  try {


    const response =await checkPracticeTypeApi(postedData);
 
   
    if(response.success){
      return response;
    }else{
      return response.data;
    }
  
  } catch (error) {
    return error;
  }
});

//check course have questions?
export const checkCourseQuestion = createAsyncThunk("check-course-question", async (practiceData,{dispatch}) => {
  try {
    
    dispatch(resetAllData())
    const response =await checkCourseQuestionsApi(practiceData);
  
  
    if(response.success){
      return response;
    }else{
      return response.data;
    }
  
  } catch (error) {
    return error;
  }
});


export const resetFlags =createAsyncThunk("reset-states", async (qid) => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
   
  }
});

//Question Type
export const saveQuestionType = createAsyncThunk("question-type", async (questionType) => {
  try {
   return questionType;
  
  } catch (error) {
    return error;
  }
});

// Reset Question List
export const questionListResetFlag =createAsyncThunk("reset-q-list", async () => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
   
  }
});

// Reset Question List
export const questionSaveResetFlage =createAsyncThunk("reset-q-save", async () => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
   
  }
});

//update system and exam
export const updateSystemAndExam = createAsyncThunk("update-system-exam-status", async (postedData) => {
  try {
  
    const response =await updateSystemmAndExamApi(postedData); 

    if(response.success){
      return response;
    }else{
      return response.data;
    }
  
  } catch (error) {
    return error;
  }
});

export const resetAllData =createAsyncThunk("reset-all-data", async () => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
   
  }
});


export const resetPracticeType =createAsyncThunk("reset-practice-type", async () => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
   
  }
});











