// Language
export const ADD_NEW_LANGUAGE = "/save-lang";
export const GET_LANGUAGES = "/get-all-lang";
export const DELETE_LANGUAGE = "/delete-lang";
export const UPDATE_Language = "/update-lang";

//app Configuration
export const ADD_NEW_Config = "/save-config";
export const GET_CONFIG = "/get-config";

// User Roles
export const ADD_NEW_ROLE = "/save-role";
export const GET_ROLES = "/get-all-roles";
export const DELETE_ROLE = "/delete-role";
export const UPDATE_ROLE = "/update-role";

//Role Permissions
export const GET_MODULE_PERMISSIONS = "/get-all-permissions";
export const ADD_NEW_ROLE_PERMISSIONS = "/save-role-permissions";

// User Roles
export const ADD_NEW_USER = "/create-user";
export const GET_USERS = "/get-all-users";
export const DELETE_USER = "/delete-user";
export const UPDATE_USER = "/update-user";

//Course
export const ADD_NEW_COURSE = "/save-course";
export const ADD_COURSE_TRANSLATION = "/save-course-translation";
export const GET_COURSES = "/get-all-courses";
export const DELETE_COURSE = "/delete-course";
export const UPDATE_COURSE = "/update-course";

export const GET_COURSE_CONFIG = "/get-course-config";
export const ADD_COURSE_CONFIG = "/save-course-config";

//Topic Area
export const ADD_NEW_TOPIC_AREA = "/save-topic-area";
export const ADD_TOPIC_AREA_TRANSLATION = "/save-topic-area-translation";
export const GET_TOPIC_AREAS = "/get-all-topics";
export const DELETE_TOPIC_AREA = "/delete-topic";
export const UPDATE_TOPIC_AREA = "/update-topic-area";

export const GET_DATA_FOR_ADD_QUESTION = "/get-data-question";
export const GET_TRANSLATION_FOR_QUESTION = "/get-translation-question";
export const REMOVE_ASSET_ITEM_FOR_QUESTION = "/remove-q-asset";
export const ADD_TRANSLATION_FOR_QUESTION = "/save-question-translation";
export const ADD_NEW_QUESTION = "/create-questions";

export const GET_ALL_QUESTIONS = "/get-all-questions";
export const IMPORT_QUESTION = "/import-question";
export const DELETE_QUESTION = "/delete-question";


export const ADD_NEW_BRANCH = "/save-branch";
export const GET_BRANCHES = "/get-all-branches";
export const GET_BRANCHES_LIST = "/get-branches-list";
export const DELETE_BRANCH = "/delete-branch";
export const UPDATE_BRANCH = "/update-branch";


export const ADD_NEW_ROOM = "/save-room";
export const GET_ROOMS = "/get-all-rooms";
export const DELETE_ROOMS = "/delete-room";
export const UPDATE_ROOM = "/update-room";
export const GET_BRANCH_ROOMS = "/get-branch-rooms";

// Systems
export const GET_SYSTEMS = "/system-list";
export const CREATE_SYSTEM = "/system-create";
export const DELETE_SYSTEM = "/delete-system";
export const GET_ROOM_SYSTEMS = "/get-room-systems";
export const CHECK_SYSTEM_IP = "/check-system-ip";


// Exams
export const GET_EXAM_QUESTIONS = "/get-exam-questions";
export const SAVE_EXAM_QUESTIONS = "/save-exam-questions";
export const SAVE_PRACTICE_QUESTIONS = "/save-practice-questions";


// Result
export const GET_RESULT = "/get-result-detail";
export const GET_ALL_RESULT_LIST = "/get-all-results";
export const GET_STUDENT_RESULT = "/get-student-result";
export const GET_PRACTICE_RESULT = "/get-practice-result";
export const SAVE_RESULT_PDF = "/store-result-pdf";
export const GET_ATTEMPT_HISTORY = "/get-attempt-history";





// Schedule Exam
export const GET_SCHEDULE_EXAM_LIST = "/get-schedule-exam-list";
export const GET_BDC_STUDENT = "/get-bdc-std";
export const SAVE_SCHEDULE_EXAM = "/store-schedule-exam";
export const UPDATE_SCHEDULE_EXAM = "/update-schedule-exam";
export const DELETE_EXAM = "/delete-exam";
export const RESTART_EXAM = "/restart-exam";
export const EXIT_EXAM = "/exit-exam";
export const UPDATE_SYSTEM_AND_EXAM = "/exam-system-status-update";
export const CHEK_PRACTICE_TYPE = "/check-practice-type";
export const GET_RUNNING_EXAMS = "/get-running-exam";

//Check course question
export const CHECK_COURSE_QUESTIONS_AND_EXAM_ATTEMPT_INFO = "/get-exam-attempt-info";

//SMS Logs
export const GET_SMS_LOGS = "/get-sms-logs";
export const RESEND_NOTIFICATION = "/resend-notification";

export const MARK_ELIGIBLE = "/mark-eligible";
export const SYNC_EXAM = "/sync-exam";


export const TEST_DATA = "/test-data";

