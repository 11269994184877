// DataFetcher.js

import React, { useState, useEffect } from 'react';
import {
    getAppConfig as getAppConfigApi,
} from "../../helpers/custom_helper/backend_helper";
import {toast} from "react-toastify";
import { Navigate } from 'react-router-dom';

const DataFetcher = () => {
    const [data, setData] = useState(null);

   

    useEffect(() => {
        const fetchData = async () => {
            try {
               
                const response = getAppConfigApi();
                const result = await response;
                if(result.success)
                {
                    window.localStorage.setItem('apiData', JSON.stringify(result.data));
                }else {
                    
                    window.localStorage.removeItem('apiData')
                }
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);



    return (
       ""
    );
};

export default DataFetcher;
