
import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react'
import { Card, Button , CardHeader, Col, Container, Form, FormFeedback, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner  } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next'

import {
    checkPracticeType as onCheckPracticeType,
     resetFlags as onResetFlags,
     saveQuestionType as onSaveQuestionType,
     updateSystemAndExam as onUpdateSystemAndExam,
     checkCourseQuestion as onCheckCourseQuestionAndAttemptInfo,
     resetAllData as onResetAllData,
    resetPracticeType as onResetPracticeType

  } from '../../../slices/exam/thunk'
import { createSelector } from 'reselect';
import PagesLogoComponent from '../../../Components/Common/PagesLogoComponent';
import TimerComponent from '../timer/TimerComponent';
import Swal from 'sweetalert2';
import { isEmpty } from 'lodash';
import Loader from '../../../Components/Common/Loader';


function PracticeTypeScreen() {


    const [studentInfo, setStudentInfo] = useState([])
    const [dbAttemptId, setDbAttemptId] = useState('')
    const [practiceType, setPracticeType] = useState('')
    const [isStartPractice, setIsStartPractice] = useState('')
    const [courseConfigInfo, setCourseConfigInfo] = useState([])
    const [modal, setModal] = useState(false);

    const dispatch = useDispatch();
   
    const [isloading , setIsloading] = useState(false);
    const [examDuration , setExamDuration] = useState(0);
    const [isOpenModal , setIsOpenModal] = useState(false);
    const [qLang, setQLang] = useState('en');
    const [direction, setDirection] = useState('ltr');
    const [isloadingPracticeType , setIsloadingPracticeType] = useState(true);

    const [isSpecific , setIsSpecific] = useState(0);
    const [isCommon , setIsCommon] = useState(0);
    const [isVideo , setIsVideo] = useState(0);


    const navigate = useNavigate()
    const { t, i18n }  = useTranslation();
   
    const selectExamState = (state) => state.ExamReducer;


    useEffect(() => {
        const studentDataFromLocalStorage = localStorage.getItem('StudentInformation');
         setStudentInfo(studentDataFromLocalStorage ? JSON.parse(studentDataFromLocalStorage) : null);
         setIsStartPractice(window.localStorage.getItem('isStart'));
    },[]);


    useEffect(() => {
        if(!isEmpty(studentInfo)){
            const practiceData = {
                exam_id: studentInfo  && studentInfo.examId,
                course_id:studentInfo && studentInfo.courseId,
              };
           
            dispatch(onCheckCourseQuestionAndAttemptInfo(practiceData));
        }
    
    },[studentInfo]);


    const handlePracticeType=(practiceType)=>{
        dispatch(onResetPracticeType());
        setIsOpenModal(true);
        setPracticeType(practiceType);
    
    const practiceData = {
        std_id: studentInfo  && studentInfo.stdId,
        practice_type:practiceType,
        exam_id:studentInfo  && studentInfo.examId,

      };


    dispatch(onCheckPracticeType(practiceData));
    }

    useEffect(() => {
        if(studentInfo.qLangShortName!=undefined){
            i18n.changeLanguage(studentInfo.qLangShortName)
            setDirection(studentInfo.direction)
        }
     
    },[studentInfo]);


    const examProperties = createSelector(
     
        selectExamState,
        (data) => ({
            attemptId: data.attemptId, 
            isCheckPracticeType: data.isCheckPracticeType,
        })
      );

      const {attemptId,isCheckPracticeType} = useSelector(examProperties)

      
      useEffect(() => {
       
                setDbAttemptId(attemptId);
                if(attemptId ==404){
                    handleNavigation(1);
                }
        
                if(attemptId > 0 ){
                setIsOpenModal(true);
                }else{
                    setIsOpenModal(false);
                }
            }, [attemptId]);

      const courseProperties = createSelector(
        selectExamState,
        (data) => ({
            courseConfig: data.courseConfig, 
            isSuccess:data.isSuccess
        })
      );


      const {courseConfig,isSuccess } = useSelector(courseProperties)

      useEffect(() => {
       
        setIsloading(!isSuccess);
        }, [isSuccess]);

      useEffect(() => {
   
        setCourseConfigInfo(courseConfig);
        setIsSpecific(courseConfig.isEnableSpecific);
        setIsCommon(courseConfig.isEnableCommon);
        setIsVideo(courseConfig.isEnableVideo);
    }, [courseConfig]);

    useEffect(() => {
 
        setIsloadingPracticeType(!isCheckPracticeType);
        }, [isCheckPracticeType]);



    const toggle = useCallback(() => {
        dispatch(onResetFlags());
        if (isOpenModal) {
          setIsOpenModal(false);
    
        } else {
            setIsOpenModal(true);
        }
      }, [isOpenModal]);

 


      const handleNavigation=(navType)=>{
      
            dispatch(onSaveQuestionType(practiceType));
        if(navType==1){
         
            dispatch(onResetFlags());
        }
        setIsOpenModal(false)
        navigate('/theory-practice')
      }

      const handleClose = (closeType) => {

        const systemLocalIp = localStorage.getItem('systemLocalIp');
        const studentDataFromLocalStorage =localStorage.getItem('StudentInformation');
        const stdInfo=JSON.parse(studentDataFromLocalStorage);
   
        const examPostedData = {
          exam_id:stdInfo.examId,
          system_ip:systemLocalIp,
          closeType:closeType
        };

        Swal.fire({
          text: t('Are you sure you want to exit?'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#F1416C',
          cancelButtonColor: '#181C32',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
        }).then((result) => {
          if (result.isConfirmed) {

            dispatch(onUpdateSystemAndExam(examPostedData));
             navigate('/student')
          }
        })
      };

    return (
        <React.Fragment>
            <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100" dir={direction}>
                <div className="bg-overlay"></div>
                <div className="auth-page-content overflow-hidden pt-lg-1">
                    <PagesLogoComponent />
                    <Container>

                    <div className="justify-content-center row">
                    {/* <div>
                 
                    
                        <h5 className="text-primary">Practice Type {dbAttemptId}</h5>
                        </div> */}
                        {isStartPractice?
                        <div className="col-sm-3 justify-content-center row">
                        <TimerComponent 
                         duration={studentInfo.examDuration}
                         handleTimeUp={'abc'} 
                         examType={2}
                         pageType={1}                     
                        />
                        </div>
                        :''}

{isloading?
                        <Loader />
                        :
                        <>
                            <div className='row mt-5'>
                            
                                <div className="col-xxl-4 col-lg-6">
                                    <div className="card card-body">
                                        <div className="avatar-sm mb-3">
                                            <div className="avatar-title bg-primary-subtle text-primary fs-36 rounded">
                                                <i className=" ri-traffic-light-line"></i>
                                            </div>
                                        </div>
                                        <h4 className="card-title">{t('specific')}</h4>
                                        <p className="card-text text-muted">{t('specific_text')}</p>
                                     
                                        <Button color="primary" className="w-100" type="button" onClick={()=>handlePracticeType(1)}  disabled={isSpecific > 0 ? false : true}>{t('Start Now')}</Button>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6">
                                    <div className="card card-body text-center">
                                        <div className="avatar-sm mx-auto mb-3">
                                            <div className="avatar-title bg-primary-subtle text-primary fs-36 rounded">
                                                <i className="ri-roadster-line"></i>
                                            </div>
                                        </div>
                                        <h4 className="card-title">{t('common')}</h4>
                                        <p className="card-text text-muted">{t('common_text')}</p>
                                     
                                        
                                        <Button color="primary" className="w-100" type="submit" onClick={()=>handlePracticeType(2)}  disabled={isCommon > 0 ? false : true}>{t('Start Now')}</Button>
                                    </div>
                                </div>
                                <div className="col-xxl-4 col-lg-6">
                                    <div className="card card-body text-end">
                                        <div className="avatar-sm ms-auto mb-3">
                                            <div className="avatar-title bg-primary-subtle text-primary fs-36 rounded">
                                                <i className="ri-video-line"></i>
                                            </div>
                                        </div>
                                        <h4 className="card-title">{t('video')}</h4>
                                        <p className="card-text text-muted">{t('video_text')}</p>
                                     
                                        <Button color="primary" className="w-100" type="submit" onClick={()=>handlePracticeType(3)} disabled={ isVideo > 0 ? false : true}>{t('Start Now')}</Button>
                                    </div>
                                </div>
                                </div>
                                {
                                  isSpecific==0 && isCommon==0 && isVideo==0?
                                <Button className='btn-lg w-100'  onClick={() => {handleClose(3)}}>{t('close')}</Button>
                                :
                                <Button className='btn-lg w-100'  onClick={() => {handleClose(5)}}>{t('Exit')}</Button>
}
                                </>
}

                          
                             
                            </div>
                   
                    </Container>
                </div>



            </div>


            <Modal id="showModal" dir={direction}  isOpen={isOpenModal} backdrop="static" centered className='modal modal-lg'>
        {/* <ModalHeader className="bg-light p-3" toggle={toggle}>{t('Practice Type')} </ModalHeader> */}

   
{isloadingPracticeType? 
<Loader/>
:
<>
          <ModalBody>
        

          <div className='row mb-3'>
              <div className='col-md-8'>
              <p> {t('Your questions are pending , if you want to solve previous questions then click on resume button')}</p>
              </div>
          
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="hstack gap-2 justify-content-end">
           
            {/* <Button color="primary" disabled={isloading ? true : false} className="btn-load" onClick={()=>handleNavigation(1)}>
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 ms-2"> {t('New Start')} </span>
                </span>
              </Button> */}

           {dbAttemptId > 0 && dbAttemptId!=404?
            <Button color="success" disabled={isloading ? true : false} className="btn-load"  onClick={()=>handleNavigation(2)} >
                <span className="d-flex align-items-center">
                  <span className="flex-grow-1 ms-2"> {t('Resume')}</span>
                   
                 
                </span>
              </Button>
            
              :''}
            <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  toggle();
                 
                }}
              >
             
                Close{" "}
            </button>
            </div>
          </ModalFooter>
          </>
          }
       
        
      </Modal>
        </React.Fragment>
    );
}

export default PracticeTypeScreen