import { createSlice } from "@reduxjs/toolkit";

import {getTestData} from './thunk';

export const initialState = {
    trafficIdList:'',
 

};

const SystemSlice = createSlice({
    name: 'TestDataSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {

          //get questions for exam
        builder.addCase(getTestData.fulfilled, (state, action) => {
             state.trafficIdList = action.payload.data;
      
        });

        builder.addCase(getTestData.rejected, (state, action) => {
            state.error = action.payload.success || null;
            state.isQuestionSuccess = false;
            state.isLoading = true;
        });




    }
});

export default SystemSlice.reducer;