import React,{useEffect, useState} from "react";
import { Navigate } from "react-router-dom";

//Dashboard

import DashboardEcommerce from "../pages/DashboardEcommerce";



//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import CoverSignIn from "../pages/AuthenticationInner/Login/CoverSignIn";



// User Profile
import UserProfile from "../pages/Authentication/user-profile";



//custom
import Appconfig from "../custom-component/config/Appconfig";
import Language from "../custom-component/language/Language";
import Roles from "../custom-component/roles/Roles";
import RolePermission from "../custom-component/rolePermissions/RolePermissions";
import User from "../custom-component/user/User";
import Course from "../custom-component/course/Course";
import CourseConfig from "../custom-component/course/CourseConfig";
import TopicArea from "../custom-component/topicArea/TopicArea";
import QuestionBank from "../custom-component/questionBank/QuestionBank";
import CreateQuestion from "../custom-component/questionBank/CreateQuestion";
import QuestionTranslation from "../custom-component/questionBank/QuestionTranslation";

import Branch from "../custom-component/branch/Branch";
import Room from "../custom-component/room/Room";
import System from "../custom-component/system/System";
import Result from "../custom-component/result/Result";




import AuthorizedComponent from "../Routes/AuthorizedComponent";
import Home from "../custom-component/studentPanel/home/Home";
import ExamIndex from "../custom-component/studentPanel/exam/ExamIndex";
import ExamSchedule from "../custom-component/exam/ExamSchedule";


import MyPusher from "../custom-component/studentPanel/home/MyPusher";
import SystemStart from "../custom-component/studentPanel/home/SystemStart";
import StudentResults from "../custom-component/studentPanel/results/StudentResults";
import InstructionScreen from "../custom-component/studentPanel/home/InstructionScreen";
import PracticeTypeScreen from "../custom-component/studentPanel/home/PracticeTypeScreen";

import ClearComponent from "../custom-component/studentPanel/home/ClearComponent";
import ResultDetail from "../custom-component/studentPanel/results/ResultDetail";
import TestData from "../custom-component/studentPanel/home/TestData";
import PracticeResult from "../custom-component/studentPanel/results/PracticeResult";
import RunningExams from "../custom-component/exam/examTimer/RunningExams";
import ExamMainPage from "../custom-component/studentPanel/exam/ExamMainPage";
import SmsLogs from "../custom-component/logs/SmsLogs";
import AttemptHistory from "../custom-component/studentPanel/results/AttemptHistory";



const authProtectedRoutes = [

  { path: "/admin/dashboard", component: <DashboardEcommerce /> },
  { path: "/admin/index", component: <DashboardEcommerce /> },


    //custom routes
  { path: "/admin/app-config", component:<AuthorizedComponent requiredPermissions={['config-create']} > <Appconfig /> </AuthorizedComponent>   },
  { path: "/admin/language", component: <AuthorizedComponent requiredPermissions={['language-view']} > <Language /> </AuthorizedComponent>  },
  { path: "/admin/roles", component: <AuthorizedComponent requiredPermissions={['role-view']} > <Roles /> </AuthorizedComponent>   },
  { path: "/admin/role-permissions/:id", component:  <AuthorizedComponent requiredPermissions={['role-create','role-view']} > <RolePermission /> </AuthorizedComponent>  },
  { path: "/admin/user", component: <AuthorizedComponent requiredPermissions={['user-view']} > <User /> </AuthorizedComponent> },
  { path: "/admin/course", component: <AuthorizedComponent requiredPermissions={['course-view']} > <Course /> </AuthorizedComponent> },
  { path: "/admin/course-config/:id", component: <AuthorizedComponent requiredPermissions={['course-view']} > <CourseConfig /> </AuthorizedComponent> },
  { path: "/admin/topic-area", component: <AuthorizedComponent requiredPermissions={['topic-view']} > <TopicArea /> </AuthorizedComponent> },
  { path: "/admin/question-bank", component: <AuthorizedComponent requiredPermissions={['q-view']} > <QuestionBank /> </AuthorizedComponent> },
  { path: "/admin/create-question/:id", component: <AuthorizedComponent requiredPermissions={['q-create']} > <CreateQuestion  /> </AuthorizedComponent> }, 
  { path: "/admin/question-translation/:id", component: <AuthorizedComponent requiredPermissions={['q-translate']} > <QuestionTranslation  /> </AuthorizedComponent> },
  { path: "/admin/branch", component: <AuthorizedComponent requiredPermissions={['branch-view']} > <Branch /> </AuthorizedComponent> },
  { path: "/admin/room", component: <AuthorizedComponent requiredPermissions={['room-view']} > <Room /> </AuthorizedComponent> },
  { path: "/admin/system", component: <AuthorizedComponent requiredPermissions={['system-view']} > <System /> </AuthorizedComponent> },
  { path: "/admin/exam", component: <AuthorizedComponent requiredPermissions={['exam-view']} > <ExamSchedule /> </AuthorizedComponent> },
  { path: "/admin/running-exams", component: <AuthorizedComponent requiredPermissions={['running-exam-view']} > <RunningExams /> </AuthorizedComponent> },
  { path: "/admin/result", component: <AuthorizedComponent requiredPermissions={['exam-result-view']} > <Result /> </AuthorizedComponent> },
  { path: "/admin/result-detail/:attemptId/:type",  exact: true,component: <ResultDetail />},

  { path: "/admin/practice-result",  exact: true,component: <AuthorizedComponent requiredPermissions={['practice-result-view']}> <PracticeResult /> </AuthorizedComponent>},
  { path: "/admin/attempt-history/:attemptId",  exact: true,component: <AuthorizedComponent requiredPermissions={['practice-result-view']}> <AttemptHistory /> </AuthorizedComponent>},
  { path: "/admin/logs",  exact: true,component: <AuthorizedComponent requiredPermissions={['mail-log-view']}> <SmsLogs /> </AuthorizedComponent>},

 
    
   

  //User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/admin/dashboard" />,
  },
  { path: "/admin/*", component: <Navigate to="/admin/dashboard" /> },
];

const publicRoutes = [

  {
    path: "/admin",
    exact: true,
    component: <Navigate to="/admin/login" />,
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/system-start" />,
  },

  //exam section
  {
    path: "/student",
    exact: true,
    component: <Home />,
  },

  {
    path: "/theory-practice",
    exact: true,
    component: <ExamMainPage />,
  },


  {
    path: "/student-result/:examid/:type",
    exact: true,
    component: <StudentResults />,
  },

  {
    path: "/practice-type",
    exact: true,
    component: <PracticeTypeScreen />,
  },

 

  {
    path: "/pusher-test",
    exact: true,
    component: <MyPusher />,
  },


  {
    path: "/test",
    exact: true,
    component: <TestData />,
  },

  


  {
    path: "/system-start",
    exact: true,
    component: <SystemStart />,
  },
  {
    path: "/instructions",
    exact: true,
    component: <InstructionScreen />,
  },

  {
    path: "/clear",
    exact: true,
    component: <ClearComponent />,
  },





  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <CoverSignIn /> },
  { path: "/admin/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },

 





];






export { authProtectedRoutes, publicRoutes };