import { createSlice } from "@reduxjs/toolkit";
import { getCourses,  addNewCourse,addCourseTranslation,updateCourse,  deleteCourse } from './thunk';

export const initialState = {
    courses: [],
    langs: [],
    error: {},


};

const CourceSlice = createSlice({
    name: 'CourceSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getCourses.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
       
            state.courses = action.payload.data['courses']['data'];
            state.langs = action.payload.data['langs']['data'];
            state.isCourseCreated = false;
            state.isCourseSuccess = true;
            }else {
                state.error = action.payload.data || null;
                state.isCourseSuccess = false;
            }
        });

        builder.addCase(getCourses.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isCourseCreated = false;
            state.isCourseSuccess = false;
        });

        builder.addCase(addNewCourse.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.courses.push(action.payload.data);
                state.isCourseCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isCourseCreated = false;
            }
        });

        builder.addCase(addNewCourse.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isCourseCreated = false;
        });
        builder.addCase(addCourseTranslation.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
                state.courses = state.courses.map(course =>
                    course.id === action.payload.data.id
                        ? {...course, ...action.payload.data}
                        : course
                );
                state.isCourseCreated = true;
            }else {
            state.error = action.payload.data || null;
            state.isCourseCreated = false;
             }
        });

        builder.addCase(addCourseTranslation.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(updateCourse.fulfilled, (state, action) => {
            if(action.payload.success) {
                state.courses = state.courses.map(course =>
                    course.id === action.payload.data.id
                        ? {...course, ...action.payload.data}
                        : course
                );
                state.isCourseCreated = true;
                state.isSaveLoading = false;
            } else {
            state.error = action.payload.data || null;
            state.isCourseCreated = false;
            state.isSaveLoading = true;
             }

        });
        builder.addCase(updateCourse.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(deleteCourse.fulfilled, (state, action) => {
            state.courses = state.courses.filter(
                course => course.id.toString() !== action.payload.course.toString()
            );
        });
        builder.addCase(deleteCourse.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


    }
});

export default CourceSlice.reducer;