import React, {useEffect, useState} from "react";
import UiContent from "../../Components/Common/UiContent";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    InputGroup,
    Label,
    Row,
    Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addNewConfig as onAddNewConfig,
    getAppConfig as onGetAppConfig,

} from "../../slices/appConfig/thunk";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {toast, ToastContainer} from "react-toastify";

import {isEmpty} from "lodash";
import Loader from "../../Components/Common/Loader";
import { api } from "../../config";
import {Link} from "react-router-dom";


const Appconfig = () => {

    const dispatch = useDispatch();
    const [appConfig, setAppConfig] = useState([]);
    const [test, setTest] = useState("");
    const imageUrl = api.IMAGE_URL;


    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);


    const selectLayoutState = (state) => state.AppConfig;
    const appConfigProperties = createSelector(
        selectLayoutState,
        (app) => ({
            appConfigs: app.appconfigs,
            isAppConfigSuccess: app.isAppConfigSuccess,
            isAppConfigCreated: app.isAppConfigCreated,
            error: app.error,
        })
    );
    // Inside your component
    const {
        appConfigs, isAppConfigSuccess, error,isAppConfigCreated
    } = useSelector(appConfigProperties)


    useEffect(() => {
        // if (appConfigs && !appConfigs.length) {
            dispatch(onGetAppConfig());
            setTest("new");
        // }
    }, [dispatch,isAppConfigSuccess,isAppConfigCreated,error]);


    useEffect(() => {
        setAppConfig(appConfigs);
        setLoading(!isAppConfigSuccess);
        if(isAppConfigCreated){
            setIsloading(false);
        }
        error? setIsloading(false):null
    }, [appConfigs,isAppConfigSuccess,isAppConfigCreated,error]);

    useEffect(() => {
        if (!isEmpty(appConfigs)) {
            setAppConfig(appConfigs);
        }
    }, [appConfigs,isAppConfigSuccess,isAppConfigCreated,error]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            id: (appConfig && appConfig.id) || '',
            title: (appConfig && appConfig.title) || '',
            logo: (appConfig && appConfig.logo) || '',
            result_logo: (appConfig && appConfig.result_logo) || '',
            enable_email: (appConfig && appConfig.enable_email == 1 ? true : false) || false,
            e_host: (appConfig && appConfig.e_host) || '',
            e_user_name: (appConfig && appConfig.e_user_name) || '',
            e_password: (appConfig && appConfig.e_password) || '',
            e_port: (appConfig && appConfig.e_port) || '',
            smtp_secure: (appConfig && appConfig.smtp_secure) || '',
            email_template: (appConfig && appConfig.email_template) || '',
            enable_sms: (appConfig && appConfig.enable_sms == 1 ? true : false) || false,
            sms_template: (appConfig && appConfig.sms_template) || '',

        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title"),
            // logo: Yup.string().required("Please Enter Logo"),
        }),
        onSubmit: (values) => {
            setIsloading(true);
            const newAppConfig = {
                id: values["id"] ? values["id"] : "",
                title: values["title"],
                logo: values["logo"],
                result_logo: values["result_logo"],
                enable_email: values["enable_email"] && values["enable_email"] ? 1 : 0,
                e_host: values["e_host"],
                e_user_name: values["e_user_name"],
                e_password: values["e_password"],
                e_port: values["e_port"],
                smtp_secure: values["smtp_secure"],
                email_template: values["email_template"],
                enable_sms: values["enable_sms"] && values["enable_sms"] ? 1 : 0,
                sms_template: values["sms_template"],

            };
            console.log(newAppConfig);
            dispatch(onAddNewConfig(newAppConfig));

        },
    });


    document.title = "App Configuration | Theory Practice";
    return (
        <React.Fragment>
            <UiContent/>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="App Configuration" pageTitle="Settings"/>
                    <Row>
                        <Col xxl={12}>
                            <Card>
                                <PreviewCardHeader title="General Settings"/>
                                <CardBody>
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                            {/*{toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}*/}
                                        </React.Fragment>
                                        :<div>
                               {isAppConfigSuccess ? (
                                    <div className="">
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <input type="hidden" id="id" value={validation.values.id || ""}/>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="applogo" className="form-label">Application
                                                        Logo</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="file" className="form-control" id="applogo" placeholder="Enter your Application Logo" />*/}
                                                    <div className="text-left">
                                                        <div
                                                            className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                            <img src={appConfig? imageUrl + appConfig.logo:avatar1}
                                                                 className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                                 alt="user-profile"/>
                                                            <div
                                                                className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                                <Input
                                                                    id="profile-img-file-input"
                                                                    type="file"
                                                                    name="logo"
                                                                    className="profile-img-file-input"
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={(event) => {
                                                                        validation.setFieldValue('logo', event.currentTarget.files[0]);
                                                                    }}
                                                                    onBlur={validation.handleBlur}

                                                                    invalid={
                                                                        validation.touched.logo && validation.errors.logo ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.logo && validation.errors.logo ? (
                                                                    <FormFeedback type="invalid">{validation.errors.logo}</FormFeedback>
                                                                ) : null}
                                                                <Label htmlFor="profile-img-file-input"
                                                                       className="profile-photo-edit avatar-xs">
                                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                                            <i className="ri-camera-fill"></i>
                                                                        </span>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="nameInput" className="form-label">Application
                                                        Title</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="text" className="form-control" id="nameInput" placeholder="Enter your Application Title" />*/}
                                                    <Input
                                                        name="title"
                                                        id="title-field"
                                                        className="form-control"
                                                        placeholder="Enter your Application Title"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.title || ""}
                                                        invalid={
                                                            validation.touched.title && validation.errors.title ? true : false
                                                        }
                                                    />
                                                    {validation.touched.title && validation.errors.title ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.title}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <h5>Email Configuration</h5>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="enableEmail" className="form-label">Enable
                                                        Email</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    <Col lg={4} md={6}>
                                                        <div className="">
                                                            <div className="form-check form-switch form-switch-md mb-3"
                                                                 dir="ltr">
                                                                {/*<Input type="checkbox" className="form-check-input" id="enableEmail"/>*/}

                                                                <Input
                                                                    name="enable_email"
                                                                    id="enableEmail-field"
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={validation.values.enable_email}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                />
                                                                {validation.touched.enable_email && validation.errors.enable_email ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.enable_email}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="emailHost" className="form-label">Email Host</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="text" className="form-control" id="emailHost" placeholder="Enter your Email Host" />*/}
                                                    <Input
                                                        name="e_host"
                                                        id="e_host-field"
                                                        className="form-control"
                                                        placeholder="Enter your Email Host"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.e_host || ""}
                                                        invalid={
                                                            validation.touched.e_host && validation.errors.e_host ? true : false
                                                        }
                                                    />
                                                    {validation.touched.e_host && validation.errors.e_host ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.e_host}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="emailUsername" className="form-label">Email
                                                        Username</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="email" className="form-control" id="emailUsername" placeholder="Enter your Email Username" />*/}
                                                    <Input
                                                        name="e_user_name"
                                                        id="e_user_name-field"
                                                        className="form-control"
                                                        placeholder="Enter your Email Username"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.e_user_name || ""}
                                                        invalid={
                                                            validation.touched.e_user_name && validation.errors.e_user_name ? true : false
                                                        }
                                                    />
                                                    {validation.touched.e_user_name && validation.errors.e_user_name ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.e_user_name}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="emailPassword" className="form-label">Email
                                                        Password</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="password" className="form-control" id="emailPassword" placeholder="Enter your Email Password" />*/}
                                                    <Input
                                                        name="e_password"
                                                        id="e_password-field"
                                                        className="form-control"
                                                        placeholder="Enter your Email Password"
                                                        type="password"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.e_password || ""}
                                                        invalid={
                                                            validation.touched.e_password && validation.errors.e_password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.e_password && validation.errors.e_password ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.e_password}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="emailport" className="form-label">Email Sender
                                                        Port</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="number" className="form-control" id="emailport" placeholder="Enter your Email Sender Port" />*/}
                                                    <Input
                                                        name="e_port"
                                                        id="e_port-field"
                                                        className="form-control"
                                                        placeholder="Enter your Email Sender Port"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.e_port || ""}
                                                        invalid={
                                                            validation.touched.e_port && validation.errors.e_port ? true : false
                                                        }
                                                    />
                                                    {validation.touched.e_port && validation.errors.e_port ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.e_port}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="smtp" className="form-label">SMTP Secure</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<Input type="text" className="form-control" id="smtp" placeholder="Enter your SMTP Secure" />*/}
                                                    <Input
                                                        name="smtp_secure"
                                                        id="smtp_secure-field"
                                                        className="form-control"
                                                        placeholder="Enter your Email  SMTP Secure"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.smtp_secure || ""}
                                                        invalid={
                                                            validation.touched.smtp_secure && validation.errors.smtp_secure ? true : false
                                                        }
                                                    />
                                                    {validation.touched.smtp_secure && validation.errors.smtp_secure ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.smtp_secure}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="emailTemplate" className="form-label">Email
                                                        Template</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<textarea  className="form-control" rows="3" id="emailTemplate" placeholder="Enter your Email Template" />*/}
                                                    <textarea
                                                        name="email_template"
                                                        id="email_template-field"
                                                        className="form-control"
                                                        rows="3"
                                                        placeholder="Enter your Email Template"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email_template || ""}

                                                    />
                                                    {validation.touched.email_template && validation.errors.email_template ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.email_template}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <h5>SMS Configuration</h5>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="enableSMS" className="form-label">Enable SMS</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    <Col lg={4} md={6}>
                                                        <div className="">
                                                            <div className="form-check form-switch form-switch-md mb-3"
                                                                 dir="ltr">
                                                                {/*<Input type="checkbox" className="form-check-input" id="enableSMS"/>*/}
                                                                <Input
                                                                    name="enable_sms"
                                                                    id="enable_sms-field"
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    checked={validation.values.enable_sms}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                />
                                                                {validation.touched.enable_sms && validation.errors.enable_sms ? (
                                                                    <FormFeedback
                                                                        type="invalid">{validation.errors.enable_sms}</FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="smsTemplate" className="form-label">SMS
                                                        Template</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    {/*<textarea  className="form-control" rows="3" id="smsTemplate" placeholder="Enter your SMS Template" />*/}
                                                    <textarea
                                                        name="sms_template"
                                                        id="sms_template-field"
                                                        className="form-control"
                                                        rows="3"
                                                        placeholder="Enter your SMS Template"
                                                        type="text"
                                                        validate={{
                                                            required: {value: true},
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.sms_template || ""}

                                                    />
                                                    {validation.touched.sms_template && validation.errors.sms_template ? (
                                                        <FormFeedback
                                                            type="invalid">{validation.errors.sms_template}</FormFeedback>
                                                    ) : null}
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col lg={3}>
                                                    <Label htmlFor="reslogo" className="form-label">Result Page
                                                        Logo</Label>
                                                </Col>
                                                <Col lg={9}>
                                                    <div className="text-left">
                                                        <div
                                                            className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                            <img src={appConfig? imageUrl + appConfig.result_logo:avatar1}
                                                                 className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                                 alt="user-profile"/>
                                                            <div
                                                                className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                                <Input
                                                                    id="res-img-file-input"
                                                                    type="file"
                                                                    name="result_logo"
                                                                    className="profile-img-file-input"
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={(event) => {
                                                                        validation.setFieldValue('result_logo', event.currentTarget.files[0]);
                                                                    }}
                                                                    onBlur={validation.handleBlur}

                                                                    invalid={
                                                                        validation.touched.result_logo && validation.errors.result_logo ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.result_logo && validation.errors.result_logo ? (
                                                                    <FormFeedback type="invalid">{validation.errors.result_logo}</FormFeedback>
                                                                ) : null}
                                                                <Label htmlFor="res-img-file-input"
                                                                       className="profile-photo-edit avatar-xs">
                                                                        <span className="avatar-title rounded-circle bg-light text-body">
                                                                            <i className="ri-camera-fill"></i>
                                                                        </span>
                                                                </Label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="text-end">
                                                <Button color="primary" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                              <span className="d-flex align-items-center">
                                                                  {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                                  <span className="flex-grow-1 ms-2">
                                                                    Save Changes
                                                                  </span>
                                                              </span>
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                    ) : (
                                                <div className="py-4 text-center">
                                                    <div>
                                                        <lord-icon
                                                            src="https://cdn.lordicon.com/msoeawqm.json"
                                                            trigger="loop"
                                                            colors="primary:#405189,secondary:#0ab39c"
                                                            style={{width: "72px", height: "72px"}}
                                                        ></lord-icon>
                                                    </div>

                                                    <div className="mt-4">
                                                        <h5>Sorry! No Record Found</h5>
                                                    </div>
                                                </div>
                               )}
                                            </div>
                                    )}

                                    <ToastContainer closeButton={false} limit={1}/>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );

};

export default Appconfig;

