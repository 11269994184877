import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getDataForQuestion as getDataForQuestionApi,
  addNewQuestion as addNewQuestionApi, getBranches as getBranchesApi,


} from "../../helpers/custom_helper/backend_helper";

export const RESET_FLAGS = 'RESET_FLAGS';




export const addNewQuestion = createAsyncThunk("save-question", async (question) => {
  try {
    const response = addNewQuestionApi(question);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getDataForQuestion = createAsyncThunk("get-data-for-question", async (qid) => {
  try {
    const response = getDataForQuestionApi(qid);
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const resetFlags =createAsyncThunk("reset-question", async (qid) => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    // return error;
  }
});
