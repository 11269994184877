import { createSlice } from "@reduxjs/toolkit";
import {addQuestionTranslation, getTranslationsForQuestion, resetFlagsTrenslation,removeQuestionAsset} from "./thunk";
export const initialState = {
  languages: [],
  Questions: [],
  QuestionTranslations: [],
  courses: [],
  topicAreas: [],
  error: {},
};

const TranslateQuestionSlice = createSlice({
  name: "TranslateQuestionSlice",
  initialState,
  reducer: {},
  extraReducers: (builder) => {
    builder.addCase(getTranslationsForQuestion.fulfilled, (state, action) => {
      if(action.payload && action.payload.success)
      {
        if(action.payload.data && action.payload.data["question"])
        {
          action.payload.data["question"].data
          ? (state.Questions = action.payload.data["question"].data)
          : "";
        action.payload.data["question"].data
          ? (state.QuestionTranslations =
              action.payload.data["question"].data["question_translations"])
          : (state.QuestionTranslations = "");
        }
      state.languages = action.payload.data["langs"]['data'];
      state.courses = action.payload.data["courses"]['data'];
      state.topicAreas = action.payload.data["topicAreas"]['data'];
      state.isAddQuestionCreated = false;
      state.isAddQuestionSuccess = true;
      } else {
        state.isAddQuestionSuccess = false;
      }
     
    });

    builder.addCase(getTranslationsForQuestion.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isAddQuestionCreated = false;
      state.isAddQuestionSuccess = false;
    });

    builder.addCase(addQuestionTranslation.fulfilled, (state, action) => {
      if(action.payload && action.payload.success){
        state.isAddQuestionCreated = true;
      }else{
        state.error = action.payload.data || null;
        state.isAddQuestionCreated = false;
      }
    });

    builder.addCase(addQuestionTranslation.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isAddQuestionCreated = false;
    });
    builder.addCase(removeQuestionAsset.fulfilled, (state, action) => {
      if(action.payload && action.payload.success){
        state.isAddQuestionCreated = true
      }else{
        state.error = action.payload.data || null;
        state.isAddQuestionCreated = false;
      }
    });

    builder.addCase(removeQuestionAsset.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.isAddQuestionCreated = false;
    });

    builder.addCase(resetFlagsTrenslation.fulfilled, (state, action) => {
      state.error = null;
      state.isAddQuestionCreated = false;
      // state.isAddQuestionSuccess = false;
    });
  },
});

export default TranslateQuestionSlice.reducer;
