import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getUsers as getUsersApi,
  addNewUser as addNewUserApi,
  updateUser as updateUserApi,
  deleteUser as deleteUserApi, getLanguages as getLanguagesApi, deleteLanguage as deleteLanguageApi,

} from "../../helpers/custom_helper/backend_helper";

export const updateUser = createAsyncThunk("update-user", async (user) => {
  try {
    const response = addNewUserApi(user);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("User Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const addNewUser = createAsyncThunk("save-user", async (user) => {
  try {
    const response = addNewUserApi(user);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getUsers = createAsyncThunk("get-all-users", async () => {
  try {
    const response = getUsersApi();
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const deleteUser = createAsyncThunk("delete-user", async (user) => {
  try {
    const response = deleteUserApi(user);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { user, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("User Deleted Failed", { autoClose: 3000 });
    return error;
  }
});