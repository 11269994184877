import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getCourses as getCoursesApi,
  addNewCourse as addNewCourseApi,
  addCourseTranslation as addCourseTranslationApi,
  updateCourse as updateCourseApi,
  deleteCourse as deleteCourseApi,

} from "../../helpers/custom_helper/backend_helper";

export const updateCourse = createAsyncThunk("update-course", async (course) => {
  try {
    const response = addNewCourseApi(course);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
        toast.error(data.message, { autoClose: 3000 });
        return data;
    }
  } catch (error) {
    toast.error("Course Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const addNewCourse = createAsyncThunk("save-course", async (course) => {
  try {
    const response = addNewCourseApi(course);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error("Course Added Failed", { autoClose: 3000 });
    return error;
  }
});

export const addCourseTranslation = createAsyncThunk("save-course-Translation", async (course) => {
  try {
    const response = addCourseTranslationApi(course);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getCourses = createAsyncThunk("get-courses", async () => {
  try {
    const response = getCoursesApi();
    const data = await response;


    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error("Something wrong", { autoClose: 3000 });
    return error;
  }
});

export const deleteCourse = createAsyncThunk("delete-course", async (course) => {
  try {
    const response = deleteCourseApi(course);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return { course, ...response }
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }

  } catch (error) {
    toast.error("Course Deleted Failed", { autoClose: 3000 });
    return error;
  }
});