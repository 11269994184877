import { createSlice } from "@reduxjs/toolkit";


import { getExamResults,getAllResults,getStudentResult, getPracticeResults,resetResults, saveResultPdf, getSmsLogs,resendNotification, markEligible, resetResultDetail,getAttemptHistory } from "./thunk";
import { attempt } from "lodash";





export const initialState = {
    resultList: [],
    allResultsList: [],
    error: {},
    isResultSuccess:false,
    isLoading:true,
    practiceResultList: [],
    isQuestionSuccess:false,
    logsList: [],
    isResultDetail:false,
    attemptHistory:[],
    isAttemptHistorySuccess:false

};

const ResultSlice = createSlice({
    name: 'ResultSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {

          //get questions for exam
        builder.addCase(getExamResults.fulfilled, (state, action) => {
        
            if(action.payload!=undefined){
              
            state.resultList = action.payload.data;
            state.isQuestionSuccess = true;
            state.isLoading = false;
            state.isResultDetail=true;
            }
        });

        builder.addCase(getExamResults.rejected, (state, action) => {
            state.error = action.payload.success || null;
            state.isQuestionSuccess = false;
            state.isLoading = false;
            state.isResultSucces=true;
        });
        builder.addCase(getAllResults.fulfilled, (state, action) => {
          
            state.isLoading=true;
            if(action.payload && action.payload.success){
                state.allResultsList = action.payload.data;
                state.isQuestionSuccess = true;
                state.isLoading=false
            }else{
                state.isLoading=false
                state.isQuestionSuccess = false;
               
            }
        });

        builder.addCase(getAllResults.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isQuestionSuccess = false;
        });


               //get student result
               builder.addCase(getStudentResult.fulfilled, (state, action) => {
         
                if(action.payload!=undefined){
                
                state.resultList = action.payload.data;
                state.isResultSucces=true
                state.isQuestionSuccess = true;
                state.isLoading = false;
                
                }
            });
    
            builder.addCase(getStudentResult.rejected, (state, action) => {
            
                state.error = action.payload.success || null;
                state.isQuestionSuccess = false;
                state.isLoading = false;
                state.isResultSucces=true
            });
                         //get practice result
                         builder.addCase(getPracticeResults.fulfilled, (state, action) => {
                    
                            if(action.payload.data!=undefined){
                                state.practiceResultList = action.payload.data;
                                state.isResultSuccess = true;
                                state.isLoading = false;
                            }else{
                               
                                state.practiceResultList=[];
                                state.isResultSucces=true;
                                state.isLoading=false;
                            }
                    
                        });
                
                        builder.addCase(getPracticeResults.rejected, (state, action) => {
                            state.error = action.payload.success || null;
                            state.isQuestionSuccess = false;
                            state.isLoading = false;
                        });


                           // save result pdf

                           builder.addCase(saveResultPdf.fulfilled, (state, action) => {
                            state.isResultSuccess = true;
                            state.isLoading = false;
                        });
                
                        builder.addCase(saveResultPdf.rejected, (state, action) => {
                            state.error = action.payload.success || null;
                            state.isQuestionSuccess = false;
                            state.isLoading = false;
                        });

                                    // sms logs

                                    builder.addCase(getSmsLogs.fulfilled, (state, action) => {
                                        if(action.payload.data!=undefined){
                                            state.logsList = action.payload.data,
                                            state.isLoading = false;
                                        }else{
                                           
                                            state.logsList=[];
                                            state.isLoading=false;
                                        }
                                    });
                            
                                    builder.addCase(getSmsLogs.rejected, (state, action) => {
                                        state.error = action.payload.success || null;
                                        state.logsList=[];
                                        state.isLoading = false;
                                    });


                             // Resend Notification

                            
                                
                                        builder.addCase(resendNotification.rejected, (state, action) => {
                                            state.error = action.payload.success || null;
                                         
                                        });

                                        //Mark Eligible
                                        builder.addCase(markEligible.fulfilled, (state, action) => {
                                            if(action.payload.data!=undefined){
                    
                                                state.isLoading = false;
                                            }else{
                                               
                                        
                                                state.isLoading=false;
                                            }
                                        });

                                        //resetResultDetail
                                              //Rset Practice Type
                                    builder.addCase(resetResultDetail.fulfilled, (state, action) => {
                                        state.resultList =[];
                                        state.isQuestionSuccess = false;
                                        state.isResultDetail=false;
                                        state.attemptHistory=[];
                                        state.isAttemptHistorySuccess=false
                                    });

                                         //Get Attempt History

                                         builder.addCase(getAttemptHistory.fulfilled, (state, action) => {
                            
                                            if(action.payload.data!=undefined){
                                                state.attemptHistory = action.payload.data,
                                                state.isAttemptHistorySuccess = true;
                                            }else{
                                               
                                                state.attemptHistory=[];
                                                state.isAttemptHistorySuccess=true;
                                            }
                                        });
                                
                                        builder.addCase(getAttemptHistory.rejected, (state, action) => {
                                            state.error = action.payload.success || null;
                                            state.attemptHistory=[];
                                            state.isLoading = false;
                                        });


    }
});

export default ResultSlice.reducer;