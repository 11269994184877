import React, { useRef, useEffect } from 'react';

const TestData = () => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        console.log('buttonRef.current',buttonRef.current);
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div>
      <button ref={buttonRef} onClick={() => alert('Button clicked!')}>
        Click Me
      </button>
    </div>
  );
};

export default TestData;
