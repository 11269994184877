import React from 'react'
import { useTranslation } from 'react-i18next'

  const ExamFooter = (props) => {
    
    const {index,totalQuestion,examType,courseName,totalWrongQ,totalCorrectQ,totalSkipQ,direction} = props;
    const { t, i18n }  = useTranslation();

  return (
    <>
     

    <footer className="footer m-0 p-3" style={{position:"fixed", boxShadow: "inset 0px 0.1px 5px 0px #38414a26"}}>
<div className="container-fluid navbar-header1 row fs-3 text-primary">
    <div className="col-sm-6 gap-3 "><i className={`ri-book-line align-bottom ${direction === 'ltr' ? 'me-2' : 'ms-2'}`}> </i>{t('Theory Driving')} {examType==1? t('Exam'): t('Practice')} {t('for')}   {courseName}</div>
    {examType==2?
               <div className="col-sm-6 d-flex gap-2 justify-content-end">
                  <button className="btn btn-danger waves-effect waves-light">WA : {totalWrongQ}</button>
                  <button className="btn btn-primary waves-effect waves-light" >CA : {totalCorrectQ}</button>
                  <button className="btn btn-info waves-effect waves-light">SA : {totalSkipQ}</button>
                  </div>
                  :''}
        
        <div className='col-sm-6 d-flex justify-content-end'>
          <span>{t('Question')} {index+1} {t('out of')} {totalQuestion}</span>
        </div>
</div>



</footer>
    </>
  )
}

export default ExamFooter