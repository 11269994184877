import { createSlice } from "@reduxjs/toolkit";
import {checkCourseQuestion, checkPracticeType, getQuestions,questionListResetFlag,questionSaveResetFlage,resetAllData,resetFlags,resetPracticeType,saveQuestionType,saveQuestions, saveStdInfo, studentInfoResetFlag} from './thunk';


export const initialState = {
    questionsList: [],
    stdInfo: [],
    error: {},
    isQuestionSuccess:false,
    isQuestionSave:false,
    isLoading:true,
    attemptId:0,
    questionType:0,
    courseConfig:[],
    isSuccess:false,
    isCheckPracticeType:false,
    isQuestionFetched:false

};

const SystemSlice = createSlice({
    name: 'ExamSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {

          //get questions for exam
        builder.addCase(getQuestions.fulfilled, (state, action) => {
   

            if(action.payload){
          
                state.questionsList = action.payload.data;
                state.isQuestionSuccess = true;
                state.isLoading = false;
            

            }
            else{
           
                state.error = action.payload || null;
                state.isQuestionSuccess = true;
          
            }
        
        });

        builder.addCase(getQuestions.rejected, (state, action) => {
           
            state.error = action.payload.success || null;
            state.isQuestionSuccess = false;
            state.isLoading = true;
        });


        //save questions for exam
        builder.addCase(saveQuestions.fulfilled, (state, action) => {
       
            if(action.payload) {
                state.isQuestionSave = true;
            }
        });

        builder.addCase(saveQuestions.rejected, (state, action) => {
            state.isQuestionSave = false;
        });


            //save student info
            builder.addCase(saveStdInfo.fulfilled, (state, action) => {

                if(action.payload) {
                    state.stdInfo = action.payload;
                }
            });
    
            builder.addCase(saveStdInfo.rejected, (state, action) => {
                state.isQuestionSave = false;
            });


            

        //chek practice type
        builder.addCase(checkPracticeType.fulfilled, (state, action) => {
            if(action.payload) {
                state.attemptId = action.payload.data;
                state.isCheckPracticeType=true;
            }
        });

        builder.addCase(checkPracticeType.rejected, (state, action) => {
            state.attemptId = 0;
            state.isCheckPracticeType=true;
        });

        //Rset
        builder.addCase(resetFlags.fulfilled, (state, action) => {
            state.attemptId = 0;
          
        });

          //Question Type
          builder.addCase(saveQuestionType.fulfilled, (state, action) => {
         
            if(action.payload) {
                state.questionType = action.payload;
            }
        });


             //Rset Question List
             builder.addCase(questionListResetFlag.fulfilled, (state, action) => {
                state.questionsList = [];
                state.attemptId=0;
                state.questionType=0;
                state.isQuestionSuccess=false
            });

                //Rset Question Save
                builder.addCase(questionSaveResetFlage.fulfilled, (state, action) => {
                    state.isQuestionSave=false;
                    state.isQuestionSuccess=false;
                    state.stdInfo=[];
                    state.questionsList = [];
                    state.isSuccess=false;
                    state.courseConfig=false;
                    
                });


        //check course questions
        builder.addCase(checkCourseQuestion.fulfilled, (state, action) => {

            if(action.payload.success){
                state.courseConfig = action.payload.data;
                state.isSuccess = true;
               
            }
            else{
                state.error = action.payload || null;
                state.isSuccess = true;
            }
        
        });

        builder.addCase(checkCourseQuestion.rejected, (state, action) => {
           
            state.error = action.payload.success || null;
            state.isQuestionSuccess = false;
            state.isLoading = true;
        });


             //Rset All Data
             builder.addCase(resetAllData.fulfilled, (state, action) => {
                state.isSuccess=false;
            });


               //Rset Practice Type
               builder.addCase(resetPracticeType.fulfilled, (state, action) => {
                state.isCheckPracticeType=false;
            });


             
    }
});

export default SystemSlice.reducer;