import { createSlice } from "@reduxjs/toolkit";
import {
    getScheduleExam, getBdcStudent, saveScheduleExam,
    updateExam, deleteExam,
    resetFlags,
    restartExam,
    exitExam,
    resetBdcStd,
    resetSaveExamSchedule,
    getRunningExams,
    syncExam,
    holdExam
} from './thunk';
import { act } from "react-dom/test-utils";
import { stubFalse } from "lodash";

export const initialState = {
    scheduleExamList: [],
    studentInfo: [],
    error:false,
    isExamSuccess: false,
    traficIdValid: false,
    isLoader: false,
    isSaveExam: false,
    isCloseExam:false,
    isRetartExam:false,
    isContinueExam:false,
    isFilterLoader:false,
    runningExamList:[],
    isSyncExam:false

};

const ScheduleExamSlice = createSlice({

    name: 'ScheduleExamSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {

        // schedule exam list
        builder.addCase(getScheduleExam.fulfilled, (state, action) => {
      

        state.isLoader = true;
        state.isCloseExam=false;
        state.isRetartExam=false
   
        if(action.payload.success){
          
            state.scheduleExamList = action.payload.data;
            state.isExamSuccess = true;
        }else{
            state.isExamSuccess = false;
        }

     
           
         
        });

        builder.addCase(getScheduleExam.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isExamSuccess = false;
        });

        // get BDC Student
        builder.addCase(getBdcStudent.fulfilled, (state, action) => {


            if (action.payload.success) {
                state.studentInfo = action.payload.data;
                state.traficIdValid = true;
                

            } else {
                state.error = action.payload.data;
                state.traficIdValid = false;
            }


        });

        builder.addCase(getBdcStudent.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.traficIdValid = false;
        });


        // save schedule exam
        builder.addCase(saveScheduleExam.fulfilled, (state, action) => {
            if (action.payload.success) {
           
                state.isSaveExam = true;
                state.isCloseExam=false;
          
            } else {
              
                state.isSaveExam = false;
                state.error-true;
                state.isContinueExam=0
            }
          
        });

        builder.addCase(saveScheduleExam.rejected, (state, action) => {
        
            state.error = action.payload.error || null;
            state.isSaveExam = false;
            state.isLoader = false;
        });


        // update schedule exam
        builder.addCase(updateExam.fulfilled, (state, action) => {
          
            if (action.payload && action.payload.success){
                console.log('save payload',action.payload);
                state.scheduleExamList = state.scheduleExamList.map(exam =>
                    exam.id === action.payload.data.id
                        ? {...exam, ...action.payload.data}
                        : exam
                );
                state.isSaveExam = true;
            }else {
                console.log('not save payload',action.payload);
                state.error = action.payload.data || null;
                state.isSaveExam = true;
            }

        
        });

        builder.addCase(updateExam.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isSaveExam = false;
            state.isLoader = false;
        });

        // delete schedule exam
        builder.addCase(deleteExam.fulfilled, (state, action) => {
            if(action.payload!=undefined){
            state.scheduleExamList = state.scheduleExamList.filter(
                system => system.id.toString() !== action.payload.systemId.toString()
            );
        }
        });
        builder.addCase(deleteExam.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


             // Restart schedule exam
             builder.addCase(restartExam.fulfilled, (state, action) => {
            });
            builder.addCase(restartExam.rejected, (state, action) => {
                state.error = action.payload.error || null;
            });

        // Reset Flags

        builder.addCase(resetFlags.fulfilled, (state, action) => {
            state.error = null;
            state.traficIdValid=false
            state.isLoader = false;
            state.isSaveExam = false;
            state.studentInfo=[];


        });

              // Close schedule exam
              builder.addCase(exitExam.fulfilled, (state, action) => {
        
                if(action.payload.success){
                    state.isCloseExam=true

                }else{
                    state.isCloseExam=false 
                }
            });
            builder.addCase(exitExam.rejected, (state, action) => {
                state.isCloseExam=false 
            });

                  // Reset BDC student 
                  builder.addCase(resetBdcStd.fulfilled, (state, action) => {
                    state.traficIdValid = false;
                    state.isCloseExam = false;
                    state.isRetartExam = false;
                    state.isContinueExam = false;
                    state.studentInfo=[]

                });

                    // Reset Save exam schedule 
                    builder.addCase(resetSaveExamSchedule.fulfilled, (state, action) => {
                        state.isSaveExam = false;
                        state.isContinueExam=false,
                        state.isLoader=false,
                        state.isFilterLoader=false,
                        state.runningExamList=[]
                    
    
                    });

                      // Get Runniing exam list
        builder.addCase(getRunningExams.fulfilled, (state, action) => {
    
            if(action.payload.success){
              
                state.runningExamList = action.payload.data;
                state.isExamSuccess = true;
            }else{
                state.isExamSuccess = false;
            } 
             
            });
    
            builder.addCase(getRunningExams.rejected, (state, action) => {
                state.error = action.payload.error || null;
                state.isExamSuccess = false;
            });

               //Sync Exam 
               builder.addCase(syncExam.fulfilled, (state, action) => {
                state.isSyncExam = true;
            });


                    //Hold Exam Api
                    builder.addCase(holdExam.fulfilled, (state, action) => {
        
                        if(action.payload.success){
                            state.isCloseExam=true
        
                        }else{
                            state.isCloseExam=false 
                        }
                    });
                    builder.addCase(holdExam.rejected, (state, action) => {
                        state.isCloseExam=false 
                    });
    }
});

export default ScheduleExamSlice.reducer;