import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Spinner
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {toast, ToastContainer} from 'react-toastify';
import TableContainer from '../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { Link } from 'react-router-dom';
import Loader from '../../Components/Common/Loader';
import { isEmpty } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from "yup";
import {
  getSystem as onGetSystem,
  addNewSystem as onAddNewSystem,
  updateSystem as onUpdateSystem,
  deleteSystem as onDeleteSystem
} from '../../slices/system/thunk';
import DeleteModal from '../../Components/Common/DeleteModal';
import {deleteRoom as onDeleteRoom} from "../../slices/room/thunk";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";

const System = () => {
    
    const [systemData, setSystemData] = useState([]);
    const [roomsData, setRoomData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);

    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const selectLayoutState = (state) => state.System;
    
    const systemProperties = createSelector(
        selectLayoutState,
        (data) => ({
            systemList: data.systemList,
            roomsList: data.roomList,
            isSystemSuccess: data.isSystemSuccess,
            isSystemCreated: data.isSystemCreated,
            error: data.error,
        })
    );


    const {
        systemList,error,isSystemSuccess,roomsList,isSystemCreated
    } = useSelector(systemProperties)


    useEffect(() => {
        // if (systemList && !systemList.length) {
            dispatch(onGetSystem());
        // }
    }, [dispatch]);

    useEffect(() => {
      setSystemData(systemList);
      setRoomData(roomsList);
        setLoading(!isSystemSuccess);
      
        if(isSystemCreated){
            setIsloading(false);
            validation.resetForm();
            setModal(false);
        }
        error? setIsloading(false):null
  }, [systemList,roomsList,isSystemSuccess,isSystemCreated,error]);

  useEffect(() => {
      if (!isEmpty(systemList)) {
        setSystemData(systemList);
        setRoomData(roomsList);
          setIsEdit(false);
      }
  }, [systemList,roomsList,isSystemSuccess,isSystemCreated]);

    const toggle = useCallback(() => {
      if (modal) {
          setModal(false);

      } else {
          setModal(true);
      }
  }, [modal]);

  const statusList = [
    {
        options: [
            { label: "Select Status", value: " " },
            { label: "Active", value: "1" },
            { label: "In-Active", value: "2" },
        ],
    },
];

const validation = useFormik({
  // enableReinitialize : use this flag when initial values needs to be changed
  enableReinitialize: true,
  initialValues: {
      title:(isEdit?(systemData && systemData.title):"")  || '',
      room_id:(isEdit?(systemData && systemData.room_id):"")  || '',
      status:(isEdit?(systemData && systemData.status):"")  || '',
      system_ip:(isEdit?(systemData && systemData.system_ip):"")  || '',

  },

  validationSchema: Yup.object({
      title: Yup.string().required("Required field"),
      room_id: Yup.string().required("Required field"),
      status: Yup.string().required("Required field"),
      system_ip: Yup.string().required("Required field")
  }),
  onSubmit: (values) => {
    setIsloading(true);
          const postedSystemData = {
             id:isEdit?systemData.id:0,
              title: values["title"],
              room_id: values["room_id"],
              status: values["status"],
              system_ip: values["system_ip"],
      
          };
       isEdit?
       dispatch(onUpdateSystem(postedSystemData))
       :
           dispatch(onAddNewSystem(postedSystemData));
  },
});


    const systemTableColumns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="customerCheckBox form-check-input" value={cellProps.row.original.id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',

            },
          {
            Header: "System",
            accessor: "title",
            filterable: false,
          },
          {
            Header: "Room",
            accessor: "room.title",
            filterable: false,
          },
            {
                Header: "Branch",
                accessor: "room.branch.title",
                filterable: false,
            },
          {
            Header: "System IP",
            accessor: "system_ip",
            filterable: false,
          },
    


          {
            Header: "Status",
            accessor: "status",

            Cell: (cell) => {
    
              if (cell.value == 1) {
                return (
                  <span className="badge text-uppercase bg-success-subtle text-success">Active</span>
                );
              }
              if (cell.value == 2) {
                return (
                  <span className="badge text-uppercase bg-info-subtle text-danger">In-Active</span>
                );
              }

              if (cell.value == 3) {
                return (
                  <span className="badge text-uppercase bg-info-subtle text-dark">Busy</span>
                );
              }
          
    
            },
          },
    

          {
            Header: "Action",
            Cell: (cellProps) => {
              return (
                <ul className="list-inline hstack gap-2 mb-0">
                    <AuthorizeForAction requiredPermissions={['system-edit']}>
                  <li className="list-inline-item edit" title="Edit">
                    <Link
                      to="#"
                      className="text-primary d-inline-block edit-item-btn"
                      onClick={() => { const systemData = cellProps.row.original; handleEditSystem(systemData); }}
                  
                    >
                      <i className="ri-pencil-fill fs-16"></i>
                    </Link>
                  </li>
                    </AuthorizeForAction>
                    <AuthorizeForAction requiredPermissions={['system-delete']}>
                  <li className="list-inline-item" title="Remove">
                    <Link
                      to="#"
                      className="text-danger d-inline-block remove-item-btn"
                      onClick={() => { const systemData = cellProps.row.original; onClickDelete(systemData); }}
                   
                    >
                      <i className="ri-delete-bin-5-fill fs-16"></i>
                    </Link>
                  </li>
                    </AuthorizeForAction>
                </ul>
              );
            },
          },
        ],

        []
      );


      const handleCustomerClicks = () => {
        setSystemData("");
        setIsEdit(false);
        toggle();
    };

    const handleEditSystem = useCallback((arg) => {
      const system = arg;
      setSystemData({
          id: system.id,
          title: system.title,
          room_id: system.room_id,
          status: system.status,
          system_ip: system.system_ip,
          
      });
      setIsEdit(true);
      toggle();
  }, [toggle]);


  const onClickDelete = (system) => {

    setSystemData(system);
    setDeleteModal(true);
};

const handleDeleteSystem = () => {
  if (systemData) {
 
      dispatch(onDeleteSystem(systemData.id));
      setDeleteModal(false);
  }
};

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".customerCheckBox");
        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);
    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".customerCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(onDeleteSystem(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };

    document.title = "Systems | Theory Practice";
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Systems List" pageTitle="Dashboard" />
            <Row>
            <Col lg={12}>
                      <Card id="customerList">
                        <CardHeader className="border-0">
                          <Row className="g-4 align-items-center">
                            <div className="col-sm">
                              <div>
                                <h5 className="card-title mb-0">Systems List</h5>
                              </div>
                            </div>
                            <div className="col-sm-auto">
                              <div>
                                  <AuthorizeForAction requiredPermissions={['system-delete']}>
                                  {isMultiDeleteButton && <button className="btn btn-soft-danger me-1"
                                                                  onClick={() => setDeleteModalMulti(true)}
                                  ><i className="ri-delete-bin-2-line"></i></button>}
                                  </AuthorizeForAction>
                                  <AuthorizeForAction requiredPermissions={['system-create']}>
                                <button
                                  type="button"
                                  className="btn btn-success add-btn"
                                  id="create-btn"
                                   onClick={() => { setIsEdit(false); toggle(); }}

                                >
                                  <i className="ri-add-line align-bottom me-1"></i> Add
                                  System
                                </button>{" "}
                                  </AuthorizeForAction>
                              </div>
                            </div>
                          </Row>
                        </CardHeader>
                        <div className="card-body pt-0">
                            {(loading ?
                                <React.Fragment>
                                    <div className="d-flex justify-content-center mx-2 mt-2">
                                        <Spinner color="primary"> Loading... </Spinner>
                                    </div>
                                </React.Fragment>
                                : <div>
                                                {isSystemSuccess && systemList.length ? (
                                                    <TableContainer
                                                        columns={systemTableColumns}
                                                        data={(systemList || [])}
                                                        isGlobalFilter={true}
                                                        isAddUserList={false}
                                                        customPageSize={8}
                                                        className="custom-header-css"
                                                        handleCustomerClick={handleCustomerClicks}
                                                        isCustomerFilter={false}
                                                        SearchPlaceholder='Search for Room, status or something...'
                                                    />
                                                ) : (
                                                    <div className="py-4 text-center">
                                                        <div>
                                                            <lord-icon
                                                                src="https://cdn.lordicon.com/msoeawqm.json"
                                                                trigger="loop"
                                                                colors="primary:#405189,secondary:#0ab39c"
                                                                style={{width: "72px", height: "72px"}}
                                                            ></lord-icon>
                                                        </div>

                                                        <div className="mt-4">
                                                            <h5>Sorry! No Record Found</h5>
                                                        </div>
                                                    </div>
                                                )
                                                }
                        </div>)}

                            <ToastContainer closeButton={false} limit={1}/>
                        </div>

                          <Modal id="showModal" isOpen={modal} backdrop="static" toggle={toggle}  centered>
                              <ModalHeader className="bg-light p-3" toggle={toggle}>

                                  {!!isEdit ? "Edit System" : "Add System"}

                              </ModalHeader>
                              <Form className="tablelist-form"  onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}>
                                                    <ModalBody>
                                                        <input type="hidden" id="id-field"/>

                                                        <div
                                                            className="mb-3"
                                                            id="modal-id"
                                                            style={{display: "none"}}
                                                        >

                                                        </div>

                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="title-field"
                                                                className="form-label"
                                                            >
                                                                System Title
                                                            </Label>
                                                            <Input
                                                                name="title"
                                                                id="name-field"
                                                                className="form-control"
                                                                placeholder="Enter System Title"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                validate={{
                                                                    required: {value: true},
                                                                }}
                                                                value={validation.values.title || ""}
                                                                invalid={
                                                                    validation.touched.title && validation.errors.title ? true : false
                                                                }

                                                            />

                                                {validation.touched.title && validation.errors.title ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.title}</FormFeedback>
                                                            ) : null}

                                                        </div>

                                                        <div className="mb-3">
                                                            <Label
                                                                htmlFor="title-field"
                                                                className="form-label"
                                                            >
                                                                System IP
                                                            </Label>
                                                            <Input
                                                                name="system_ip"
                                                                id="name-field"
                                                                className="form-control"
                                                                placeholder="Enter System IP"
                                                                type="text"
                                                                onChange={validation.handleChange}
                                                                validate={{
                                                                    required: {value: true},
                                                                }}
                                                                value={validation.values.system_ip || ""}
                                                                invalid={
                                                                    validation.touched.system_ip && validation.errors.system_ip ? true : false
                                                                }

                                                            />

                                                {validation.touched.system_ip && validation.errors.system_ip ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.system_ip}</FormFeedback>
                                                            ) : null}

                                                        </div>


                                                        <div className="mb-3">
                                                            <Label htmlFor="branch_id-field" className="form-label">
                                                                Rooms
                                                            </Label>
                                                            <Input
                                                                name="room_id"
                                                                type="select"
                                                                className="form-select"
                                                                id="branch_id-field"
                                                                onChange={validation.handleChange}
                                                                validate={{
                                                                    required: {value: true},
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={
                                                                    validation.values.room_id || ""
                                                                }
                                                                invalid={
                                                                    validation.touched.room_id && validation.errors.room_id ? true : false
                                                                }

                                                            >

                                                           <React.Fragment>
                                                                    <option value="">Select Room</option>
                                                                    {roomsData && roomsData.map((item, key) => (
                                                                        <option value={item.id} key={key}>
                                                                            {item.title + " ("+item.branch.title+")"}
                                                                        </option>
                                                                    ))}
                                                                </React.Fragment>
                                                            </Input>

                                                            {validation.touched.room_id && validation.errors.room_id ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.room_id}</FormFeedback>
                                                            ) : null}


                                                        </div>
                                                        <div className="mb-3">
                                                            <Label htmlFor="status-field" className="form-label">
                                                                Status
                                                            </Label>
                                                            <Input
                                                                name="status"
                                                                type="select"
                                                                className="form-select"
                                                                id="status-field"

                                                                onChange={validation.handleChange}
                                                                validate={{
                                                                    required: {value: true},
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                value={
                                                                    validation.values.status || ""
                                                                }
                                                                invalid={
                                                                    validation.touched.status && validation.errors.status ? true : false
                                                                }

                                                            >

                                                              {statusList.map((item, key) => (
                                                                    <React.Fragment key={key}>
                                                                        {item.options.map((item, key) => (
                                                                            <option value={item.value}
                                                                                    key={key}>{item.label}</option>))}
                                                                    </React.Fragment>
                                                                ))}

                                                            </Input>

                                                            {validation.touched.status && validation.errors.status ? (
                                                                <FormFeedback
                                                                    type="invalid">{validation.errors.status}</FormFeedback>
                                                            ) : null}

                                                        </div>

                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="button" className="btn btn-light" onClick={() => {
                                                                setModal(false);
                                                                validation.resetForm();
                                                            }}> Close
                                                            </button>

                                                            <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                              <span className="d-flex align-items-center">
                                                                  {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                                  <span className="flex-grow-1 ms-2">
                                                                     {!!isEdit ? "Update" : "Add System"}{" "}
                                                                  </span>
                                                              </span>
                                                            </Button>
                                                        </div>
                                                    </ModalFooter>
                                                </Form>
                                            </Modal>

                                            <DeleteModal
                            show={deleteModal}
                            onDeleteClick={handleDeleteSystem}
                            onCloseClick={() => setDeleteModal(false)}
                        />
                          <DeleteModal
                              show={deleteModalMulti}
                              onDeleteClick={() => {
                                  deleteMultiple();
                                  setDeleteModalMulti(false);
                              }}
                              onCloseClick={() => setDeleteModalMulti(false)}
                          />
                      </Card>
                    </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };

export default System;
