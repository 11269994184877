

import React, { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';



const MyComponent = () => {

  const [examData, setExamData] = useState([])

  useEffect(() => {
		const pusher = new Pusher('17a726fd842ba679e19b', {
			cluster: 'ap2'
		})
		const channel1 = pusher.subscribe('examData');


  channel1.bind_global(function(examData, data) {
    console.log(examData, data);
    console.log('My Data', data.data);
  
   if(data && data.data){
    console.log('data yes',data.data.stdName)
    setExamData(data.data)
   }else{
    setExamData([])
   }
 
  });
  
		
		return (() => {
			pusher.unsubscribe('examData')
			
		})

	}, []);



  return(

  <>
  <h2>Student Name:{examData  && examData.stdName  }</h2>
  <h2>qLang : {examData  && examData.qLang  }</h2>
  <h2>ingName : {examData  && examData.ingName  }</h2>
  <h2>courseName: {examData  && examData.courseName  }</h2>
  </>
  )

};

export default MyComponent;
