import React, {useCallback, useEffect, useMemo, useState} from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import {toast, ToastContainer} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import {
    addNewLanguage as onAddNewLanguage, deleteLanguage as onDeleteLanguage,
    getLanguages as onGetLanguages,
    updateLanguage as onUpdateLanguage
} from "../../slices/language/thunk";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";

const Language = () => {

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);

    const [modal, setModal] = useState(false);

    const [language, setLanguage] = useState([]);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

    // Delete language
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);

    const [lastdeletedtitle, setlastdeletedtitle] = useState("");

    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);

    const selectLayoutState = (state) => state.Language;
    const ecomLanguagesProperties = createSelector(
        selectLayoutState,
        (ecom) => ({
            languages: ecom.languages,
            isLanguageSuccess: ecom.isLanguageSuccess,
            isLanguageCreated: ecom.isLanguageCreated,
            error: ecom.error,
        })
    );
    // Inside your component
    const {
        languages, isLanguageSuccess, error,isLanguageCreated
    } = useSelector(ecomLanguagesProperties)


    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
        } else {
            setModal(true);
        }
    }, [modal]);


    useEffect(() => {
        if (languages && !languages.length) {
            dispatch(onGetLanguages());
        }
    }, [dispatch]);


    useEffect(() => {
        setLanguage(languages);
        setLoading(!isLanguageSuccess);
        if(isLanguageCreated){
            setIsloading(false);
            validation.resetForm();
            setModal(false);
        }
        error? setIsloading(false):null
    }, [languages,isLanguageCreated,error]);

    useEffect(() => {
        if (!isEmpty(languages)) {
            setLanguage(languages);
            setIsEdit(false);

        }
    }, [languages,lastdeletedtitle]);

    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            lang: (isEdit?(language && language.lang):"") || '',
            short_code: (isEdit? (language && language.short_code):"") || '',
            direction:(isEdit? (language && language.direction):"") || '',
            status:(isEdit? (language && language.status):"") || '',
        },
        validationSchema: Yup.object({
            lang: Yup.string().required("Please Enter Language Title"),
            short_code: Yup.string().required("Please Enter Language Short Code"),
            direction: Yup.string().required("Please Select Language Direction"),
            status: Yup.string().required("Please Enter Your Status")
        }),
        onSubmit: (values) => {
            setIsloading(true);
            if (isEdit) {
                const updateLanguage = {
                    id: language ? language.id : 0,
                    lang: values.lang,
                    lang_short: values.short_code,
                    direction: values.direction,
                    status: values.status,
                };
                // update customer
                dispatch(onUpdateLanguage(updateLanguage));
            } else {
                const newLanguage = {
                    // _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
                    lang: values["lang"],
                    lang_short: values["short_code"],
                    direction: values["direction"],
                    status: values["status"]
                };
                // save new customer
                dispatch(onAddNewLanguage(newLanguage));
            }
        },
    });

    const languageStatus = [
        {
            options: [
                { label: "Select Status", value: "" },
                { label: "Active", value: "1" },
                { label: "In-Active", value: "2" },
            ],
        },
    ];
    const languageDirection = [
        {
            options: [
                { label: "Select Direction", value: "" },
                { label: "Left To Right", value: "2" },
                { label: "Right To Left", value: "1" },
            ],
        },
    ];

    const handleCustomerClick = useCallback((arg) => {
        const language = arg;

        setLanguage({
            id: language.id,
            lang: language.lang,
            short_code: language.lang_short,
            direction: language.direction,
            status: language.status
        });

        setIsEdit(true);
        toggle();
    }, [toggle]);

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".customerCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".customerCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const columns = useMemo(
        () => [
            {
                Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
                Cell: (cellProps) => {
                    return <input type="checkbox" className="customerCheckBox form-check-input" value={cellProps.row.original.id} onChange={() => deleteCheckbox()} />;
                },
                id: '#',
            },
            {
                Header: "Language Title",
                accessor: "lang",
                filterable: false,
            },
            {
                Header: "Short Code",
                accessor: "lang_short",
                filterable: false,
            },
            {
                Header: "Direction",
                accessor: "direction",
                Cell: (cell) => {
                    switch (cell.value) {
                        case 1:
                            return <span className="badge text-uppercase bg-success-subtle text-success"> {cell.value==1?"RTL":"LTR"} </span>;
                        case 2:
                            return <span className="badge text-uppercase bg-danger-subtle text-danger"> {cell.value == 2?"LTR":"RTL"} </span>;
                        default:
                            return <span className="badge text-uppercase bg-info-subtle text-info"> {cell.value==1?"RTL":"LTR"} </span>;
                    }
                }
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case 1:
                            return <span className="badge text-uppercase bg-success-subtle text-success"> {cell.value==1?"Active":"In-Active"} </span>;
                        case 2:
                            return <span className="badge text-uppercase bg-danger-subtle text-danger"> {cell.value==2?"In-Active":"Active"} </span>;
                        default:
                            return <span className="badge text-uppercase bg-info-subtle text-info">{cell.value==1?"Active":"In-Active"} </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <AuthorizeForAction requiredPermissions={['language-edit']}>
                            <li className="list-inline-item edit" title="Edit">
                                <Link
                                    to="#"
                                    className="text-primary d-inline-block edit-item-btn"
                                    onClick={() => { const customerData = cellProps.row.original; handleCustomerClick(customerData); }}
                                >

                                    <i className="ri-pencil-fill fs-16"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['language-delete']}>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => { const customerData = cellProps.row.original; onClickDelete(customerData); }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['language-translate']}>
                            <li className="list-inline-item" title="Translation">
                                <Link
                                    to="#"
                                    className="text-success d-inline-block translate-item-btn"
                                    >
                                    <i className="ri-translate fs-18"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                        </ul>
                    );
                },
            },

        ],
        [handleCustomerClick, checkedAll]
    );

    const onClickDelete = (language) => {
        setLanguage(language);
        setDeleteModal(true);
    };
    const onClickTranslate = (language) => {
        setLanguage(language);
        if (language) {
            dispatch(onDeleteLanguage(language.id));
        }

    };




    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        console.log(selectedCheckBoxDelete);
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(onDeleteLanguage(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };





    const handleCustomerClicks = () => {
        setLanguage("");
        setIsEdit(false);
        toggle();
    };

    const handleDeleteCustomer = () => {
        if (language) {
            dispatch(onDeleteLanguage(language.id));
            setDeleteModal(false);
        }
    };



    document.title = "Language | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteCustomer}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Languages" pageTitle="Settings" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Language List</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div>
                                                <AuthorizeForAction requiredPermissions={['language-delete']}>
                                                {isMultiDeleteButton && <button className="btn btn-soft-danger me-1"
                                                                                onClick={() => setDeleteModalMulti(true)}
                                                ><i className="ri-delete-bin-2-line"></i></button>}
                                                </AuthorizeForAction>
                                                <AuthorizeForAction requiredPermissions={['language-create']}>
                                                <button
                                                    type="button"
                                                    className="btn btn-success add-btn"
                                                    id="create-btn"
                                                    onClick={() => { setIsEdit(false); toggle(); }}>
                                                    <i className="ri-add-line align-bottom me-1"></i> Add
                                                    Language
                                                </button>{" "}
                                                </AuthorizeForAction>

                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0">
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                            {/*{toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}*/}
                                        </React.Fragment>
                                        : <div>
                                        {isLanguageSuccess && languages.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(languages || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={8}
                                                className="custom-header-css"
                                                handleCustomerClick={handleCustomerClicks}
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for Language, status or something...'
                                            />
                                        ) : (
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{width: "72px", height: "72px"}}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )
                                        }
                                    </div>)}

                                    <Modal id="showModal" isOpen={modal} backdrop="static" toggle={toggle} centered>
                                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                                            {!!isEdit ? "Edit Language" : "Add Language"}
                                        </ModalHeader>
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <ModalBody>
                                                <input type="hidden" id="id-field" />

                                                <div
                                                    className="mb-3"
                                                    id="modal-id"
                                                    style={{ display: "none" }}
                                                >
                                                    <Label htmlFor="id-field1" className="form-label">

                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="id-field1"
                                                        className="form-control"
                                                        placeholder="ID"
                                                        readOnly
                                                    />
                                                </div>

                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="lang-field"
                                                        className="form-label"
                                                        >
                                                        Language Title
                                                    </Label>
                                                    <Input
                                                        name="lang"
                                                        id="lang-field"
                                                        className="form-control"
                                                        placeholder="Enter Language Title"
                                                        type="text"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.lang || ""}
                                                        invalid={
                                                            validation.touched.lang && validation.errors.lang ? true : false
                                                        }
                                                    />
                                                    {validation.touched.lang && validation.errors.lang ? (
                                                        <FormFeedback type="invalid">{validation.errors.lang}</FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="short_code-field" className="form-label">
                                                        Short Code
                                                    </Label>
                                                    <Input
                                                        name="short_code"
                                                        type="text"
                                                        id="short_code-field"
                                                        placeholder="Enter Short Code"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.short_code || ""}
                                                        invalid={
                                                            validation.touched.short_code && validation.errors.short_code ? true : false
                                                        }
                                                    />
                                                    {validation.touched.short_code && validation.errors.short_code ? (
                                                        <FormFeedback type="invalid">{validation.errors.short_code}</FormFeedback>
                                                    ) : null}

                                                </div>
                                                <div  className="mb-3">
                                                    <Label htmlFor="direction-field" className="form-label">
                                                        Language Direction
                                                    </Label>

                                                    <Input
                                                        name="direction"
                                                        type="select"
                                                        className="form-select"
                                                        id="direction-field"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.direction || ""
                                                        }
                                                        invalid={
                                                            validation.touched.direction && validation.errors.direction ? true : false
                                                        }
                                                    >
                                                        {languageDirection.map((item, key) => (
                                                            <React.Fragment key={key}>
                                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                                            </React.Fragment>
                                                        ))}

                                                    </Input>
                                                    {validation.touched.direction &&
                                                    validation.errors.direction ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.direction}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>

                                                <div className="mb-3">
                                                    <Label htmlFor="status-field" className="form-label">
                                                        Language Status
                                                    </Label>
                                                    <Input
                                                        name="status"
                                                        type="select"
                                                        className="form-select"
                                                        id="status-field"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={
                                                            validation.values.status || ""
                                                        }
                                                        invalid={
                                                            validation.touched.status && validation.errors.status ? true : false
                                                        }

                                                        >
                                                        {languageStatus.map((item, key) => (
                                                            <React.Fragment key={key}>
                                                                {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                                            </React.Fragment>
                                                        ))}

                                                    </Input>
                                                    {validation.touched.status &&
                                                    validation.errors.status ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.status}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { setModal(false); validation.resetForm() }}> Close </button>
                                                    <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                      <span className="d-flex align-items-center">
                                                          {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                          <span className="flex-grow-1 ms-2">
                                                             {!!isEdit ? "Update" : "Add Language"}{" "}
                                                          </span>
                                                      </span>
                                                    </Button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default Language;