// timer.js
export const startTimer = () => {
    const startTime = localStorage.getItem('startTime');
    if (!startTime) {
      localStorage.setItem('startTime', Date.now().toString());
    }
  };
  
  export const getTimeLeft = (timeParams) => {
    const duration = timeParams * 60 * 1000; // 60 minutes in milliseconds
    const startTime = localStorage.getItem('startTime');
    if (startTime) {
      const elapsed = Date.now() - parseInt(startTime, 10);
      const timeLeft = duration - elapsed;
      return Math.max(0, timeLeft);
    }
    return duration;
  };
  
  export const clearTimer = () => {
    localStorage.removeItem('startTime');
  };


  export const getDateWithFormated = (filterDate) => {
    const today = filterDate ;
    const year = today.getFullYear();
    const month = (`0${today.getMonth() + 1}`).slice(-2); // getMonth() is zero-based; ensure two digits
    const day = (`0${today.getDate()}`).slice(-2); // ensure two digits
    return `${year}-${month}-${day}`;
  };



  export const changeDateFormate1 = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  export const changeDateFormate = (date) => {
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
  
  
  
    return `${day} ${month}, ${year}`;
  };
  
  
  
  