import React, { createContext, useState, useEffect } from 'react';

// Create a context
export const AuthContext = createContext(null);

import { logoutUserSuccess } from '../../slices/auth/login/reducer';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
// Provider component
export function AuthProvider({ children }) {
    const dispatch = useDispatch();

    const isSessionActive = () => {
        const expiresAt = window.localStorage.getItem('expires_at');
        return new Date().getTime() < expiresAt;
      };
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const login = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
    // Optionally, save to localStorage or handle tokens here
  };

  const logout = () => {
    
    setIsLoggedIn(false);
    setUser(null);
    window.localStorage.removeItem("authUser");
    window.localStorage.removeItem("user");
    window.localStorage.removeItem("permissions");
    window.localStorage.removeItem("sessionExpiration");
    window.localStorage.removeItem("expires_at");
      dispatch(logoutUserSuccess(true));
    
   // window.location.href = '/logout';
    // Clear storage or tokens
  };

  const checkLoginStatus = () => {

    if (!isSessionActive()) {
    
      logout();
    }else{
      // console.log("login");
    }
  };

  // Consider checking the auth state when the app starts
  useEffect(() => {

    checkLoginStatus();
  }, []);

  // The value prop of the context provider should include all data and methods
  // that components might need access to
  return (
    <AuthContext.Provider value={{ isLoggedIn, user, login, logout, checkLoginStatus }}>
      {children}
    </AuthContext.Provider>
  );
}
