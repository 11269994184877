import React from 'react'
import FullScreenDropdown from '../../../Components/Common/FullScreenDropdown'
import TimerComponent from '../timer/TimerComponent';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';



const ExamHeader = (props) => {

    const { percentage, stdName, trafficId, remainingTime, examType,handelExit, examDuration,handleTimeUp,isReviewMode,reviewAfterMarkedStack,handleReviewAfter,index,isShowRevSection,direction} = props;
    const { t, i18n }  = useTranslation();

    const headerGradent = {
        background: 'linear-gradient(90deg, #E8A23D 0%, #000000CF 100%)',
        width: `${percentage}%`,
    };
    const rtlheaderGradent = {
        background: 'linear-gradient(90deg, #000000CF 0%, #E8A23D 100%)',
        width: `${percentage}%`,
    };
    return (
        <>
            <header id="page-topbar" className="" style={{ borderBottom: "none" }}>


                <div className="container-fluid">
                    <div className="navbar-header mt-3">
                        <div className="d-flex">
                        <div className={`btn-primary rounded-pill shadow-lg btn btn-icon btn-md p-2 ${direction === 'ltr' ? 'me-3' : 'ms-3'}`}>
                            <i className=" ri-user-fill fs-18 align-bottom"> </i>
                        </div>
                        <div className="fs-2"> 
                            {stdName} | {trafficId}
                        </div>

            

                        </div>

                        <div className="d-flex align-items-center">

                        
                            <TimerComponent 
                            duration={examDuration}
                            handleTimeUp={handleTimeUp} 
                            examType={examType}
                            direction={direction}
                            />

                            {examType==2?
                            <div className="ms-1 header-item d-sm-flex">
                                <button className="btn btn-icon btn-top-bar btn-ghost-secondary rounded-circle fs-1" title='Exit' onClick={() => { handelExit() }}><i className="bx bx-exit fs-30"></i> </button>
                            </div>
                            :''}
                            <FullScreenDropdown />


                            {/* {isReviewMode?
                            <i className='ri-close-line fs-36 text-danger cursor-pointer' onClick={() => { handelExit() }} title={t('Exit Review')}></i>
                            
                            :''} */}

                        {examType==1 && !isShowRevSection?
                        <div className="form-check flex-grow-1 fs-2" data-toggle="tooltip" title="Review this question before submit exam">
                            <input className="form-check-input" type="checkbox" id="formCheck1" style={{backgroundColor:"#ffffff"}}
                             onChange={(e) => handleReviewAfter(e, index)} checked={reviewAfterMarkedStack.includes(index)}
                             
                            />
                        <label className="form-check-label fs-20" htmlFor="formCheck1">{t('Review Later')}</label>
        
    
                            </div>
                            :''}
                        </div>
                        
                        </div>
                        

                    <div className="me-3 ms-3 mt-3 progress animated-progress progress-xl" style={{
                        borderRadius: "10px",
                        padding: "5px",
                        height: "30px"
                    }}>
                        <div className="progress-bar bg-danger" role="progressbar" style={direction === 'ltr' ? headerGradent : rtlheaderGradent} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    
                </div>

            </header>

        </>
    )
}

export default ExamHeader