import { createSlice } from "@reduxjs/toolkit";
import {getSystem,addNewSystem, updateSystem, deleteSystem, getRoomSystem,checkSystemIp,resetSystemStates} from './thunk';

export const initialState = {
    systemList: [],
    roomSystemList: [],
    roomList: [],
    error: {},
    isSystemSuccess:false,
    isSystemCreated:false,
    isIpFound:0,
};

const SystemSlice = createSlice({
    name: 'SystemSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {

        builder.addCase(getSystem.fulfilled, (state, action) => {
            if (action.payload && action.payload.success){
            state.systemList = action.payload.data['systems']['data'];
            state.roomList = action.payload.data['rooms']['data'];
            state.isSystemSuccess = true;
            }else{
                state.isSystemSuccess = false;
            }
        });

        builder.addCase(getSystem.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isSystemSuccess = false;
        });

        builder.addCase(addNewSystem.fulfilled, (state, action) => {
            if (action.payload && action.payload.success){
                state.systemList.push(action.payload.data);
                state.isSystemCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isSystemCreated = false;
            }
        });

        builder.addCase(addNewSystem.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isSystemCreated = false;
        });

        builder.addCase(updateSystem.fulfilled, (state, action) => {

            if (action.payload && action.payload.success){
                state.systemList = state.systemList.map(system =>
                    system.id === action.payload.data.id
                        ? {...system, ...action.payload.data}
                        : system
                );
                state.isSystemCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isSystemCreated = false;
            }
        });
        builder.addCase(updateSystem.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isSystemCreated = false;
        });

        builder.addCase(deleteSystem.fulfilled, (state, action) => {
            if(action.payload!=undefined){
            state.systemList = state.systemList.filter(
                system => system.id.toString() !== action.payload.systemId.toString()
            );
            }
        });
        builder.addCase(deleteSystem.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        //Get Room Wise Systems
        builder.addCase(getRoomSystem.fulfilled, (state, action) => {

            if (action.payload && action.payload.success){
            state.roomSystemList = action.payload.data;
            state.isSystemSuccess = true;
            }else{
                state.isSystemSuccess = false;
            }
        });

        builder.addCase(getRoomSystem.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isSystemSuccess = false;
        });

             //Check system Ip
             builder.addCase(checkSystemIp.fulfilled, (state, action) => {

                if (action.payload && action.payload.success){
                state.roomSystemList = action.payload.data;
                state.isSystemSuccess = true;
                state.isIpFound=1;
                }else{
                    console.log('reducer else part');
                    state.isSystemSuccess = false;
                    state.isIpFound=2;
                }
            });
    
            builder.addCase(checkSystemIp.rejected, (state, action) => {
                state.error = action.payload.error || null;
                state.isSystemSuccess = false;
            });

            //Reset System States
                
                   builder.addCase(resetSystemStates.fulfilled, (state, action) => {
                    state.isIpFound = 0;
               
                

                });
    


    }
});

export default SystemSlice.reducer;