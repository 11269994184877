import React, { useEffect, useRef } from 'react'
import { api } from '../../../config';

function ExamVideoSection(props) {
  // examTemplate 1 mean for sharja 2 mean for dubai
    const { qData,handleVideoEnded,index,examTemplate } = props;
    const videoRef = useRef(null);

    useEffect(() => {
      const videoElement = videoRef.current;
      if (videoElement) {
        videoElement.controlsList = "nodownload noremoteplayback";
      }
    }, []);
   
    
  const fileUrl = api.IMAGE_URL;
  return (
    <>
    <div className="main-content">

    <div className="page-content bg-white" style={{ margin: examTemplate == '2' ? '0px' : '' }}>


        <div className="container-fluid">
            <div className="row ">
                <div className="col-lg-12">
                    <div className="card">
                    <div className="ratio ratio-21x9">
                    {qData.length > 0 &&  qData[index].question.q_video ? (
       

              

          <video ref={videoRef} autoPlay controls onEnded={handleVideoEnded}>
          <source src={fileUrl + qData[index].question.q_video} type="video/mp4"  /> 
          Your browser does not support the video tag.
          </video>
         
       ) : (
         ''
       )}


   
   
 
     </div>
      </div>
        
                    </div>
                </div>


            </div>

        </div>
    </div>
    
    </>
  )
}

export default ExamVideoSection