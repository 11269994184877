import { createSlice } from "@reduxjs/toolkit";
import { getRoles,  addNewRole,updateRole,  deleteRole } from './thunk';
export const initialState = {
    roles: [],
    error: {},
};

const RolesSlice = createSlice({
    name: 'RolesSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getRoles.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
            state.roles = action.payload.data;
            state.isRolesCreated = false;
            state.isRolesSuccess = true;
            }else {
                state.isRolesSuccess = false;
            }
        });

        builder.addCase(getRoles.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isRolesCreated = false;
            state.isRolesSuccess = false;
        });

        builder.addCase(addNewRole.fulfilled, (state, action) => {

            if(action.payload && action.payload.success){
                state.roles.push(action.payload.data);
                state.isRolesCreated = true;
            }else{
                state.error = action.payload.data || null;
                state.isRolesCreated = false;
            }

        });

        builder.addCase(addNewRole.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(updateRole.fulfilled, (state, action) => {
            if(action.payload && action.payload.success) {
            state.roles = state.roles.map(role =>
                role.id === action.payload.data.id
                    ? { ...role, ...action.payload.data }
                    : role
            );
                state.isRolesCreated = true;
            }else{
                state.error = action.payload.data || null;
                state.isRolesCreated = false;
            }
        });
        builder.addCase(updateRole.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(deleteRole.fulfilled, (state, action) => {
            state.roles = state.roles.filter(
                role => role.id.toString() !== action.payload.role.toString()
            );
        });
        builder.addCase(deleteRole.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


    }
});

export default RolesSlice.reducer;