import React, { useEffect, useMemo, useState } from 'react'
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import AuthorizeForAction from '../../../Routes/AuthorizeForAction';
import TableContainer from '../../../Components/Common/TableContainer';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { ToastContainer } from 'react-toastify';

import { getExamResults as onGetExamResult } from '../../../slices/result/thunk';
import MyLoader from '../../../common/MyLoader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import ImageComponent from '../../../common/ImageComponent';


function ResultDetail() {

  const dispatch = useDispatch();
  const [resultData, setResultData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t, i18n }  = useTranslation();
  const {attemptId,type} = useParams();





  const selectLayoutState = (state) => state.ResultReducer;

  const resultProperties = createSelector(
    selectLayoutState,
    (data) => ({
      resultList: data.resultList,
      isResultSuccess: data.isResultSuccess,
      error: data.error,
      isResultDetail:data.isResultDetail
  
    })
  );


  const { resultList, error, isResultSuccess,isResultDetail} = useSelector(resultProperties)


  useEffect(() => {
    i18n.changeLanguage('en');

  
    if (resultList && resultList.length > 0) {
      setResultData(resultList);
    } 
  }, [resultList]);


  useEffect(() => {  
setIsLoading(!isResultDetail);
  }, [isResultDetail]);




  useEffect(() => {
      const postedResultData = {
        attempt_id:attemptId,
        q_lang: 'en'
      };

      dispatch(onGetExamResult(postedResultData));
    
  }, [dispatch]);


  const resultTableColumns = useMemo(
    () => [

      {
        Header: ("Question"),
        accessor: "question",
        filterable: false,
      },
   
      {
        Header: "Chosen Answer",
        accessor: "choosed_ans",
        filterable: false,
        Cell: ({ row }) => (
          <ImageComponent
            choosedAns={row.original.choosed_ans}
            imagePath={row.original.choosedImage}
          />
        )
      },

      {
        Header: ("Status"),
        accessor: "q_status",
        Cell: (cell) => {

          var questionClass = "";
          
          if (cell.value == 'Correct') {
            questionClass = "text-uppercase text-green fw-bold";
          }
          if (cell.value == 'Wrong') {
            questionClass = " text-uppercase  text-danger fw-bold";
          }

          if (cell.value == '-') {
            questionClass = " text-uppercase text-warning fw-bold";
          }

          return (
            <span className={questionClass}>{cell.value}</span>

          );
        },
      },

      {
        Header: "Correct Answer",
        accessor: "correct_ans",
        filterable: false,
        Cell: ({ row }) => (
          <ImageComponent
            choosedAns={row.original.correct_ans}
            imagePath={row.original.correctImage}
          />
        )
      },

    ],

    []
  );

  document.title = "Results|Theory Practice";
  return (
    <React.Fragment>
      <div className="page-content" dir='ltr'>
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader className="border-0">
                 
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">Result Detail</h5>
                      </div>
                    </div>
                    <div className="col-sm-auto d-flex">
                      <div>
                      <div className="mt-3 mt-lg-0">
                                                    <div className="row g-3 mb-0 align-items-center">
                                                        <div className="col-auto">
                                                          {type==1?
                                                          <Link to='/admin/result'>  <Button type="button" color='success'>Back</Button></Link>
                                                          :
                                                          <Link to='/admin/practice-result'>  <Button type="button" color='success'>Back</Button></Link>
}
                                                        </div>
                                                    </div>
                                            </div>

                      </div>
                    </div>
                  </Row>


                </CardHeader>
                <div className="card-body pt-0">
                  <div>
                    <div>

                      {
                        isLoading ?
                          <MyLoader />
                          : 
                      <>
                      {resultData.length > 0 ? (
                        <TableContainer
                          columns={resultTableColumns}
                          data={(resultData || [])}
                          customPageSize={1000}
                          className="custom-header-css align-middle"

                        />
                      ) : (
                        <div className="py-4 text-center">
                          <div>
                            <lord-icon
                              src="https://cdn.lordicon.com/msoeawqm.json"
                              trigger="loop"
                              colors="primary:#405189,secondary:#0ab39c"
                              style={{ width: "72px", height: "72px" }}
                            ></lord-icon>
                          </div>

                          <div className="mt-4">
                            <h5>Sorry! No Record Found</h5>
                          </div>
                        </div>
                      )
                      }
                      </>
                    }
                    </div>


                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </div>

              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResultDetail