import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods

import {
  getAllResults as getAllResultsApi,
  getExamResult as getExamResultApi,
  getStudentResult as getStudentResultApi,
  getPracticeResults as getPracticeResultsApi,
  saveResultPdf as saveResultPdfApi,
  getSMSLogs as getSMSLogsApi,
  resendNotification as resendNotificationApi,
  markEligible as markEligibleApi,
  getAttemptHistory as getAttemptHistoryApi
} from "../../helpers/custom_helper/backend_helper";


//result detail
export const getExamResults = createAsyncThunk("get-exam-result", async (examPostedData,{dispatch}) => {
  try {
    dispatch(resetResultDetail())
    const response =await getExamResultApi(examPostedData);


    if(response.success){
      return response;
    }else{
    
      toast.error(response.message, { autoClose: 3000 });
    }
  
  } catch (error) {
    return error;
  }
});

export const getAllResults = createAsyncThunk("get-all-results", async (resultPostedData) => {
  try {
    const response = getAllResultsApi(resultPostedData);
    const data = await response;


    if(data.success)
    {
      return response;
    }else {
      return response;
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getStudentResult = createAsyncThunk("get-student-result", async (examId) => {
  try {
 
    const response =await getStudentResultApi(examId);


    if(response.success){
      return response;
    }else{
      toast.error('Result not found', { autoClose: 3000 });
    }
  
  } catch (error) {
    return error;
  }
});

export const getPracticeResults = createAsyncThunk("get-practice-result", async (postdData) => {
  try {
    const response = getPracticeResultsApi(postdData);
    const data = await response;

    if(data.success)
    {
      return response;
    }else {
   
      toast.error(data.message, { autoClose: 3000 });
      return response;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});


export const saveResultPdf = createAsyncThunk("save-result-pdf", async (postedData) => {
  try {

    const response = saveResultPdfApi(postedData);
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});



export const getSmsLogs = createAsyncThunk("get-sms-logs", async (resultPostedData) => {
  try {
    const response = getSMSLogsApi(resultPostedData);
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      return response;
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const resendNotification = createAsyncThunk("resend-notification", async (resultPostedData) => {
  try {
    const response = resendNotificationApi(resultPostedData);
    const data = await response;
   
    if(data.success)
    {
      toast.error(data.message, { autoClose: 3000 });
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return response;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});



export const markEligible = createAsyncThunk("mark-eligible", async (resultPostedData) => {
  try {
    const response = markEligibleApi(resultPostedData);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return response;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});


export const resetResultDetail =createAsyncThunk("reset-result-detail", async () => {
  try {
      return true;
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
  }
});

export const getAttemptHistory = createAsyncThunk("get-attempt-history", async (examPostedData,{dispatch}) => {
  try {
    dispatch(resetResultDetail())
    const response =await getAttemptHistoryApi(examPostedData);
    if(response.success){
      return response;
    }else{
    
      toast.error(response.message, { autoClose: 3000 });
    }
  
  } catch (error) {
    return error;
  }
});




