import { createSlice } from "@reduxjs/toolkit";
import { getUsers,  addNewUser,updateUser,  deleteUser } from './thunk';
import {toast} from "react-toastify";
export const initialState = {
    users: [],
    roles: [],
    branches: [],
    error: {},
};

const UsersSlice = createSlice({
    name: 'UsersSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getUsers.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.users = action.payload.data['users']['data'];
                state.roles = action.payload.data['roles']['data'];
                state.branches = action.payload.data['branches']['data'];
                state.isUserCreated = false;
                state.isUserSuccess = true;
            }else {
                state.isUserSuccess = false;
            }

        });

        builder.addCase(getUsers.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isUserCreated = false;
            state.isUserSuccess = false;
        });

        builder.addCase(addNewUser.fulfilled, (state, action) => {
            if(action.payload && action.payload.success)
            {
                state.users.push(action.payload.data);
                state.isUserCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isUserCreated = false;
            }
        });

        builder.addCase(addNewUser.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(updateUser.fulfilled, (state, action) => {
            if(action.payload && action.payload.success)
            {
                state.users = state.users.map(user =>
                    user.id === action.payload.data.id
                        ? { ...user, ...action.payload.data }
                        : user
                );
                state.isUserCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isUserCreated = false;
            }

        });
        builder.addCase(updateUser.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


        builder.addCase(deleteUser.fulfilled, (state, action) => {
            state.users = state.users.filter(
                user => user.id.toString() !== action.payload.user.toString()
            );
        });
        builder.addCase(deleteUser.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });


    }
});

export default UsersSlice.reducer;