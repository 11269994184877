import React, {useCallback, useEffect, useMemo, useState} from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import {toast, ToastContainer} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import { Navigate } from "react-router-dom";
import {
    importQuestionFile as onImportQuestionFile,
    getQuestionsBank as onGetQuestionsBank,
    deleteQuestion as onDeleteQuestion
} from "../../slices/questionBank/thunk";

import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";

const QuestionBank = () => {

    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [question, setQuestion] = useState([]);
    const [myquestion, setmyQuestion] = useState([]);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteModalMulti, setDeleteModalMulti] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isloading, setIsloading] = useState(false);

    const selectLayoutState = (state) => state.QuestionBank;
    const questionBankProperties = createSelector(
        selectLayoutState,
        (ecom) => ({
            questions: ecom.questions,
            isQuestionSuccess: ecom.isQuestionSuccess,
            isQuestionCreated: ecom.isQuestionCreated,
            error: ecom.error,
        })
    );
  
    const {
        questions,isQuestionSuccess, error,isQuestionCreated
    } = useSelector(questionBankProperties)

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);

        } else {
            setModal(true);
        }
    }, [modal]);

    useEffect(() => {
            dispatch(onGetQuestionsBank());
    }, [dispatch,isQuestionSuccess,isQuestionCreated]);

    useEffect(() => {
        setQuestion(questions);
        setLoading(!isQuestionSuccess);
        if(isQuestionCreated){
            setIsloading(false);
            validation.resetForm();
            setModal(false);
        }
        error? setIsloading(false):null
    }, [isQuestionCreated,error]);

    useEffect(() => {
        if (!isEmpty(questions)) {
            setQuestion(questions);
            setLoading(!isQuestionSuccess);
            setIsEdit(false);
        }
    }, [isQuestionSuccess,isQuestionCreated]);

    // validation
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            import_file:   null,
        },
        validationSchema: Yup.object({
            import_file: Yup.string().required("Please Select File"),
        }),
        onSubmit: (values) => {
            setIsloading(true);
            const newImportFile = {
                file: values["import_file"] ? values["import_file"]: "",
            }
                dispatch(onImportQuestionFile(newImportFile));

        },

    });

    const handleCustomerClick = useCallback((arg) => {
        const question = arg;
        setQuestion({
            id: question.id,

        });
        setIsEdit(true);
        toggle();
    }, [toggle]);

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".customerCheckBox");
        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);

    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".customerCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };

    const columns = useMemo(
        () => [
            // {
            //     Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
            //     Cell: (cellProps) => {
            //         return <input type="checkbox" className="customerCheckBox form-check-input" value={cellProps.row.original.id} onChange={() => deleteCheckbox()} />;
            //     },
            //     id: '#',
            // },
            {
                Header: "Code",
                accessor: "code",
                filterable: false,
            },
            {
                Header: "Questions",
                accessor: "question_detail.q_title",
                filterable: false,
            },
            {
                Header: "Correct Answer",
                Cell: (cellProps) => {
                    switch (cellProps.row.original.correct_opt) {
                        case "a":
                            return <span className=""> {cellProps.row.original.question_detail.opt_a} </span>;
                        case "b":
                            return <span className=""> {cellProps.row.original.question_detail.opt_b} </span>;
                        case "c":
                            return <span className=""> {cellProps.row.original.question_detail.opt_c} </span>;
                        default:
                            return <span className="badge text-uppercase bg-danger-subtle text-danger"> {'Not Found'} </span>;
                    }
                }
            },
            {
                Header: "Course",
                accessor: "q_courses",
                Cell: (cellProps) => {
                    return (
                        <div>
                            {cellProps.value? cellProps.value.map((item,key) => (
                             <div key={key}>
                                <span className="badge text-uppercase bg-info-subtle text-info">{item.course?item.course.short_name:"-"}</span>{" "}
                            </div>
                            )):""}
                        </div>
                    );
                },
            },
            {
                Header: "Topics",
                accessor: "topic.topic_area_translation[0].full_name",
                filterable: false,
            },
            {
                Header: "Type",
                accessor: "q_type",
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "status",
                Cell: (cell) => {
                    switch (cell.value) {
                        case 1:
                            return <span className="badge text-uppercase bg-success-subtle text-success"> {cell.value == 1?"Active":""} </span>;
                        case 2:
                            return <span className="badge text-uppercase bg-danger-subtle text-danger"> {cell.value == 2?"In-Active":""} </span>;
                        default:
                            return <span className="badge text-uppercase bg-info-subtle text-info"> {cell.value == 2?"In-Active":"Active"} </span>;
                    }
                }
            },
            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                            <AuthorizeForAction requiredPermissions={['q-edit']}>
                          <li className="list-inline-item" title="Edit">
                                <Link
                                    to={`/admin/create-question/${cellProps.row.original.id}`}
                                    className="text-primary d-inline-block create-item-btn"
                                >
                                    <i className="ri-pencil-fill fs-18"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['q-delete']}>
                            <li className="list-inline-item" title="Remove">
                                <Link
                                    to="#"
                                    className="text-danger d-inline-block remove-item-btn"
                                    onClick={() => { const customerData = cellProps.row.original; onClickDelete(customerData); }}
                                >
                                    <i className="ri-delete-bin-5-fill fs-16"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                            <AuthorizeForAction requiredPermissions={['q-translate']}>
                            <li className="list-inline-item" title="Translation">
                                <Link
                                   to={`/admin/question-translation/${cellProps.row.original.id}`}
                                    className="text-success d-inline-block translate-item-btn"
                                    >
                                    <i className="ri-translate fs-18"></i>
                                </Link>
                            </li>
                            </AuthorizeForAction>
                        </ul>
                    );
                },
            },

        ],
        [handleCustomerClick, checkedAll]
    );
    const onClickDelete = (questionmy) => {
        setmyQuestion(questionmy);
        setDeleteModal(true);
    };

    const deleteMultiple = () => {
        const checkall = document.getElementById("checkBoxAll");
        selectedCheckBoxDelete.forEach((element) => {
            dispatch(onDeleteQuestion(element.value));
            setTimeout(() => { toast.clearWaitingQueue(); }, 3000);
        });
        setIsMultiDeleteButton(false);
        checkall.checked = false;
    };
    const handleCustomerClicks = () => {
        setQuestion("");
        setIsEdit(false);
        toggle();
    };
    const handleDeleteCustomer = () => {
        if (myquestion) {
            dispatch(onDeleteQuestion(myquestion.id));
            setDeleteModal(false);
        }
    };

    document.title = "Question Bank | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteCustomer}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <DeleteModal
                    show={deleteModalMulti}
                    onDeleteClick={() => {
                        deleteMultiple();
                        setDeleteModalMulti(false);
                    }}
                    onCloseClick={() => setDeleteModalMulti(false)}
                />
                <Container fluid>
                    <BreadCrumb title="Question Bank" pageTitle="Dashboard" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Questions List</h5>
                                            </div>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div>
                                                <AuthorizeForAction requiredPermissions={['q-delete']}>
                                                {isMultiDeleteButton && <button className="btn btn-soft-danger me-1"
                                                                                onClick={() => setDeleteModalMulti(true)}
                                                ><i className="ri-delete-bin-2-line"></i></button>}
                                                </AuthorizeForAction>
                                                    <AuthorizeForAction requiredPermissions={['q-create']}>
                                                <Link
                                                    to={'/admin/create-question/0'}
                                                    type="button"
                                                    className="btn btn-success add-btn"
                                                    id="create-btn"
                                                    >
                                                    <i className="ri-add-line align-bottom me-1"></i> Add
                                                    Question
                                                </Link>{" "}
                                                <button
                                                    type="button"
                                                    className="btn btn-primary import-btn"
                                                    id="import-btn"
                                                    onClick={() => { setIsEdit(false); toggle(); }}>
                                                    <i className="ri-file-upload-line align-bottom me-1"></i> Import
                                                </button>{" "}
                                                    </AuthorizeForAction>
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0">
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                            {/*{toast.error(props.error, { position: "top-right", hideProgressBar: false, progress: undefined, toastId: "" })}*/}
                                        </React.Fragment>
                                        : <div>
                                        {isQuestionSuccess && questions.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(questions || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                handleCustomerClick={handleCustomerClicks}
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for questions, course, topic, type status or something...'
                                            />
                                        ) :(
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{ width: "72px", height: "72px" }}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>)}
                                    <Modal id="showModal" isOpen={modal} backdrop="static" toggle={toggle} centered={true}>
                                        <ModalHeader className="bg-light p-3" toggle={toggle}>
                                          <span>Import Questions</span>
                                        </ModalHeader>
                                        <Form className="tablelist-form" onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                            <ModalBody>
                                                <input type="hidden" id="id-field" />
                                                <div className="mb-3">
                                                    <Label
                                                        htmlFor="title-field"
                                                        className="form-label"
                                                        >
                                                       Select File to Import
                                                    </Label>
                                                    <Input
                                                        name="import_file"
                                                        id="name-field"
                                                        className="form-control"
                                                        type="file"
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        accept=".xlsx, .xls"
                                                        onChange={(event) => {
                                                            validation.setFieldValue('import_file', event.currentTarget.files[0]);
                                                        }}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.import_file && validation.errors.import_file ? true : false
                                                        }
                                                    />
                                                    {validation.touched.import_file && validation.errors.import_file ? (
                                                        <FormFeedback type="invalid">{validation.errors.import_file}</FormFeedback>
                                                    ) : null}
                                                </div>

                                            </ModalBody>
                                            <ModalFooter>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="button" className="btn btn-light" onClick={() => { setModal(false); validation.resetForm(); }}> Close </button>

                                                    <Button color="success" disabled={isloading ? true : false} className="btn-load" type="submit">
                                                      <span className="d-flex align-items-center">
                                                          {isloading ?  <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>: null}
                                                          <span className="flex-grow-1 ms-2">
                                                            Import Questions
                                                          </span>
                                                      </span>
                                                    </Button>
                                                </div>
                                            </ModalFooter>
                                        </Form>
                                    </Modal>
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default QuestionBank;