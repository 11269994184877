import React, {useState,useEffect} from "react";
import AccessDenied from "../pages/AuthenticationInner/Errors/Alt404";
const AuthorizeForAction = ({ requiredPermissions, children }) => {
    const [permData, setPermData] = useState([])

    useEffect(() => {
        const storedArray = window.localStorage.getItem('permissions')
        if (storedArray) {
            setPermData(JSON.parse(storedArray))
        }
    }, [])

    const permArray = permData.map(perm => perm.name);
    if (!requiredPermissions.every((permission) => permArray.includes(permission))) {
        // return <div className='alert alert-danger'>Error: Permission denied</div>
       return ""
    }

    return <>{children}</>;
};
export default AuthorizeForAction;