import React, {useCallback, useEffect, useMemo, useState} from "react";


import {

    Card,
    CardHeader,
    Col,
    Container,
    Row, Spinner
} from "reactstrap";


import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import {toast, ToastContainer} from "react-toastify";
import { getRunningExams as onGetRunningExams } from "../../../slices/examSchedule/thunk";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {isEmpty} from "lodash";
import { getDateWithFormated } from "../../utility/TimerUtility";
import ExamCountdown from "./ExamCountdown";

const RunningExams = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);



    const selectExamStates = (state) => state.ScheduleExamReducer;
    const examProperties = createSelector(
      selectExamStates,
        (ecom) => ({
          runningExamList: ecom.runningExamList,
          isExamSuccess: ecom.isExamSuccess,
         error: ecom.error,
        })
    );
  
    const { runningExamList,isExamSuccess } = useSelector(examProperties)
     
    useEffect(() => {
      const authUser = JSON.parse(window.localStorage.getItem('user'));
      handleGetScheduleExamList(authUser.user_id,getDateWithFormated(new Date()),getDateWithFormated(new Date()));
        }, [dispatch,isExamSuccess]);

        useEffect(() => {
 
          const intervalId = setInterval(() => {
            const authUser = JSON.parse(window.localStorage.getItem('user'));
            handleGetScheduleExamList(authUser.user_id,getDateWithFormated(new Date()),getDateWithFormated(new Date()));
          }, 5000);
      

          return () => {
            clearInterval(intervalId);
          };
        }, []); 

      

        const handleGetScheduleExamList = (invgId,fromDate,toDate)=>{
          if(invgId!==undefined){
            const examPostedData = {
              invgId:invgId,
              start_date:fromDate,
              end_date:toDate
            };
          
            dispatch(onGetRunningExams(examPostedData));
          }
          }
          


    useEffect(() => {
     setLoading(!isExamSuccess);
      
    }, [isExamSuccess]);


    const columns = useMemo(
        () => [
            {
                Header: "Traffic ID",
                accessor: "traffic_id",
                filterable: false,
            },
            {
                Header: "Student Name",
                accessor: "std_name",
                filterable: false,
            },

            {
              Header: "Type",
              accessor: "exam_type",
              filterable: false,
          },
          {
            Header: "invigilator",
            accessor: "invigilator",
            filterable: false,
        },
        
            {
                Header: "Held On",
                accessor: "created_at",
                filterable: false,
            },
            {
                Header: "System",
                accessor: "system",
                filterable: false,
            },
            {
              Header: "Status",
              accessor: "exam_status",
              Cell: (cell) => {
      
                if (cell.value == 1) {
                  return (
                    <span className="badge text-uppercase bg-success-subtle text-success">Initlized</span>
                  );
                }
                if (cell.value == 2) {
                  return (
                    <span className="badge text-uppercase bg-danger-subtle text-danger">InProgress</span>
                  );
                }
                if (cell.value == 3) {
                  return (
                    <span className="badge text-uppercase bg-info-subtle text-info">Completed</span>
                  );
                }
      
                if (cell.value == 4) {
                  return (
                    <span className="badge text-uppercase bg-info-subtle text-info">Closed</span>
                  );
                }
                if (cell.value == 5) {
                  return (
                    <span className="badge text-uppercase bg-info-subtle text-info">Hold</span>
                  );
                }
      
              },
            },

       

          {
            Header: "Time Left",
            accessor: (rowData) => {
      
              if (rowData.exam_status==2) {
                return <ExamCountdown targetDate={rowData.exam_start_from} />;
              } else {
                return '-'; 
              }
            },
            filterable: false,
          },
          
     
          
 

        ],
        []
    );

    document.title = "Running Exams | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Running Exams" pageTitle="Dashboard" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Running Exams </h5>
                                            </div>
                                        </div>

                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0">
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        : <div>
                                        {isExamSuccess && runningExamList.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(runningExamList || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for student, traffic id ...'
                                            />
                                        ) :(
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{ width: "72px", height: "72px" }}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>)}
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default RunningExams;