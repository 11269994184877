import React, {useCallback, useEffect, useMemo, useState} from "react";
import ExportCSVModal from "../../Components/Common/ExportCSVModal";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
    Button,
    Card,
    CardHeader,
    Col,
    Container,
    Form,
    FormFeedback,
    Input,
    Label,
    Modal,
    ModalBody, ModalFooter,
    ModalHeader,
    Row, Spinner
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TableContainer from "../../Components/Common/TableContainer";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import {toast, ToastContainer} from "react-toastify";
import {useFormik} from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import {
    getSmsLogs as onGetSmsLogs,
    resendNotification as onResendNotification
} from "../../slices/result/thunk";

import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {Link} from "react-router-dom";
import {isEmpty} from "lodash";
import AuthorizeForAction from "../../Routes/AuthorizeForAction";
import { changeDateFormate } from "../utility/TimerUtility";

const SmsLogs = () => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [logsData, setLogData] = useState([]);



    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const startDate = changeDateFormate(sevenDaysAgo);
    const  endDate= changeDateFormate(new Date());


    const [selectedDates, setSelectedDates] = useState([startDate,endDate]);
    const [isLoadingFilter,setIsLoadingFilter]=useState(false);

    let navigate = useNavigate();


    const selectLayoutState = (state) => state.ResultReducer;
    const questionBankProperties = createSelector(
        selectLayoutState,
        (ecom) => ({
            logsList: ecom.logsList,
            isLoading:ecom.isLoading
        })
    );
  
    const {logsList,isLoading} = useSelector(questionBankProperties)
       

    useEffect(() => {
        handleGetResultList(selectedDates[0],selectedDates[1]);
    }, [dispatch]);


    const handleGetResultList = (fromDate,toDate)=>{

          const resultPostedData = {
            start_date:fromDate,
            end_date:toDate
          }
        
          dispatch(onGetSmsLogs(resultPostedData));
        }

          
        

    useEffect(() => {

      setLoading(isLoading);
    }, [isLoading]);

    useEffect(() => {
        
        if (!isEmpty(logsList)) {
            setLogData(logsList);
        }
     
    }, [logsList]);


    const columns = useMemo(
        () => [
            {
                Header: "Traffic ID",
                accessor: "traffic_id",
                filterable: false,
            },
            {
                Header: "Student Name",
                accessor: "std_name",
                filterable: false,
            },

               
    
            {
                Header: "Held On",
                accessor: "created_at",
                filterable: false,
            },
            {
                Header: "Content",
                accessor: "content",
                filterable: false,
            },
            {
                Header: "Type",
                accessor: "type",
                filterable: false,
            },
          

            {
                Header: "Send",
                accessor: "is_send_sms",
                Cell: (cell) => {
                    if(cell.row.original.isSend=='Yes'){
                        return <span className=" text-uppercase  text-green fw-bold">Yes </span>;
                    }else{
                        return <span className=" text-uppercase  text-danger fw-bold">No </span>;
                    }
                }
            },

            {
                Header: "Action",
                Cell: (cellProps) => {
                    return (
                        <ul className="list-inline hstack gap-2 mb-0">
                          <li className="list-inline-item" title="View Result">
                            <button className="btn btn-primary" onClick={()=>handleResendNotification(cellProps.row.original.traffic_id,cellProps.row.original.exam_id,cellProps.row.original.notificationType)}>Resend</button>
                            </li>
                        </ul>
                    );
                },
            },

        ],
        []
    );

    
const handleDateChange = (selectedDates) => {
    setSelectedDates(selectedDates);
 };

 
const filterExamResult= () =>{

    console.log('selectedDates[0]',changeDateFormate(selectedDates[0]));
      setIsLoadingFilter(true)
      if(selectedDates[0] &&  selectedDates[1]){
        handleGetResultList(changeDateFormate(selectedDates[0]),changeDateFormate(selectedDates[1]));
      }else{
        toast.error('Invalid date', { autoClose: 3000 });
      }
      setIsLoadingFilter(false)
  
  }


  
  const handleResendNotification = (trafficId,examId,nofificationType)=>{
    const resultPostedData = {
        exam_id:examId,
        traffic_id:trafficId,
        type:nofificationType
    }
  
     dispatch(onResendNotification(resultPostedData));
  }


    document.title = "Sms/Email Logs | Theory Practice";
    return (
        <React.Fragment>
            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="Sms/Email Logs" pageTitle="Dashboard" />
                    <Row>
                        <Col lg={12}>
                            <Card id="customerList">
                                <CardHeader className="border-0">
                                    <Row className="g-4 align-items-center">
                                        <div className="col-sm">
                                            <div>
                                                <h5 className="card-title mb-0">Sms/Email Logs</h5>
                                            </div>
                                        </div>

                                        <div className="col-sm-auto d-flex">

<div>

<div className="mt-3 mt-lg-0">

                              <div className="row g-3 mb-0 align-items-center">
                                  <div className="col-sm-auto">
                                      <div className="input-group">

                  <Flatpickr
                          className="form-control border-0 dash-filter-picker shadow"
                          options={{
                              mode: "range",
                              dateFormat: "d M, Y",
                              defaultDate: [startDate,endDate],
                              onChange:handleDateChange

                          }}

                      />
                      <div className="input-group-text bg-primary border-primary text-white"><i className="ri-calendar-2-line"></i></div>


                                      </div>
                                  </div>

                                  <div className="col-auto">
                                      <Button type="button" color='success' onClick={filterExamResult}>    {isLoadingFilter ? <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner> : null}<i className="ri-search-line align-middle me-1"></i>Filter</Button>
                                  </div>
                              </div>

                      </div>



</div>
</div>

                                    </Row>
                                </CardHeader>
                                <div className="card-body pt-0"> 
                                    {(loading ?
                                        <React.Fragment>
                                            <div className="d-flex justify-content-center mx-2 mt-2">
                                                <Spinner color="primary"> Loading... </Spinner>
                                            </div>
                                        </React.Fragment>
                                        : <div>
                                        {!isLoading && logsList.length ? (
                                            <TableContainer
                                                columns={columns}
                                                data={(logsList || [])}
                                                isGlobalFilter={true}
                                                isAddUserList={false}
                                                customPageSize={10}
                                                className="custom-header-css"
                                                isCustomerFilter={false}
                                                SearchPlaceholder='Search for student, traffic id, course, type status ...'
                                            />
                                        ) :(
                                            <div className="py-4 text-center">
                                                <div>
                                                    <lord-icon
                                                        src="https://cdn.lordicon.com/msoeawqm.json"
                                                        trigger="loop"
                                                        colors="primary:#405189,secondary:#0ab39c"
                                                        style={{ width: "72px", height: "72px" }}
                                                    ></lord-icon>
                                                </div>

                                                <div className="mt-4">
                                                    <h5>Sorry! No Record Found</h5>
                                                </div>
                                            </div>
                                        )}
                                    </div>)}
                                    <ToastContainer closeButton={false} limit={1} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
export default SmsLogs;