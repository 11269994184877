import { createSlice } from "@reduxjs/toolkit";
import { getQuestionsBank,  importQuestionFile,deleteQuestion} from './thunk';

export const initialState = {
    questions: [],
    error: {},
};

const QuestionBankSlice = createSlice({
    name: 'QuestionBankSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getQuestionsBank.fulfilled, (state, action) => {
           if(action.payload && action.payload.success){
               state.questions = action.payload.data ?action.payload.data.data:"";
               state.isQuestionCreated = false;
               state.isQuestionSuccess = true;
           }else {
               state.isQuestionSuccess = false;
           }

        });

        builder.addCase(getQuestionsBank.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isQuestionCreated = false;
            state.isQuestionSuccess = false;
        });

        builder.addCase(importQuestionFile.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                state.isQuestionCreated = true;
            }else {
                state.error = action.payload.data || null;
                state.isQuestionCreated = false;
            }
        });

        builder.addCase(importQuestionFile.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isQuestionCreated = false;
        });


        builder.addCase(deleteQuestion.fulfilled, (state, action) => {
            state.questions = state.questions.filter(
                question => question.id.toString() !== action.payload.question.toString()
            );
        });
        builder.addCase(deleteQuestion.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });

    }
});

export default QuestionBankSlice.reducer;