import React, {useEffect, useState} from 'react';
import { Col, Container, Row } from 'reactstrap';

const Footer = () => {
    const [storedData, setStoredData] = useState([]);
    useEffect(() => {
        const dataFromLocalStorage = localStorage.getItem('apiData');
        setStoredData(dataFromLocalStorage ? JSON.parse(dataFromLocalStorage) : null);
    },[]);

    return (
        <React.Fragment>
            <footer className="footer">
                <Container fluid>
                    <Row>
                        <Col sm={6}>
                            {new Date().getFullYear()} © {storedData && storedData.title}.
                        </Col>
                        <Col sm={6}>
                            <div className="text-sm-end d-none d-sm-block">
                                Design & Develop by MAIT
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </React.Fragment>
    );
};

export default Footer;