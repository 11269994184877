import { createSlice } from "@reduxjs/toolkit";
import {   addNewConfig,getAppConfig   } from './thunk';
export const initialState = {

    languages: [],
    appconfigs: [],
    error: {},
};

const AppConfig = createSlice({
    name: 'AppConfigSlice',
    initialState,
    reducer: {},
    extraReducers: (builder) => {


        builder.addCase(getAppConfig.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
            state.appconfigs = action.payload.data;
            state.isAppConfigCreated = false;
            state.isAppConfigSuccess = true;
            }else {
                state.isAppConfigSuccess = false;
            }
        });

        builder.addCase(getAppConfig.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isAppConfigCreated = false;
            state.isAppConfigSuccess = false;
        });

        builder.addCase(addNewConfig.fulfilled, (state, action) => {
            if(action.payload && action.payload.success){
                if(state.appconfigs && state.appconfigs.id)
                {
                    state.appconfigs =
                        state.appconfigs.id === action.payload.data.id
                            ? { ...state.appconfigs, ...action.payload.data }
                            : state.appconfigs
                }else {
                    state.appconfigs = action.payload.data
                }

                    state.isAppConfigCreated = true;
            }else{
                state.error = action.payload.data || null;
                state.isAppConfigCreated = false;
            }
        });

        builder.addCase(addNewConfig.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.error = 'true';
            state.isAppConfigCreated = false;
        });



    }
});

export default AppConfig.reducer;