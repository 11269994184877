import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

// Dashboard Ecommerce
import DashboardEcommerceReducer from "./dashboardEcommerce/reducer";

// Custom Reducer

import LanguageReducer from "./language/reducer";

import RoleReducer from "./roles/reducer";

import AppConfigReducer from "./appConfig/reducer";

import RolePermissionReducer from "./rolePermissions/reducer";

import UserReducer from "./user/reducer";

import CourseReducer from "./course/reducer";

import CourseConfigReducer from "./courseConfig/reducer";

import TopicAreaReducer from "./topicArea/reducer";

import CreateQuestionReducer from "./createQuestion/reducer";
import QuestionBankReducer from "./questionBank/reducer";
import TranslateQuestionReducer from "./translateQuestion/reducer";
import BranchReducer from "./branch/reducer";
import RoomReducer from "./room/reducer";
import SystemReducer from "./system/reducer";
import ExamReducer from "./exam/reducer";
import ResultReducer from "./result/reducer";
import  ScheduleExamReducer  from "./examSchedule/reducer";
import TestDataReducer from "./test/reducer";




const rootReducer = combineReducers({
  Layout: LayoutReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,

  Language: LanguageReducer,
  Role: RoleReducer,
  AppConfig: AppConfigReducer,
  RolePermission: RolePermissionReducer,
  User: UserReducer,
  Course: CourseReducer,
  CourseConfig: CourseConfigReducer,
  TopicArea: TopicAreaReducer,
  CreateQuestion: CreateQuestionReducer,
  QuestionBank: QuestionBankReducer,
  TranslateQuestion: TranslateQuestionReducer,
  Branch: BranchReducer,
  Room: RoomReducer,
  System: SystemReducer,
  ExamReducer: ExamReducer,
  ResultReducer: ResultReducer,
  DashboardEcommerce: DashboardEcommerceReducer,
  ScheduleExamReducer: ScheduleExamReducer,
  TestDataReducer:TestDataReducer,
});

export default rootReducer;
