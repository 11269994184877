import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Include Both Helper File with needed methods
import {

  getAllModulePermissions as getAllModulePermissionsaApi,

  addRolePermissions as addRolePermissionsApi, getLanguages as getLanguagesApi,


} from "../../helpers/custom_helper/backend_helper";



export const addNewRolePermissions = createAsyncThunk("save-permissions", async (role) => {
  try {
    const response = addRolePermissionsApi(role);
    const data = await response;
    if(data.success)
    {
      toast.success(data.message, { autoClose: 3000 });
      return data;
    }else {
      toast.error(data.message, { autoClose: 3000 });
      return data;
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }
});

export const getAllModulePermissions = createAsyncThunk("get-all-permissions", async (roleId) => {
  try {
    const response = getAllModulePermissionsaApi(roleId.id);
    const data = await response;
    if(data.success)
    {
      return response;
    }else {
      toast.error(data.message, { autoClose: 3000 });
    }
  } catch (error) {
    toast.error(error, { autoClose: 3000 });
    return error;
  }

});
